import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { EditorState, convertToRaw } from "draft-js";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import Ticketsystem2 from "./Ticketsystem2.web";
import React from "react";
export const ChatWidget = require("../../../components/src/HappyFoxChat.js")
import { getStorageData } from "../../../framework/src/Utilities";

export interface Account {
  data: AccountData
}

export interface AccountData {
  id: string
  type: string
  attributes: AccountAttributes
}

export interface AccountAttributes {
  first_name: string
  last_name: string
  full_phone_number: string
  country_code: number
  phone_number: number
  email: string
  activated: boolean
  country: string
  city: string
  qb_id: number
}

export interface IDItem {
  label: string,
  value: string,
}

export interface SubCategoryCategorySelection {
  [key: string]: number | string | null;
}

export interface SubCategoryRoot {
  category_id: number
  custom_fields: SubCategoryCustomField[]
}

export interface SubCategoryCustomField {
  name: string
  depends_on_choice: boolean | null
  required: boolean
  compulsory_on_completed: boolean
  choices: SubCategoryChoice[]
  compulsory_on_move: boolean
  type: string
  id: number
  categories: SubCategoryCategory[]
  visible_to_staff_only: boolean
}

export interface SubCategoryChoice {
  text: string
  id: number
  dependant_fields: string[]
}

export interface SubCategoryCategory {
  category: number
  order: number
}

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  txtInputValue: string;

  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start

  ticketsPageInfo: any,
  emailState: string,
  ticketConcerns: any;
  recordsPartnerSchool: any;
  ticketListdataone: any,
  allListData: any,

  rowsPerPage: any;
  prevDisable: boolean;
  nextDisable: boolean;
  pageCount: number;
  statusColor: any;
  isloadingset: any;
  dropdownState: boolean;
  dropdownValue: string;
  arrowState: boolean;
  arrowPrevious: boolean;
  ticketErrors: any;
  ticketConsent: boolean;
  ticketCategory: any;
  ticketSubject: string;
  customCategorisData: any;
  academicConcern: any;
  editorState: EditorState;
  academicProgram: any;
  typeOfDocRecords: any,
  ticketNameOfStudent: string;
  hubRecords: any;
  partnerSchoolRecords: any;

  schoolYearRecords: any;
  ticketAddCC: string;
  ticketAddBCC: string;
  updateOpenOne: boolean;
  hubAutoCompleteValue: any;
  typeOfDocAutoCompleteValue: any;
  recordsoncerns: any;
  partnerSchoolAutoCompleteValue: any;
  recordsHGPHHubs: any;
  recordsSchoolYear: any;
  ticketProgram: any;
  schoolYearAutoCompleteValue: any;
  academicProgramAutoCompleteValue: any;
  academicConcernAutoCompleteValue: any;

  ticketCategoryDataSelect: any;
  academicProgramData: any;
  openCC: any;
  openBCC: any;
  ticketFile: any;
  isError: any;
  createTicketfromuser: any;
  phoneNumber: any;
  ticketEmail: string;
  ticketPhoneNumber: string;
  open: boolean
  openChat: boolean
  subCategory: SubCategoryCustomField[]
  slectedSubCategory: SubCategoryCategorySelection[]
  subCategoryErrors: (string | number | null)[]
  AccountCountryCode: number
  createTicketLoader: boolean
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class Ticketsystem2Controller extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getTicketdataId: string = "";
  apiCreateTicketCallId: string = "";
  getTicketCategorydataId: string = "";
  apiRequestCallIds: any = ""
  childRef: React.RefObject<Ticketsystem2> = React.createRef()
  apiGetSubCategory: string = '';
  GetAccountSettingDatadataId: string = ''
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),

      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      allListData: [],
      pageCount: 0,
      rowsPerPage: 10,
      prevDisable: true,

      isloadingset: true,
      nextDisable: true,
      recordsPartnerSchool: [],
      ticketConcerns: [],
      statusColor: { "completed": "#99CB51", "pending": "#EAC543", "Pending Action required": "#F4A760" },
      dropdownState: false,
      dropdownValue: "",
      ticketListdataone: [],
      ticketsPageInfo: {},
      arrowState: false,
      arrowPrevious: false,
      ticketErrors: {},
      updateOpenOne: false,
      ticketConsent: false,
      openCC: false,
      openBCC: false,
      customCategorisData: [],
      academicConcern: { id: "", text: "choose" },
      editorState: EditorState.createEmpty(),
      partnerSchoolAutoCompleteValue: "",
      ticketCategory: { id: "", name: "choose" },
      ticketSubject: "",
      academicProgram: { id: "", text: "choose" },
      ticketNameOfStudent: '',
      typeOfDocRecords: { id: "", text: "choose" },
      hubRecords: { id: "", text: "choose" },
      schoolYearRecords: { id: "", text: "choose" },
      partnerSchoolRecords: { id: "", text: "choose" },
      ticketAddCC: "",
      ticketAddBCC: "",
      typeOfDocAutoCompleteValue: "",
      recordsoncerns: [],
      recordsSchoolYear: [],
      hubAutoCompleteValue: "",
      recordsHGPHHubs: [],
      schoolYearAutoCompleteValue: "",
      academicProgramAutoCompleteValue: "",
      academicConcernAutoCompleteValue: "",
      ticketCategoryDataSelect: [],
      ticketFile: "",
      academicProgramData: [],
      ticketProgram: [],
      isError: "",
      createTicketfromuser: {},
      phoneNumber: localStorage.getItem("phoneNumber") || '',
      emailState: localStorage.getItem('emailState') || '',

      ticketEmail: "",
      ticketPhoneNumber: "",
      open: false,
      openChat: false,
      subCategory: [],
      slectedSubCategory: [],
      subCategoryErrors: [],
      AccountCountryCode: 0,
      createTicketLoader: false
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

      this.showAlert(
        "Change Value",
        "From: " + this.state.txtSavedValue + " To: " + value
      );

      this.setState({ txtSavedValue: value });
    }

    // Customizable Area Start

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {

      const apiRequestCallIds = message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
      let response: any = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      let errorReponse: any = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      
      switch (apiRequestCallIds) {
        case this.apiGetSubCategory:
          this.setSubCategorydata(response)
          break;
        case this.getTicketCategorydataId:
          this.setAllticketCategoryListdata(response, errorReponse);
          break;
        case this.apiCreateTicketCallId:
          this.setApiCreateTicketCallId(response, errorReponse);
          break;
        case this.getTicketdataId:
          this.setAllticketListApi(response, errorReponse);
          break;
        case this.GetAccountSettingDatadataId:
          this.setAccontData(response);
          break;
        default: 
          break;
      }

    }


    // Customizable Area End
  }

  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false,
  };

  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address",
  };

  txtInputProps = this.isPlatformWeb()
    ? this.txtInputWebProps
    : this.txtInputMobileProps;

  btnShowHideProps = {
    onPress: () => {
      this.setState({ enableField: !this.state.enableField });
      this.txtInputProps.secureTextEntry = !this.state.enableField;
      this.btnShowHideImageProps.source = this.txtInputProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    },
  };

  btnShowHideImageProps = {
    source: this.txtInputProps.secureTextEntry
      ? imgPasswordVisible
      : imgPasswordInVisible,
  };

  btnExampleProps = {
    onPress: () => this.doButtonPressed(),
  };

  doButtonPressed() {
    let msg = new Message(getName(MessageEnum.AccoutLoginSuccess));
    msg.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.txtInputValue
    );
    this.send(msg);
  }

  // web events
  setInputValue = (text: string) => {
    this.setState({ txtInputValue: text });
  };

  setEnableField = () => {
    this.setState({ enableField: !this.state.enableField });
  };

  // Customizable Area Start

  createTicket = () => {
    this.setState({ createTicketLoader: true })
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      'token': localStorage.getItem('authToken')
    };
     const name = localStorage.getItem("username")
     const email = localStorage.getItem("emailState")
    const httpBody = {

      ticket: {
        "name": name,
        "email": email,
        "subject": this.state.ticketSubject,
        "text": convertToRaw(this.state.editorState.getCurrentContent()).blocks[0].text,
        "category_id":  this.state.ticketCategory.id,
        "category": "48",
        "t-cf-13": "Krupali",
        "t-cf-37": "432"
      }
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiCreateTicketCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.apiCreateTicketCallUrl
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.exampleAPiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }


  ticketCategoryDataSelects = () => {
    const header = {
      'Content-Type': 'application/json',
      'token': localStorage.getItem('authToken')
    };
   
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getTicketCategorydataId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.ticketsAllCategoryEndpoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  };
  ticketAllDetails = () => {
    const newPagination = this.state.pageCount

    const header = {
      'Content-Type': 'application/json',
      'token': localStorage.getItem('authToken')
    };
  
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getTicketdataId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.allTicketsUrl + '?page=' + newPagination
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  };
   async componentDidMount() {
    super.componentDidMount();
    this.ticketAllDetails()
    this.ticketCategoryDataSelects()
    this.accountSettinGetDataApiFunc()
    ChatWidget.loadChatWidget();
  }


  handleTicketNavigation = () => {
    this.props.navigation.navigate('CreateTickets')
  }
  handleDropdownClick = () => {
    this.setState((prevState) => ({
      dropdownState: !prevState.dropdownState,
    }));
  };
  handleDropdownItemClick = (value: string, valueToDisplay: string) => {
    this.handleSetDropdownValue(valueToDisplay);
    if (value === "All Tickets") {
      this.setState({ ticketListdataone: this.state.allListData })
    } else {
      const completeData = this.state.allListData.filter((item: any) => item.status.behavior === value);
      this.setState({ ticketListdataone: completeData })
    }

  };
  handleSetDropdownValue = (value: any) => {
    this.setState({
      dropdownValue: value,
      dropdownState: !this.state.dropdownState,
    });
  };
  handlePreviousDisable = () => {
    this.setState({ arrowPrevious: true })
    return this.state.ticketsPageInfo.start_index === 1 || this.state.ticketsPageInfo.start_index === 0;
  }
  handlePrevious = () => {
    if (this.state.pageCount > 1) {
      this.setState(
        {
          pageCount: this.state.pageCount - 1,
        },
        () => {
          this.ticketAllDetails();
        }
      );
    } else {
      this.setState({ prevDisable: true });
    }
  };
  handleNext = () => {
    const { ticketsPageInfo } = this.state;
    if (this.state.pageCount < ticketsPageInfo.last_index) {
      this.updatePageCount(this.state.pageCount + 1);
    } else {
      this.setState({ nextDisable: true });
    }
  };

  updatePageCount = (newPageCount: number) => {
    this.setState(
      {
        pageCount: newPageCount,
      },
      () => {
        this.ticketAllDetails();
      }
    );
  };


  handleNextDisable = () => {
    this.setState({ arrowState: true })
    return this.state.ticketsPageInfo.end_index === this.state.ticketsPageInfo.last_index
  }

  setAllticketListApi = (response: any, errorReponse: any) => {

    if (response) {
    
      this.setState({
        ticketsPageInfo: response?.page_info,
        ticketListdataone: response?.data,
        allListData: response?.data,
        isloadingset: false,

      })
      
    }
    else {
      this.parseApiCatchErrorResponse(errorReponse);
    }

  }

  setAccontData = (response: Account) => {
    if (response.data) {
      this.setState({
        AccountCountryCode: response.data.attributes.country_code,
      })
    }
  }

  setAllticketCategoryListdata = (response: any, errorReponse: any) => {

    if (response) {

      this.setState({
        ticketCategoryDataSelect: response,


      })
    

    }
    else {
      this.parseApiCatchErrorResponse(errorReponse);
    }

  }
  setApiCreateTicketCallId = (response: any, errorReponse: any) => {

    if (response) {
      this.setState({ updateOpenOne: true, createTicketLoader: false }, () => {
        setTimeout(()=>{
          this.props.navigation.navigate('TicketStatusInfo');
        },10000)
     
    
      })


    }
   

  }







  handlerChangeticketConsent = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ ticketConsent: e.target.checked })
    this.state.ticketErrors['ticketConsent'] = "";
  }

  setSubCategorydata = (responseJson: SubCategoryRoot) => {
    if (responseJson && responseJson.category_id) {
      this.setState({
        subCategory: responseJson.custom_fields
      },()=>{
        this.setSubcategoryBasicSelect()
      })
    }
  }

  setSubcategoryBasicSelect = () => {
    const documents:SubCategoryCategorySelection[] = [];

    this.state.subCategory.forEach(entry => {
      documents.push({
        id: entry.id,
        name: 'choose'
      });
    });

    this.setState({slectedSubCategory: documents})
  }

  handleCancelTicket = () => {
    this.props.navigation.navigate('TicketStatusInfo')
    this.setState({
      editorState: EditorState.createEmpty(),
      ticketCategory: "choose",
 
      ticketErrors: {},
    })
  }

  handlerChangeticketNameOfStudent = (e: React.ChangeEvent<HTMLInputElement>) => this.setState({ ticketNameOfStudent: e.target.value })
  handleChangePartnerSchoolAutoCompleteValue = (e: any, newValue: any) => this.setState({ partnerSchoolAutoCompleteValue: newValue })



  hideError = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.state.ticketErrors[event.target.name] = '';
    this.setState({ ticketErrors: this.state.ticketErrors })
    
  }




  handlerChangeHubRecords = (e: React.ChangeEvent<HTMLInputElement>, newInputValue: any) => {
    this.state.ticketErrors['hubRecords'] = "";
    this.setState({
      hubRecords: {
        ...this.state.hubRecords,
        text: newInputValue,
        id: this.state.recordsHGPHHubs.find((o: any) => o.text == newInputValue)?.id
      }
    })
  }



  ticketValidate = () => {
    let isFormValid = true;
    const regexName = /^[a-zA-Z.][a-zA-Z. ]*$/;
    const regexEmail = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;

    if (this.state.ticketCategory.name === 'choose') {
      this.state.ticketErrors['ticketCategory'] = "Required"
      isFormValid = false
    }
    if (this.state.ticketSubject === '') {
      this.state.ticketErrors['ticketSubject'] = "Required"
      isFormValid = false
    }
    if (convertToRaw(this.state.editorState.getCurrentContent()).blocks[0].text == "") {
      this.state.ticketErrors['editorState'] = "Required"
      isFormValid = false
    }
    if (!regexEmail.test(this.state.emailState)) {
      this.state.ticketErrors['emailState'] = "Invalid Email";
      isFormValid = false;
    }
    if (this.state.phoneNumber === "") {
      this.state.ticketErrors['phoneNumber'] = "Required"
      isFormValid = false
    }
    if (this.state.ticketConsent === false) {
      this.state.ticketErrors['ticketConsent'] = "Required"
      isFormValid = false
    }

    const errors = this.state.slectedSubCategory
      .filter(item => item.name === 'choose')
      .map(item => item.id);
      this.setState({ subCategoryErrors:errors });
      
      if (errors.length > 0){
      this.state.ticketErrors['subCategory'] = "Required"
      isFormValid = false
    } else {
      this.state.ticketErrors['subCategory'] = ""
    }

    return isFormValid
  }
  handleCreateTicket = () => {
    if (this.ticketValidate()) {
      this.createTicket()
    }
    else {

      this.setState({
        ticketErrors: this.state.ticketErrors
      })
    
    }
  }
  handleGetsOptionLabel = (option: any) => {
    return (option.text || "")
  }
  handlerChangeTypeOfDocRecords = (e: React.ChangeEvent<HTMLInputElement>, newInputValue: any) => {
    this.state.ticketErrors['typeOfDocRecords'] = "";
    this.setState({
      typeOfDocRecords: {
        ...this.state.typeOfDocRecords,
        text: newInputValue,
        id: this.state.recordsoncerns.find((o: any) => o.text == newInputValue)?.id
      }
    })
  }
  handlerChangeSchoolYearRecords = (e: React.ChangeEvent<HTMLInputElement>, newInputValue: any) => {
    this.state.ticketErrors['schoolYearRecords'] = "";
    this.setState({
      schoolYearRecords: {
        ...this.state.schoolYearRecords,
        text: newInputValue,
        id: this.state.recordsSchoolYear.find((o: any) => o.text == newInputValue)?.id
      }
    })
  }
  handleChangeTicketCategory = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.state.ticketErrors['ticketCategory'] = "";
    const selectedCategory = this.state.ticketCategoryDataSelect.find((o: any) => o.name === e.target.value);
    this.setState({
      ticketCategory: {
        ...this.state.ticketCategory,
        name: e.target.value,
        id: selectedCategory?.id
      }

    }, () => {
      this.getSubCategory()
    })

  }
  onEditorStateChange = (editorState: EditorState) => {
    console.log("editorState",editorState)
    this.setState({
      editorState
    });
    this.state.ticketErrors['editorState'] = ""
   
  };

  handlerChangeacademicConcern = (e: React.ChangeEvent<HTMLInputElement>, newInputValue: any) => {
    this.state.ticketErrors['academicConcern'] = "";
    this.setState({
      academicConcern: {
        ...this.state.academicConcern,
        text: newInputValue,
        id: this.state.ticketConcerns.find((o: any) => o.text == newInputValue)?.id
      }
    })
  }
  handlerChangePartnerSchoolRecords = (e: React.ChangeEvent<HTMLInputElement>, newInputValue: any) => {
    this.state.ticketErrors['partnerSchoolRecords'] = "";
    this.setState({
      partnerSchoolRecords: {
        ...this.state.partnerSchoolRecords,
        text: newInputValue,
        id: this.state.recordsPartnerSchool.find((o: any) => o.text == newInputValue)?.id
      }
    })
  }
  handlerChangeacademicProgram = (e: React.ChangeEvent<HTMLInputElement>, newInputValue: any) => {
    this.state.ticketErrors['academicProgram'] = "";
    this.setState({
      academicProgram: {
        ...this.state.academicProgram,
        text: newInputValue,
        id: this.state.academicProgramData.find((o: any) => o.text == newInputValue)?.id
      }
    })
  }
  uploadFiles = (file: any, purpose: any) => {
    if (purpose === 'ticketFile') {
      this.setState({ ticketFile: { filenm: file } })
    }
  }


  handleAutocompleteChange = (event: any, newValue: any) => {
    const eventTraet = event.target.value

    this.setState({ typeOfDocAutoCompleteValue: newValue });
  };

  handleOpenModal = (value: boolean) => {
    this.setState({ open: value })
  }

  handleCloseChat = () => {
    this.setState({ openChat: false })
  }

  handleOpenChat = () => {
    this.setState({ open: !this.state.open, openChat: true })
  }

  handleCloseModal = () => {
    this.setState({ open: !this.state.open })
  }

  getSubCategory = async() => {
    const header = {
      "token": await getStorageData('authToken'),
      "Content-Type": configJSON.validationApiContentType,
    };

    const httpBody = {
      "data": {
        "category_id":  this.state.ticketCategory.id
      }
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiGetSubCategory = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getSubCategoryApiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.exampleAPiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  accountSettinGetDataApiFunc = async() => {
   
    const header = {
      "token": await getStorageData('authToken'),
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.GetAccountSettingDatadataId = requestMessage.messageId;


    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),configJSON.accountDetailsEndPoint
    );

    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage),configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  };

  handleChangeTicketSubCategory = (event: React.ChangeEvent<HTMLInputElement>, itemID: number) => {

    const data = this.state.slectedSubCategory.map(item => {
      if (item.id === itemID) {
        return { ...item, name: event.target.value };
      }
      return item;
    });
    this.setState({ slectedSubCategory: data }, () => {
      const errors = this.state.slectedSubCategory
        .filter(item => item.name === 'choose')
        .map(item => item.id);
      this.setState({ subCategoryErrors: errors });
      if (errors.length === 0) {
        this.state.ticketErrors['subCategory'] = "";
        this.setState({
          ticketErrors: this.state.ticketErrors
        })
      }
    });
  }

  onCountryCodeSelectedWeb = (item: number) => {
  
    this.setState({
      AccountCountryCode: item,
    })
  }

  setSubCategoryValue = (itemID:number) => {
    const selectedItem = this.state.slectedSubCategory.find(item => item.id === itemID);
    return selectedItem ? selectedItem.name : 'choose';
  }

  handleModelCloseOnCross = () => {
    const msg = new Message(getName(MessageEnum.NavigationMessage));
    msg.addData(getName(MessageEnum.NavigationTargetMessage), "TicketStatusInfo");
    msg.addData(
      getName(MessageEnum.NavigationPropsMessage),
      this.props
    );
    this.send(msg);
  }
  // Customizable Area End
}

