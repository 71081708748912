

import React from "react";

import {
    // Customizable Area Start
    Container,
    Box,
    Button,
    Typography,
    Grid,
    // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { createTheme, ThemeProvider, withStyles } from "@material-ui/core/styles";
import Radio, { RadioProps } from '@material-ui/core/Radio';
import { green } from '@material-ui/core/colors';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import CircularProgress from '@material-ui/core/CircularProgress';
import HorizontalLine from "../../../components/src/HorizontalLine.web"
import Snackbar from "@material-ui/core/Snackbar";
import Alert from '@material-ui/lab/Alert';

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Avatar from '@material-ui/core/Avatar';

import {
  avatar

} from './assets';

export interface AnswerAttributes {
  program_name: string
  description: string
  priority: number
}

export interface AnswerArray {
  data: Answer[]
}

export interface Answer {
  id: string
  type: string
  attributes: AnswerAttributes
}

const GreenRadiobutton = withStyles({
    root: {
        color: green[400],
        '&$checked': {
            color: green[600],
        },
    },
    checked: {},
})((props: RadioProps) => <Radio color="default" {...props} />);

const theme = createTheme({
    palette: {
        primary: {
            main: "#nnn",
            contrastText: "#fff",
        },
    },
    typography: {
        h6: {
            fontWeight: 500,
        },
        subtitle1: {
            margin: "20px 0px",
        },
    },
});

// Customizable Area End

// Customizable Area Start
import Customform3Controller, {
  HomeProgramQuestionOption,
    Props,
    AllQuestion,
    AllQuestionOption,
} from "./Customform3Controller";
import AppWithoutDrawer from "../../../components/src/AppWithoutDrawer.web";
import Sidebarmenu from "../../../components/src/Sidebarmenu.web";
// Customizable Area End
export default class HomeschoolProgram extends Customform3Controller {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    firstInitialQuestion = () => {
        const { firstQuestion } = this.state
        return (
            <>
                <Box style={webStyle.Allignment}>
                    <Typography style={webStyle.QuestionList}>{firstQuestion.attributes.question_title}</Typography>
                    <Typography style={webStyle.SubQuestionList}>{firstQuestion.attributes.question}</Typography>
                    <Box style={webStyle.BoxStyle}>
                        <RadioGroup
                            test-ID="qustionOne"

                            aria-labelledby="demo-controlled-radio-buttons-group"
                            name="childGradeLevel"

                            value={this.state.childGradeLevel}
                            onFocus={this.hideError}
                            onChange={this.firsthandleChnage} >
                            {firstQuestion.attributes.options.data.map((data: HomeProgramQuestionOption, index: number) => (
                                <FormControlLabel
                                    name={(data.id).toString()}
                                    style={webStyle.labelWidth}
                                    value={data.id}
                                    checked={data.id == this.state.childGradeLevel ? true : false}
                                    control={<GreenRadiobutton size="small" />}
                                    label={<span style={webStyle.radio}>{data.attributes.title}</span>}
                                />
                            ))}
                        </RadioGroup>
                    </Box>
                    <Box>
                        {
                            (this.state.errors.childGradeLevel)
                                ? <small style={webStyle.errorStyle}>{this.state.errors.childGradeLevel}</small>
                                : <small style={{ visibility: 'hidden' }}>""</small>
                        }
                    </Box>
                    {this.createBoxWithBreakline()}
                </Box>
            </>
        )
    }

    allOtherQuestion = () => {
        const { allQuestion } = this.state

        return (
            <>
                {allQuestion.data.map((data: AllQuestion, index:number) => (
                    <Box style={webStyle.Allignment}>
                        <Typography style={webStyle.QuestionList}>{data.attributes.question_title}</Typography>
                        <Typography style={webStyle.SubQuestionList}>{index+1}. {data.attributes.question}</Typography>
                        <Box style={webStyle.BoxStyle}>
                        <RadioGroup
                            test-ID={`qustionAll-${data.id}`}
                            aria-labelledby="demo-controlled-radio-buttons-group"
                            name="childGradeLevel"
                            onFocus={this.hideError}
                            onChange={(e)=>this.selectedAnswer(e, data.id)} >
                            {data.attributes.options.data.map((dataOption: AllQuestionOption, index: number) => (
                                <FormControlLabel
                                    key={index}
                                    style={webStyle.labelWidth}
                                    value={dataOption.id}
                                    checked={this.filterSeletionProgram(data.id, dataOption.id)}
                                    control={<GreenRadiobutton size="small" />}
                                    label={<span style={webStyle.radio}>{dataOption.attributes.option}</span>}
                                />
                            ))}
                        </RadioGroup>
                        </Box>
                        <Box>
                            {
                                (this.state.filterQuestionId.includes(data.id))
                                    ? <small style={webStyle.errorStyle}>{this.state.errors.supportLevel}</small>
                                    : <small style={{ visibility: 'hidden' }}>""</small>
                            }
                        </Box>
                        {this.createBoxWithBreakline()}
                    </Box>
                ))}
           </>
        )
    }

    createBoxWithBreakline() {
        return (
            <Box style={webStyle.Breakline}>
                <p></p>
            </Box>
        );
    }
    
    // Customizable Area End

    render() {
        return (
            // Customizable Area Start
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <AppWithoutDrawer navigation={this.props.navigation} />
            <Sidebarmenu activeKey="Overview" />
            <ThemeProvider theme={theme}>
              <Container maxWidth={false}>
                <Grid container style={{ display: "block" }}>
                  <Grid item>
                    <Container maxWidth={false}>
                      <Container maxWidth={false} >
                        <Box style={{ marginTop: '80px', display: 'flex', justifyContent: 'space-between' }}>
                          <Typography style={webStyle.welcomeTextStyle} >Homeschool Program</Typography>
                        </Box>
                        <Grid container style={{ display: 'flex' }}>
                          <HorizontalLine />
                        </Grid>
                        <Container maxWidth={false} style={webStyle.GridWrapper}>
                          {this.state.recommendAnswer.length === 0 ?
                            <>
                              {this.firstInitialQuestion()}
                              {this.allOtherQuestion()}
                              <Box style={{
                                textAlign: "left",
                                paddingBottom: '4em',
                                marginTop: '2em',
                                display: "flex",
                                justifyContent: "end"
                              }}>
                                <Snackbar anchorOrigin={{
                                  vertical: 'top',
                                  horizontal: 'center',
                                }} open={this.state.snackbarOpenProgram} autoHideDuration={2000} onClose={this.handleCloseProgram} >
                                  <Alert severity="success">
                                    Saved to Draft Successfully
                                  </Alert>
                                </Snackbar>
                                {this.state.firstQuestion.attributes.options.data.length > 0 &&
                                  <>
                                    <Box style={{ paddingRight: '1em' }}>
                                      <Button test-ID="saveToDraft" disabled={this.state.answerArray.length === 0 ? true : false} style={{ textAlign: "center", textTransform: "none", ...webStyle.homeSchoolStyleSaveToDraft }}
                                        onClick={this.SaveToDraft}
                                      >Save to Draft</Button>
                                    </Box>

                                    <Button test-ID="submitQuestion" style={{
                                      textTransform: "none", textAlign: "center", ...webStyle.handleProgramSubmitAnswer
                                    }}
                                      disabled={this.state.childGradeLevel.length !== 0 ? false : true}
                                      onClick={this.handleProgramSubmitAnswers}
                                    >{this.state.homeSchoolProgramSubmit ? <CircularProgress /> : "Submit"}
                                    </Button>
                                  </>
                                }
                              </Box>
                            </>
                            :
                            <Box style={webStyle.AllignmentNew}>
                              <Typography style={webStyle.QuestionList}>
                                HG Recommends the below home school program
                              </Typography>
                              <Box style={webStyle.answerMargin}>
                                {this.state.recommendAnswer.map((data: Answer) => (
                                  <Card style={webStyle.answerCard}>
                                    <CardContent>
                                      <Grid
                                        container
                                        style={webStyle.cardFlex}
                                      >
                                        <Avatar
                                          style={webStyle.avatarRadious}
                                          alt="Avatar"
                                          src={avatar}
                                        />
                                        <div style={webStyle.cardContent}>
                                          <Typography style={webStyle.answerHeading}>
                                            {data.attributes.program_name}
                                          </Typography>
                                          <Typography
                                            style={{ fontFamily: "Montserrat" }}
                                          >
                                            {data.attributes.description}
                                          </Typography>
                                        </div>
                                      </Grid>
                                    </CardContent>
                                  </Card>
                                ))}
                              </Box>
                              <Grid item xs={12} style={{ marginTop: '32px', textAlign: "right" }}>
                                <Button
                                  variant="contained"
                                  style={webStyle.backButtonStyles2 as React.CSSProperties}
                                  data-test-id="backBtn"
                                  onClick={this.gotoHome}
                                >
                                  Go to Home
                                </Button>
                              </Grid>
                            </Box>
                          }
                        </Container>
                      </Container>
                    </Container>
                  </Grid>
                </Grid>
              </Container>
            </ThemeProvider>
          </div>
            // Customizable Area End
        );
    }
}

// Customizable Area Start
const webStyle = {
  homeSchoolStyleSaveToDraft: {
    height: "52px",
    width: '200px',
    fontFamily: "Montserrat",
    fontSize: "16px",
    color: "#fafafb",
    fontWeight: 600,
    borderRadius: "10px",
    backgroundColor: "#b9b9b9",
  },

  Breakline: {
        width: '100%', height: '1px', backgroundColor: '#dcdcdc'
  },
  BoxStyle: {
        marginTop: '0.5em'
  },
  Allignment: {
        paddingTop: '2em', paddingLeft: '1em'
  },
  AllignmentNew: { padding: "2em 1em",
  },
  handleProgramSubmitAnswer: {
    height: "52px",
    width: '200px',
    fontFamily: "Montserrat",
    fontSize: "16px",
    color: "#fafafb",
    fontWeight: 600,
    borderRadius: "10px",
    backgroundColor: "#fab03d",
  },
  SubQuestionList: {
    color: "#171725",
        paddingTop: '1em',
    fontFamily: "Montserrat",
    fontSize: "14px",
        fontWeight: 600
  },
  QuestionList: {
    color: "#171725",
    fontFamily: "Montserrat",
    fontSize: "20px",
        fontWeight: 600
  },
  errorStyle: {
        padding: '5px',
        color: 'red',
        fontFamily: "Montserrat",
        fontSize: ' 12px',
  },
  radio: {
    color: "#171725",
    fontFamily: "Montserrat",
    fontSize: "12px",
    fontWeight: 400,
  },
  labelWidth: {
        maxWidth: 'fit-content'
  },
  welcomeTextStyle: {
    //marginTop: '50px',
        color: '#171725',
    fontFamily: "Montserrat",
        fontSize: '24px',
    fontWeight: 600,
  },
  GridWrapper: {
    borderRadius: "15px",
    background: "#ffffff",
    border: "1px solid #f1f1f5",
    marginTop: "2em",
    boxShadow: "rgba(68, 68, 79, 0.1) 0px 5px 15px"
  },
  answerMargin: {
    marginTop: "20px",
  },
  answerCard: {
    marginBottom: "10px",
    border: "0.5px solid #B9B9B9",
    borderRadius: "11px",
    boxShadow: "none",
  },
  cardFlex: {
    alignItems: "start",
    display: "flex",
  },
  avatarRadious: { borderRadius: "25%" },
  answerHeading: {
    fontSize: "22px",
    color: "#171725",
    fontFamily: "Montserrat",
    fontWeight: 600,
  },
  cardContent: {
    width: "90%",
    marginLeft: "10px",
  },
  backButtonStyles2: {
    width: "200px",
    height: "52px",
    fontWeight: 600,
    fontSize: "16px",
    color: "#fafafb",
    borderRadius: "10px",
    display: 'inline',
    fontFamily: "Montserrat",
    backgroundColor: "#9167ac",
    textTransform: "none",
  },
};
// Customizable Area End
