import React from "react";

import {
  Container,
  Box,
  Input,
  Button,
  InputLabel,
  Typography,
  InputAdornment,
  IconButton,
  // Customizable Area Start
  Fade,
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  Avatar,
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import Visibility from "@material-ui/icons/Visibility";
import CloseIcon from '@material-ui/icons/Close';
import { CSSProperties } from "@material-ui/core/styles/withStyles";
import AttachFile from "@material-ui/icons/AttachFile";
import { avatar } from './assets';
import AvatarGroup from '@material-ui/lab/AvatarGroup';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';

const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});
// Customizable Area End

import Ticketsystem2Controller, {
  Props,
  configJSON,
} from "./Ticketsystem2Controller";

export default class Ticketsystem2 extends Ticketsystem2Controller {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <ThemeProvider theme={theme}>
        <Dialog onClose={this.handleCloseModal} fullWidth
          PaperProps={{
            style: {
              position: 'fixed',
              bottom: 25,
              right: '25',
              margin: 'auto',
            },
          }}
          style={webStyle.modal} aria-labelledby="customized-dialog-title" open={this.state.open}>
          <DialogTitle id="customized-dialog-title">
            <Typography style={webStyle.mainTitle}>Chat</Typography>
          </DialogTitle>
          <IconButton aria-label="close" style={webStyle.dotButton}>
            <MoreHorizIcon />
          </IconButton>
          <IconButton aria-label="close" data-test-id="closemodal" style={webStyle.closeButton} onClick={this.handleCloseModal}>
            <CloseIcon />
          </IconButton>
          <DialogContent dividers>
            <Fade in={this.state.open}>
              <>
                <Typography style={webStyle.subTitle} gutterBottom>
                  Hey clientname
                </Typography>
                <Typography>
                  Welcome to Parent Portal Chat
                </Typography>
                <Button
                  data-test-id="btnSendMessage"
                  variant="contained"
                  style={webStyle.sendMessageBtnNew}
                  onClick={() => this.handleOpenChat()}
                >
                  Ask Us Anything
                </Button>
                <Box sx={{ ...webStyle.bottomContainer, marginTop: '20px' }}>
                  <Avatar alt="client avatar" src={avatar} style={webStyle.avatarStyle} />
                  <Typography style={{ color: '#6B6B6B', cursor: 'pointer', marginLeft: '15px' }}> Customer Support Available</Typography>
                </Box>
              </>
            </Fade>
          </DialogContent>
        </Dialog>

        <Dialog
          onClose={this.handleCloseChat}
          fullWidth
          PaperProps={{
            style: {
              position: 'fixed',
              bottom: 25,
              right: '25',
              margin: 'auto',
            },
          }}
          style={webStyle.modal} aria-labelledby="customized-dialog-title" open={this.state.openChat}>
          <DialogTitle id="customized-dialog-title">
            <Typography style={webStyle.mainTitle}>Chat</Typography>
          </DialogTitle>
          <IconButton aria-label="close" style={webStyle.dotButton}>
            <MoreHorizIcon />
          </IconButton>
          <IconButton aria-label="close" style={webStyle.closeButton} data-test-id="closechat" onClick={this.handleCloseChat}>
            <CloseIcon />
          </IconButton>
          <DialogContent dividers>
            <Fade in={this.state.openChat}>
              <>
                <Box style={webStyle.avatarGroup}>
                  <Typography style={webStyle.subTitle} gutterBottom>
                    Customer Support Team
                  </Typography>
                  <AvatarGroup max={4}>
                    <Avatar alt="Remy Sharp" src={avatar} />
                    <Avatar alt="Travis Howard" src={avatar} />
                    <Avatar alt="Cindy Baker" src={avatar} />
                    <Avatar alt="Agnes Walker" src={avatar} />
                    <Avatar alt="Trevor Henderson" src={avatar} />
                  </AvatarGroup>
                </Box>
                <Box>
                  Messages List
                </Box>
                <Box sx={webStyle.bottomContainer}>
                  <Box sx={webStyle.messageInputView}>
                    <TextField
                      id="standard-textarea"
                      data-test-id={"inputMessage"}
                      placeholder="Type a Message"
                      InputProps={{ disableUnderline: true }}
                      fullWidth={true}
                      // onChange={(event) =>
                      //   this.handleMessageChange(event.target.value ?? "Hi")
                      // }
                      style={webStyle.input}
                      multiline
                    />
                  </Box>
                  <IconButton
                    data-test-id="btnInsertImage"
                    // onClick={this.handleInsertImage}
                    style={{ transform: 'rotate(45deg)' }}
                  >
                    <AttachFile fontSize="medium" />
                  </IconButton>
                  <Input
                    data-test-id="FileInput"
                    // inputRef={this.fileInputRef}
                    style={webStyle.fileInput}
                    type="file"
                    inputProps={{ accept: "image/png, image/jpeg, application/pdf, video/mp4" }}
                  // onChange={this.handleFileChange}
                  />
                  <Button
                    data-test-id="btnSendMessagenew"
                    variant="contained"
                    style={webStyle.sendMessageBttn}
                  // disabled={this.state.message.length === 0}
                  // onClick={this.handleSendMessage}
                  >
                    Send Message
                  </Button>
                </Box>
              </>
            </Fade>
          </DialogContent>
        </Dialog>
      </ThemeProvider>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle: Record<string, CSSProperties> = {
  mainWrapper: {
    display: "flex",
    fontFamily: "Montserrat",
    flexDirection: "column",
    alignItems: "center",
    paddingBottom: "30px",
    background: "#fff",
  },
  inputStyle: {
    borderBottom: "1px solid rgba(0, 0, 0, 0.6)",
    width: "100%",
    height: "100px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  buttonStyle: {
    width: "100%",
    height: "45px",
    marginTop: "40px",
    border: "none",
    backgroundColor: "rgb(98, 0, 238)",
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  dotButton: {
    position: 'absolute',
    right: theme.spacing(6),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  chatContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  chatMessage: {
    margin: theme.spacing(1, 0),
    padding: theme.spacing(1),
    borderRadius: 4,
  },
  chatInput: {
    marginTop: theme.spacing(2),
    display: 'flex',
  },
  bottomContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: 'center',
    paddingTop: 10,
    paddingBottom: 10,
    paddingRight: 20,
    paddingLeft: 20,
    marginTop: 6,
    width: '90%',
    borderRadius: 12,
    elevation: 6,
    background: "rgba(236, 235, 235, 0.57)",
  },
  messageInputView: {
    width: "100%",
    marginRight: 10,
  },
  input: {
    boxShadow: '0px 0px 7px #cacaca',
    borderRadius: 12,
    background: 'white',
    fontSize: 12,
    paddingLeft: 12,
  },
  fileInput: {
    display: "none",
  },
  sendMessageBttn: { 
    height: '95%', 
    width: '50%', 
    backgroundColor: '#ffb631', 
    color: 'white', 
    fontSize: 12, 
    borderRadius: 10, 
    fontWeight: 700,
    textTransform: 'none'
  },
  sendMessageBtnNew: { 
    width: '35%', 
    backgroundColor: '#ffb631', 
    color: 'white', 
    fontSize: 12, 
    borderRadius: 10, 
    fontWeight: 700,
    marginTop: '25px',
    textTransform: 'none'
  },
  dialogPaper: {
    position: 'fixed',
    bottom: 0,
    left: 0,
    margin: 0,
    maxWidth: '600px',
    width: '100%',
  },
  mainTitle:{
    fontSize: '24px',
    fontWeight: 700
  },
  subTitle:{
    fontSize: '20px',
    fontWeight: 600
  },
  avatarGroup:{
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  }
};
// Customizable Area End
