import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  scholarshipList: any;
  citiesList: any;
  barangayList: any;
  religionList: any;
  nationalitiesList: any;
  languageList: any;
  countryList: any;
  uniqueCountryCodeList: any;
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  languageList: any;
  nationalitiesList: any;
  religionList: any;
  barangayList: any;
  citiesList: any;
  countryList: any;
  uniqueCountryCodeList: any;
  scholarshipList: any;
  activeStep: any;
  loadingStudent: boolean;
  isLoading: boolean;
  snackbarOpen: boolean;
  snackbarMessage: string;

  studentFormData: {
    studentFirstName: string,
    studentLastName: string,
    studentMiddleName: string,
    studentResidenceCountry: any,
    studentBirthCountry: any,
    studentResidenceCity: any,
    studentBirthCity: any,
    studentResidenceBarangay: any,
    studentBirthBarangay: any
    studentGender: string;
    studentScholarShipType: string,
    studentAge: string,
    studentBirthDate: any,
    studentProofOfRelationship: string
    studentFatherDeathDate: string,
    studentFatherCauseOfDeath: any
    studentSpecification: string,
    studentTypeOfScholarShip: any,
    programDetailsProgram: any,
    studentChildsFirstLanguage: any,
    studentNationality: any,
    studentReligion: any;
    errorMessage: any;
    studentDateOfBirth: any,
    studentWouldYouLikeToApplyForAScholarShip: boolean,
    studentRefferalProgram: boolean,
    studentPwd: boolean,
    studentParentDiscount: boolean,
    studentPwdIdNumber: any,
    studentSoloParentId: any,
    studentAdvocatecode: any
    studentTypesOfScholarShip: any,
    studentResidentialAddress: string,
  }
  studentFormErrors: {
    studentFirstName: string,
    studentLastName: string,
    studentMiddleName: string,
    studentResidenceCountry: string,
    studentBirthCountry: string,
    studentResidenceCity: string,
    studentBirthCity: string,
    studentBirthBarangay: string,
    studentResidenceBarangay: string,
    studentGender: string,
    studentScholarShipType: string,
    studentAge: string,
    studentBirthDate: string,
    studentProofOfRelationship: string
    studentFatherDeathDate: string,
    studentFatherCauseOfDeath: string
    studentSpecification: string,
    studentTypeOfScholarShip: string,
    programDetailsProgram: string,
    studentChildsFirstLanguage: string,
    studentNationality: string,
    studentReligion: string
    errorMessage: string;
    studentDateOfBirth: string,
    studentWouldYouLikeToApplyForAScholarShip: string,
    studentRefferalProgram: string,
    studentPwd: string,
    studentParentDiscount: string,
    studentPwdIdNumber: string,
    studentSoloParentId: string,
    studentAdvocatecode: string
    studentTypesOfScholarShip: string,
    studentResidentialAddress: string,
  }
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class FormStep1Controller extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getProvincesCallId = "";
  getBarangayCallId = "";
  getReligionsCallId = "";
  getNationalityCallId = "";
  getLanguagesCallId = "";
  getScholarshipTypesId = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      languageList: this.props.languageList,
      nationalitiesList: this.props.nationalitiesList,
      religionList: this.props.religionList,
      barangayList: this.props.barangayList,
      citiesList: this.props.citiesList,
      countryList: this.props.countryList,
      scholarshipList: this.props.scholarshipList,
      uniqueCountryCodeList: [],
      activeStep: 0,
      loadingStudent: false,
      isLoading: false,
      snackbarOpen: false,
      snackbarMessage: '',

      studentFormData: {
        studentFirstName: '',
        studentLastName: '',
        studentMiddleName: '',
        studentResidenceCountry: '',
        studentBirthCountry: '',
        studentResidenceCity: {},
        studentBirthCity: {},
        studentBirthBarangay: {},
        studentResidenceBarangay: {},
        studentGender: '',
        studentScholarShipType: '',
        studentAge: '',
        studentBirthDate: '',
        studentProofOfRelationship: '',
        studentFatherDeathDate: '',
        studentFatherCauseOfDeath: '',
        studentSpecification: '',
        studentTypeOfScholarShip: {},
        programDetailsProgram: {},
        studentChildsFirstLanguage: {},
        studentNationality: {},
        studentReligion: {},
        errorMessage: '',
        studentDateOfBirth: '',
        studentWouldYouLikeToApplyForAScholarShip: false,
        studentRefferalProgram: false,
        studentPwd: false,
        studentParentDiscount: false,
        studentPwdIdNumber: '',
        studentSoloParentId: '',
        studentAdvocatecode: '',
        studentTypesOfScholarShip: {},
        studentResidentialAddress: '',
      },
      studentFormErrors: {
        studentFirstName: '',
        studentLastName: '',
        studentMiddleName: '',
        studentResidenceCountry: '',
        studentBirthCountry: '',
        studentResidenceCity: '',
        studentBirthCity: '',
        studentBirthBarangay: '',
        studentResidenceBarangay: '',
        studentGender: '',
        studentScholarShipType: '',
        studentAge: '',
        studentBirthDate: '',
        studentProofOfRelationship: '',
        studentFatherDeathDate: '',
        studentFatherCauseOfDeath: '',
        studentSpecification: '',
        studentTypeOfScholarShip: '',
        programDetailsProgram: '',
        studentChildsFirstLanguage: '',
        studentNationality: '',
        studentReligion: '',
        errorMessage: '',
        studentDateOfBirth: '',
        studentWouldYouLikeToApplyForAScholarShip: '',
        studentRefferalProgram: '',
        studentPwd: '',
        studentParentDiscount: '',
        studentPwdIdNumber: '',
        studentSoloParentId: '',
        studentAdvocatecode: '',
        studentTypesOfScholarShip: '',
        studentResidentialAddress: '',
      }
      // Customize Area Start
    };

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    
    // Customizable Area End
  }
  // Customizable Area Start
  onProcessGetProviceResponse = (message: Message) => {
  }

  onProcessGetBarangayResponse = (message: Message) => {
    
  }

  callApi = async (
    endpoint: string,
    method: "GET" | "POST",
    body?: Object
  ) => {
    
  }

  handleChangePersonal = (e: any) => {
    
  }

  hideError = (event: any) => {
    
  }

  genderChange = (e: any) => {
    
  }

  dateFunction = () => {
    
  }

  datechange = (e: any) => {
    
  }

  handleChangeCountry = (e: any, newValue: any, type: string) => {
    
  }

  changeHandlerGetCities = async (e: any) => {
    
  }

  handleChangeCity = (e: React.ChangeEvent<HTMLInputElement>, newValue: any, type: string) => {
    
  }

  barangayChange = (e: any, type: string) => {
    
  }

  religionDataChange = (e: React.ChangeEvent<HTMLInputElement>) => {
  }

  nationalitiesDataChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    
  }

  languageDataChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    
  }

  handleCheckedStudentScholarShip = (e: any) => {
    
  }

  handleChangeScholarshipType = (e: any) => {
    
  }

  scholarshipChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    
  }

  handleAdvocatecode = (e: any) => {
    
  }

  handlePwdDiscount = (e: any) => {

  }

  handleParentDiscount = (e: any) => {
    
  }

  handleProofOfRelationship = (e: any) => {
    
  }

  handleClearFatherDeath = (e: any) => {
    
  }

  handleStudentCauseOfDeath = (e: any) => {
    
  }

  handlestudentSpecification = (e: any) => {
    
  }



  validateField = (fieldName: string, errorKey: string, errorMessage: string) => {
    
  };

  isRequired = (value: string, errorKey: string, errorMessage: string) => {
    
  };

  formOneValidate = () => {
  };

  validateAge = (age: any) => {
  };

  // Customizable Area End
}
