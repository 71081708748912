import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  scholarshipList: any;
  languageList: any;
  nationalitiesList: any;
  religionList: any;
  barangayList: any;
  citiesList: any;
  countryList: any;
  uniqueCountryCodeList: any;
  activeStep: any;
  loadingStudent: boolean;
  isLoading: boolean;
  snackbarOpen: boolean;
  snackbarMessage: string;

  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class CustomformEnrollmentController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getProvincesCallId = "";
  getBarangayCallId = "";
  getReligionsCallId = "";
  getNationalityCallId = "";
  getLanguagesCallId = "";
  getScholarshipTypesId = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      scholarshipList: [],
      languageList: [],
      nationalitiesList: [],
      religionList: [],
      barangayList: [],
      citiesList: [],
      countryList: [],
      uniqueCountryCodeList: [],
      activeStep: 0,
      loadingStudent: false,
      isLoading: false,
      snackbarOpen: false,
      snackbarMessage: '',

      // Customize Area Start
    };

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

      this.showAlert(
        "Change Value",
        "From: " + this.state.txtSavedValue + " To: " + value
      );

      this.setState({ txtSavedValue: value });
    }

    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  
  async componentDidMount() {
    this.getAllCountriesData();
    this.getAllReligion();
    this.getAllNationalities();
    this.getAllLanguages();
    this.getScholarshipTypes();
  }

  getAllCountriesData = () => {
  }

  getAllLanguages = () => {
  }

  getAllReligion = () => {
  }

  getAllNationalities = () => {
  }

  getScholarshipTypes = () => {
  }

  onProcessGetScholarshipTypesResponse = (message: Message) => {
    
  }

  onProcessgetLanguagesResponse = (message: Message) => {

  }

  onProcessGetNationalityResponse = (message: Message) => {
    
  }

  onProcessgetReligionsResponse = (message: Message) => {
    
  }

  callApi = async () => {
    
  }

  getSteps = () => {
    return ['Student', 'Previous School', 'Program Details', 'Parents', 'Files', 'Review', 'T&C'];
  }

  handleBackMain = async () => {
  };

  onClickNextOfPrevSchool = () => {
  }

  handleCloseSnackbar = () => {

  }
  
  // Add other helper functions for specific validations if needed
  

  handleNextMain = async () => {

  }
  // Customizable Area End
}
