// Customizable Area Start
import React, { useState } from "react";
import { Box } from "@material-ui/core";
import { ThemeProvider, createTheme, makeStyles } from "@material-ui/core/styles";
import PlayCircleFilledIcon from '@material-ui/icons/PlayCircleFilled';
import ReactPlayer from 'react-player';

const theme = createTheme({
    palette: {
        primary: {
            main: "#nnn",
            contrastText: "#fff",
        },
    },
    typography: {
        h6: {
            fontWeight: 500,
        },
        subtitle1: {
            margin: "20px 0px",
        },
    },
});

const useStyles = makeStyles({
    playerWrapper: {
        width: "100%",
        position: "relative",
    },
    controlWrapper: {
        position: "absolute",
        top: 0,
        right: 0,
        left: 0,
        bottom: 0,
        height: '80%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        zIndex: 1,
    },
    controlIcons: {
        color: '#777',
        fontSize: 50,
        transform: 'scale(0.9)',
        "&:hover": {
            color: '#fff',
            transform: 'scale(1)',
        },
    },
    bottomIcons: {
        color: '#eeeee4',
        transform: 'scale(0.9)',
        "&:hover": {
            color: '#fff',
            transform: 'scale(1)',
        },
    },
    volumeSlider: {
        width: 100,
    },
});

const HgVideo = ({ introVideoURL }: any) => {
    const classes = useStyles();
    const [playing, setPlaying] = useState(false)
    const [isReady, setIsReady] = useState(false);
    const handlePlayPause = () => {
        setPlaying(!playing)
    }
    const onReady = React.useCallback(() => {
        if (!isReady) {
            setIsReady(true);
        }
    }, [isReady]);
    return (
        <ThemeProvider theme={theme}>
            <Box style={{ display: 'flex', alignItems: 'center' }} >
                <div className={classes.playerWrapper}>
                    <ReactPlayer
                        config={{ file: { attributes: { controlsList: 'nodownload noplaybackrate', disablepictureinpicture: 'true' } } }}
                        // Disable right click
                        onContextMenu={(e: any) => {
                            e.preventDefault()
                        }}
                        width="100%"
                        height="100%"
                        style={{borderRadius: '20px 20px 0 0', overflow: 'hidden'}}
                        url={introVideoURL}
                        muted={false}
                        playing={playing}
                        controls={true}
                        onReady={onReady}
                        pip={false}
                    />
                    {isReady ?
                        <div className={classes.controlWrapper}>
                            <Box onClick={handlePlayPause} style={{ height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', cursor: 'pointer' }}>
                                {
                                    !playing && <PlayCircleFilledIcon onClick={handlePlayPause} style={{ marginTop: '70px', fontSize: '60px', color: 'white' }} />
                                }
                            </Box>
                        </div>
                        : null
                    }
                </div>
            </Box>
        </ThemeProvider>
    );
}

export default HgVideo;
// Customizable Area End