import React from "react";

import {

    // Customizable Area Start
    Container,
    Box,
    Button,
    Typography,
    Grid,
    styled,
    CircularProgress


    // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start

import { createTheme, ThemeProvider } from '@material-ui/core/styles';

import "./CreateTicket.css"
import { arrowleft, rightarrow } from './assets';

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import AppWithoutDrawer from "../../../components/src/AppWithoutDrawer.web";
import Sidebarmenu from "../../../components/src/Sidebarmenu.web";
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import HorizontalLine from "../../../components/src/HorizontalLine.web"

const theme = createTheme({

    palette: {
        primary: {
            main: "#nnn",
            contrastText: "#fff",
        },
    },
    typography: {

        h6: {
            fontWeight: 500,
        },
        subtitle1: {
            margin: "20px 0px",
        },
    },

    overrides: {
        MuiInput: {
            underline: {

                " &:before": {
                    borderBottom: 'none',
                    transition: 'none',
                    content: 'none',
                },

            },
        },

    },
});
const Boxcontainertwo = styled(Box)({
    padding: "0.5rem",
    color: "#1b1b1b",
    fontFamily: "Montserrat",
    fontSize: "14px",
    fontWeight: 400,
    letterSpacing: "0.1px",
    width: "200px", // Change this value to the width you desire
});
const BoxcontainertwoChild = styled(Box)({
    display: 'flex', alignItems: 'center', justifyContent: 'center', margin: "1rem auto"



})


// Customizable Area End

import Ticketsystem2Controller, {
    Props,

} from "./Ticketsystem2Controller";

class TicketStatusInfo extends Ticketsystem2Controller {
    popoverAnchor: Element | ((element: Element) => Element) | null | undefined;
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End 
    }

    // Customizable Area Start

    // Customizable Area End

    render() {
        console.log("john", this.state.ticketsPageInfo.start_index, this.state.ticketsPageInfo.end_index)
        return (
            // Customizable Area Start

            <>
                <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>

                    <AppWithoutDrawer navigation={this.props.navigation} />
                    <Sidebarmenu activeKey="touch" subActiveKey="ticket" />

                    <ThemeProvider theme={theme}>

                        <Container maxWidth={false}>

                            <Grid container style={{ display: 'block' }}>
                                {/* ---------------------- drawer ------------------------- */}

                                {/* ---------------------- drawer Content ------------------------- */}
                                <Grid item>
                                    <Container maxWidth={false}
                                    >

                                        {/* ---------------------- MAIN CONTENT OF FILE START ------------------------- */}

                                        <Container maxWidth={false} style={{  width: '100%' }}>
                                            <Box style={{ marginTop: '76px', display: "flex", justifyContent: "space-between" }}>
                                                <Typography className='welcomeText' style={webStyle.welcomeTextStyle} >Tickets </Typography>

                                                <Button
                                                    data-testid="Navigation"
                                                    style={{ textAlign: "center", textTransform: "none", ...webStyle.btnTicketCreate }}
                                                    onClick={() => this.handleTicketNavigation()}
                                                >
                                                    New Ticket
                                                </Button>

                                            </Box>
                                            <Grid container style={{ display: 'flex' }}>
                                                <HorizontalLine />
                                            </Grid>

                                            <Box className="ticketBox" style={webStyle.ticketBox}>
                                                <Box className="boxHeader" style={webStyle.boxHeader}>
                                                    <div style={webStyle.container}>
                                                        <div style={webStyle.dropdown}>
                                                            <div onClick={this.handleDropdownClick} style={{
                                                                fontSize: "16px",
                                                                background: "white",
                                                                borderRadius: "4px",
                                                                width: "auto",
                                                                cursor: "pointer",
                                                                position: "relative"
                                                            }}
                                                            >
                                                                <div
                                                                    style={{
                                                                        display: "flex",
                                                                        justifyContent: "flex-start",
                                                                        alignItems: "center",
                                                                        gap: "1rem",
                                                                    }}
                                                                >
                                                                    <div>{this.state.dropdownValue === "" ? "All Tickets" : this.state.dropdownValue}</div>
                                                                    <div>
                                                                        <ExpandMoreIcon />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div style={{ display: this.state.dropdownState ? "block" : "none" }} className="dropdownparent">
                                                                <div style={{
                                                                    width: "173px",
                                                                    position: "absolute",
                                                                    marginTop: "10px",
                                                                    display: "flex",
                                                                    flexDirection: "column",
                                                                    background: "#ffffff",
                                                                    padding: "8px 15px",
                                                                    borderRadius: "10px",
                                                                    border: "2px solid #e2e2ea",
                                                                    // fontWeight:"400",
                                                                    color: "#1b1b1b",
                                                                    fontFamily: "Montserrat",
                                                                    fontSize: "14px",
                                                                    letterSpacing: "0.1px"
                                                                 
                                                                
                                                                }}
                                                                    className="dropdown-menu"
                                                                >
                                                                    <div >
                                                                        <div style={webStyle.dropdownitemone}>
                                                                            <Boxcontainertwo
                                                                                data-testid={"dropdownOpen"}
                                                                                className="dropdown__link"
                                                                                onClick={() => this.handleDropdownItemClick("All Tickets", "All Tickets")}
                                                                            >
                                                                                All Tickets
                                                                            </Boxcontainertwo>
                                                                        </div>
                                                                        <div style={webStyle.dropdownitemone}>
                                                                            <Boxcontainertwo
                                                                                data-testid={"dropdownItems"}
                                                                                className="dropdown__link"
                                                                                onClick={() => this.handleDropdownItemClick("pending", "Pending Tickets")}
                                                                            >
                                                                                Pending Tickets
                                                                            </Boxcontainertwo>
                                                                        </div>
                                                                        <div style={webStyle.dropdownitemone}>
                                                                            <Boxcontainertwo
                                                                                data-testid={"dropdownItem"}
                                                                                className="dropdown__link"
                                                                                onClick={() => this.handleDropdownItemClick("completed", "Completed Tickets")}
                                                                            >
                                                                                Completed Tickets
                                                                            </Boxcontainertwo>
                                                                        </div>
                                                                        <div style={webStyle.dropdownitemone}>
                                                                            <Boxcontainertwo
                                                                                data-testid={"dropdownItemagain"}
                                                                                className="dropdown__link"
                                                                                onClick={() => this.handleDropdownItemClick("Pending Action Required", "Pending Action Required")}
                                                                            >
                                                                                Pending Action Required
                                                                            </Boxcontainertwo>
                                                                        </div>
                                                                    </div>

                                                                </div>
                                                            </div>

                                                        </div>
                                                    </div>


                                                    <Box sx={{ display: "flex", alignItems: "center" }}>
                                                        <Button data-testid={"dropdownImage2"} onClick={this.handlePrevious} disabled={this.state.pageCount == 0 ? true : false}>
                                                            {
                                                                this.state.arrowPrevious ?
                                                                    <ChevronRightIcon />
                                                                    :
                                                                    <ChevronLeftIcon />


                                                            }
                                                        </Button>

                                                        <Typography>{this.state.ticketsPageInfo.start_index} - {this.state.ticketsPageInfo.end_index}</Typography>
                                                        <Button data-testid={"dropdownImage3"} onClick={this.handleNext} disabled={this.state.pageCount == 0 ? true : false}>
                                                            {
                                                                this.state.arrowState ? 
                                                                    <ChevronLeftIcon data-testid={"dropdownImage"} />
                                                                    :
                                                                    <ChevronRightIcon data-testid={"dropdownImage"} />
                                                            }
                                                        </Button>
                                                    </Box>
                                                </Box>
                                                <hr style={{
                                                    background:"#ececf1",
                                                    width: "100%",
                                                    height: "0px",
                                                    border:"1px solid #ececf1",
                                                    marginBottom:"2rem"
                                                
                                                    }}/>
                                                <Box className="headerStyle" style={webStyle.headerStyle as React.CSSProperties}>
                                                    <Typography className="width30" style={webStyle.width25}>Status</Typography>
                                                    <Typography className="width30" style={webStyle.width50}>Subject</Typography>
                                                    <Typography className="width20" style={webStyle.width35}>Submitted On</Typography>
                                                    <Typography className="width20" style={webStyle.width20}>Ticket ID</Typography>
                                                </Box>

                                                {
                                                    this.state.isloadingset ?
                                                        <BoxcontainertwoChild>
                                                            <CircularProgress color="inherit" />
                                                        </BoxcontainertwoChild> :
                                                        <>
                                                            {this.state.ticketListdataone.length > 0 ? 
                                                                this.state.ticketListdataone.map((rowValue: any, index: any) => (
                                                                <Box className="bodyStyle" style={webStyle.bodyStyle as React.CSSProperties} key={index}>
                                                                    <Typography className="width30 statusBar" style={{ ...webStyle.width25, ...webStyle.statusBar, height: "auto", textAlign: "center", color: "#ffffff",textTransform: "capitalize",  fontFamily: "Montserrat", backgroundColor: this.state.statusColor[rowValue.status.behavior] }}>{rowValue.status.behavior}</Typography>
                                                                    {/* <Typography className="width30 statusBar" style={{ ...webStyle.width30, ...webStyle.statusBar, height: "auto", textAlign: "center", backgroundColor: rowValue.status.color }}>{rowValue.status.behavior}</Typography> */}
                                                                    <Typography className="width30 subjectName" style={{ ...webStyle.width50, ...webStyle.subjectName, whiteSpace: "nowrap" }}>{rowValue.subject}</Typography>
                                                                    <Typography className="width20" style={webStyle.width35}>{rowValue.created_at.split(' ')[0]}</Typography>
                                                                    <Typography className="width20" style={webStyle.width20}>{rowValue.id}</Typography>
                                                                </Box> 
                                                            ))
                                                                :
                                                                <Box style={webStyle.alignCenter as React.CSSProperties}>
                                                                    {
                                                                        <p style={webStyle.boldFont}>No tickets found</p>
                                                                    }
                                                                </Box>
                                                            }
                                                        </>

                                                }

                                            </Box>


                                        </Container>

                                        {/* ---------------------- MAIN CONTENT OF FILE END ------------------------- */}

                                    </Container>
                                </Grid>
                            </Grid>
                        </Container>
                    </ThemeProvider >
                </div>
            </>
            // Customizable Area End
        );
    }
}

// Customizable Area Start

const webStyle = {
    setImage: {
        width: "7px", height: "11px"
    },

    "dropdownitemone:not(last-child)": {
        paddingTop: "15px",
        paddingBottom: "15px"
    },
    dropdownitemone: {
        cursor: "pointer",

    },
    "dropdownitemone:not(first-child)": {
        paddingTop: "5px",
        paddingBottom: "15px"
    },
    ticketBox: {
        borderRadius: "15px",
        border: "1px dashed #f1f1f5",

        backgroundBlendMode: "normal",
        boxShadow: "0px 5px 15px rgba(68, 68, 79, 0.1)",
        padding: "29px 47px",
        marginTop: "17px",

    },
    boxHeader: {
        display: "flex",
        justifyContent: "space-between",
        marginBottom:"1.7rem"
    },
    hLine: {
        borderColor: "#ececf1",
    },
    width25: {
        width: "25%",
    },
    width35: {
        width: "35%",
    },
    width20: {
        width: "20%",
    },
    width50:{
        width: "50%"
    },
    'dropdownItems::before': {
        content: '',
        position: "relative",
        top: "-1rem",
        right: -"1rem",
        width: "12px",
        height: "12px",
        transform: "rotate(45deg)",
        backgroundColor: "white",
        borderLeft: " 1px solid #e2e2ea",
        borderTop: "1px solid #e2e2ea"
    },
    headerStyle: {
        display: "flex",
        height: "50px",
        borderRadius: "10px",
        background: "#9167ac",
        backgroundBlendMode: "normal",
        alignItems: "center",
        color: "#ffffff",
        textAlign: 'center',
        padding: '0 0.5rem'
    },

    bodyStyle: {
        display: "flex",
        marginTop: "12px",
        height: "58px",
        borderRadius: "10px",
        border: "1px solid #e2e2ea",
        alignItems: "center",
        textAlign: 'center',
        padding: '0 0.5rem'
    },
    statusBar: {
        padding: "0.5rem 0px",
        borderRadius: "40px",
        backgroundBlendMode: "normal",

    },
    subjectName: {

        overflow: 'hidden',
        textOverflow: 'ellipsis'
    },
    container: {
        maxWidth: "1024px", display: "flex;",
        alignItems: "center",
        justifyContent: "center",
    
    },
    dropdown: {
        minWidth: "100px"
    },

    btnTicketCreate: {
        fontFamily: "Montserrat",
        width: "175px",
        fontSize: "16px",
        color: "#fafafb",
        fontWeight: 1000,
        borderRadius: "10px",
        backgroundColor: "#fab03d"
    },
    welcomeTextStyle: {
        //marginTop: '50px',
        color: '#171725',
        fontFamily: "Montserrat",
        fontSize: '24px',
        fontWeight: 600,
        display: "flex",
        alignItems: "center",

    },

    btnStatus: {
        width: '200px',
        height: '24px',
        borderRadius: '10px',
        border: "none",
        color: '#fafafb',
        fontFamily: "Montserrat",
        fontSize: ' 11px',
        fontWeight: 500,
        backgroundBlendMode: 'normal',
        padding: '1.2em 0.5em'
    },

    gridContentContainerStyle: {
        marginTop: '14px',
        border: '1px solid #f1f1f5',
        borderRadius: '15px',
        backgroundColor: '#ffffff',
        boxShadow: '0px 5px 15px rgba(68,68,79,0.1)',
        padding: '20px',
        display: 'flex',
        alignItems: 'stretch',
        flexDirection: 'column' as 'column',
        justifyContent: 'space-between'


    },
    textTicketStyle: {
        height: '36px',
        color: '#171725',
        fontFamily: "Montserrat",
        fontSize: '14px',
        fontWeight: 500,
        lineHeight: '36px',
    },
    headingTextStyle: {
        height: '18px',
        color: '#92929d',
        fontFamily: "Montserrat",
        fontSize: '12px',
        fontWeight: 500,
        lineHeight: '18px',
    },

    headingPinTextStyle: {
        height: '21px',
        color: '#171725',
        fontFamily: "Montserrat",
        fontSize: '14px',
        fontWeight: 600,
        lineHeight: '21px',
    },
    headingStatusTextStyle: {

        color: '#383838',
        fontFamily: "Montserrat",
        fontSize: '15px',
        fontWeight: 400,

    },
    avatarTitleTextStyle: {
        height: '14px',
        color: ' #92929d',
        fontFamily: "Montserrat",
        fontSize: '12px',
        fontWeight: 500,
        lineHeight: '14px',
        marginBottom: '0.6em',

    },
    cardAvatarStyle: {
        width: '24px',
        height: '24px',
        marginRight: '10px'
    },
    boldFont: {
        fontWeight: 600,
        margin: '2rem 0'
    },
    alignCenter: {
        textAlign: "center"
    }


};

function useState(second: any) {
    throw new Error("Function not implemented.");
}
export default TicketStatusInfo
// Customizable Area End
