Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "customform3";
exports.labelBodyText = "customform3 Body";
exports.StudentsViewDeatislEndPoint = "bx_block_student/students/";
exports.btnExampleTitle = "CLICK ME";

exports.provinceApiEndpoint = "provinces"
exports.barangayApiEndpoint = "get_barangays"
exports.religionApiEndpoint = "bx_block_student/religions"
exports.nationalityApiEndpoint = "bx_block_student/nationalities"
exports.languagesApiEndpoint = "bx_block_student/languages"
exports.scholarshipTypesApiEndpoint = "bx_block_student/scholarship_types"
exports.StudentsListendPoint = "bx_block_student/students"
exports.allQustioncomingEndpoint = "bx_block_dashboard/homeschool_program_questions?grade=Pre kinder"
exports.schoolProgramApiEndPoint = "bx_block_dashboard/homeschool_program_questions"
exports.saveDraftProgramApiEndPoint = "bx_block_dashboard/homeschool_recommendations"
exports.getAnswerApiEndPoint = "bx_block_dashboard/homeschool_recommendations/recommend_homeschool_program"
exports.getStyleQuestoin = "bx_block_dashboard/homeschool_style_questions"
exports.getStyleAnswerApiEndPoint = "bx_block_dashboard/homeschool_recommendations/recommend_homeschool_style"
exports.getSubmittedProgram = "bx_block_dashboard/homeschool_recommendations/draft_homeschool_program"
exports.getDraftStyleAnswer = "bx_block_dashboard/homeschool_recommendations/draft_homeschool_style"
exports.getGrade = "bx_block_student/grade_organisers"
exports.test = "Test1New"
exports.data = "newdata"
// Customizable Area End