Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URLNew";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "ticketsystem2";
exports.labelBodyText = "ticketsystem2 Body";
exports.ticketCategory = "Category*";
exports.ticketNameOfStudent = "Name of Student*";
exports.ticketSubject = "Subject*";
exports.ticketMessage = "Message*";
exports.ticketAddCC = "CC";
exports.ticketAddBCC = "BCC";
exports.contactDetails = "Contact Details";
exports.ticketFullName = "Full Name*";
exports.ticketEmail = "Email*";
exports.ticketPhoneNumber = "Phone Number*";
exports.ticketConsent = " I consent to collecting my personal information for your support team to communicate.";
exports.allTicketsUrl="bx_block_ticketsystem/ticket_systems"
exports.ticketsAllCategoryEndpoint = "bx_block_ticketsystem/ticket_systems/get_categories"
exports.apiCreateTicketCallUrl="bx_block_ticketsystem/ticket_systems?status=Closed";
exports.btnExampleTitle = "CLICK ME";
exports.getSubCategoryApiEndPoint = "bx_block_ticketsystem/ticket_systems/get_category_custom_fields"
exports.accountDetailsEndPoint = "bx_block_customisableuserprofiles/profiles/6"
// Customizable Area End