import React, { CSSProperties } from "react";

import {

	// Customizable Area Start
	Container,
	Box,
	Button,
	Typography,
	Grid,
	Paper,
	Tab,
	Tabs,
	Avatar,	
	Accordion,
	AccordionSummary,
	Checkbox,
	AccordionDetails,
	Card,
	CardMedia,
	CardContent,
	FormGroup
	// Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import AppWithoutDrawer from "../../../components/src/AppWithoutDrawer.web";
import Sidebarmenu from "../../../components/src/Sidebarmenu.web";
import { createTheme, styled, ThemeProvider } from "@material-ui/core/styles";
import HorizontalLine from "../../../components/src/HorizontalLine.web";
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import { avatar, test } from './assets'
import CheckBoxOutlinedIcon from '@material-ui/icons/CheckBoxOutlined';

const theme = createTheme({

	palette: {
		primary: {
			main: "#nnn",
			contrastText: "#fff",
		},
	},
	typography: {
		h6: {
			fontWeight: 500,
		},
		subtitle1: {
			margin: "20px 0px",
		},
	},

	overrides: {
		MuiButton: {
			root: {
				textTransform: 'none',
			},
		},
	},
});

const curriculumData = [
	{
		id: 'full-online',
		title: 'Full Online Curriculum',
		bundle: 'Ignitia Bundle - DepEd',
		materials: [
			'Ignitia Online Curriculum with a student and parent teacher account. Student accounts come with Lessons Quizzes, Projects, Unit Tests, and Answer Keys for:',
			'Bible 700',
			'English Language Arts 700',
			'Math 700',
			'Science 7 DepEd',
			'Asian Identity in the Global World',
			'Filipino 7',
			'And with Printed Materials For:',
			'Filipino: Kalinangan 7 (Version 3) (Text Book and Teacher\'s Manual)',
			'Social Studies: Asian Identity in the Global World (Text Book)'
		],
		price: '₱ 45,000.00'
	},
	{
		id: 'blended',
		title: 'Blended Curriculum',
		bundle: 'BJU Bundle - DepEd',
		materials: [
			'Teacher\'s Manual, Teaching Videos w/ Mom\'s Minutes, Worktext/Activities Answer Keys and Printables, and Earth Science and Pre-Algebra Student Texts',
			'Printed Materials:',
			'BJU Bible 7. Bible A Student Worktext (3rd ed.)',
			'Asian Identity in the Global World Textbook',
			'BJU English 7. Explorations in Literature Student Text (4th ed)',
			'BJU Pre-Algebra Student Text (2nd Ed.)',
			'BJU Earth Science Student Lab Manual (4th ed)',
			'BJU English Writing & Grammar Student Writet (3rd ed)',
			'Kalinangan 7 Textbook',
			'Kalinangan 7 Teacher\'s Manual',
			'MAPEH in Action 7 TB',
			'MAPEH in Action 7 TM'
		],
		price: '₱ 50,000.00'
	},
	{
		id: 'custom',
		title: 'Custom',
		bundle: '',
		materials: [
			'Fully customize your child’s learning. Select and compare every book, Test and other curricular activities accredited in the program'
		],
		price: ''
	}
];

const books = [
	{ title: 'Achieving True Success Hardbound', price: '₱ 739.00' },
	{ title: 'Power for True Success Hardbound', price: '₱ 739.00' },
	{ title: 'The Mystery of History Volume 1-A Set (3rd Edition)', price: '₱ 739.00' },
	{ title: 'The family Book of Manners', price: '₱ 739.00' },
	{ title: 'The mystery of History Volume 1-B Set (3rd Edition)', price: '₱ 739.00' },
	{ title: 'Teenage Book of Manners', price: '₱ 739.00' },
];

const subjects = [
	{ name: 'Filipino Subject', price: 8000},
	{ name: 'English Subject', price: 8000},
	{ name: 'Science Subject', price: 8000},
	{ name: 'Math Subject', price: 8000},
	{ name: 'Araling Panlipunan Subject', price: 8000}
];

const additionalTesting = [
	{ name: 'Science', price: 1000},
	{ name: 'Social Studies', price: 1000}
];
// Customizable Area End

import CheckoutController, {
	Props,

} from "../src/CheckoutController.web";

class Checkout extends CheckoutController {
	popoverAnchor: Element | ((element: Element) => Element) | null | undefined;
	constructor(props: Props) {
		super(props);
		// Customizable Area Start
		// Customizable Area End 
	}

	// Customizable Area Start
	familyBook = () => {
		return (
			<Box>
				{this.state.familyBookValue.length ?
					<>
						<hr style={{ color: '#E6E6E6' }} />
						<Box style={webStyle.itemContetPrice}>
							<Typography style={webStyle.pricingTotalSub}>Family Books</Typography>
							<Typography style={webStyle.priceValueSub}>₱ 50,000.00</Typography>
						</Box>
					</> : ''}
			</Box>
		)
	}

	course = () => {
		return (
			<Box>
				{this.state.courseValue !== '' &&
					<>
						<hr style={{ color: '#E6E6E6', marginTop: '15px' }} />
						<Box style={webStyle.itemContetPrice}>
							<Typography style={webStyle.pricingTotalSubTitle}>Text Book Type</Typography>
							<Typography style={webStyle.pricingTotalSubTitle}>{this.state.courseValue}</Typography>
						</Box>
						{this.state.courseValue === 'custom' &&
							<>
								{this.state.accordionExpandValue.length ?
									<Box style={webStyle.itemContetPrice}>
										<Typography style={webStyle.pricingTotalBalck}>Subjects</Typography>
									</Box>
									: ""}
								{this.state.accordionExpandValue.includes("english") &&
									<Box style={webStyle.itemContetPrice}>
										<Typography style={webStyle.pricingTotalSub}>English</Typography>
										<Typography style={webStyle.priceValueSub}>₱ 50,000.00</Typography>
									</Box>
								}
								{this.state.accordionExpandValue.includes("maths") &&
									<Box style={webStyle.itemContetPrice}>
										<Typography style={webStyle.pricingTotalSub}>Maths</Typography>
										<Typography style={webStyle.priceValueSub}>₱ 50,000.00</Typography>
									</Box>
								}
							</>
						}
					</>
				}
			</Box>
		)
	}

	missBill = () => {
		return (
			<Box>
				{(this.state.onlineSubject.length || this.state.additoinalSubject.length) ?
					<>
						<hr style={{ color: '#E6E6E6' }} />
						<Box style={webStyle.itemContetPrice}>
							<Typography style={webStyle.pricingTotalSub}>Miscellaneous Add Ons</Typography>
							<Typography style={webStyle.priceValueSub}>₱ 50,000.00</Typography>
						</Box>
						{this.state.onlineSubject.map((item, index) => (
							<Box style={webStyle.itemContetPrice}>
								<Typography style={webStyle.pricingTotalSubLight}>{item}</Typography>
								<Typography style={webStyle.priceValueSub}>₱ 50,000.00</Typography>
							</Box>
						))}
						{this.state.additoinalSubject.map((item, index) => (
							<Box style={webStyle.itemContetPrice}>
								<Typography style={webStyle.pricingTotalSubLight}>{item}</Typography>
								<Typography style={webStyle.priceValueSub}>₱ 50,000.00</Typography>
							</Box>
						))}
					</> : ''
				}
			</Box>
		)
	}
	// Customizable Area End

	render() {
		return (
			// Customizable Area Start
			<div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
				<AppWithoutDrawer navigation={this.props.navigation} />
				<Sidebarmenu activeKey="checkout" />
				<ThemeProvider theme={theme}>
					<Container maxWidth={false}>
						<Grid container style={{ display: 'block' }}>
							{/* ---------------------- drawer ------------------------- */}
							<Grid item>
								<Container maxWidth={false} style={{paddingLeft: '0px', paddingRight: '0px'}} >
									{/* ---------------------- MAIN CONTENT OF FILE START ------------------------- */}
									<Container style={{ margin: '0 auto', width: "100%", paddingLeft: '0', paddingRight: '0' }}>
										<Box style={{ marginTop: '80px', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
											<Typography style={webStyle.welcomeTextStyle} >Checkout</Typography>
										</Box>

										<Grid container style={{ display: 'flex' }}>
											<HorizontalLine />
										</Grid>

										<Container maxWidth={false} style={{ padding: '0' }}>
											<Grid container spacing={3} style={{ display: "flex", alignItems: "stretch" }}>
												<Grid item xs={12} lg={12}>
													<Box>
														<CustomTabs
															value={this.state.value}
															aria-label="styled tabs example"
															style={{pointerEvents: 'none'}}
															TabIndicatorProps={{
																style: this.state.indicatorStyle,
															}}
															variant="scrollable"
														>
															<Tab data-test-id="0" label="01 PROGRAM INFO" id="tab-0" aria-controls="tabpanel-0" ref={this.tabRefs[0]} style={{ color: this.state.selectedTabs.includes(0) ? '#9CC853' : undefined,  }} />
															<Tab data-test-id="1" label="02 CURRICULUM" id="tab-1" aria-controls="tabpanel-1" ref={this.tabRefs[1]} style={{ color: this.state.selectedTabs.includes(1) ? '#9CC853' : undefined,  }} />
															<Tab data-test-id="2" label="03 TEXT BOOK SELECTION" id="tab-2" aria-controls="tabpanel-2" ref={this.tabRefs[2]} style={{ color: this.state.selectedTabs.includes(2) ? '#9CC853' : undefined,  }} />
															<Tab data-test-id="3" label="04 FAMILY BOOK SELECTION" id="tab-3" aria-controls="tabpanel-3" ref={this.tabRefs[3]} style={{ color: this.state.selectedTabs.includes(3) ? '#9CC853' : undefined,  }} />
															<Tab data-test-id="4" label="05 MISCELLANEOUS" id="tab-4" aria-controls="tabpanel-4" ref={this.tabRefs[4]} style={{ color: this.state.selectedTabs.includes(4) ? '#9CC853' : undefined,  }} />
														</CustomTabs>
													</Box>
												</Grid>
											</Grid>
										</Container>

										<Box>
											<Grid container spacing={2}>
												<Grid item xs={12} md={8} lg={8}>
													<GridContentContainerStyle>
														{this.state.value === 1 &&
															<Box>
																<Box>
																	<Box style={webStyle.TitleSection}>
																		<Typography style={webStyle.TitleFont}>Checkout for</Typography>
																	</Box>
																	<Box style={{ marginTop: '15px' }}>
																		<Box style={webStyle.avatarSection}>
																			<Avatar
																				alt="student avatar"
																				src={avatar} />
																			<Typography style={webStyle.avatarName}>Ovais Fedan</Typography>
																		</Box>
																	</Box>
																</Box>
																<hr style={{ color: '#E6E6E6', marginTop: '20px' }} />
																<Box>
																	<Box style={webStyle.TitleSection}>
																		<Typography style={webStyle.TitleFont}>Program Items</Typography>
																	</Box>
																	<Box style={webStyle.programItemOuterSection}>
																		<Box>
																			<Box style={webStyle.itemContet}>
																				<Typography style={webStyle.proramItem}>Touch pogram - Full Payment</Typography>
																				<Typography style={webStyle.price}>₱ 35,000.00</Typography>
																			</Box>
																			<Box style={webStyle.itemContet}>
																				<Typography style={webStyle.proramItem}>WCFS US Accreditation Fee - Pre K to Grade 8</Typography>
																				<Typography style={webStyle.price}>₱ 12,500.00</Typography>
																			</Box>
																			<Box style={webStyle.itemContet}>
																				<Typography style={webStyle.proramItem}>Testing and Learning Enhancement Tool</Typography>
																				<Typography style={webStyle.price}>₱ 2,5000.00</Typography>
																			</Box>
																			<hr style={{ color: '#E6E6E6', marginTop: '20px' }} />
																			<Box style={webStyle.itemContet}>
																				<Typography style={webStyle.proramItem}>Total</Typography>
																				<Typography style={webStyle.price}>₱ 50,000.00</Typography>
																			</Box>
																		</Box>
																	</Box>
																</Box>
															</Box>
														}
														{(this.state.value === 2 && this.state.courseValue !== 'custom') &&
															<Box>
																<Box>
																	<Box style={webStyle.TitleSection}>
																		<Typography style={webStyle.TitleFont}>Text Book Selection</Typography>
																	</Box>
																</Box>
																<Box>
																	<RadioGroup data-test-id="main-course" value={this.state.courseValue} onChange={this.handleChangeCourse}>
																		<Grid style={{ marginTop: '25px' }} container spacing={3}>
																			{curriculumData.slice(0, 2).map((curriculum) => (
																				<Grid item xs={12} md={6} key={curriculum.id}>
																					<Box style={webStyle.courseSelection}>
																						<FormControlLabel
																							value={curriculum.id}
																							control={<CustomRadio />}
																							label={<Typography style={webStyle.avatarName}>{curriculum.title}</Typography>}
																							labelPlacement="end"
																							style={{ alignItems: 'center', marginBottom: 16 }}
																						/>
																						<Typography variant="body1" gutterBottom>
																							{curriculum.bundle}
																						</Typography>
																						<Box component="div">
																							{curriculum.materials.map((item) => (
																								<Typography style={{ fontSize: '13px' }}>{item}</Typography>
																							))}
																						</Box>
																						<hr style={{ color: '#E6E6E6', marginTop: '20px' }} />
																						<Typography style={webStyle.totalTitle}>Price: {curriculum.price}</Typography>
																					</Box>
																				</Grid>
																			))}
																			<Grid item xs={12}>
																				<Box style={webStyle.courseSelection}>
																					<FormControlLabel
																						value={curriculumData[2].id}
																						control={<CustomRadio />}
																						label={<Typography style={webStyle.avatarName}>{curriculumData[2].title}</Typography>}
																						style={{ alignItems: 'center' }}
																					/>
																					<Typography variant="body2" component="div" gutterBottom>
																						{curriculumData[2].materials.map((item, index) => (
																							<Typography style={{ fontSize: '13px' }}>{item}</Typography>
																						))}
																					</Typography>
																				</Box>
																			</Grid>
																		</Grid>
																	</RadioGroup>
																</Box>
															</Box>
														}
														{(this.state.value === 2 && this.state.courseValue === 'custom') &&
															<Box>
																<Box>
																	<Box style={webStyle.TitleSection}>
																		<Typography style={webStyle.TitleFont}>Text Book Selection</Typography>
																	</Box>
																</Box>
																<GridContentContainerStyle>
																	<Box>
																		<Typography style={webStyle.TitleFont}>Subjects</Typography>
																	</Box>
																	<Box>
																		<Box m={3}>
																			<Accordion style={webStyle.removeShadow}>
																				<AccordionSummary data-test-id="english-accordion" expandIcon={this.state.accordionExpandValue.includes("english") ? <ArrowDropUpIcon /> : <ArrowRightIcon />}>
																					<FormControl component="fieldset">
																						<FormControlLabel
																							control={<Checkbox onClick={() => this.courseAccordion('english')} checkedIcon={<CheckBoxOutlinedIcon style={{color: '#8E749D'}} />} />}
																							label={<Typography style={{fontSize: '16px'}}>English</Typography>}
																						/>
																					</FormControl>
																				</AccordionSummary>
																				<AccordionDetails>
																					<div>
																						<Typography style={{fontSize: '14px'}}>Recommended</Typography>
																						<FormControl component="fieldset">
																							<FormControlLabel
																								control={<Checkbox checkedIcon={<CheckBoxOutlinedIcon style={{color: '#8E749D'}} />} />}
																								label={<Typography style={{fontSize: '14px'}}>Explore the world through reading and grammar 1 (TB + TM)</Typography>}
																							/>
																						</FormControl>
																					</div>
																				</AccordionDetails>
																			</Accordion>
																		</Box>

																		<Box m={3}>
																			<Accordion style={webStyle.removeShadow}>
																				<AccordionSummary expandIcon={this.state.accordionExpandValue.includes("maths") ? <ArrowDropUpIcon /> : <ArrowRightIcon />}>
																					<FormControl component="fieldset">
																						<FormControlLabel
																							control={<Checkbox onClick={() => this.courseAccordion('maths')} checkedIcon={<CheckBoxOutlinedIcon style={{color: '#8E749D'}} />} />}
																							label={<Typography style={{fontSize: '16px'}}>Maths</Typography>}
																						/>
																					</FormControl>
																				</AccordionSummary>
																				<AccordionDetails>
																					<div>
																						<Typography style={{ fontSize: '14px' }}>Recommended</Typography>
																						<FormControl component="fieldset">
																							<FormControlLabel
																								control={<Checkbox checkedIcon={<CheckBoxOutlinedIcon style={{color: '#8E749D'}} />} />}
																								label={<Typography style={{fontSize: '14px'}}>Targeting Mathematics 1A (TB + WB + TM)</Typography>}
																							/>
																							<FormControlLabel
																								control={<Checkbox checkedIcon={<CheckBoxOutlinedIcon style={{color: '#8E749D'}} />} />}
																								label={<Typography style={{fontSize: '14px'}}>Targeting Mathematics 1B (TB + WB + TM)</Typography>}
																							/>
																						</FormControl>

																						<Typography style={{fontSize: '14px'}}>Alternatives</Typography>
																						<FormControl component="fieldset">
																							<RadioGroup>
																								<FormControlLabel
																									control={<CustomRadio />}
																									value="LIFEPAC First Grade Math Set"
																									label={<Typography style={{fontSize: '15px'}}>LIFEPAC First Grade Math Set</Typography>}
																								/>
																								<FormControlLabel
																									control={<CustomRadio />}
																									value="Math for Life 1"
																									label={<Typography style={{fontSize: '15px'}}>Math for Life 1</Typography>}
																								/>
																								<FormControlLabel
																									control={<CustomRadio />}
																									value="New Syllabus Primary Math 1"
																									label={<Typography style={{ fontSize: '15px' }}>New Syllabus Primary Math 1 (TB + TM)</Typography>}
																								/>
																								<Box style={{marginLeft: '25px'}}>
																									<Paper id="handleTouchPrgId1" elevation={0} style={webStyle.gridProgramContainerStyleBook} >
																										<img alt="program avatar" style={webStyle.bookAvatar}
																											src={test}
																										/>
																										<Box style={webStyle.programBox}>
																											<Box style={webStyle.itemContetRight}>
																												<Typography style={{ fontSize: '13px' }}>BJU Phonics and English (3rd Edition) Phonics Practice Book (Optional)</Typography>
																												<Typography style={{ fontSize: '13px' }}>Edition 2nd Edition</Typography>
																											</Box>
																										</Box>
																									</Paper>
																								</Box>
																								<FormControlLabel
																									control={<CustomRadio />}
																									value="Language Lessons for a Living Education Level 1"
																									label={<Typography style={{fontSize: '15px'}}>Language Lessons for a Living Education Level 1</Typography>}
																								/>
																								<FormControlLabel
																									control={<CustomRadio />}
																									value="BJU Math 1 Student Worktext"
																									label={<Typography style={{fontSize: '15px'}}>BJU Math 1 Student Worktext</Typography>}
																								/>
																							</RadioGroup>
																						</FormControl>
																					</div>
																				</AccordionDetails>
																			</Accordion>
																		</Box>
																	</Box>
																</GridContentContainerStyle>
															</Box>
														}
														{this.state.value === 3 &&
															<Box>
																<Box>
																	<Box style={webStyle.TitleSection}>
																		<Typography style={webStyle.TitleFont}>Family Book</Typography>
																	</Box>
																	<Box style={webStyle.TitleSection}>
																		<Grid container spacing={3}>
																			{books.map((book, index) => (
																				<Grid item xs={12} sm={6} md={3} key={index}>
																					<Card style={{boxShadow: 'none'}}>
																						<CardMedia
																							component="img"
																							alt={book.title}
																							height="140"
																							image={test}
																							title={book.title}
																						/>
																						<CardContent>
																							<Box style={webStyle.bookTitleItem}>
																								<Box>
																									<FormControlLabel
																										data-test-id="familyBookSelection"
																										onChange={() => this.familyBookSelection(book.title)}
																										control={
																											<Checkbox
																												name={book.title}
																												color="primary"
																												checkedIcon={<CheckBoxOutlinedIcon style={{ color: '#8E749D' }} />}
																											/>
																										}
																										label=""
																									/>
																								</Box>
																								<Box>
																									<Typography style={{ fontSize: '13px' }}>
																										{book.title}
																									</Typography>
																									<Typography style={webStyle.bookPrice} component="p">
																										{book.price}
																									</Typography>
																								</Box>
																							</Box>
																						</CardContent>
																					</Card>
																				</Grid>
																			))}
																		</Grid>
																	</Box>
																</Box>
															</Box>
														}
														{this.state.value === 4 &&
															<Box>
																<Box>
																	<Box style={webStyle.TitleSection}>
																		<Typography style={webStyle.TitleFont}>Miscellaneous Add Ons</Typography>
																	</Box>
																</Box>
																<GridContentContainerStyle>
																	<Box p={3}>
																		<Typography style={webStyle.TitleFont}>Optional Items</Typography>
																		<Box style={webStyle.programItemInnerSection}>
																			<Typography style={webStyle.TitleFontSmall}>Online Group Tutorials - 5 Subjects</Typography>
																			<FormGroup>
																				{subjects.map((subject, index) => (
																					<Grid container alignItems="center" key={subject.name}>
																						<Grid item xs={8}>
																							<FormControlLabel
																								style={webStyle.TitleFontSmallContent}
																								data-test-id="onlineSubject"
																								onChange={() => this.onlineSubject(subject.name)}
																								control={
																									<Checkbox
																										color="primary"
																										checkedIcon={<CheckBoxOutlinedIcon style={{ color: '#8E749D' }} />}
																									/>
																								}
																								label={<Typography style={webStyle.TitleFontSmallContent}>{subject.name}</Typography>}
																							/>
																						</Grid>
																						<Grid item xs={4}>
																							<Typography align="right">₱ {subject.price.toLocaleString()}</Typography>
																						</Grid>
																					</Grid>
																				))}
																			</FormGroup>
																		</Box>
																		<Box mt={3}>
																			<Typography style={webStyle.TitleFont}>Additional Testing</Typography>
																			<Box style={webStyle.programItemInnerSection}>
																				<Typography style={webStyle.TitleFontSmall}>Additional MobyMax Subject</Typography>
																				<FormGroup>
																					{additionalTesting.map((item, index) => (
																						<Grid container alignItems="center" key={item.name}>
																							<Grid item xs={8}>
																								<FormControlLabel
																									data-test-id="additionalSubject"
																									onChange={() => this.additionalSubject(item.name)}
																									control={
																										<Checkbox
																											color="primary"
																											checkedIcon={<CheckBoxOutlinedIcon style={{ color: '#8E749D' }} />}
																										/>
																									}
																									label={<Typography style={webStyle.TitleFontSmallContent}>{item.name}</Typography>}
																								/>
																							</Grid>
																							<Grid item xs={4}>
																								<Typography align="right">₱ {item.price.toLocaleString()}</Typography>
																							</Grid>
																						</Grid>
																					))}
																				</FormGroup>
																			</Box>
																		</Box>
																	</Box>
																</GridContentContainerStyle>
																<Box p={3}>
																	<Box style={webStyle.itemContetPrice}>
																		<Typography style={webStyle.TitleFontSmall}>Program - Touch</Typography>
																		<Typography style={webStyle.TitleFontSmallWeight}>₱ 50,000.00</Typography>
																	</Box>
																	<Box style={webStyle.itemContetPrice}>
																		<Typography style={webStyle.TitleFontSmall}>Curriculum</Typography>
																		<Typography style={webStyle.TitleFontSmallWeight}>₱ 12,347.00</Typography>
																	</Box>
																	<Box style={webStyle.itemContetPrice}>
																		<Typography style={webStyle.TitleFontSmall}>Family Books</Typography>
																		<Typography style={webStyle.TitleFontSmallWeight}>₱ 10,000.00</Typography>
																	</Box>
																	<Box style={webStyle.itemContetPrice}>
																		<Typography style={webStyle.TitleFontSmall}>Miscellaneous Add Ons</Typography>
																		<Typography style={webStyle.TitleFontSmallWeight}>₱ 42,000.00</Typography>
																	</Box>
																	<hr style={{ color: '#E6E6E6', marginTop: '20px' }} />
																	<Box style={webStyle.itemContetPrice}>
																		<Typography style={webStyle.TitleFontSmall}>Sub Total</Typography>
																		<Typography style={webStyle.TitleFontWeight}>₱ 1,06,347.00</Typography>
																	</Box>
																	<hr style={{ color: '#E6E6E6', marginTop: '10px' }} />
																	<Box mt={2}>
																		<FormControl component="fieldset">
																			<RadioGroup>
																				<FormControlLabel
																					control={<CustomRadio />}
																					label={<Typography style={{ fontSize: '13px' }}>Continue with other selected students</Typography>}
																					value="Continue with other selected students"
																				/>
																				<FormControlLabel
																					control={<CustomRadio />}
																					label={<Typography style={{ fontSize: '13px' }}>Ignore other students and proceed to checkout</Typography>}
																					value="Ignore other students and proceed to checkout"
																				/>
																			</RadioGroup>
																		</FormControl>
																	</Box>
																</Box>
															</Box>
														}
														<ButtonSection>
															<SwitchButton
																variant="contained"
																data-test-id="backBtn"
															>
																Switch Program / Accreditation
															</SwitchButton>
															<NextButton
																data-test-id="nextbutton"
																onClick={this.handleNavigationNext}
															>
																{this.state.value < 3 ? 'Next' : 'Checkout'}
															</NextButton>
														</ButtonSection>
													</GridContentContainerStyle>
												</Grid>
												<Grid item xs={12} md={4} lg={4}>
													<Box style={webStyle.gridContentOrangeStyle}>
														<Box>
															<Box style={webStyle.TitleSection}>
																<Typography style={webStyle.TitleFont}>Checkout Info</Typography>
															</Box>
															<Box style={{ marginTop: '15px' }}>
																<Box style={webStyle.avatarSection}>
																	<Avatar
																		alt="student avatar"
																		src={avatar} />
																	<Typography style={webStyle.avatarName}>Ovais Fedan</Typography>
																</Box>
															</Box>
														</Box>
														<Box style={{ marginTop: '25px' }}>
															<Paper id="handleTouchPrgId1" elevation={0} style={webStyle.gridProgramContainerStyle} >
																<Avatar alt="program avatar" style={webStyle.programAvatar}
																	src={avatar}
																/>
																<Box style={webStyle.programBox}>
																	<Box style={webStyle.itemContetRight}>
																		<Typography style={webStyle.proramItem}>Touch pogram - Full Payment</Typography>
																		<Typography style={webStyle.price}>Cost: ₱ 35,000.00</Typography>
																	</Box>
																</Box>
															</Paper>
														</Box>
														<Box style={{ marginTop: '25px' }}>
															<Box style={webStyle.itemContetPrice}>
																<Typography style={webStyle.pricingTotal}>Sub Total</Typography>
																<Typography style={webStyle.priceValue}>₱ 50,000.00</Typography>
															</Box>
															<Box style={webStyle.itemContetPrice}>
																<Typography style={webStyle.pricingTotal}>Shipping</Typography>
																<Typography style={webStyle.priceValue}>Free</Typography>
															</Box>
														</Box>
														{this.course()}
														{this.familyBook()}
														{this.missBill()}
														<hr style={{ color: '#E6E6E6'}} />
														<Box style={{ marginTop: '15px' }}>
															<Box style={webStyle.itemContetPrice}>
																<Typography style={webStyle.totalTitle}>Total</Typography>
																<Typography style={webStyle.total}>₱ 50,000.00</Typography>
															</Box>
														</Box>
													</Box>
												</Grid>
											</Grid>
										</Box>
									</Container>
								</Container>
							</Grid>
						</Grid>
					</Container>
				</ThemeProvider>
			</div>
			// Customizable Area End
		);
	}
}

// Customizable Area Start
const webStyle: Record<string, CSSProperties> = {
	welcomeTextStyle: {
		color: '#171725',
		fontFamily: "Montserrat",
		fontSize: '24px',
		fontWeight: 600,
	},
	gridContentContainerStyle: {
		marginTop: '40px',
		border: '1px solid #f1f1f5',
		borderRadius: '15px',
		backgroundColor: '#ffffff',
		boxShadow: '0px 5px 15px rgba(68,68,79,0.1)',
		padding: '25px',
	},
	gridContentOrangeStyle: {
		marginTop: '40px',
		border: '2px solid #FAB03F',
		borderRadius: '15px',
		backgroundColor: '#ffffff',
		padding: '25px',
	},
	TitleSection: {
		marginTop: '20px'
	},
	TitleFont: {
		color: '#000',
		fontFamily: "Montserrat",
		fontSize: '18px',
		fontWeight: 600,
	},
	TitleFontWeight: {
		color: '#000',
		fontFamily: "Montserrat",
		fontSize: '18px',
		fontWeight: 500,
	},
	TitleFontSmall: {
		color: '#000',
		fontFamily: "Montserrat",
		fontSize: '15px',
		fontWeight: 600,
		paddingBottom: '5px'
	},
	TitleFontSmallWeight: {
		color: '#000',
		fontFamily: "Montserrat",
		fontSize: '15px',
		fontWeight: 500,
		paddingBottom: '5px'
	},
	TitleFontSmallContent: {
		color: '#000',
		fontFamily: "Montserrat",
		fontSize: '13px',
		fontWeight: 600,
		paddingBottom: '5px'
	},
	cardAvatarStyle: {
		width: "42px",
		height: "42px",
		marginRight: "10px",
	},
	avatarSection: {
		display: 'flex',
		alignItems: 'center'
	},
	avatarName: {
		color: '#000',
		fontFamily: "Montserrat",
		fontSize: '16px',
		fontWeight: 600,
		marginLeft: '15px'
	},
	programItemOuterSection:{
		border: '1px solid #f1f1f5',
		borderRadius: '15px',
		backgroundColor: '#ffffff',
		padding: '25px',
		marginTop: '30px'
	},
	programItemInnerSection:{
		border: '1px solid #f1f1f5',
		borderRadius: '15px',
		backgroundColor: '#ffffff',
		padding: '25px',
		marginTop: '15px'
	},
	itemContet:{
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between',
		padding: '7px 0'
	},
	itemContetPrice:{
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between',
		padding: '5px 0'
	},
	itemContetRight:{
		padding: '5px 0'
	},
	proramItem: {
		color: '#000',
		fontFamily: "Montserrat",
		fontSize: '14px',
		fontWeight: 600,
	},
	price: {
		color: '#646464',
		fontFamily: "Montserrat",
		fontSize: '14px',
		fontWeight: 600,
	},
	pricingTotal: {
		color: '#646464',
		fontFamily: "Montserrat",
		fontSize: '18px',
		fontWeight: 600,
	},
	pricingTotalSub: {
		color: '#000',
		fontFamily: "Montserrat",
		fontSize: '15px',
		fontWeight: 600,
	},
	pricingTotalSubLight: {
		color: '#000',
		fontFamily: "Montserrat",
		fontSize: '15px',
	},
	pricingTotalBalck: {
		color: '#000',
		fontFamily: "Montserrat",
		fontSize: '18px',
		fontWeight: 700,
	},
	pricingTotalSubTitle: {
		color: '#000',
		fontFamily: "Montserrat",
		fontSize: '19px',
	},
	priceValue: {
		color: '#000	',
		fontFamily: "Montserrat",
		fontSize: '18px',
		fontWeight: 600,
	},
	priceValueSub: {
		color: '#000	',
		fontFamily: "Montserrat",
		fontSize: '15px',
		fontWeight: 500,
	},
	totalTitle: {
		color: '#000',
		fontFamily: "Montserrat",
		fontSize: '16px',
		fontWeight: 600,
	},
	total: {
		color: '#F9B03D',
		fontFamily: "Montserrat",
		fontSize: '16px',
		fontWeight: 600,
	},
	welcomeText1: {
		height: "52px",
		fontFamily: "Montserrat",
		width: "235px",
		fontSize: "16px",
		color: "#fafafb",
		fontWeight: 500,
		borderRadius: "10px",
		backgroundColor: "#fab03d"
	},
	backButtonStyles2: {
		height: "52px",
		fontFamily: "Montserrat",
		fontSize: "16px",
		color: "#fafafb",
		fontWeight: 600,
		borderRadius: "10px",
		backgroundColor: "#9167ac",
		textTransform: "none",
		margin: '0 20px'
	},
	buttonSection: {
		justifyContent: 'flex-end',
		display: 'flex',
		marginTop: '25px'
	},
	gridProgramContainerStyle: {
        border: '1px solid #f1f1f5',
        borderRadius: '75px 35px 35px 75px',
        backgroundColor: '#ffffff',
        boxShadow: '0px 5px 15px rgba(68,68,79,0.1',
        padding: '10px',
        display: 'flex',
        marginTop: '10px',
        boxSizing: 'border-box' as 'border-box',
		alignItems: 'center'

    },
	gridProgramContainerStyleBook: {
        border: '1px solid #f1f1f5',
        borderRadius: '15px',
        backgroundColor: '#ffffff',
        boxShadow: '0px 5px 15px rgba(68,68,79,0.1',
        padding: '10px',
        display: 'flex',
        boxSizing: 'border-box' as 'border-box',
		maxWidth: '410px',
		width: '100%',
    },
	programAvatar: {
        width: '55px',
        height: '55px',
		marginLeft: '-15px'
    },
	bookAvatar: {
        width: '55px',
        height: 'inherit',
    },
	programBox: {
        marginLeft: '15px',
    },
	ProgramInnerTextHeader1: {
        color: ' #757575',
        fontFamily: "Montserrat",
        fontSize: '0.6em',
        fontWeight: 700,
    },
    ProgramInnerSubTxt: {
        color: ' #757575',
        fontFamily: "Montserrat",
        fontSize: '0.5em',
		fontWeight: 300,
	},
	courseSelection: {
		border: '1px solid #f1f5f9',
		borderRadius: '20px',
		padding: '15px'
	},
	removeShadow: {
		boxShadow: 'none',
		border: '1px solid #f1f5f9',
		borderRadius: '10px',
	},
	bookTitleItem: {
		display: 'flex'
	},
	bookPrice: {
		marginTop: '5px',
		fontSize: '13px',
		fontWeight: 600
	}
}

const CustomTabs = styled(Tabs)({
	backgroundColor: 'none',
	'& .MuiTab-root': {
		padding: '25px 15px',
		fontSize: '12px',
		fontWeight: 600,
		flex: 1,
		color: '#333',
		'&.Mui-selected': {
			color: '#9CC853',
		},
	},
	'& .MuiTabs-indicator': {
		height: '4px',
		backgroundColor: '#9CC853',
		transition: 'left 0.2s ease, width 0.2s ease',
		width: '100%'
	}
});

const CustomRadio = styled(Radio)({
	color: '#4caf50', // Green color
	'&.Mui-checked': {
		color: '#4caf50',
	},
});

const ButtonSection = styled(Box)({
	justifyContent: 'flex-end',
	display: 'flex',
	marginTop: '15px',
	"@media (max-width: 454px)": {
		flexDirection: "column"
	}
});

const SwitchButton = styled(Button) ({
	height: "52px",
	fontFamily: "Montserrat",
	fontSize: "16px",
	color: "#fafafb",
	fontWeight: 600,
	borderRadius: "10px",
	backgroundColor: "#9167ac",
	textTransform: "none",
	margin: '10px 20px',
	'&:hover': {
		backgroundColor: "#9167ac",
	},
	"@media (max-width: 454px)": {
		margin: '10px 0px',
	}
});

const GridContentContainerStyle = styled(Box) ({
	marginTop: '40px',
	border: '1px solid #f1f1f5',
	borderRadius: '15px',
	backgroundColor: '#ffffff',
	boxShadow: '0px 5px 15px rgba(68,68,79,0.1)',
	padding: '25px',
	"@media (max-width: 454px)": {
		padding: '15px',
	}
});

const NextButton = styled(Button) ({
	height: "52px",
	fontFamily: "Montserrat",
	width: "235px",
	fontSize: "16px",
	color: "#fafafb",
	fontWeight: 500,
	borderRadius: "10px",
	backgroundColor: "#fab03d",
	textTransform: "none",
	margin: '10px 0',
	'&:hover': {
		backgroundColor: "#fab03d",
	},
	"@media (max-width: 454px)": {
		width: "100%",
	}
});
export default Checkout
// Customizable Area End
