// Customizable Area Start


import React from "react";
import HappyFoxChat from "../../../components/src/HappyFox";
import Uploadmedia3Controller, {
  Props,
  configJSON,
} from "./Uploadmedia3Controller";

export default class Uploadmedia3 extends Uploadmedia3Controller {
  render() {
    return (
      // Customizable Area Start
      <HappyFoxChat /> 
      // Customizable Area End
    );
  }
}

// Customizable Area End