

import React from "react";

import {
    // Customizable Area Start
    Container,
    Box,
    Button,
    Typography,
    Grid,
    // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { createTheme, ThemeProvider, withStyles } from "@material-ui/core/styles";
import Radio, { RadioProps } from '@material-ui/core/Radio';
import { green } from '@material-ui/core/colors';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import CircularProgress from '@material-ui/core/CircularProgress';
import HorizontalLine from "../../../components/src/HorizontalLine.web"
import Snackbar from "@material-ui/core/Snackbar";
import Alert from '@material-ui/lab/Alert';

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Avatar from '@material-ui/core/Avatar';

import {
  avatar

} from './assets';

export interface Answer {
  id: string
  type: string
  attributes: AnswerAttributes
}

export interface AnswerAttributes {
  style_name: string
  description: string
  priority: number
}

export interface AllQuestionOptionAttributes {
    option: string;
  }
  
  export interface AllQuestionOption {
    id: string;
    type: string;
    attributes: AllQuestionOptionAttributes;
  }
  
  export interface AllOptionsData {
    data: AllQuestionOption[];
  }
  
  export interface AllQuestionAttributes {
    grade: string;
    question_title: string;
    question: string;
    options: AllOptionsData;
  }
  
  export interface AllQuestion {
    id: string;
    type: string;
    attributes: AllQuestionAttributes;
  }
  
  export interface AllQuestionsData {
    data: AllQuestion[];
  }

const theme = createTheme({
    palette: {
        primary: {
            main: "#nnn",
            contrastText: "#fff",
        },
    },
    typography: {
        h6: {
            fontWeight: 500,
        },
        subtitle1: {
            margin: "20px 0px",
        },
    },
});

const GreenRadio = withStyles({
    root: {
        color: green[400],
        '&$checked': {
            color: green[600],
        },
    },
    checked: {},
})((props: RadioProps) => <Radio color="default" {...props} />);
const dummyData = [
    { value: 'option1', label: 'Option 1' },
    { value: 'option2', label: 'Option 2' },
    { value: 'option3', label: 'Option 3' },
];
const dummyData1 = [
    { value: 'option1', label: 'Option 1' },
    { value: 'option2', label: 'Option 2' },
    { value: 'option3', label: 'Option 3' },
];
const dummyData2 = [
    { value: 'option1', label: 'Option 1' },
    { value: 'option2', label: 'Option 2' },
    { value: 'option3', label: 'Option 3' },
];
const dummyData3 = [
    { value: 'option1', label: 'Option 1' },
    { value: 'option2', label: 'Option 2' },
    { value: 'option3', label: 'Option 3' },
];
const dummyData4 = [
    { value: 'option1', label: 'Option 1' },
    { value: 'option2', label: 'Option 2' },
    { value: 'option3', label: 'Option 3' },
];

const dummyDataEducationalPhilosophy = [
    { value: 'philosophy1', label: 'Philosophy 1' },
    { value: 'philosophy2', label: 'Philosophy 2' },
    { value: 'philosophy3', label: 'Philosophy 3' },
  ];
 
// Customizable Area End
// Customizable Area Start
import Customform3Controller, {
    Props,
} from "./Customform3Controller";
import AppWithoutDrawer from "../../../components/src/AppWithoutDrawer.web";
import Sidebarmenu from "../../../components/src/Sidebarmenu.web";

const GreenRadiobutton = withStyles({
    root: {
      color: green[400],
      "&$checked": {
        color: green[600],
      },
    },
    checked: {},
  })((props: RadioProps) => <Radio color="default" {...props} />);
// Customizable Area End
export default class HomeschoolStyle extends Customform3Controller {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    allOtherQuestion = () => {
        const { styleQuestion } = this.state;
    
        return (
          <>
            {styleQuestion.map((data: AllQuestion, index: number) => (
              <Box style={webStyle.Allignment}>
                <Typography style={webStyle.QuestionList}>
                  {data.attributes.question_title}
                </Typography>
                <Typography style={webStyle.SubQuestionList}>
                  {index+1}. {data.attributes.question}
                </Typography>
                <Box style={webStyle.BoxStyle}>
                  <RadioGroup
                    test-ID="qustionAll"
                    aria-labelledby="demo-controlled-radio-buttons-group"
                    name="childGradeLevel"
                    onFocus={this.hideError}
                    onChange={(e) => this.selectedStyleAnswer(e, data.id)}
                  >
                    {data.attributes.options.data.map(
                      (dataOption: AllQuestionOption, index: number) => (
                        <FormControlLabel
                          key={index}
                          style={webStyle.labelWidth}
                          checked={this.filterSeletionStyle(data.id, dataOption.id)}
                          value={dataOption.id}
                          control={<GreenRadiobutton size="small" />}
                          label={
                            <span style={webStyle.radio}>
                              {dataOption.attributes.option}
                            </span>
                          }
                        />
                      )
                    )}
                  </RadioGroup>
                </Box>
                <Box>
                  {this.state.styleQuestionId.includes(data.id) ? (
                    <small style={webStyle.errorStyle}>
                      {this.state.errors.supportLevel}
                    </small>
                  ) : (
                    <small style={{ visibility: "hidden" }}>""</small>
                  )}
                </Box>
                {this.createBoxWithBreakline()}
              </Box>
            ))}
          </>
        );
      };

      createBoxWithBreakline() {
        return (
          <Box style={webStyle.Breakline}>
            <p />
          </Box>
        );
      }
    // Customizable Area End

    render() {
        return (
            // Customizable Area Start
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <AppWithoutDrawer navigation={this.props.navigation} />
            <Sidebarmenu activeKey="Overview" />
            <ThemeProvider theme={theme}>
              <Container maxWidth={false}>
                <Grid container style={{ display: "block" }}>
                  <Grid item>
                    <Container maxWidth={false}>
                      <Container maxWidth={false} style={{ margin: '0 auto' }}>
                        <Box style={{ marginTop: '80px', display: 'flex', justifyContent: 'space-between' }}>
                          <Typography style={webStyle.welcomeTextStyle} >Homeschooling Style</Typography>
                        </Box>

                        <Grid container style={{ display: 'flex' }}>
                          <HorizontalLine />
                        </Grid>
                        <Container maxWidth={false} style={webStyle.GridWrapper}>
                          {this.state.recommendStyleAnswer.length === 0 ?
                            <>
                              {this.allOtherQuestion()}
                              <Box style={{
                                textAlign: "left",
                                paddingBottom: '4em',
                                marginTop: '2em',
                                display: "flex",
                                justifyContent: "end"
                              }}>
                                <Snackbar test-ID="closeSnack" anchorOrigin={{
                                  vertical: 'top',
                                  horizontal: 'center',
                                }} open={this.state.snackbarsOpen} autoHideDuration={2000} onClose={this.handleClose} >
                                  <Alert severity="success">
                                    Saved to Draft Successfully
                                  </Alert>
                                </Snackbar>
                                {this.state.styleQuestion.length > 0 &&
                                  <>
                                    <Box style={{ paddingRight: '1em' }}>
                                      <Button test-ID="saveToDraft" style={{ textAlign: "center", textTransform: "none", ...webStyle.homeSchoolStyleSaveToDraft }}
                                        disabled={this.state.styleAnswerArray.length === 0 ? true : false}
                                        onClick={this.SaveToDraftStyle}
                                      >Save to Draft</Button>
                                    </Box>

                                    <Button test-ID="submitQuestion" style={{ textAlign: "center", textTransform: "none", ...webStyle.homeSchoolStyle }}
                                      onClick={() => this.handleStyleAnswersSubmit()}
                                    >{this.state.homeSchoolStyleSubmit ? <CircularProgress /> : "Submit"}</Button>
                                  </>
                                }
                              </Box>
                            </>
                            :
                            <Box style={webStyle.AllignmentNew}>
                              <Typography style={webStyle.QuestionList}>
                                HG Recommends the below home school style
                              </Typography>
                              <Box style={webStyle.answerMargin}>
                                {this.state.recommendStyleAnswer.map((data: Answer) => (
                                  <Card style={webStyle.answerCard}>
                                    <CardContent>
                                      <Grid
                                        container
                                        style={webStyle.cardFlex}
                                      >
                                        <Avatar
                                          style={webStyle.avatarRadious}
                                          alt="Avatar"
                                          src={avatar}
                                        />
                                        <div style={webStyle.cardContent}>
                                          <Typography style={webStyle.answerHeading}>
                                            {data.attributes.style_name}
                                          </Typography>
                                          <Typography
                                            style={{ fontFamily: "Montserrat" }}
                                          >
                                            {data.attributes.description}
                                          </Typography>
                                        </div>
                                      </Grid>
                                    </CardContent>
                                  </Card>
                                ))}
                              </Box>
                              <Grid item xs={12} style={{ marginTop: '32px', textAlign: "right" }}>
                                <Button
                                  variant="contained"
                                  style={webStyle.backButtonStyles2 as React.CSSProperties}
                                  data-test-id="backBtn"
                                  test-ID="backBtn"
                                  onClick={this.gotoHome}
                                >
                                  Go to Home
                                </Button>
                              </Grid>
                            </Box>
                          }
                        </Container>
                      </Container>
                    </Container>
                  </Grid>
                </Grid>
              </Container>
            </ThemeProvider>
          </div>
            // Customizable Area End

        );
    }
}

// Customizable Area Start
const webStyle = {
    homeSchoolStyle: {
        height: "52px",
        width: '200px',
        fontFamily: "Montserrat",
        fontSize: "16px",
        color: "#fafafb",
        fontWeight: 600,
        borderRadius: "10px",
        backgroundColor: "#fab03d",
    },
    homeSchoolStyleSaveToDraft: {
        height: "52px",
        width: '200px',
        fontFamily: "Montserrat",
        fontSize: "16px",
        color: "#fafafb",
        fontWeight: 600,
        borderRadius: "10px",
        backgroundColor: "#b9b9b9",
    },

    Breakline: {
        width: '100%', height: '1px', backgroundColor: '#dcdcdc'
    },
    BoxStyle: {
        marginTop: '0.5em'
    },
    QuestionList: {
        color: "#171725",
        fontFamily: "Montserrat",
        fontSize: "20px",
        fontWeight: 600
    },
    SubQuestionList: {
        color: "#171725",
        paddingTop: '1em',
        fontFamily: "Montserrat",
        fontSize: "14px",
        fontWeight: 600
    },
    errorStyle: {
        padding: '5px',
        color: 'red',
        fontFamily: "Montserrat",
        fontSize: ' 12px',
    },
    radio: {
        color: "#44444f",
           fontFamily: "Montserrat",
        fontSize: "14px",
        fontWeight: 400,
    },
    welcomeTextStyle: {
        color: '#171725',
        fontFamily: "Montserrat",
        fontSize: '24px',
        fontWeight: 600,
    },
    GridWrapper: {
        borderRadius: "15px",
        background: "#ffffff",
        border: "1px solid #f1f1f5",

        marginTop: "2em",
        boxShadow: "rgba(68, 68, 79, 0.1) 0px 5px 15px"
    },
    Allignment: {
        paddingTop: "2em",
        paddingLeft: "1em",
      },
      AllignmentNew: {
        padding: "2em 1em",
      },
      labelWidth: {
        maxWidth: "fit-content",
      },
      answerMargin: {
        marginTop: "20px",
      },
      answerCard: {
        marginBottom: "10px",
        boxShadow: "none",
        border: "0.5px solid #B9B9B9",
        borderRadius: "11px",
      },
      cardFlex: {
        display: "flex",
        alignItems: "start",
      },
      avatarRadious: {
        borderRadius: "25%",
      },
      answerHeading: {
        color: "#171725",
        fontFamily: "Montserrat",
        fontSize: "22px",
        fontWeight: 600,
      },
      cardContent: {
        marginLeft: "10px",
        width: "90%",
      },
      backButtonStyles2: {
        width: "200px",
        height: "52px",
        fontFamily: "Montserrat",
        fontSize: "16px",
        color: "#fafafb",
        fontWeight: 600,
    
        borderRadius: "10px",
        backgroundColor: "#9167ac",
        textTransform: "none",
        display: 'inline'
      },
};
// Customizable Area End
