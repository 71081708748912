import React from "react";

import {
  Container,
  Box,
  Button,
  Typography,
  // Customizable Area Start
  Step, 
  Stepper,
  StepLabel,
  Snackbar,
  Grid
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { createTheme, ThemeProvider } from "@material-ui/core/styles";

import CircularProgress from '@material-ui/core/CircularProgress';
import Alert from '@material-ui/lab/Alert';

// forms
import FormStep1 from './FormStep1'

const theme = createTheme({
  palette: {
    primary: {
      main: "#nnn",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
  overrides: {
    MuiStepIcon: {

      root: {
        height: '48px',
        width: '48px',
        textAlign: 'center',
        color: '#fff',
        border: '1px solid #9167ac',
        borderRadius: '50px',
        '&$completed': {
          color: '#9167ac',
          height: '48px',
          width: '48px',
          border: 'none',
        },
        '&$active': {
          color: 'rgba(145, 103, 172, 0.5)',
          border: 'none',
        },

        '&$line': {
          borderColor: 'red',
        },

      },

      text: {
        fill: '#9167ac',
        ffontFamily: "Montserrat",
      },
    },
    MuiStepConnector: {
      alternativeLabel: {
        top: '24px',
        left: 'calc(-50% + 25px)',
        right: 'calc(50% + 25px)',
      },
      line: {
        borderColor: '#9167ac',
      }
    },
    MuiPaper: {
      root: {
        backgroundColor: 'none',
      }
    }
  },
});
// Customizable Area End

import CustomformEnrollmentController, {
  Props,
} from "./CustomformEnrollmentController";
import Sidebarmenu from "../../../components/src/Sidebarmenu.web";

export default class CustomformEnrollment extends CustomformEnrollmentController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  getStepContent = (stepIndex: number) => {
    switch (stepIndex) {
      case 0:
        return (
          <FormStep1
            navigation={null}
            id=""
            citiesList={this.state.citiesList}
            barangayList={this.state.barangayList}
            religionList={this.state.religionList}
            nationalitiesList={this.state.nationalitiesList}
            languageList={this.state.languageList}
            scholarshipList={this.state.scholarshipList}
            countryList={this.state.countryList}
            uniqueCountryCodeList={this.state.uniqueCountryCodeList}
          />);

      case 1:
        return (
          <></>
        );

      case 2:
        return null;

      default:
        break;
    }
  }
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <ThemeProvider theme={theme}>
        <Sidebarmenu activeKey="enroll" />
        <Container maxWidth={false} style={{ padding: '0', marginLeft: '50px' }}>
          <Container maxWidth={false} style={{ margin: '0 auto', paddingRight: '1.1em' }}>
            <Box style={{ marginTop: '100px', display: 'flex', justifyContent: 'space-between' }}>
              <Typography style={webStyle.welcomeTextStyle} >
                Student Enrollment</Typography>
              <span style={{ width: '50%', display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}>
                {this.state.loadingStudent && localStorage.getItem('studentID') ? <CircularProgress /> : null}
              </span>
            </Box>
          </Container>
          <Grid container style={{ display: 'flex', margin: '0' }}>
            {/* <HorizontalLine /> */}
            <Grid item xs={12} style={{ marginTop: '20px', padding: '0' }}>
              <Stepper activeStep={this.state.activeStep} alternativeLabel>
                {this.getSteps().map((label: any) => (
                  <Step key={label}>
                    <StepLabel StepIconProps={{
                      classes: {
                        text: 'red',
                      }
                    }}>{label}</StepLabel>
                  </Step>
                ))}
              </Stepper>
            </Grid>
            <Box style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}></Box>

            <Container maxWidth={false} style={webStyle.mainWrapper}>
              {this.getStepContent(this.state.activeStep)}
              <Grid container spacing={4} >
                <Grid item xs={12} style={{ marginBottom: '3em', paddingRight: '5em', textAlign: "right" }}>
                  <Button variant="contained" style={{
                    width: "200px",
                    height: "52px",
                    fontFamily: "Montserrat",
                    fontSize: "16px",
                    color: "#fafafb",
                    fontWeight: 600,
                    textAlign: "center",
                    borderRadius: "10px",
                    backgroundColor: "#9167ac",
                    textTransform: "none",
                    ...this.state.activeStep === 0 ? { display: 'none' } : { display: 'inline' },
                    ...this.state.activeStep === 7 && { display: 'none' }
                  }}
                    onClick={this.handleBackMain}
                  >Back</Button>

                  <Button variant="contained" style={{
                    width: "200px",
                    height: "52px",
                    color: "#fafafb",
                    fontFamily: "Montserrat",
                    fontSize: "16px",
                    fontWeight: 600,
                    textAlign: "center",
                    borderRadius: "10px",
                    marginLeft: '1em',
                    backgroundColor: "#b9b9b9",
                    textTransform: "none",
                    ...this.state.activeStep > 5 ? { display: 'none' } : { display: 'inline' }

                  }}
                    onClick={this.onClickNextOfPrevSchool}>
                    {this.state.isLoading ? <CircularProgress /> : "Save to Draft"}
                  </Button>

                  <Snackbar anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                  }} open={this.state.snackbarOpen} autoHideDuration={2000} onClose={this.handleCloseSnackbar} >
                    <Alert severity="success">
                      {this.state.snackbarMessage}
                    </Alert>
                  </Snackbar>

                  <Button data-test-id="btnNext" variant="contained" style={{
                    width: "200px",
                    height: "52px",
                    fontFamily: "Montserrat",
                    fontSize: "16px",
                    fontWeight: 600,
                    color: "#fafafb",
                    textAlign: "center",
                    backgroundColor: "#fab03d",
                    borderRadius: "10px", marginLeft: '1em',
                    textTransform: "none",
                    ...this.state.activeStep === 7 && { display: 'none' }
                  }} onClick={this.handleNextMain}>
                    {(this.state.activeStep > 5 && !this.state.isLoading ) && 'Submit'}
                    {(!this.state.isLoading && this.state.activeStep < 5) && 'Next'}
                    {this.state.isLoading && <CircularProgress /> }
                  </Button>
                </Grid>
              </Grid>
            </Container>
          </Grid>

        </Container>
      </ThemeProvider>
      // Customizable Area End
    );
  }
}

// Customizable Area Start

const webStyle = {
  mainWrapper: {
    borderRadius: " 50px",
    border: " 1px dashed #f1f1f5",
    background: "#ffffff",
    backgroundBlendMode: "normal",
    boxShadow: "0px 5px 15px rgba(68,68,79,0.1)",
    marginTop: '20px',
    padding: '0',
    marginBottom: '6em',
  },
  heading: {
    left: "53px",
    top: "57px",
    width: " 344px",
    height: " 30px",
    color: " #171725",
    ffontFamily: "Montserrat",
    fontSize: "20px",
    fontWeight: 600,
    lineHeight: "30px",
    letterSpacing: "0.08333328px",
    marginTop: "1em",
    marginBottom: "1em"
  },
  imgLogoBoxStyle: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',

  },
  welcomeTextStyle: {
    color: '#171725',
   fontFamily: "Montserrat",
    fontSize: '24px',
    fontWeight: 600,
   },
  imgLogoStyle: {
    width: '100%',
  },
  avatarStyle: {
    width: '32px',
    height: '32px',
    marginRight: '10px'
  },
  inputSelectLabelStyle: {
    width: '220px',
    fontFamily: "Montserrat",
    height: '22px',
    color: '#555555',
    fontSize: '12px',
    lineHeight: '22px',
    fontWeight: 400,
    marginBottom: "10px"
  },
  inputBoxStyle: {
    marginTop: '30px',
  },
  inputSelectLabelStyleselect: {
    width: '220px',
    fontSize: '12px',
    height: '22px',
    color: '#555555',
    fontFamily: "Montserrat",
    fontWeight: 400,
    lineHeight: '22px',
    marginBottom: "-1em"
  },
  inputLabelStyle: {
    color: "#757575",
    marginBottom: '10px',
    width: '220px',
    height: '22px',
    fontFamily: "Montserrat",
    fontWeight: 400,
    fontSize: '12px',
    lineHeight: '22px',
  },
  mainInnerWrapper: {
    flex: 1,
    display: "flex",
    alignItems: "stretch",
    justifyContent: "center",
    padding: '2em',
    top: "2.69px",
    width: "420px",
    height: "60.25px"
  },
  leftWrapper: {
    borderTopLeftRadius: 20,
    borderBottomLeftRadius: 20,
    display: "flex",
    flexDirection: "column" as "column",
    alignItems: "center",
    justifyContent: "center",
  },
  innerRightStyle: {
    padding: "10px 30px",
  }, imgStyle: {
    width: '100%',
  },
  gridTextStyle: {
    marginTop: '30px',
    color: '#171725',
   fontFamily: "Montserrat",
    fontSize: '20px',
    fontWeight: 600,
    lineHeight: '30px',
    lineSpacing: '0.08333328px',
  },
  gridContentContainerStyle: {
    marginTop: '10px',
    border: '1px solid #f1f1f5',
    borderRadius: '50px',
    backgroundColor: '#ffffff',
    paddingLeft: '30px',
    paddingRight: '30px',
  },
  innerLeftStyle: {
    display: "flex",
    flexDirection: "column" as "column",
    alignItems: "center",
    justifyContent: "center",
    padding: "50px",
    textAlign: "center",
  },
  gridContainerStyle: {
    display: 'flex',
    flexDirection: 'row' as 'row',
    alignItems: 'stretch',
    justifyContent: 'center',
  },
  gridItemsLeftStyle: {
    display: 'flex',
    flexDirection: 'column' as 'column',
    flex: 1,
    marginRight: '20px',
    marginBottom: '30px',
  },
  gridItemsRightStyle: {
    display: 'flex',
    flexDirection: 'column' as 'column',
    flex: 1,
    marginLeft: '20px',
    marginBottom: '30px',
  },
  forgotHeading: {
    justifyContent: "center",
    alignItems: "left",
    alignText: "left",
  },
  rightWrapper: {
    backgroundColor: "#fbfbfb",
    borderTopRightRadius: 20,
    borderBottomRightRadius: 20,
  },
  buttonStyle: {
    width: "100%",
    height: "45px",
    marginTop: "40px",
    border: "none",
    backgroundColor: "rgb(98, 0, 238)",
  },
};
// Customizable Area End
