import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import moment from 'moment';

export interface SubCategoryCategorySelection {
  [key: string]: number | string | null;
}

export interface InialGrade {
  data: GradeObject[]
}

export interface GradeObject {
  id: string
  type: string
  attributes: GradeAttribute
}

export interface GradeAttribute {
  title: string
}


export interface Document {
  id: number;
  content_type: string;
  url: string;
  file_size: string;
  name: string;
}

export interface SelectionStyleAnswer {
  draftedID: number;
  answerID: number;
}

export interface DocumentEntry {
  [key: string]: Document | string | null;
  label: string;
}

import { getStorageData } from "../../../framework/src/Utilities";
import { toggleCheckboxState } from "../../../components/src/asset";

export interface DraftAnswer {
  grade: Grade;
  recommendations: Recommendations;
}

export interface Grade {
  id: number;
  grade: string;
  priority: null;
  grade_organisers_id: number
}

export interface Recommendations {
  data: Datum[];
}

export interface Datum {
  id: string;
  type: string;
  attributes: RecommendationsObject;
}

export interface RecommendationsObject {
  recommender_type: string;
  question: Question;
  option: Option;
  status: string;
  grade: Grade;
}

export interface Option {
  id: number;
  option: string;
}

export interface Question {
  id: number;
  question_title: string;
  question: string;
}


export interface HomeProgramQuestionOptionAttributes {
  title: string;
}

export interface HomeProgramQuestionOption {
  id: string;
  type: string;
  attributes: HomeProgramQuestionOptionAttributes;
}

export interface OptionsData {
  data: HomeProgramQuestionOption[];
}

export interface HomeProgramQuestionAttributes {
  question_title: string;
  question: string;
  options: OptionsData;
}

export interface HomeProgramQuestion {
  id: string;
  type: string;
  attributes: HomeProgramQuestionAttributes;
}

export interface AllQuestionOptionAttributes {
  option: string;
}

export interface AllQuestionOption {
  id: string;
  type: string;
  attributes: AllQuestionOptionAttributes;
}

export interface AllOptionsData {
  data: AllQuestionOption[];
}

export interface AllQuestionAttributes {
  grade: string;
  question_title: string;
  question: string;
  options: AllOptionsData;
}

export interface AllQuestion {
  id: string;
  type: string;
  attributes: AllQuestionAttributes;
}

export interface AllQuestionsData {
  data: AllQuestion[];
}

interface RecommenderAnswer {
  recommender_type: string;
  question_id: number;
  option_id: number;
}

export interface Root {
  data: Daum[]
}

export interface Daum {
  id: string
  type: string
  attributes: Attributes
}

export interface Attributes {
  first_name: string
  middle_name: string
  last_name: string
  account_id: number
  gender: string
  date_of_birth: string
  country_of_birth: string
  city_of_birth: string
  birth_barangay_id: any
  religion_id: number
  other_religion: string
  nationality_id: number
  country_of_residence: string
  city_of_residence: string
  child_first_language: number
  other_language: string
  scholarship: boolean
  scholarship_category: string
  scholarship_type_id: number
  proof_of_relationship: string
  father_cause_of_death: string
  specification: string
  referral: boolean
  referral_code: string
  pwd_discount: boolean
  pwd_id: string
  solo_parent_discount: boolean
  solo_parent_id: string
  residential_address: string
  status: string
  barangay_id: number
  reason_of_registering_id: any
  barangay: any
  birth_barangay: any
  reason_of_registering: any
  child_language: string
  religion: string
  nationality: string
  scholarship_type: string
  school_year: string
  submitted_date: string
  father_death_date: string
  parent: Parent
  previous_school: PreviousSchool
  program_detail: ProgramDetail
  letter_of_recommendation: DocProof
  pwd_id_proof: DocProof
  psa_birth_certificate: DocProof
  recent_report_card: DocProof
  school_records: DocProof
  learner_reference_number: DocProof
  solo_parent_id_proof: DocProof
  parent_valid_id: DocProof
  proof_of_achievements: DocProof
  church_affiliation_letter: DocProof
  proof_of_income_or_billing: DocProof
  father_death_certificate: DocProof
  student_birth_certificate: DocProof
  family_details_letter: DocProof
  electric_bill: DocProof
  parent_birth_certificate: DocProof
  luova_staff_birth_certificate: DocProof
  id_picture: DocProof
}

export interface Parent {
  data: Data
}

export interface Data {
  id: string
  type: string
  attributes: Attributes2
}

export interface Attributes2 {
  student_id: number
  father_first_name: string
  father_middle_name: string
  father_last_name: string
  father_occupation: string
  father_country_code: number
  father_mobile_number: number
  father_email: string
  mother_first_name: string
  mother_middle_name: string
  mother_last_name: string
  mother_occupation: string
  mother_country_code: number
  mother_mobile_number: number
  mother_email: string
  marital_status: string
  guardian_name: string
  guardian_address: string
  guardian_occupation: string
  guardian_country_code: number
  guardian_mobile_number: number
  guardian_email: string
  relationship_to_the_student: string
}

export interface PreviousSchool {
  data: Data2
}

export interface Data2 {
  id: string
  type: string
  attributes: Attributes3
}

export interface Attributes3 {
  student_id: number
  grade_id: number
  school_attended: string
  school_curriculum_id: number
  school_email: string
  school_contact_number: string
  school_address: string
  last_year_of_school: string
  learner_reference_number: number
  other_curriculum: string
  grade: string
  school_curriculum: string
}

export interface ProgramDetail {
  data: Data3
}

export interface Data3 {
  id: string
  type: string
  attributes: Attributes4
}

export interface Attributes4 {
  student_id: number
  hub_id: number
  community_id: number
  grade_id: number
  program_id: number
  accreditation_id: number
  cycle: string
  shs_track: string
  hub: string
  community: string
  grade: string
  program: string
  accreditation: string
}

export interface DocProof {
  id: number
  content_type: string
  url: string
  file_size: string
  name: string
}

export interface StyleAnswerArray {
  data: StyleAnswer[]
}

export interface StyleAnswer {
  id: string
  type: string
  attributes: StyleAnswerAttributes
}

export interface AnswerAttributes {
  program_name: string
  description: string
  priority: number
}

export interface AnswerArray {
  data: Answer[]
}

export interface Answer {
  id: string
  type: string
  attributes: AnswerAttributes
}

export interface StyleAnswerAttributes {
  style_name: string
  description: string
  priority: number
}


// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  showPopover: boolean,
  anchorEl: HTMLElement | null,
  popoverAnchor: any,
  studentsListdata: any,
  homeSchoolingprogram: any;
  valueShow: any,
  loading: any,
  getStudentsViewId: any
  studentId: any;
  filteredStudents: any;
  errors: any;

  childGradeLevel: any;
  supportLevel: any;
  parentalInvolvement: any;
  HomeschoolCurriculum: any;
  primaryMedium: any;
  parentAdvisor: any;
  learningProgress: any;
  assessment: any;
  howManyChildrenAreYouPlanningToEnroll: any;
  tlcFilter: any;
  extendedSupportForMyChild: any;
  doYouHaveAnotherChild: any;
  liveOnlineClasses: any;
  yearsHomeSchooling: any;
  homeSchoolingStyleGeneral: any;
  snackbarsOpen: boolean;
  snackbarOpenProgram: boolean;
  homeSchoolProgramSubmit: any;
  homeSchoolingStyleLearningStyleOfTheChild: any,
  homeSchoolingStyleCurriculumAndMaterials: any,
  homeSchoolingStyleEducationalPhilosophyg: any,
  homeSchoolingStyleSchedule: any,
  homeSchoolingStyleParentalInvolvement: any,
  homeSchoolStyleSubmit: boolean,
  studentData: string,
  checkboxState: boolean,
  selectedStudentIds: number[];
  checkboxStudState:boolean,
  fileName:boolean,
  firstQuestion: HomeProgramQuestion
  authToken: string
  allQuestion: AllQuestionsData 
  filterQuestionId: string[]
  answerArray: RecommenderAnswer[]
  documentList: DocumentEntry[]
  finalDocumentList: DocumentEntry[]
  recommendAnswer: Answer[]
  styleQuestion: AllQuestion[]
  styleQuestionId: string[]
  styleAnswerArray: RecommenderAnswer[]
  recommendStyleAnswer: StyleAnswer[]
  initialGrade: GradeObject[]
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start

  // Customizable Area End
}

export default class Customform3Controller extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  GetStudentsdataId: string = "";
  GetStudentsViewdataById: string = ""
  apiRequestCallId: any = ""
  apiGetProgramList: string = "";
  apiSaveDraftProgram: string = ''
  apiSubmitProgram: string = ''
  apiGetAnswer: string = ''
  apiGetStyleQuestion: string = ''
  apiSubmitStyle: string = ''
  apiGetStyleAnswer: string = ''
  apiGetSubmittedProgram: string = ''
  apiGetDraftStyle: string = ''
  apiGetInitialGrade: string = ''
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage)
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      showPopover: false,
      anchorEl: null,
      popoverAnchor: null,
      valueShow: "All",
      studentsListdata: [],
      homeSchoolingprogram: [],
      filteredStudents: [],
      loading: true,
      getStudentsViewId: "",
      studentId: 0,
      errors: {},
      childGradeLevel: "",
      supportLevel: "",
      parentalInvolvement: "",
      HomeschoolCurriculum: "",
      primaryMedium: "",
      parentAdvisor: "",
      learningProgress: "",
      assessment: "",
      howManyChildrenAreYouPlanningToEnroll: "",
      tlcFilter: "",
      extendedSupportForMyChild: "",
      doYouHaveAnotherChild: "",
      liveOnlineClasses: "",
      yearsHomeSchooling: "",
      homeSchoolingStyleGeneral: "",
      snackbarsOpen: false,
      snackbarOpenProgram: false,
      homeSchoolProgramSubmit: false,
      homeSchoolingStyleLearningStyleOfTheChild: "",
      homeSchoolingStyleCurriculumAndMaterials: "",
      homeSchoolingStyleEducationalPhilosophyg: "",
      homeSchoolingStyleSchedule: "",
      homeSchoolingStyleParentalInvolvement: "",
      homeSchoolStyleSubmit: false,
      studentData: "",
      checkboxState: true,
      selectedStudentIds: [],
      checkboxStudState:true,
      fileName:true,
      firstQuestion: {
        id: "",
        type: "",
        attributes: {
          question_title: "",
          question: "",
          options: {
            data: []
          }
        }
      },
      authToken: '',
      allQuestion: {
        data: []
      },
      filterQuestionId: [],
      styleQuestionId: [],
      answerArray: [],
      styleAnswerArray: [],
      documentList: [
        { letter_of_recommendation: null, label: "Letter of recommendation" },
        { pwd_id_proof: null, label: "PWD ID" },
        { psa_birth_certificate: null, label: "Birth Certificate" },
        { recent_report_card: null, label: "Report Card" },
        { school_records: null, label: "Transcript/School Records" },
        { learner_reference_number: null, label: "Learner Reference Number" },
        { solo_parent_id_proof: null, label: "Solo Parent ID" },
        { parent_valid_id: null, label: "Valid Parent ID" },
        { proof_of_achievements: null, label: "Proof of achievements" },
        { church_affiliation_letter: null, label: "Letter from Church Affiliation" },
        { proof_of_income_or_billing: null, label: "Proof of income/billing" },
        { father_death_certificate: null, label: "Death certificate of the father" },
        { student_birth_certificate: null, label: "Birth certificate of the student" },
        { family_details_letter: null, label: "Letter to committee" },
        { electric_bill: null, label: "Electric bill" },
        { parent_birth_certificate: null, label: "Birth certificate of Parent" },
        { luova_staff_birth_certificate: null, label: "Birth certificate of Luova staff" },
        { id_picture: null, label: "ID Picture" }
      ],
      finalDocumentList: [],
      recommendAnswer: [],
      recommendStyleAnswer: [],
      styleQuestion: [],
      initialGrade: []
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

      this.showAlert(
        "Change Value",
        "From: " + this.state.txtSavedValue + " To: " + value
      );

      this.setState({ txtSavedValue: value });
    }

    // Customizable Area Start


    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
      let responseJson: any = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      let errorReponse: any = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      if (apiRequestCallId !== null) {
        if (apiRequestCallId === this.GetStudentsdataId) {
          this.setStudentsListApi(responseJson, errorReponse);
          console.log("setStudentsListApi",responseJson)
        }
        else if (apiRequestCallId === this.GetStudentsViewdataById) {
          this.setStudentsViewdetalsfuncApi(responseJson, errorReponse);
        }

      }

      switch (apiRequestCallId) {
        case this.apiGetProgramList:
          this.setAllQuestion(responseJson)
          break;
        case this.apiSaveDraftProgram:
          if (responseJson.data) {
            this.setState({snackbarOpenProgram:true})
          }
          break;
        case this.apiSubmitProgram:
          this.getRecommendAnswer();
          break;
        case this.apiGetAnswer:
          this.recommendselectedAnswer(responseJson);
          break;
        case this.apiGetStyleQuestion:
          this.setStyleQuestion(responseJson);
          break;
        case this.apiSubmitStyle:
          this.getStyleRecommendAnswer();
          break;
        case this.apiGetStyleAnswer:
          this.styleRecommendselectedAnswer(responseJson);
          break;
        case this.apiGetSubmittedProgram:
          this.setDraftedAnswer(responseJson);
          break;
        case this.apiGetDraftStyle:
          this.getDraftedStyleQuestionAnswer(responseJson);
          break;
        case this.apiGetInitialGrade:
          this.setInitialGrade(responseJson);
          break;
        default: 
          break;
      }
    }
    // Customizable Area End
  }

  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false,
  };

  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address",
  };

  txtInputProps = this.isPlatformWeb()
    ? this.txtInputWebProps
    : this.txtInputMobileProps;

  btnShowHideProps = {
    onPress: () => {
      this.setState({ enableField: !this.state.enableField });
      this.txtInputProps.secureTextEntry = !this.state.enableField;
      this.btnShowHideImageProps.source = this.txtInputProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    },
  };

  btnShowHideImageProps = {
    source: this.txtInputProps.secureTextEntry
      ? imgPasswordVisible
      : imgPasswordInVisible,
  };

  changecheckboxState = (id: number) => {
    this.setState(prevState => {
      const { selectedStudentIds } = prevState;
      const updatedIds = toggleCheckboxState(selectedStudentIds, id);
      return { selectedStudentIds: updatedIds };
    });
  }

 changeStudListState= () => {
  if (this.state.checkboxStudState) {
    this.setState({ checkboxStudState: false })
  }
  else {
    this.setState({ checkboxStudState: true })
  }
}
  

  changefileNameState = () => {
    if (this.state.fileName) {
      this.setState({ fileName: false })
    }
    else {
      this.setState({ fileName: true })

    }
  } 
  
  btnExampleProps = {
    onPress: () => this.doButtonPressed(),
  };

  doButtonPressed() {
    let msg = new Message(getName(MessageEnum.AccoutLoginSuccess));
    msg.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.txtInputValue
    );
    this.send(msg);
  }

  // web events
  setInputValue = (text: string) => {
    this.setState({ txtInputValue: text });
  };

  setEnableField = () => {
    this.setState({ enableField: !this.state.enableField });
  };

  // Customizable Area Start

  async componentDidMount() {
    super.componentDidMount();
    this.studentsdetailsdata();
    const getauthToken = await getStorageData("authToken");
    this.setState({ authToken: getauthToken })
    this.getInitialGrade();
    this.getStyleQuestion();
    this.getSubmittedProgramAnswer();
    const path: string = String(window.location)
    const finalRoute = path.split('/').pop()
    if (finalRoute === 'studentsDetails') {
      const studentId = await getStorageData("studentId");
      this.setState({ studentId: studentId }, () => {
        this.studentsViewDeatilsByID(this.state.studentId)
      })
    }

  }

  async componentDidUpdate(prevProps: any, prevState: any) {
    if (this.state.styleQuestion !== prevState.styleQuestion) {
      this.getDraftedStyleAnswer();
    }
  }
  studentsdetailsdata = () => {

    const header = {
      'token': localStorage.getItem('authToken')
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.GetStudentsdataId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.StudentsListendPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  };
  studentsViewDeatilsByID = (studentId: any) => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      'token': localStorage.getItem('authToken')

    };



    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.GetStudentsViewdataById = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.StudentsViewDeatislEndPoint + studentId
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

  };


  handleChangeShow = (event: any) => {

    this.setState({ valueShow: event?.target.value, })


  }
    ;
  openPopover = (e: any, id: any) => {

    this.setState({
      popoverAnchor: e?.currentTarget, studentId: id,
      showPopover: true,
    })

  }
  handleClosePopover = () => {
    this.setState({ popoverAnchor: null })
  }

  setStudentsListApi = (responseJson: any, errorReponse: any) => {

    this.setState({
      loading: false,
    })
    if (responseJson && responseJson.data) {

      this.setState({
        studentsListdata: responseJson.data,
      })
      console.log("studentsListdata", this.state.studentsListdata)
    }
    else {
      this.parseApiCatchErrorResponse(errorReponse);
    }

  }

  extractDocuments(dataResponse: Attributes): DocumentEntry[] {
    const documents: DocumentEntry[] = [];
  
  
    this.addMandatoryDocuments(documents, dataResponse);
  
    this.addScholarshipDocuments(documents, dataResponse);
    this.addConditionalDocuments(documents, dataResponse);
  
    return documents;
  }
  addMandatoryDocuments(documents: DocumentEntry[], dataResponse: Attributes): void {
    const mandatoryFiles = [
      { key: 'psa_birth_certificate', label: 'Birth Certificate' },
      { key: 'id_picture', label: 'ID Picture' },
      { key: 'parent_valid_id', label: 'Valid Parent ID' }
    ];
  
    mandatoryFiles.forEach(file => {
      if (dataResponse[file.key as keyof Attributes]) {
        documents.push({ [file.key]: dataResponse[file.key as keyof Attributes], label: file.label });
      }
    });
  }
  
  // Helper to add scholarship-related documents
  addScholarshipDocuments(documents: DocumentEntry[], dataResponse: Attributes): void {
    const scholarshipDocuments: { [key: string]: { key: string; label: string }[] } = {
      'Chrissie Gracia Scholarship': [
        { key: 'letter_of_recommendation', label: 'Letter of Recommendation' }
      ],
      'The International/National Achievement Scholarship': [
        { key: 'letter_of_recommendation', label: 'Letter of Recommendation' },
        { key: 'proof_of_achievements', label: 'Proof of achievements' }
      ],
      'The Ministry Leaders Assistance': [
        { key: 'church_affiliation_letter', label: 'Letter from Church Affiliation' }
      ],
      'Scholarship for HG Servant Leaders': [
        { key: 'letter_of_recommendation', label: 'Letter of Recommendation' }
      ],
      'Financial Aid': [
        { key: 'letter_of_recommendation', label: 'Letter of Recommendation' },
        { key: 'proof_of_income_or_billing', label: 'Proof of income/billing' }
      ],
      'The Jovi Villote Memorial Scholarship': [
        { key: 'father_death_certificate', label: 'Death Certificate of the Father' },
        { key: 'student_birth_certificate', label: 'Birth certificate of the student' },
        { key: 'family_details_letter', label: 'Letter to committee' },
        { key: 'electric_bill', label: 'Electric Bill' }
      ],
      'HG Staff Student Scholarship': [
        { key: 'parent_birth_certificate', label: 'Birth certificate of Parent' },
        { key: 'luova_staff_birth_certificate', label: 'Birth Certificate of Luova staff' }
      ]
    };

    const scholarshipType = dataResponse.scholarship_type as keyof typeof scholarshipDocuments;
    if (scholarshipType && scholarshipDocuments[scholarshipType]) {
      scholarshipDocuments[scholarshipType].forEach(doc => {
        if (dataResponse[doc.key as keyof Attributes]) {
          documents.push({ [doc.key]: dataResponse[doc.key as keyof Attributes], label: doc.label });
        }
      });
    }
  }
  
  
  addConditionalDocuments(documents: DocumentEntry[], dataResponse: Attributes): void {
    const conditionalFiles = [
      { condition: dataResponse.pwd_discount, key: 'pwd_id_proof', label: 'PWD ID' },
      { condition: dataResponse.solo_parent_discount, key: 'solo_parent_id_proof', label: 'Solo Parent ID' }
    ];
  
    conditionalFiles.forEach(file => {
      if (file.condition && dataResponse[file.key as keyof Attributes]) {
        documents.push({ [file.key]: dataResponse[file.key as keyof Attributes], label: file.label });
      }
    });
  
    if (dataResponse.previous_school?.data && dataResponse.previous_school?.data?.attributes?.school_attended.toLowerCase() !== 'n/a') {
      const schoolFiles = [
        { key: 'recent_report_card', label: 'Original Report Card' },
        { key: 'school_records', label: 'Transcript/School Records' },
        { key: 'learner_reference_number', label: 'Learner Reference Number' }
      ];
  
      schoolFiles.forEach(file => {
        if (dataResponse[file.key as keyof Attributes]) {
          documents.push({ [file.key]: dataResponse[file.key as keyof Attributes], label: file.label });
        }
      });
    }
  }
  setStudentsViewdetalsfuncApi = (responseJson: any, errorReponse: any) => {
    if (responseJson) {
      const relevantDocuments = this.extractDocuments(responseJson.data.attributes);
      this.setState({ finalDocumentList: relevantDocuments})

      localStorage.setItem("getStudentsViewId", JSON.stringify(responseJson.data));

      this.setState({
        getStudentsViewId: responseJson.data,


      }

      )

    }
    else {
      this.parseApiCatchErrorResponse(errorReponse);
    }

  }


  getStudentId: any = (id: any) => {

    localStorage.setItem("studentId", this.state.studentId)
    this.studentsViewDeatilsByID(this.state.studentId)
    if (this.state.studentId) {
      setTimeout(() => {
        this.props.navigation.navigate('StudentsDetails',


        )
      }, 1000);
    }
  }
  handlePopoverClosepop = () => {
    this.setState({
      showPopover: false,
    });
  };

  formatSchoolYearDate = (schoolYear: string | undefined): string | null => {



    if (!schoolYear) {
      return null;
    }


    const formattedDate = moment(schoolYear, 'YYYY-MM-DD').format('DD-MM-YYYY');

    return formattedDate
  };

  filterStudents = () => {
    const { studentsListdata, valueShow, } = this.state;

    if (valueShow === 'All') {

      return studentsListdata
    } else {
      const count = parseInt(valueShow, 10);
      return studentsListdata.slice(0, count);
    }
  };

  hideError = (event: any) => {
   this.state.errors[event?.target.name] = '';
    this.setState({
      errors: this.state.errors
    })
  }

  handleCloseProgram = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === "clickaway") {
      return;
    }

    this.setState({ snackbarOpenProgram: false })
  };
  handleClose = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === "clickaway") {
      return;
    }

    this.setState({ snackbarsOpen: false })
  };



  homeStyleValidation = () => {
    let isFormValid = true;
    if (this.state.homeSchoolingStyleGeneral
      === '') {
      this.state.errors['homeSchoolingStyleGeneral'] = "Answer Required"
      isFormValid = false
    }
    if (this.state.homeSchoolingStyleParentalInvolvement === '') {
      this.state.errors['homeSchoolingStyleParentalInvolvement'] = "Answer Required"
      isFormValid = false
    }
    if (this.state.homeSchoolingStyleLearningStyleOfTheChild === '') {
      this.state.errors['homeSchoolingStyleLearningStyleOfTheChild'] = "Answer Required"
      isFormValid = false
    }
    if (this.state.homeSchoolingStyleCurriculumAndMaterials
      === '') {
      this.state.errors['homeSchoolingStyleCurriculumAndMaterials'] = "Answer Required"
      isFormValid = false
    }
    if (this.state.homeSchoolingStyleEducationalPhilosophyg === '') {
      this.state.errors['homeSchoolingStyleEducationalPhilosophyg'] = "Answer Required"
      isFormValid = false
    }
    if (this.state.homeSchoolingStyleSchedule === '') {
      this.state.errors['homeSchoolingStyleSchedule'] = "Answer Required"
      isFormValid = false
    }
    return isFormValid

  }


  handleStyleSubmitAnswers = async () => {
    if (this.homeStyleValidation()) {
      console.log("apicall")
    }
    else {

      this.setState({
        errors: this.state.errors
      })
    }
  }
  firsthandleChnage = (e: any) => {
    this.setState({ childGradeLevel: e.target.value },()=>{this.getProgramList();})
  }
  homecomplexityremovingFunc = () => {
    let isFormValid = true;
    if (this.state.childGradeLevel === '') {
      this.state.errors['childGradeLevel'] = "Answer Required"
      isFormValid = false
    }
    if (this.state.supportLevel === '') {
      this.state.errors['supportLevel'] = "Please choose an answer"
      isFormValid = false
    }
    if (this.state.parentalInvolvement === '') {
      this.state.errors['parentalInvolvement'] = "Answer Required"
      isFormValid = false
    }
    if (this.state.HomeschoolCurriculum
      === '') {
      this.state.errors['HomeschoolCurriculum'] = "Answer Required"
      isFormValid = false
    }
    if (this.state.primaryMedium === '') {
      this.state.errors['primaryMedium'] = "Answer Required"
      isFormValid = false
    }
    if (this.state.parentAdvisor === '') {
      this.state.errors['parentAdvisor'] = "Answer Required"
      isFormValid = false
    }
    return isFormValid
  }
  homeSchoolValidation = () => {
    let isFormValid = true;
    this.homecomplexityremovingFunc()

    if (this.state.learningProgress
      === '') {
      this.state.errors['learningProgress'] = "Answer Required"
      isFormValid = false
    }
    if (this.state.assessment === '') {
      this.state.errors['assessment'] = "Answer Required"
      isFormValid = false
    }
    if (this.state.howManyChildrenAreYouPlanningToEnroll === '') {
      this.state.errors['howManyChildrenAreYouPlanningToEnroll'] = "Answer Required"
      isFormValid = false
    }
    if (this.state.tlcFilter
      === '') {
      this.state.errors['tlcFilter'] = "Answer Required"
      isFormValid = false
    }
    if (this.state.extendedSupportForMyChild === '') {
      this.state.errors['extendedSupportForMyChild'] = "Answer Required"
      isFormValid = false
    }
    if (this.state.childGradeLevel != "" && this.state.childGradeLevel != "Nursery (2-3 years old)" && this.state.childGradeLevel != "Pre-Kinder (4 years old)") {
      if (this.state.yearsHomeSchooling == "") {
        this.state.errors['yearsHomeSchooling'] = "Answer Required"
        isFormValid = false
      }
    }
    if (this.state.childGradeLevel != "" && this.state.childGradeLevel != "Nursery (2-3 years old)" && this.state.childGradeLevel != "Pre-Kinder (4 years old)" && this.state.childGradeLevel != "Grades 11-12") {
      if (this.state.liveOnlineClasses == "") {
        this.state.errors['liveOnlineClasses'] = "Answer Required"
        isFormValid = false
      }
    }

    if (this.state.doYouHaveAnotherChild === '') {
      this.state.errors['doYouHaveAnotherChild'] = "Answer Required"
      isFormValid = false
    }

    return isFormValid

  }
  getAgeStudent = (dateOfbirth : string , submissionDate : string) => {
    const birthDate = new Date(dateOfbirth);
  const submitDate = new Date(submissionDate);

  let age = submitDate.getFullYear() - birthDate.getFullYear();
  const monthDiff = submitDate.getMonth() - birthDate.getMonth();
  const dayDiff = submitDate.getDate() - birthDate.getDate();

 
  if (monthDiff < 0 || (monthDiff === 0 && dayDiff < 0)) {
    age--;
  }
  return age;
  }
  handleProgramSubmitAnswers = async () => {
    if (this.state.filterQuestionId.length === 0) {
      this.programSubmit();
    }
    else {
      this.homeSchoolValidation()
      this.setState({
        errors: this.state.errors
      })
    }
  }
  handleStyleAnswersSubmit = () => {
    if (this.state.styleQuestionId.length === 0) {
      this.styleSubmit();
    }
    else {
      this.homeSchoolValidation()
      this.setState({
        errors: this.state.errors
      })
    }
  }
  handleHomeschoolProgramNavigate = () => {

    this.props.navigation.navigate('HomeschoolProgram')
  }
  handleHomeschoolStyleNavigate = () => {
    this.props.navigation.navigate('HomeschoolStyle')
  }

  NavigateCheckout = () => {
    this.props.navigation.navigate('Checkout')
  }

  getProgramList = () => {
    const header = {
      "Content-Type": "application/json",
      "token": this.state.authToken
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiGetProgramList = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.schoolProgramApiEndPoint}?grade=${this.state.childGradeLevel}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

  }

  setFirstQuestion = () => {
    this.setState({
      firstQuestion: {
        id: "-1",
        type: "home_program_question",
        attributes: {
          question_title: "Grade",
          question: "My child's grade level is ...",
          options: {
            data: this.state.initialGrade
          }
        }
      }
    })
  }

  setAllQuestion = (responseJson: AllQuestionsData) => {
    if (responseJson.data) {
      this.setState({ allQuestion: responseJson });
      let ids: string[] = [];
      responseJson.data.forEach((item)=>(
        ids.push(item.id)
        ))
        this.setState({filterQuestionId: ids},()=>{
          this.removeSelectedProgramError();
        })
    } else {
      this.setState({
        allQuestion: {
          data: []
        },
      });
    }
  }

  recommendselectedAnswer = (responseJson: AnswerArray) => {
    if (responseJson.data) {
      this.setState({ recommendAnswer: responseJson.data });
    }
  }

  styleRecommendselectedAnswer = (responseJson: StyleAnswerArray) => {
    if (responseJson.data) {
      this.setState({ recommendStyleAnswer: responseJson.data });
    }
  }

  setDraftedAnswer = (responseJson: DraftAnswer) => {
    if (responseJson.grade && responseJson.recommendations) {
      this.setState({
        childGradeLevel: responseJson.grade.id.toString()
      }, () => this.getProgramList());

      const documents:RecommenderAnswer[] = [];
      responseJson.recommendations.data.forEach(entry => {
        documents.push({
          "recommender_type": "homeschool_program",
          "question_id": Number(entry.attributes.question.id),
          "option_id": Number(entry.attributes.option.id)
        });
      });

      this.setState({ answerArray: documents },()=>{
        this.removeSelectedProgramError();
      })
    }
  }

  removeSelectedProgramError = () => {
    const idsToRemove = this.state.answerArray.map(item => item.question_id.toString());
      const newFilterdProgramQueId =  this.state.filterQuestionId.filter(id => !idsToRemove.includes(id));
      this.setState({ filterQuestionId: newFilterdProgramQueId })
  }

  getDraftedStyleQuestionAnswer = (responseJson: Recommendations) => {
    if (responseJson.data) {
      const documents:RecommenderAnswer[] = [];
      responseJson.data.forEach(entry => {
        documents.push({
          "recommender_type": "homeschool_styling",
          "question_id": Number(entry.attributes.question.id),
          "option_id": Number(entry.attributes.option.id)
        });
      });
      const idsToRemove = documents.map(item => item.question_id.toString());
      const newFilterdStyleQueId =  this.state.styleQuestionId.filter(id => !idsToRemove.includes(id));
      this.setState({ styleAnswerArray: documents, styleQuestionId: newFilterdStyleQueId })
    }
  }

  setInitialGrade = (responseJson: InialGrade) => {
    if (responseJson.data) {
      this.setState({ initialGrade: responseJson.data }, () => {
        this.setFirstQuestion();
      });
    }
  }

  setStyleQuestion = (responseJson: AllQuestionsData) => {
    if (responseJson.data) {
      this.setState({ styleQuestion: responseJson.data });
      let ids: string[] = [];
      responseJson.data.forEach((item)=>(
        ids.push(item.id)
        ))
        this.setState({styleQuestionId: ids})
    } else {
      this.setState({ styleQuestion: [] });
    }
  }

  selectedAnswer = (e: React.ChangeEvent<HTMLInputElement>, value: string) => {
    let arrayOfAnswer:RecommenderAnswer[] = this.state.answerArray;
    let ids: string[] = [];
    ids = this.state.filterQuestionId.filter(id => id !== value);
    let answer = {
      "recommender_type": "homeschool_program",
      "question_id": parseInt(value),
      "option_id": parseInt(e.target.value)
    }
    arrayOfAnswer.push(answer)
    if (arrayOfAnswer.length > 0) {
      arrayOfAnswer = this.filterAndAddNewKey(arrayOfAnswer);
    }
    this.setState({ filterQuestionId: ids, answerArray: arrayOfAnswer })
  }

  selectedStyleAnswer = (e: React.ChangeEvent<HTMLInputElement>, value: string) => {
    let arrayOfStyleAnswer:RecommenderAnswer[] = this.state.styleAnswerArray;
    let ids: string[] = [];
    ids = this.state.styleQuestionId.filter(id => id !== value);
    let answer = {
      "recommender_type": "homeschool_styling",
      "question_id": parseInt(value),
      "option_id": parseInt(e.target.value)
    }
    arrayOfStyleAnswer.push(answer)
    if (arrayOfStyleAnswer.length > 0) {
      arrayOfStyleAnswer = this.filterAndAddNewKey(arrayOfStyleAnswer);
    }
    this.setState({ styleQuestionId: ids, styleAnswerArray: arrayOfStyleAnswer })
  }

  filterAndAddNewKey = (arr:RecommenderAnswer[]) => {
    const uniqueQuestions = new Map();
  
    arr.forEach((item:RecommenderAnswer) => {
      uniqueQuestions.set(item.question_id, {
        ...item,
      });
    });
  
    return Array.from(uniqueQuestions.values());
  }

  SaveToDraft = () => {
      const header = {
        "Content-Type": "application/json",
        "token": this.state.authToken
      };

      const httpBody = {
        "data":{
        "status": "draft",
        "grade_id": this.state.childGradeLevel,
        "recommender_answers": this.state.answerArray
     }
    }
  
      const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
  
      this.apiSaveDraftProgram = requestMessage.messageId;
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),configJSON.saveDraftProgramApiEndPoint
      );
  
      requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),JSON.stringify(httpBody));
  
      requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage),configJSON.exampleAPiMethod);
  
      runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  SaveToDraftStyle = () => {
    const header = {
      "Content-Type": "application/json",
      "token": this.state.authToken
    };

    const httpBody = {
      "data":{
      "status": "draft",
      "recommender_answers": this.state.styleAnswerArray
   }
  }

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiSaveDraftProgram = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.saveDraftProgramApiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.exampleAPiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
}

  programSubmit = () => {
    const header = {
      "Content-Type": "application/json",
      "token": this.state.authToken
    };

    const httpBody = {
      "data":{
      "status": "submitted",
      "grade_id": this.state.childGradeLevel,
      "recommender_answers": this.state.answerArray
   }
    }

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiSubmitProgram = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.saveDraftProgramApiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.exampleAPiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  styleSubmit = () => {
    const header = {
      "Content-Type": "application/json",
      "token": this.state.authToken
    };

    const httpBody = {
      "data":{
      "status": "submitted",
      "recommender_answers": this.state.styleAnswerArray
   }
    }

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiSubmitStyle = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.saveDraftProgramApiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.exampleAPiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getRecommendAnswer = () => {
    const header = {
      "token": this.state.authToken
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiGetAnswer = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getAnswerApiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getStyleRecommendAnswer = () => {
    const header = {
      "token": this.state.authToken
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiGetStyleAnswer = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getStyleAnswerApiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getStyleQuestion = () => {
    const header = {
      "token": this.state.authToken
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiGetStyleQuestion = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getStyleQuestoin
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getSubmittedProgramAnswer = () => {
    const header = {
      "token": this.state.authToken
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiGetSubmittedProgram = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getSubmittedProgram
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getDraftedStyleAnswer = () => {
    const header = {
      "token": this.state.authToken
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiGetDraftStyle = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getDraftStyleAnswer
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getInitialGrade = () => {
    const header = {
      "token": this.state.authToken
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiGetInitialGrade = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getGrade
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  filterSeletionStyle = (QuestionID: string, AnswerID: string) => {
    if (this.state.styleAnswerArray.length) {
      const data = this.state.styleAnswerArray.map((item: RecommenderAnswer) => {
        return { "draftedID": item.question_id, "answerID": item.option_id }
      })

      const tempAnswer = data.find(
        (item: SelectionStyleAnswer) => item.draftedID.toString() === QuestionID
      )?.answerID

      if (tempAnswer?.toString() === AnswerID) {
        return true
      } else {
        return false
      }
    } else {
      return false
    }
  }

  filterSeletionProgram = (QuestionID: string, AnswerID: string) => {
    if (this.state.answerArray.length) {
      const data = this.state.answerArray.map((item: RecommenderAnswer) => {
        return { "draftedID": item.question_id, "answerID": item.option_id }
      })

      const tempAnswer = data.find(
        (item: SelectionStyleAnswer) => item.draftedID.toString() === QuestionID
      )?.answerID

      if (tempAnswer?.toString() === AnswerID) {
        return true
      } else {
        return false
      }
    } else {
      return false
    }
  }

downloadDoc = (item:string) => {
  window.open(item, "_blank")
}

  gotoHome = async() => {
    const getcomplete = await getStorageData("complete");
    const msg = new Message(getName(MessageEnum.NavigationMessage));
    msg.addData(getName(MessageEnum.NavigationTargetMessage), getcomplete === "true" ? "DashBoardDayInLifeVideo" : "DashBoardNonExpandablePage");
    msg.addData(
      getName(MessageEnum.NavigationPropsMessage),
      this.props
    );
    this.send(msg);
  }

  // Customizable Area End
}
