import React from "react";

import {
  Container,
  Box,


  CircularProgress,
  Typography,

  IconButton,
  // Customizable Area Start


  Grid,
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { createTheme, ThemeProvider, styled } from "@material-ui/core/styles";
import HorizontalLine from "../../../components/src/HorizontalLine.web";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import Visibility from "@material-ui/icons/Visibility";
import Sidebarmenu from "../../../components/src/Sidebarmenu.web";
import AppWithoutDrawer from "../../../components/src/AppWithoutDrawer.web";
import FaqCard from "../../../components/src/FaqCard.web";

const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});
const Boxcontainertwofaq = styled(Box)({
  display: 'flex', alignItems: 'center', justifyContent: 'center', margin: "auto", marginTop: '1rem'

})
// Customizable Area End

import AssessmenttestController, {
  Props,

} from "./AssessmenttestController";

export default class Assessmentfaqtest extends AssessmenttestController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
        <AppWithoutDrawer navigation={this.props.navigation} />
        <Sidebarmenu activeKey="FAQs" />

        <ThemeProvider theme={theme}>
          <Container maxWidth={false}>
            <Grid container style={{ display: 'block' }}>
              {/* ---------------------- drawer ------------------------- */}

              <Grid item>
                <Container

                  maxWidth={false}
                >
                  {/* ---------------------- MAIN CONTENT OF FILE START ------------------------- */}

                  <Container maxWidth={false} style={{ margin: '0 auto', width: '100%', paddingLeft: '0', paddingRight: '0', marginBottom: '6em' }}>

                    <Container maxWidth={false} style={{ margin: '0 auto' }}>
                      <Box style={{ marginTop: '80px', display: 'flex', justifyContent: 'space-between' }}>
                        <Typography style={webStyle.welcomeTextStyle} >FAQs</Typography>
                      </Box>
                      <Grid container style={{ display: 'flex' }}>
                        <HorizontalLine />
                      </Grid>
                      {
                        this.state.loadingfaq ?
                          <Boxcontainertwofaq>
                            <CircularProgress color="inherit" />
                          </Boxcontainertwofaq> :
                          <Container maxWidth={false} style={webStyle.GridWrapper}>

                            <Box style={{ marginTop: '1em', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                              <Typography style={webStyle.styleHeadingText}>Frequently Asked Questions </Typography>
                              <Box style={{ display: 'flex', alignItems: 'center' }}>
                                <IconButton
                                  onClick={this.handleViewClick}
                                >
                                  {this.state.view ? <Visibility /> : <VisibilityOff />}

                                </IconButton>
                                <Typography style={webStyle.styleViewAll}>View All </Typography>
                              </Box>
                            </Box>
                            {
                              Array.isArray(this.state?.faqData) && this.state.faqData.map((item: any) => {

                                return (
                                  <FaqCard
                                    key={item?.attributes?.question}
                                    view={this.state.view}
                                    questionNo={item?.attributes?.question}
                                    answer={item?.attributes?.faq_answer}
                                  />
                                );
                              })
                            }
                          </Container>
                      }
                    </Container>
                    <Box style={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginTop: "7rem", marginBottom: "-2rem" }}>
                      <div >
                        <h1 style={{ display: "none" }}>hhhh</h1>
                      </div>
                    </Box>
                  </Container>

                </Container>

              </Grid>

            </Grid>

          </Container>

        </ThemeProvider >
      </div>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {
  welcomeTextStyle: {
    color: '#171725',
    fontFamily: "Montserrat",
    fontSize: '24px',
    fontWeight: 600,
  },
  GridWrapper: {
    borderRadius: "15px",
    background: "#ffffff",
    border: "1px solid #f1f1f5",
    marginTop: "2em",
    boxShadow: "rgba(68, 68, 79, 0.1) 0px 5px 15px"
  },
  styleHeadingText: {
    color: '#171725',
    fontFamily: "Montserrat",
    fontSize: '16px',
    fontWeight: 600,
  },
  styleViewAll: {
    color: ' #92929d',
    fontFamily: "Montserrat",
    fontSize: '14px',
    fontWeight: 400,
  }

};
// Customizable Area End
