import React from "react";
// Customizable Area Start
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import {
  // Customizable Area Start
  Container,
  Box,
  Typography,
  Grid,
  // Customizable Area End
} from "@material-ui/core";
import AppWithoutDrawer from "../../../components/src/AppWithoutDrawer.web";

// Customizable Area End
import DashboardController, { Props } from "./DashboardController.web";

import IntroVideo from "../../../components/src/IntroVideo.web";
import CircularProgress from '@material-ui/core/CircularProgress';
import Sidebarmenu from "../../../components/src/Sidebarmenu.web";

const theme = createTheme({

  palette: {
    primary: {
      main: "#nnn",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },

  overrides: {
    MuiButton: {
      root: {
        textTransform: 'none',
      },
    },
  },
});


export default class DashboardIntroVideo extends DashboardController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start

  // Customizable Area End

  render() {
    // Customizable Area Start
    console.log("gdhetdhh",this.state.allVideos_introVideoURL)
    const playerHeight:any = '392px';
    return (
      //Merge Engine DefaultContainer
      <>
        <AppWithoutDrawer />
     
        <ThemeProvider theme={theme}>
          <Container maxWidth={false} style={webStyle.mainContainer}>

            <Grid container style={webStyle.gridContainerStyle}>

              <Box style={{ position: 'absolute', height: '60vh', width: '60vw' }}>
                <Typography align='center' style={webStyle.welcomeTextStyle}>Welcome to our Homeschool Orientation. Learn more about
                the heart of what we do, our programs, and how you can begin your journey with us. </Typography>

                <Box style={{width: '80%', margin: '0 auto'}}>
                {(!this.state.isLoading || this.state.allVideos_introVideoURL === "" || this.state.timer === 0) ?
                  <Box style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <CircularProgress />
                  </Box>
                  : (this.state.timer >= 0.1 &&
                    <IntroVideo handleNext={this.handleNext}
                      handleWatchLater={this.handleWatchLater}
                      data-test-id="introVideos"
                      height="100%"
                      introVideoURL={this.state.allVideos_introVideoURL}
                      watchTime={this.state.timer}
                      isIntroVideo={true}
                      storage={true}
                    />)

                }
                </Box>

              </Box>
              <Grid item xs={12} style={{ width: '100vw', height: '30vw', backgroundColor: '9167AC' }}>

              </Grid>
              <Grid item xs={12} style={{ width: '100vw', height: '20vw' }}>

              </Grid>

            </Grid>
          </Container>
        </ThemeProvider>


      </>
      //Merge Engine End DefaultContainer
    );
    // Customizable Area End
  }
}

// Customizable Area Start


const webStyle = {

  mainContainer: {
    margin: "0px auto",
    width: "100%",
    padding: "0em",
  },
  gridContainerStyle: {
    width: '100vw',
    height: '100vh',
    position: 'relative' as 'relative',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',

  },


  welcomeTextStyle: {
    marginBottom: '1em',
    color: '#fff',
    fontFamily: "Montserrat",
    fontSize: '23px',
    // fontSize: '28px',
    fontWeight: 600,
  },

  avatarStyle: {
    width: '32px',
    height: '32px',
    marginRight: '10px'
  },

  imgLogoStyle: {
    width: '100%',
  },
};
// Customizable Area End
