import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { EventSubscription } from "react-native";
import { setStorageData } from "../../../framework/src/Utilities";
import { FormEvent, MouseEvent } from "react";
import { pwdError } from "../../../components/src/asset";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  errors: any;
  snackbarOpen: boolean;
  snackbarOpenFalse: boolean;
  snackbarMessage: any;
  loadingSignin: boolean;
  checkedRememberMe: boolean,
  passwordInputState: any;
  passwordState: boolean;
  email: any,
  password: string,
  userStatus:boolean
 
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class EmailAccountLoginController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  apiEmailLoginCallId: string = "";
  validationApiCallId: string = "";
  emailReg: RegExp;
  labelTitle: string = "";
  focusListener: EventSubscription | undefined = undefined
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.ReciveUserCredentials),
    ];

    this.state = {
      snackbarOpen: false,
      snackbarMessage: "",
      email: "",
      password: "",
      checkedRememberMe: false,
      loadingSignin: false,
      passwordState: true,
      errors: {},
      userStatus:false,
      snackbarOpenFalse: false,
      passwordInputState: 'password',
    
    };

    this.emailReg = new RegExp("");
    this.labelTitle = configJSON.labelTitle;
    // Customizable Area End

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    this.callGetValidationApi();
    this.send(new Message(getName(MessageEnum.RequestUserCredentials)));
    // Customizable Area Start
    const rememberMe = localStorage.getItem('rememberMe');
      const storedEmail = localStorage.getItem("emailState")
    localStorage.clear();
    setStorageData('rememberMe', rememberMe)
    setStorageData('emailState', storedEmail)
    if (rememberMe != "false") {
      console.log("rememberMe",rememberMe)
      
      this.setState({ checkedRememberMe: rememberMe === 'true' });
      this.setState({ email: storedEmail });}

    // Customizable Area End
  }

  // Customizable Area Start
  loginRequest = () => {
    this.setState({ loadingSignin: true })
    const headerData = {
      "Content-Type": configJSON.loginApiContentType
    };

    const httpDataBody = {
      data: {
        type: "email_account",
        attributes: {
          email: this.state.email,
          password: this.state.password
        }
      }
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiEmailLoginCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpDataBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.loginAPiMethod
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.apiEndPointAddDetail
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headerData)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  onCallLoginApi = () => {
    const header = {
      "Content-Type": configJSON.loginApiContentType,
    };
    const attrs = {
      email: this.state.email,
      password: this.state.password,
    };
    const data = {
      type: "email_account",
      attributes: attrs,
    };
    const httpBody = {
      data: data,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiEmailLoginCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.apiEndPointAddDetail
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.loginAPiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  formValidate = () => {
    let isFormValid = true;
    const regex = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;

    if (this.state.email === "") {
      this.state.errors["email"] = "Email address is required";
      isFormValid = false;
    } else if (!regex.test(this.state.email)) {
      isFormValid = false;
      this.state.errors["email"] = "Enter a valid email address";
    }
    if (this.state.password === "") {
      this.state.errors["password"] = configJSON.PlacehoderRequired;
      isFormValid = false;
    }
    if (this.state.password.length < 6 && this.state.password.length > 0) {
      this.state.errors["password"] = pwdError;
      isFormValid = false;
    }

    return isFormValid;
  };

  signupFormSubmit = (event: FormEvent<HTMLFormElement> | MouseEvent<HTMLButtonElement>) => {
    event.preventDefault()

    if (this.formValidate()) {

      this.loginRequest();
    } else {
      this.setState({
        errors: this.state.errors
      });
    }
  }

  handleLoginFail(message:Message) {
 
    const apiResponse = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
      this.setState({ loadingSignin: false })
      this.setState({ snackbarOpenFalse: true, snackbarMessage: apiResponse?.errors[0]?.failed_login});
    
  }

  changePasswordState = () => {
    if (this.state.passwordState) {
      this.setState({ passwordState: false })
      this.setState({ passwordInputState: 'text' })
    }
    else {
      this.setState({ passwordState: true })
      this.setState({ passwordInputState: 'password' })
    }
  }

  handleCloseAlert = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === "clickaway") {
      return;
    }

    this.setState({ snackbarOpen: false })
  };

  handleCloseAlert1 = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === "clickaway") {
      return;
    }

    this.setState({ snackbarOpenFalse: false })
  };

  onEmailChange = (text: string) => {
    this.state.errors["email"] = ""
    this.setState({
      email: text,
      errors: this.state.errors
    })
  }

  onPasswordChange = (text: string) => {
    this.state.errors["password"] = ""
    this.setState({
      password: text,
      errors: this.state.errors
    })
  }

  handleLoginSuccess(message: Message) {
    const { navigation } = this.props;
    const apiResponse = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    if (apiResponse?.data) {
      this.setState({ loadingSignin: false })
      this.setState({ snackbarOpen: true, snackbarMessage: "Logged in",userStatus:true });
      this.saveLoggedInUserData(apiResponse);
      navigation.navigate('DashboardIntroVideo');
    }
  }


  onRememberMeCheck = (e:any, checked:any) => {
  
    const checkeds = e?.target?.checked;
    this.setState({ checkedRememberMe: checkeds });
    localStorage.setItem('rememberMe', checked);
  }
  // Customizable Area End

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (apiRequestCallId != null && apiRequestCallId === this.apiEmailLoginCallId) {
        if (responseJson && responseJson.meta && responseJson.meta.token) {
    

          runEngine.unSubscribeFromMessages(this, this.subScribedMessages);
          this.handleLoginSuccess(message);


        }
        if(responseJson?.data){
         localStorage.setItem("StudID",responseJson?.data?.id)
         localStorage.setItem('emailState',responseJson?.data?.attributes?.email)
         localStorage.setItem('phoneNumber',responseJson?.data?.attributes?.phone_number);
        }else{
          this.handleLoginFail(message)
        }
      }
      

    }

    // Customizable Area End
  }

  sendLoginFailMessage() {
    const msg: Message = new Message(getName(MessageEnum.LoginFaliureMessage));
    this.send(msg);
  }

  sendLoginSuccessMessage() {
    const msg: Message = new Message(getName(MessageEnum.LoginSuccessMessage));

    msg.addData(getName(MessageEnum.LoginUserName), this.state.email);
    msg.addData(getName(MessageEnum.CountyCodeDataMessage), null);
    msg.addData(getName(MessageEnum.LoginPassword), this.state.password);
    msg.addData(
      getName(MessageEnum.LoginIsRememberMe),
      this.state.checkedRememberMe
    );

    this.send(msg);
  }

  saveLoggedInUserData(responseJson: any) {
    if (responseJson && responseJson.meta && responseJson.meta.token) {
      const msg: Message = new Message(getName(MessageEnum.SessionSaveMessage));

      msg.addData(
        getName(MessageEnum.SessionResponseData),
        JSON.stringify(responseJson)
      );
      msg.addData(
        getName(MessageEnum.SessionResponseToken),
        responseJson.meta.token
      );

      this.send(msg);
    }
  }

  openInfoPage() {
    // Merge Engine - Navigation - btnEmailLogIn - Start
    const msg: Message = new Message(getName(MessageEnum.AccoutLoginSuccess));
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
    // Merge Engine - Navigation - btnEmailLogIn - End
  }

  goToSocialLogin() {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationSocialLogInMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }

  doEmailLogIn(): boolean {
    if (
      this.state.email === null ||
      this.state.email.length === 0 ||
      !this.emailReg.test(this.state.email)
    ) {
      this.showAlert("Error", configJSON.errorEmailNotValid);
      return false;
    }

    if (this.state.password === null || this.state.password.length === 0) {
      this.showAlert("Error", configJSON.errorPasswordNotValid);
      return false;
    }

    const header = {
      "Content-Type": configJSON.loginApiContentType,
    };

    const attrs = {
      email: this.state.email,
      password: this.state.password,
    };

    const data = {
      type: "email_account",
      attributes: attrs,
    };

    const httpBody = {
      data: data,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiEmailLoginCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.loginAPiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.loginAPiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  }

  callGetValidationApi() {
    const headers = {
      "Content-Type": configJSON.validationApiContentType,
    };

    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.validationApiCallId = getValidationsMsg.messageId;

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.urlGetValidations
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
  }
}
