import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import React from "react";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  value: number;
  indicatorStyle: React.CSSProperties;
  selectedTabs: number[];
  courseValue: string;
  accordionExpandValue: string[];
  familyBookValue: string[];
  onlineSubject: string[];
  additoinalSubject: string[];
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class CheckoutController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  tabRefs: React.RefObject<HTMLDivElement>[];
  accordionRefs: React.RefObject<HTMLDivElement>[];
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      value: 2,
      indicatorStyle: { left: 0, width: 0 },
      selectedTabs: [0],
      courseValue: '',
      accordionExpandValue: [],
      familyBookValue: [],
      onlineSubject: [],
      additoinalSubject: []
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area End

    // Customizable Area Start
    this.tabRefs = [React.createRef(), React.createRef(), React.createRef(), React.createRef(), React.createRef()];
    this.accordionRefs = [React.createRef()];
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      // some code here
    }
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    super.componentDidMount();
    this.handleChangeTab(1)
  }

  handleChangeTab = (newValue: number) => {
    let selectedTabs: number[] = [];
    if (newValue === 1) {
      selectedTabs = [0, 1];
    } else if (newValue === 2) {
      selectedTabs = [0, 1, 2];
      this.setState({ courseValue: 'full-online' })
    } else if (newValue === 3) {
      selectedTabs = [0, 1, 2, 3];
    } else if (newValue === 4) {
      selectedTabs = [0, 1, 2, 3, 4];
    }
    this.setState({ value: newValue, selectedTabs }, () => { this.updateIndicatorStyle(newValue) });
  };

  updateIndicatorStyle = (value: number) => {
    const tab = this.tabRefs[value].current;
    if (tab) {
      const left = 0;
      let width = tab.offsetWidth;

      if (value > 0) {
        const prevTab = this.tabRefs[value - 1]?.current;
        if (prevTab) {
          width += prevTab.offsetLeft + prevTab.offsetWidth - left;
        }
      }

      this.setState({ indicatorStyle: { left, width } });
    }
  };

  handleNavigationNext = () => {
    if (this.state.value < 4) {
      this.handleChangeTab(this.state.value + 1)
    }
  }

  handleChangeCourse = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ courseValue: event.target.value });
  };

  courseAccordion = (value: string) => {
    if (value) {
      this.setState((prevState) => {
        const expandedAccordions = [...prevState.accordionExpandValue];
        const index = expandedAccordions.indexOf(value);
        if (index !== -1) {
          expandedAccordions.splice(index, 1);
        } else {
          expandedAccordions.push(value);
        }
        this.setState({ accordionExpandValue: expandedAccordions });
      });
    }
  }

  familyBookSelection = (value: string) => {
    if (value) {
      this.setState((prevState) => {
        const familyBook = [...prevState.familyBookValue];
        const index = familyBook.indexOf(value);
        if (index !== -1) {
          familyBook.splice(index, 1);
        } else {
          familyBook.push(value);
        }
        this.setState({ familyBookValue: familyBook });
      });
    }
  }

  onlineSubject = (value: string) => {
    if (value) {
      this.setState((prevState) => {
        const online = [...prevState.onlineSubject];
        const index = online.indexOf(value);
        if (index !== -1) {
          online.splice(index, 1);
        } else {
          online.push(value);
        }
        this.setState({ onlineSubject: online });
      });
    }
  }

  additionalSubject = (value: string) => {
    if (value) {
      this.setState((prevState) => {
        const additional = [...prevState.additoinalSubject];
        const index = additional.indexOf(value);
        if (index !== -1) {
          additional.splice(index, 1);
        } else {
          additional.push(value);
        }
        this.setState({ additoinalSubject: additional });
      });
    }
  }
  // Customizable Area End
}
