import Autocomplete from "@material-ui/lab/Autocomplete";
import { StyledTextField } from "./EnrollFormHelperFunctions";
import React from "react";

export const pwdRequire = "Password is required"
export const pwd = "password"
export const txt = "text"
export const pwdError = "Incorrect password"

export const toggleCheckboxState = (selectedStudentIds:number[] , id: number) => {
    if (selectedStudentIds.includes(id)) {
      return selectedStudentIds.filter((selectedId) => selectedId !== id);
    } else {
      return [...selectedStudentIds, id];
    }
  };

export const cityAutoComplete = (onCountryCodeSelectedWeb:any, countryPhoneCodeList:any,countryCode:any) => {
  return (
    <>
    <Autocomplete
      onChange={onCountryCodeSelectedWeb}
      data-testid="country-code-input"
      style={{ width: '190px' }}
      options={countryPhoneCodeList}
      disableClearable
      getOptionLabel={(option) => `+ ${option.value}`}
      getOptionSelected={(option, value) => option.value === value.value}
      renderOption={(option) => (`+ ${option.value} - ${option.sortName}`)}
      renderInput={(params) =>
        <StyledTextField {...params} placeholder='Select Country' fullWidth={true}
          testidentity="PleaseSelect"
          name='country' InputLabelProps={{ shrink: false }}
        />}
      value={countryPhoneCodeList.find((item:any) => `+${item.value}` == countryCode) || null}
    />
    </>
  )
}