export const webStyle = {

    EmailOutlinedIcon: {
        color: "#b5b5be",
    },
    BoxImageStyle: {
        width: "50%",
        paddingTop: '1.5em'
    },
    ContainerWidth: {
        margin: "20px auto",
        width: '95%',
        height: '100vh',
    },
    landingToAnotherPage: {
        display: "flex",
        alignItems: "center",
        marginBottom: "30px",
    },
    SignupFormSubmitStyle: {
        width: "100%",
        height: "52px",
        marginTop: "50px",
        marginBottom: "35px",
        border: "none",
        borderRadius: "10px",
        backgroundColor: " #fab03d",
        color: "#fafafb",
        fontFamily: "Montserrat",
        fontSize: " 16px",
        fontWeight: 600,
    },
    LinkToForgetPassword: {
        // width: "122px",
        height: " 17px",
        color: "#57AFEB",
        fontFamily: "Montserrat",
        fontSize: "14px",
        fontWeight: 500,
        lineHeight: "17px",
        letterSpacing: "0.1px",
    },
    CustomCheckBox: {
        color: "#282828",
        height: "17px",
        width: "17px",
    },
    RembeberLabel: {
        color: "#696974",
           fontFamily: "Montserrat",
        fontSize: "14px",
        fontWeight: 400,
    },
    formFlex: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        marginTop: "27px",
    },
    fontColour: {
        color: "#171725"
    },
    mainWrapper: {
        display: "flex",
        flexDirection: "row" as "row",
        height: "100%",
        justifyContent: "center",
        boxShadow: "3px -5px 40px rgba(205,205,212,0.1)",
    },
    mainInnerWrapper: {
        flex: 1,
        display: "flex",
        alignItems: "stretch",
        justifyContent: "center",
    },
    leftWrapper: {
        flex: 1,
        border: "1px solid #f1f1f5",
        borderTopLeftRadius: "50px",
        borderBottomLeftRadius: "50px",
        display: "flex",
        flexDirection: "column" as "column",
        alignItems: "center",
        justifyContent: "center",
    },

    innerLeftStyle: {
        display: "flex",
        flexDirection: "column" as "column",
        alignItems: "center",
        justifyContent: "center",
        padding: "90px",
        textAlign: "center",
    },

    rightWrapper: {
        flex: 1,
        borderTopRightRadius: "50px",
        borderBottomRightRadius: "50px",
        backgroundColor: "#fbfbfb",
        display: "flex",
        flexDirection: "column" as "column",
        alignItems: "center",
        justifyContent: "center",
    },

    innerRightStyleLogin: {
        paddingLeft: "50px",
        paddingRight: "50px",
    },

    imgStyleLogin: {
        width: "100%",
    },

    textStyleRightlogin: {
        marginTop: "43px",
        marginBottom: "36px",
        height: "42px",
        color: "#171725",
        fontFamily: "Montserrat",
        fontSize: "28px",
        fontWeight: 600,
        lineHeight: "42px",
        letterSpacing: "0.12px",
    },

    inputLabelStyleLogin: {
        marginBottom: "10px",
        width: "220px",
        height: "22px",
        color: "#696974",
        fontFamily: "Montserrat",
        fontSize: "16px",
        fontWeight: 500,
        lineHeight: "22px",
    },

    inputBoxStyle: {
        marginTop: "27px",
    },
    textStyleInquiry: {
        color: "#696974",
        fontFamily: "Montserrat",
        fontSize: " 16px",
        fontWeight: 400,
        lineHeight: "19px",
        letterSpacing: "0.1px",
    },
    btnStyleInquiry: {
        marginTop: "25px",
        textDecoration: "underline",
           fontFamily: "Montserrat",
        fontSize: " 16px",
        fontWeight: 500,
        lineHeight: "19px",
    },
    textStyleLeft: {
        marginTop: "20px",
        color: "#757575",
        fontFamily: "Montserrat",
        fontSize: " 25px",
        fontWeight: 600,
        lineHeight: "36px",
        letterSpacing: "0.1px",
        alignText: "center",
        width: "443px"
    },
    errorStyle: {
        color: "red",
        fontFamily: "Montserrat",
        fontSize: " 12px",
    },
    linkStyle: {
        color: 'black',
        textDecoration: 'underline',
        textDecorationColor: 'black',
        fontWeight: 600,
    },
};