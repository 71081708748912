import React from "react";

// Customizable Area Start
import {
  Container,
  Box,
  Button,
  InputLabel,
  Typography,
  InputAdornment,
  Grid,
  TextField,
  IconButton,
} from "@material-ui/core";

import {
  Platform,
  StyleSheet,
} from "react-native";

import EmailOutlinedIcon from "@material-ui/icons/EmailOutlined";
import Snackbar from "@material-ui/core/Snackbar";
import { createTheme, ThemeProvider,withStyles } from "@material-ui/core/styles";
import { imgConnect } from "./assets";
import CircularProgress from '@material-ui/core/CircularProgress';
import LockOpenIcon from '@material-ui/icons/LockOpen';
import LockOutlined from '@material-ui/icons/LockOutlined';
import Alert from '@material-ui/lab/Alert';
import { webStyle } from "../../email-account-login/src/styles";

const StyledTextField = withStyles({
  root: {
       //fontFamily: "Roboto-Medium",
    fontSize: "16",
    "& .MuiFilledInput-root": { backgroundColor: "transparent",
    },
    "& .MuiFilledInput-root:hover": {
      backgroundColor: "transparent",
      "@media (hover: none)": { backgroundColor: "transparent",
      },
    },
    "& .MuiFilledInput-root.Mui-focused": {
      backgroundColor: "transparent",
    },
    "& input::placeholder": {
      fontSize: "16",
      fontWeight: 500,
      //fontFamily: "Roboto-Medium",
      color: "#44444f",
      width: "222.98px",
      height: "19px",
    },
    "& .MuiInputBase-input.MuiInput-input": {
        fontFamily: "Montserrat",
    }
  },
})((props: any) => <TextField {...props} />);

const theme = createTheme({
  palette: {
    primary: {
      contrastText: "#fff",
      main: "#0000ff",
    },
  },
});
// Customizable Area End

import ForgotPasswordController, { Props } from "./ForgotPasswordController.web";

export default class NewPassword extends ForgotPasswordController {
  // Customizable Area Start
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  render() {
    return (
      <ThemeProvider theme={theme}>
        <Container 
          maxWidth={false} style={webStyle.ContainerWidth}>
          <Grid 
            container style={webStyle.mainWrapper}>
            <Grid 
              item xs={12} sm={6} style={webStyle.mainInnerWrapper}>
              <Box sx={
                  webStyle.leftWrapper
                }>
                <Box sx={
                    webStyle.innerLeftStyle
                  }>
                  <Box style={webStyle.BoxImageStyle}>
                    <img 
                      src={imgConnect} 
                      style={webStyle.imgStyleLogin} 
                    />
                  </Box>
                  <Box style={{ 
                    paddingTop: '1em' }}>
                    <Typography
                      style={webStyle.textStyleLeft}
                      variant="h3"
                      component={"h5"}
                    >
                      Everything you need to{" "}
                      <span 
                        style={webStyle.fontColour}>maximize your HG homeschooling journey </span>
                      right at your fingertips
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Grid>
            <Grid 
              item 
              xs={12} sm={6} style={webStyle.mainInnerWrapper}>
              <Box sx={{...webStyle.rightWrapper, alignItems: 'left'}}>
                <Box 
                  sx={webStyle.innerRightStyleLogin
                  }>
                  <Typography
                    style={webStyle.textStyleRightlogin}
                    variant="h6"
                    component={"h6"}
                  >
                    Create Your Password </Typography>
                  <Typography 
                    style={{ color: 'rgb(85, 85, 85)', fontSize: '14px', fontWeight: 400, lineHeight: '22px' }}>
                    Please enter the new password to be created.
                  </Typography>
                  <form data-test-id="emailform" onSubmit={(e)=>this.resetPasswordSubmit(e)}>
                  <Box>
                    <InputLabel style={{ ...webStyle.inputLabelStyleLogin, marginTop: '2em' }}>
                      {" "}
                      Enter New Password * </InputLabel>
                    <StyledTextField
                      data-testid="password-input"
                      placeholder="Enter Password"
                      type={this.state.passwordInputState}
                      value={this.state.password}
                      fullWidth={true}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        this.onPasswordChange(e.target.value)
                      }}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end"  >
                            <IconButton onClick={this.changePasswordState} style={{ padding: '0px' }}>
                              {
                                this.state.passwordState ?
                                  <LockOutlined style={{ ...webStyle.EmailOutlinedIcon }} />
                                  : <LockOpenIcon style={webStyle.EmailOutlinedIcon} />
                              }
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                    <small style={webStyle.errorStyle}>
                      {this.state.errors.password}
                    </small>
                  </Box>
                  <Button
                    data-test-id="passwordsubmit"
                    disabled={this.state.passwordLoading ? true : false}
                    style={{ textTransform: "none", ...webStyle.SignupFormSubmitStyle }}
                    fullWidth
                    type="submit"
                    onClick={(e)=>this.resetPasswordSubmit(e)}
                  >
                    {this.state.passwordButtonLoading ? <CircularProgress /> : "Create Password"}
                  </Button>
                  </form>
                  <Box style={webStyle.landingToAnotherPage}
                  >
                    <Typography style={{ ...webStyle.textStyleInquiry, fontFamily: '' }}>
                      Back to </Typography>
                    <Typography onClick={this.navigateToLogin}><span style={{ ...webStyle.linkStyle, marginLeft: '5px', cursor: 'pointer' }}>Login</span></Typography>
                  </Box>
                </Box>
              </Box>
            </Grid>
          </Grid>
          <Snackbar
            data-test-id="errorClosePassword"
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }} 
            open={this.state.snackbarPasswordOpenFalse} 
            autoHideDuration={4000} 
            onClose={this.handleCloseAlertPassword} >
            <Alert severity="error"> {this.state.newPasswordAPIError}
            </Alert>
          </Snackbar>
          <Snackbar
            data-test-id="successClosePassword"
            anchorOrigin={{
              vertical: 'top', horizontal: 'center'}} 
              open={this.state.snackbarPasswordSuccess} 
              autoHideDuration={4000} onClose={this.handleCloseAlertPasswordSuccess} >
            <Alert severity="success">
              {this.state.newPasswordAPIError} </Alert>
          </Snackbar>
        </Container>
      </ThemeProvider>
    );
  }
}

// Customizable Area Start
const styles = StyleSheet.create({
  containerMobile: {
    padding: 16,
    flex: 1,
    marginLeft: "auto",
    marginRight: "auto",
    maxWidth: 650,
    width: "100%",
    backgroundColor: "#fff"
  },
  containerWeb: {
    padding: 16,
    marginLeft: "auto",
    width: "50%",
    maxWidth: 650,
    marginRight: "auto",
  },
  countryCodeSelector: {
    flex: 3,
    textAlign: "left",
    marginTop: 20,
    textAlignVertical: "center"
  },
  button: {
    justifyContent: "center",
    marginTop: 16,
    width: "80%",
    alignItems: "center",
    zIndex: -1
  },

  flexContainer: {
    flexDirection: "row",
    flexWrap: "nowrap",
    justifyContent: "flex-start",
    alignItems: "flex-end",
    width: "100%"
  },

  headline: {
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center"
  },

  webInput: {
    width: "100%",
    marginTop: 20,
    zIndex: -1
  },

  inputAfterCountryCode: {
    zIndex: -1,
    width: "100%",
  },

  mobileInput: {
    flexDirection: "row",
    fontSize: 16,
    backgroundColor: "#00000000",
    textAlign: "left",
    marginTop: 20,
    borderWidth: 1,
    borderRadius: 2,
    borderColor: "#767676",
    includeFontPadding: true
  },

  codeInput: {
    width: "30%",
    marginTop: 20,
  },

  phoneInput: {
    marginTop: 20,
    flex: 3,
  },

  noBorder: {
    borderBottomWidth: 0
  },

  titleText: {
    color: "#6200EE",
    fontSize: 32,
    fontWeight: "bold"
  },

  stepText: {
    fontSize: 16,
    marginBottom: 32,
    marginVertical: 8,
    textAlign: "left",
  },

  emailText: {
    fontSize: 16,
    marginVertical: 8,
    textAlign: "left",
    marginBottom: 16,
    fontWeight: "bold"
  },

  bgRectBorder: {
    borderWidth: 1,
    marginTop: 20,
    borderColor: "#767676",
    borderRadius: 2,
    minHeight: 40,
    fontSize: 18,
    padding: 10,
    textAlignVertical: "center",
  },

  bgRectWeb: {
    marginTop: 40
  },

  errorStyle: {
    textAlign: "center",
    color: "red",
  },

  passwordShowHide: {
    alignSelf: "center"
  },

  passwordRulesStyle: {
    padding: 15
  },

  bgPasswordContainer: {
    marginTop: 20,
    flexDirection: "row",
    backgroundColor: "#00000000",
    borderWidth: 1,
    paddingLeft: 5,
    borderColor: "#767676",
    borderRadius: 2,
    paddingRight: 5
  },

  passwordContainerWeb: {
    borderBottomWidth: 1,
    flexDirection: "row",
    backgroundColor: "#00000000",
    borderColor: "#767676",
    marginLeft: 10,
    marginTop: 20,
    marginRight: 10
  },
  bgPasswordInput: {
    fontSize: 16,
    flex: 1,
    textAlign: "left",
    backgroundColor: "#00000000",
    includeFontPadding: true,
    minHeight: 40,
  },
  passwordInputWeb: {
    flex: 1,
    fontSize: 18,
    backgroundColor: "#00000000",
    textAlign: "left",
    minHeight: 40,
    includeFontPadding: true,
    borderRadius: 2,
    borderColor: "#767676",
  },
  imgPasswordShowhide: Platform.OS === "web" ? { height: 30, width: 30 } : {}
});
// Customizable Area End
