import React, { CSSProperties } from "react";


// Customizable Area Start
import { IconButton } from "@material-ui/core";
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
import CloseIcon from '@material-ui/icons/Close';
import { Link } from "react-router-dom";
import Backdrop from "@material-ui/core/Backdrop";

// Customizable Area End

import SessionManageController, {
	Props,

} from "./SessionManageController.web";

class SessionManage extends SessionManageController {
	popoverAnchor: Element | ((element: Element) => Element) | null | undefined;
	constructor(props: Props) {
		super(props);
		// Customizable Area Start
		// Customizable Area End 
	}

	// Customizable Area Start
	// Customizable Area End

	render() {
		return (
			// Customizable Area Start
			<>
				{this.state.snackbarOpen && <Backdrop style={{ zIndex: 100, color: "#fff" }} open={this.state.snackbarOpen}></Backdrop>}
			<Snackbar anchorOrigin={{
				vertical: 'top',
				horizontal: 'center',
			}} open={this.state.snackbarOpen}
			>
				<div style={{ alignItems: 'cenetr' }}>
					<Alert severity="success">
						"Account not found/not activated. Please sign up again"
						<span style={{ paddingLeft: '10px' }}>
							<Link to="/EmailAccountRegistration">
								<IconButton
									aria-label="close"
									color="inherit"
									style={{ padding: 0, color: 'black' }}
									onClick={this.handleClose}
								>
									<CloseIcon />
								</IconButton>
							</Link>
						</span>
					</Alert>
				</div>
			</Snackbar>
			</>
			// Customizable Area End
		);
	}
}

// Customizable Area Start
export default SessionManage
// Customizable Area End
