import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import React, { ChangeEvent } from "react";
import { Keyboard, ScrollView } from "react-native";
import DocumentPicker from "react-native-document-picker";
import {
  InputProps
} from "@material-ui/core";
import { getStorageData } from "../../../framework/src/Utilities";
import moment from "moment";
// Customizable Area End

export const configJSON = require("./config");

// Customizable Area Start
export interface CreateDialog {
  result: Dialog
  errors: DialogError[]
}

export interface DialogError {
  token: string
}


export interface Dialog {
  type: number
  name: string
  photo: string | null
  _id: string
  user_id: number
  created_at: string
  updated_at: string
  xmpp_room_jid: string | null
  occupants_ids: number[]
  last_message: string
  last_message_id: string
  last_message_date_sent: number | string
  last_message_user_id: number
  unread_messages_count: number
  is_join_required: boolean
}


export interface IChatData {
  id: number;
  message: string;
  user_id: string;
  user_chat_id: string;
  created_at: string;
  chat_dialog_id: string;
  sender_qb_id: number;
  sender_name: string;
  recipient_qb_id: number;
  recipient_name: string;
  chat_day: string;
  attachment_type: string | null;
  file: string | null;
  attachment_name: string | null;
  attachment_id: number | null;
  blob_id: number | null;
  uid: string | null;
  attributes: []
}

export interface Attachment {
  id: string;
  type: string;
}

export interface GroupedMessages {
  [key: string]: IChatData[];
};

export interface GroupedMessagesUserList {
  [key: string]: ChatUser[];
};

export interface IMessage {
  id: string;
  type: "chat_message";
  attributes: {
    id: number;
    message: string;
    account_id: number;
    chat_id: number;
    created_at: string;
    updated_at: string;
    is_mark_read: boolean;
    attachments: { id: number, url: string }[] | null;
  };
}

export interface ChatUser {
  id: number;
  name: string | null;
  qb_id: number | null;
  chat_dialog_id: string | null;
  chat_time: string | null;
  message: string | null;
  attachment_type: string | null;
  photo: string | null;
  unread_messages: number;
}

 export interface ChatDialog  {
  type: number;
  name: string;
  photo: string | null;
  _id: string;
  user_id: number;
  created_at: string;
  updated_at: string;
  xmpp_room_jid: string | null;
  occupants_ids: number[];
  last_message: string | null;
  last_message_id: string | null;
  last_message_date_sent: number | null | string;
  last_message_user_id: number | null;
  unread_messages_count: number;
}

export interface FileResponse {
  id: number;
  uid: string;
  content_type: string;
  name: string;
  size: number | null;
  created_at: string;
  updated_at: string;
  blob_status: string | null;
  set_completed_at: string | null;
  public: boolean;
  account_id: number;
  app_id: number;
  blob_object_access: {
      id: number;
      blob_id: number;
      expires: string;
      object_access_type: string;
      params: string;
  };
}
// Customizable Area End

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  token: string;
  chatId: number;
  selectedChatUser: ChatUser | null;
  message: string;
  accountIdInput: string;
  accountId: number;
  chatData: GroupedMessages[];
  userList: ChatUser[] | null;
  file: FileResponse | null;
  isVisibleModal: boolean;
  isVisiblePreviewModal: boolean;
  imageUrl: string;
  docRes: any;
  keyboardHeight: number;
  muted: boolean | null;
  chatLoading: boolean;
  chatLoader: boolean;
  usersLoading: boolean;
  openChatDialog: ChatDialog  | null;
  authToken: string | null;
  // Customizable Area End
}

interface SS {
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

export default class ChatViewController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  sendMessageApiCallId: string = "";
  getChatMessagesApiCallId: string = "";
  getUnreadMessagesApiCallId: string = "";
  getUserListApiCallId: string = "";
  toggleMuteApiCallId: string = "";
  updateReadMessageApiCallId: string = "";
  createDialogApiCallId: string = "";
  createFileApiCallId: string = "";
  uploadFileApiCallId: string = "";
  postFileApiCallId: string = ""

  allUnreadMessages: any[] = [];
  counter: number = 0;
  refreshChatInterval: unknown;
  //(this.isPlatformWeb() ? number: ReturnType<typeof setInterval>)
  //((this.isPlatformWeb()) ? (number) : (NodeJS.Timer));
  scrollViewRef: React.RefObject<ScrollView>;
  fileInputRef: React.RefObject<InputProps & { click: Function }>;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      token: "",
      chatId: 0,
      message: "",
      accountId: -1,
      accountIdInput: "",
      selectedChatUser: null,
      chatData: [],
      userList: null,
      file: null,
      isVisibleModal: false,
      isVisiblePreviewModal: false,
      imageUrl: "",
      docRes: null,
      keyboardHeight: 0,
      muted: null,
      chatLoading: false,
      chatLoader: false,
      usersLoading: true,
      openChatDialog: null,
      authToken:null,
      // Customizable Area End
    };

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area Start
    this.scrollViewRef = React.createRef();
    this.fileInputRef = React.createRef();
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    super.componentDidMount();
    this.getUserList()  
    const getauthToken = await getStorageData("authToken");
    this.setState({authToken: getauthToken})

    setInterval(() => {
      this.createDialog()
    }, 20000);
  }

  async componentDidUpdate(prevProps: any, prevState: any) {
    if (this.state.selectedChatUser !== prevState.selectedChatUser) {
      if (this.state.selectedChatUser?.qb_id) {
        this.setState({chatLoading: true})
        this.createDialog();
      }
      this.setState({ chatData: [] });
    } 

    if (this.state.openChatDialog != prevState.openChatDialog) {
      if (this.state.openChatDialog?._id) {
        this.getChatMessages();
      }
    }

    if (this.state.userList !== prevState.userList) {
      const chatDialogId = this.state.userList?.[this.counter]?.chat_dialog_id;
      if (chatDialogId) {
        this.getUnreadMessages(chatDialogId);
      }
    }
  }

  handleMessageChange = (message: string) => {
    this.setState({ message });
  };

  handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.value && event.target.files) {
      const file = event.target.files[0];

      const maxFileSize = 15 * 1024 * 1024;
      if (file?.size > maxFileSize) {
        alert('Files up to 15 MB are only allowed.');
        this.setState({ imageUrl: "", docRes: null })
        event.target.value = ''
        return;
      }

      let reader = new FileReader();
      reader.onload = (readerEvent) => {
        this.setState({ imageUrl: readerEvent.target?.result as string, docRes: file, isVisiblePreviewModal: true });
      };
      reader.readAsDataURL(file);
      this.setState({ docRes: file, isVisiblePreviewModal: true, message: event.target.files[0]?.name });
      event.target.value = ''
    } else {
      this.setState({ imageUrl: "", docRes: null })
    }
  };

  handleSendMessage = () => {
    if (this.state.docRes) {
      this.setState({ chatLoader: true })
      this.postCreateFile(this.state.docRes.name);
    } else {
      this.sendChatMessage();
    }
  
  };

  handleInsertImage = () => {
    const refrence = this.fileInputRef.current;
    if (refrence) {
      refrence.click();
    }
  };

  


  getUnreadMessages = (chatDialogId: string) => {
    const header = {
      token: this.state.authToken,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getUnreadMessagesApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getUnreadMessages}?chat_dialog_ids=${chatDialogId}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  sendChatMessage = async () => {
    const header = {
      "Content-Type": configJSON.apiContentType,
      token: this.state.authToken,
    };

    const body = {
      chat_dialog_id: this.state.openChatDialog?._id,
      message: this.state.message.trim(),
      recipient_id: this.state.selectedChatUser?.qb_id
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.sendMessageApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.sendMessageApiEndPoint}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  sendChatFileMessage = () => {
    const header = {
      "Content-Type": configJSON.apiContentType,
      'token': this.state.authToken,
    };

    const body = {
      chat_dialog_id: this.state.openChatDialog?._id,
      attachment_type: this.state.docRes.type,
      recipient_id: this.state.selectedChatUser?.qb_id,
      blob_id: this.state.file?.id,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.uploadFileApiCallId = requestMessage.messageId;
    
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.sendMessageApiEndPoint}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  createDialog = async () => {
    const header = {
      "Content-Type": configJSON.apiContentType,
      'token': this.state.authToken,
    };
    const bodyData = {
      dialog_data: {
        type: 3,
        occupants_ids: this.state.selectedChatUser?.qb_id
      }
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.createDialogApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.createDialog}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(bodyData)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getChatMessages = async () => {
    const header = {
      'token': this.state.authToken,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getChatMessagesApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getChatMessages}?chat_dialog_id=${this.state.openChatDialog?._id}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

 
  postUploadFile = async (uid: string, id: number) => {
    const header = {
      "Content-Type": configJSON.apiContentType,
      'token': this.state.authToken,
    };

    let fileBit64 = '';

    await this.fileToBase64(this.state.docRes)
      .then(base64String => {
        fileBit64 = `data:${this.state.docRes.type};base64,` + base64String; 
      })
      .catch(error => {
          console.error('Error to convert file to bit64:', error);
      });


    const bodyData = {
        blob_id: id,
        uid: uid,
        file: fileBit64
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.postFileApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.uploadFile}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(bodyData)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  postCreateFile = async (fileName: string) => {
    const getauthToken = await getStorageData("authToken");
    
    const header = {
      "Content-Type": configJSON.apiContentType,
      'token': getauthToken,
    };

    const bodyData = {
        content_type: this.state.docRes.type,
        name: fileName
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.createFileApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.createFile}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(bodyData)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  async fileToBase64(file: any): Promise<string> {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = () => {
            const base64String = reader.result as string;
            const base64 = base64String.split(',')[1];
            resolve(base64);
        };
        reader.onerror = () => {
            reject(new Error('Error reading file.'));
        };
        reader.readAsDataURL(file);
    });
  }

  getUserList =  async () => {
    const getauthToken = await getStorageData("authToken");
    const header = {
      "Content-Type": 'application/json',
      'token': getauthToken,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getUserListApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getListUsers}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  userListTimeSet = (userList:ChatUser[]) => {
    const groupedMessages: GroupedMessagesUserList = {};
      userList.forEach((item:ChatUser) => {
        if (item.chat_time && item.chat_time.includes("AM") || item.chat_time && item.chat_time.includes("PM")) {
        const createdAtDate = moment.utc(item.chat_time, "hh:mm A").local();
        const formattedTime = createdAtDate.format('hh:mm  A');
  
        let groupKey: string;
        item.chat_time = formattedTime;
        groupKey = formattedTime;
    
        if (!groupedMessages[groupKey]) {
          groupedMessages[groupKey] = [];
        }
        groupedMessages[groupKey].push(item);
      }
      });
  }

  groupMessagesByDate(messages: IChatData[]): GroupedMessages[] {
    const groupedMessages: GroupedMessages = {};
    messages.forEach((item) => {
      const createdAtDate = moment.utc(item.created_at).local();
      const todayDate = new Date();
      const yesterdayDate = new Date();

      const formattedDate = createdAtDate.format('DD-MM-YYYY');
      const formattedTime = createdAtDate.format('hh:mm A');

      yesterdayDate.setDate(yesterdayDate.getDate() - 1);
  
      let groupKey: string;
      if (item.chat_day === 'Yesterday' || item.chat_day === 'Today') {
        groupKey = item.chat_day
      } else {
        groupKey = formattedDate;
      }
      item.created_at = formattedTime;
  
      // Comprueba si la clave ya existe en groupedMessages, si no, inicializa una lista vacía
      if (!groupedMessages[groupKey]) {
        groupedMessages[groupKey] = [];
      }
  
      // Agrega el mensaje al grupo correspondiente
      groupedMessages[groupKey].push(item);
    });
  
    // Convertir el objeto en una lista de listas
    const groupedMessagesList: GroupedMessages[] = Object.keys(groupedMessages).map((key) => ({
      [key]: groupedMessages[key]
    }));
  
    return groupedMessagesList;
  }
  
  


 
  btnSelectChatUser = (chat: ChatUser) => {
    this.setState({ selectedChatUser: chat});
  };

 
  btnSendMessageProps = {
    onPress: () => {
      this.sendChatMessage();
      this.setState({ message: "", imageUrl: "", isVisiblePreviewModal: false });
    },
  };


  async receive(from: string, message: Message) {
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    const errorResponse = message.getData(
      getName(MessageEnum.RestAPIResponceErrorMessage)
    );
    if (errorResponse) this.parseApiCatchErrorResponse(errorResponse);
    if (responseJson?.errors) this.parseApiErrorResponse(responseJson);

    if (getName(MessageEnum.SessionResponseMessage) === message.id) {
      const token: string = message.getData(
        getName(MessageEnum.SessionResponseToken)
      );
      
     
      
      
    }

    
    const restApiDataCondition: boolean = responseJson && getName(MessageEnum.RestAPIResponceMessage) === message.id;
   
    this.renderinSwitch(from, message)
    if (restApiDataCondition && apiRequestCallId === this.getUserListApiCallId) {
      const { result: userList } = responseJson;
      this.counter = 0;
      let filteredUserList = [];
      let selectedUser = null;
      this.userListTimeSet(userList);
      filteredUserList = userList.filter((item: ChatUser) => item.name !== null);
      if (this.state.selectedChatUser == null){
        selectedUser = filteredUserList[0]
      } else {
        selectedUser = this.state.selectedChatUser
      }

      this.setState({
          userList: filteredUserList,
          selectedChatUser: selectedUser,
          usersLoading: false,
      });

    } 
    
  }
  

  renderinSwitch (from: string, message: Message)
 {
  const apiRequestCallId = message.getData(
    getName(MessageEnum.RestAPIResponceDataMessage)
  );
  const responseJson = message.getData(
    getName(MessageEnum.RestAPIResponceSuccessMessage)
  );
 

  const restApiDataCondition: boolean = responseJson && getName(MessageEnum.RestAPIResponceMessage) === message.id;
  if (restApiDataCondition && responseJson) {
    switch (apiRequestCallId) {
      case this.sendMessageApiCallId:
        this.setState({ message: "", imageUrl: "", isVisiblePreviewModal: false, file: null });
        this.getChatMessages();
        this.getUserList()
        break;
  
      case this.createFileApiCallId:
        const createFileResponse: FileResponse = responseJson.result;
        this.setState({ file: createFileResponse, chatLoader: true });
        this.postUploadFile(createFileResponse.uid, createFileResponse.id);
        break;
  
      case this.postFileApiCallId:
        const uploadFileResponse = responseJson.result;
        if (uploadFileResponse) {
          this.sendChatFileMessage();
        }
        break;
      
      case this.uploadFileApiCallId:
        this.setState({ message: "", imageUrl: "", isVisiblePreviewModal: false, file: null, docRes: null, chatLoader: false });
        this.getUserList()
        this.getChatMessages();
        break;

      case this.getUnreadMessagesApiCallId:
        this.userList(from,message)
        break;

      case this.createDialogApiCallId:
        this.setDialogData(responseJson)
        break;
  
      case this.getChatMessagesApiCallId:
        const chatData: IChatData[] = responseJson.result;
        const groupedMessages = this.groupMessagesByDate(chatData);
        this.setState({ chatData: groupedMessages, chatLoader: false });
        break;
  
      
    }
  }
  
 }

  setDialogData = (responseJson: CreateDialog) => {
    if (responseJson.result) {
      this.setState({ openChatDialog: responseJson.result, chatLoader: false, chatLoading: false });
    }
    else {
      if (responseJson.errors[0].token == 'Token has Expired' || responseJson.errors[0].token == 'Invalid token') {
        const msg = new Message(getName(MessageEnum.NavigationMessage));
        msg.addData(getName(MessageEnum.NavigationTargetMessage), "Home");
        msg.addData(
          getName(MessageEnum.NavigationPropsMessage),
          this.props
        );
        this.send(msg);
      }
    }
  }

 userList(from: string, message: Message){
  const { userList } = this.state
  
  const responseJson = message.getData(
    getName(MessageEnum.RestAPIResponceSuccessMessage)
  )
  if (userList && this.counter < userList.length) {
    this.counter = this.counter + 1;
    const updatedUserList = userList.map(user => {
      if (user.chat_dialog_id && Object.keys(responseJson.result).includes(user.chat_dialog_id)) {
        return {
          ...user,
          unread_messages: responseJson.result[user.chat_dialog_id]
        };
      }
      return user;
    });
    
    this.setState({ userList: updatedUserList });
  }
 }
 reverseChatData (){
  return this.state.chatData && this.state.chatData?.slice().reverse()
 }

  downloadDocument = (item: string) => {
    window.open(item, "_blank")
  }
  // Customizable Area End
}
