export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const arrowleft = require("../assets/Arrowleftdull.png");
export const rightarrow = require("../assets/ArrowDarkright.png");
export const avatar = require("../assets/avatar.png");

export const categotyData = [
    {
        "prepopulate_cc": "NO",
        "description": "Surrogate category",
        "time_spent_mandatory": true,
        "public": true,
        "id": 36,
        "name": "Academics and Testing"
    },
    {
        "prepopulate_cc": "AR",
        "description": "Category for Learn, OGS, and Testing Teams after surrogating from Academics & Testing",
        "time_spent_mandatory": false,
        "public": true,
        "id": 101,
        "name": "Academics & Testing"
    },
    {
        "prepopulate_cc": "NO",
        "description": "",
        "time_spent_mandatory": true,
        "public": false,
        "id": 65,
        "name": "Aircall - HP"
    },
    {
        "prepopulate_cc": "AR",
        "description": "",
        "time_spent_mandatory": false,
        "public": false,
        "id": 96,
        "name": "AO"
    },
    {
        "prepopulate_cc": "AR",
        "description": "",
        "time_spent_mandatory": false,
        "public": false,
        "id": 88,
        "name": "AO2"
    },
    {
        "prepopulate_cc": "AR",
        "description": "",
        "time_spent_mandatory": false,
        "public": false,
        "id": 89,
        "name": "AO3"
    },
    {
        "prepopulate_cc": "AR",
        "description": "",
        "time_spent_mandatory": false,
        "public": false,
        "id": 90,
        "name": "AO4"
    },
    {
        "prepopulate_cc": "AR",
        "description": "",
        "time_spent_mandatory": false,
        "public": false,
        "id": 91,
        "name": "AO5"
    },
    {
        "prepopulate_cc": "AR",
        "description": "",
        "time_spent_mandatory": false,
        "public": false,
        "id": 93,
        "name": "AO7"
    },
    {
        "prepopulate_cc": "AR",
        "description": "",
        "time_spent_mandatory": false,
        "public": false,
        "id": 94,
        "name": "AO8"
    },
    {
        "prepopulate_cc": "AR",
        "description": "",
        "time_spent_mandatory": false,
        "public": false,
        "id": 95,
        "name": "AO9"
    },
    {
        "prepopulate_cc": "NO",
        "description": "",
        "time_spent_mandatory": true,
        "public": true,
        "id": 47,
        "name": "Books & Beyond"
    },
    {
        "prepopulate_cc": "AR",
        "description": "",
        "time_spent_mandatory": false,
        "public": true,
        "id": 106,
        "name": "Builder Test"
    },
    {
        "prepopulate_cc": "NO",
        "description": "External/Portal Category for Billing and Admissions",
        "time_spent_mandatory": true,
        "public": true,
        "id": 48,
        "name": "Enrollment and Payment"
    },
    {
        "prepopulate_cc": "AR",
        "description": "",
        "time_spent_mandatory": false,
        "public": false,
        "id": 116,
        "name": "For Eval - Accreditation"
    },
    {
        "prepopulate_cc": "AR",
        "description": "",
        "time_spent_mandatory": false,
        "public": false,
        "id": 114,
        "name": "For Eval - Mid-year"
    },
    {
        "prepopulate_cc": "AR",
        "description": "",
        "time_spent_mandatory": false,
        "public": false,
        "id": 115,
        "name": "For Eval - SHS (PH)"
    },
    {
        "prepopulate_cc": "AR",
        "description": "",
        "time_spent_mandatory": false,
        "public": false,
        "id": 113,
        "name": "For Eval -  TLC"
    },
    {
        "prepopulate_cc": "AR",
        "description": "",
        "time_spent_mandatory": false,
        "public": false,
        "id": 117,
        "name": "For Eval - WCFS (Grade 10-12)"
    },
    {
        "prepopulate_cc": "AR",
        "description": "",
        "time_spent_mandatory": false,
        "public": false,
        "id": 105,
        "name": "HG Davao"
    },
    {
        "prepopulate_cc": "NO",
        "description": "",
        "time_spent_mandatory": true,
        "public": true,
        "id": 35,
        "name": "HGME Academics & Testing"
    },
    {
        "prepopulate_cc": "NO",
        "description": "",
        "time_spent_mandatory": true,
        "public": false,
        "id": 72,
        "name": "HGME Billing"
    },
    {
        "prepopulate_cc": "NO",
        "description": "",
        "time_spent_mandatory": true,
        "public": true,
        "id": 34,
        "name": "HGME Enrollment & Payment"
    },
    {
        "prepopulate_cc": "NO",
        "description": "",
        "time_spent_mandatory": true,
        "public": false,
        "id": 79,
        "name": "HGME Equip"
    },
    {
        "prepopulate_cc": "NO",
        "description": "",
        "time_spent_mandatory": true,
        "public": false,
        "id": 44,
        "name": "HGME Feedback"
    },
    {
        "prepopulate_cc": "NO",
        "description": "",
        "time_spent_mandatory": true,
        "public": false,
        "id": 73,
        "name": "HGME Online Academy"
    },
    {
        "prepopulate_cc": "NO",
        "description": "",
        "time_spent_mandatory": true,
        "public": true,
        "id": 37,
        "name": "HGME Other Concerns"
    },
    {
        "prepopulate_cc": "NO",
        "description": "",
        "time_spent_mandatory": true,
        "public": true,
        "id": 33,
        "name": "HGME Records or Documents"
    },
    {
        "prepopulate_cc": "NO",
        "description": "",
        "time_spent_mandatory": true,
        "public": true,
        "id": 43,
        "name": "HGME Shine"
    },
    {
        "prepopulate_cc": "NO",
        "description": "",
        "time_spent_mandatory": true,
        "public": false,
        "id": 71,
        "name": "HGME Support & Admissions"
    },
    {
        "prepopulate_cc": "NO",
        "description": "",
        "time_spent_mandatory": true,
        "public": false,
        "id": 76,
        "name": "HGME Tech AOP"
    },
    {
        "prepopulate_cc": "NO",
        "description": "",
        "time_spent_mandatory": true,
        "public": false,
        "id": 75,
        "name": "HGME Tech BJU"
    },
    {
        "prepopulate_cc": "NO",
        "description": "",
        "time_spent_mandatory": true,
        "public": false,
        "id": 78,
        "name": "HGME Testing"
    },
    {
        "prepopulate_cc": "AR",
        "description": "",
        "time_spent_mandatory": true,
        "public": false,
        "id": 77,
        "name": "HGME Touch"
    },
    {
        "prepopulate_cc": "LN",
        "description": "",
        "time_spent_mandatory": true,
        "public": false,
        "id": 80,
        "name": "HGME Unassigned"
    },
    {
        "prepopulate_cc": "NO",
        "description": "",
        "time_spent_mandatory": true,
        "public": false,
        "id": 74,
        "name": "HGME VCIS Online"
    },
    {
        "prepopulate_cc": "LR",
        "description": "",
        "time_spent_mandatory": true,
        "public": false,
        "id": 6,
        "name": "HGPH Admissions"
    },
    {
        "prepopulate_cc": "LR",
        "description": "",
        "time_spent_mandatory": true,
        "public": false,
        "id": 4,
        "name": "HGPH Billing"
    },
    {
        "prepopulate_cc": "LR",
        "description": "",
        "time_spent_mandatory": true,
        "public": false,
        "id": 21,
        "name": "HGPH Connect"
    },
    {
        "prepopulate_cc": "LR",
        "description": "",
        "time_spent_mandatory": true,
        "public": false,
        "id": 87,
        "name": "HGPH Enrichment Classes"
    },
    {
        "prepopulate_cc": "LR",
        "description": "",
        "time_spent_mandatory": true,
        "public": false,
        "id": 20,
        "name": "HGPH Equip"
    },
    {
        "prepopulate_cc": "LR",
        "description": "",
        "time_spent_mandatory": true,
        "public": false,
        "id": 2,
        "name": "HGPH Family Support"
    },
    {
        "prepopulate_cc": "LR",
        "description": "",
        "time_spent_mandatory": true,
        "public": false,
        "id": 50,
        "name": "HGPH Hub Dev"
    },
    {
        "prepopulate_cc": "LR",
        "description": "",
        "time_spent_mandatory": true,
        "public": false,
        "id": 81,
        "name": "HGPH Hybrid"
    },
    {
        "prepopulate_cc": "LR",
        "description": "",
        "time_spent_mandatory": true,
        "public": false,
        "id": 82,
        "name": "HGPH Learn Academic Services"
    },
    {
        "prepopulate_cc": "LR",
        "description": "",
        "time_spent_mandatory": true,
        "public": false,
        "id": 86,
        "name": "HGPH Learn Director"
    },
    {
        "prepopulate_cc": "LR",
        "description": "",
        "time_spent_mandatory": true,
        "public": false,
        "id": 17,
        "name": "HGPH LP Classes"
    },
    {
        "prepopulate_cc": "LR",
        "description": "",
        "time_spent_mandatory": true,
        "public": false,
        "id": 23,
        "name": "HGPH OGS"
    },
    {
        "prepopulate_cc": "LR",
        "description": "",
        "time_spent_mandatory": true,
        "public": false,
        "id": 13,
        "name": "HGPH Online Academy"
    },
    {
        "prepopulate_cc": "LR",
        "description": "",
        "time_spent_mandatory": true,
        "public": true,
        "id": 39,
        "name": "HGPH Other Concerns"
    },
    {
        "prepopulate_cc": "LR",
        "description": "",
        "time_spent_mandatory": true,
        "public": false,
        "id": 85,
        "name": "HGPH Records - (HG-US )VCS"
    },
    {
        "prepopulate_cc": "LR",
        "description": "",
        "time_spent_mandatory": true,
        "public": false,
        "id": 11,
        "name": "HGPH Records - TMA"
    },
    {
        "prepopulate_cc": "LR",
        "description": "",
        "time_spent_mandatory": false,
        "public": false,
        "id": 3,
        "name": "HGPH Records - VCIS"
    },
    {
        "prepopulate_cc": "LR",
        "description": "",
        "time_spent_mandatory": true,
        "public": false,
        "id": 9,
        "name": "HGPH Records - WCFS"
    },
    {
        "prepopulate_cc": "LR",
        "description": "",
        "time_spent_mandatory": false,
        "public": false,
        "id": 5,
        "name": "HGPH Shine"
    },
    {
        "prepopulate_cc": "LR",
        "description": "",
        "time_spent_mandatory": true,
        "public": false,
        "id": 111,
        "name": "HGPH Sports"
    },
    {
        "prepopulate_cc": "LR",
        "description": "",
        "time_spent_mandatory": true,
        "public": false,
        "id": 12,
        "name": "HGPH SQM"
    },
    {
        "prepopulate_cc": "LR",
        "description": "",
        "time_spent_mandatory": true,
        "public": false,
        "id": 15,
        "name": "HGPH Tech AOP"
    },
    {
        "prepopulate_cc": "LR",
        "description": "",
        "time_spent_mandatory": true,
        "public": false,
        "id": 14,
        "name": "HGPH Tech BJU"
    },
    {
        "prepopulate_cc": "LR",
        "description": "",
        "time_spent_mandatory": true,
        "public": false,
        "id": 28,
        "name": "HGPH Tech Custom"
    },
    {
        "prepopulate_cc": "LR",
        "description": "",
        "time_spent_mandatory": true,
        "public": false,
        "id": 7,
        "name": "HGPH Testing"
    },
    {
        "prepopulate_cc": "LR",
        "description": "",
        "time_spent_mandatory": true,
        "public": false,
        "id": 22,
        "name": "HGPH TLC"
    },
    {
        "prepopulate_cc": "LR",
        "description": "",
        "time_spent_mandatory": true,
        "public": false,
        "id": 29,
        "name": "HGPH Touch Charlotte Mason"
    },
    {
        "prepopulate_cc": "LR",
        "description": "",
        "time_spent_mandatory": true,
        "public": false,
        "id": 30,
        "name": "HGPH Touch Classical"
    },
    {
        "prepopulate_cc": "LR",
        "description": "",
        "time_spent_mandatory": true,
        "public": false,
        "id": 16,
        "name": "HGPH Touch Curated"
    },
    {
        "prepopulate_cc": "LR",
        "description": "Bucket Category for Support Email.",
        "time_spent_mandatory": true,
        "public": false,
        "id": 66,
        "name": "HGPH Unassigned"
    },
    {
        "prepopulate_cc": "LR",
        "description": "",
        "time_spent_mandatory": true,
        "public": false,
        "id": 27,
        "name": "HGPH VCIS Online"
    },
    {
        "prepopulate_cc": "LR",
        "description": "",
        "time_spent_mandatory": true,
        "public": false,
        "id": 84,
        "name": "HG Qatar"
    },
    {
        "prepopulate_cc": "NO",
        "description": "",
        "time_spent_mandatory": true,
        "public": false,
        "id": 18,
        "name": "High Unite"
    },
    {
        "prepopulate_cc": "NO",
        "description": "",
        "time_spent_mandatory": true,
        "public": true,
        "id": 40,
        "name": "HP Academics"
    },
    {
        "prepopulate_cc": "NO",
        "description": "",
        "time_spent_mandatory": true,
        "public": false,
        "id": 55,
        "name": "HP Academic Training"
    },
    {
        "prepopulate_cc": "NO",
        "description": "",
        "time_spent_mandatory": true,
        "public": false,
        "id": 52,
        "name": "HP Admissions"
    },
    {
        "prepopulate_cc": "NO",
        "description": "",
        "time_spent_mandatory": true,
        "public": false,
        "id": 54,
        "name": "HP Books"
    },
    {
        "prepopulate_cc": "NO",
        "description": "",
        "time_spent_mandatory": true,
        "public": false,
        "id": 59,
        "name": "HP Communications"
    },
    {
        "prepopulate_cc": "NO",
        "description": "",
        "time_spent_mandatory": true,
        "public": true,
        "id": 41,
        "name": "HP Enrollment & Payment"
    },
    {
        "prepopulate_cc": "NO",
        "description": "",
        "time_spent_mandatory": true,
        "public": false,
        "id": 58,
        "name": "HP Events & Student Affairs"
    },
    {
        "prepopulate_cc": "NO",
        "description": "",
        "time_spent_mandatory": true,
        "public": false,
        "id": 46,
        "name": "HP Feedback"
    },
    {
        "prepopulate_cc": "NO",
        "description": "",
        "time_spent_mandatory": true,
        "public": false,
        "id": 53,
        "name": "HP Finance"
    },
    {
        "prepopulate_cc": "NO",
        "description": "",
        "time_spent_mandatory": true,
        "public": false,
        "id": 61,
        "name": "HP IT & Facility"
    },
    {
        "prepopulate_cc": "NO",
        "description": "",
        "time_spent_mandatory": true,
        "public": false,
        "id": 60,
        "name": "HP Marketing & Creatives"
    },
    {
        "prepopulate_cc": "NO",
        "description": "",
        "time_spent_mandatory": true,
        "public": false,
        "id": 56,
        "name": "HP Mobymax"
    },
    {
        "prepopulate_cc": "NO",
        "description": "",
        "time_spent_mandatory": true,
        "public": false,
        "id": 42,
        "name": "HP Other Concerns"
    },
]

export const subCategoryData = [
    {
        "name": "Book Concerns",
        "depends_on_choice": null,
        "required": true,
        "compulsory_on_completed": true,
        "choices": [
            {
                "text": "AOP Ignitia Access",
                "id": 68,
                "dependant_fields": []
            },
            {
                "text": "BJU DLO Access",
                "id": 67,
                "dependant_fields": []
            },
            {
                "text": "Book Availability",
                "id": 64,
                "dependant_fields": []
            },
            {
                "text": "Book Inquiry",
                "id": 63,
                "dependant_fields": []
            },
            {
                "text": "Book Refund",
                "id": 78,
                "dependant_fields": []
            },
            {
                "text": "Callback",
                "id": 79,
                "dependant_fields": []
            },
            {
                "text": "Incorrect Order",
                "id": 72,
                "dependant_fields": []
            },
            {
                "text": "Loyalty Program",
                "id": 76,
                "dependant_fields": []
            },
            {
                "text": "Missing Order",
                "id": 71,
                "dependant_fields": []
            },
            {
                "text": "Online Group Tutorials (OGT)",
                "id": 650,
                "dependant_fields": []
            },
            {
                "text": "Order Cancellation",
                "id": 66,
                "dependant_fields": []
            },
            {
                "text": "Payment and Order Placing Assistance",
                "id": 65,
                "dependant_fields": []
            },
            {
                "text": "Quipper Access",
                "id": 69,
                "dependant_fields": []
            },
            {
                "text": "Returns and Replacement",
                "id": 77,
                "dependant_fields": []
            },
            {
                "text": "Shipping Address",
                "id": 74,
                "dependant_fields": []
            },
            {
                "text": "Shipping Fee",
                "id": 73,
                "dependant_fields": []
            },
            {
                "text": "Status of Order",
                "id": 75,
                "dependant_fields": []
            },
            {
                "text": "eBook Access",
                "id": 70,
                "dependant_fields": []
            }
        ],
        "compulsory_on_move": true,
        "type": "choice",
        "id": 7,
        "categories": [
            {
                "category": 47,
                "order": 1
            },
            {
                "category": 8,
                "order": 1
            },
            {
                "category": 106,
                "order": 1
            }
        ],
        "visible_to_staff_only": false
    },
    {
        "name": "HGME Hubs",
        "depends_on_choice": null,
        "required": true,
        "compulsory_on_completed": true,
        "choices": [
            {
                "text": "Abu Dhabi",
                "id": 643,
                "dependant_fields": []
            },
            {
                "text": "Dubai (ME Regional Office)",
                "id": 641,
                "dependant_fields": []
            },
            {
                "text": "Kingdom of Saudi Arabia",
                "id": 644,
                "dependant_fields": []
            },
            {
                "text": "Qatar",
                "id": 642,
                "dependant_fields": []
            }
        ],
        "compulsory_on_move": false,
        "type": "choice",
        "id": 52,
        "categories": [
            {
                "category": 73,
                "order": 3
            },
            {
                "category": 74,
                "order": 3
            },
            {
                "category": 37,
                "order": 2
            },
            {
                "category": 79,
                "order": 2
            },
            {
                "category": 47,
                "order": 2
            },
            {
                "category": 76,
                "order": 3
            },
            {
                "category": 75,
                "order": 3
            },
            {
                "category": 43,
                "order": 2
            },
            {
                "category": 33,
                "order": 6
            },
            {
                "category": 72,
                "order": 2
            },
            {
                "category": 71,
                "order": 2
            },
            {
                "category": 34,
                "order": 2
            },
            {
                "category": 78,
                "order": 2
            },
            {
                "category": 44,
                "order": 1
            },
            {
                "category": 80,
                "order": 1
            },
            {
                "category": 77,
                "order": 3
            },
            {
                "category": 35,
                "order": 3
            }
        ],
        "visible_to_staff_only": false
    }
]