import React from "react";

// Customizable Area Start

import {
  Container,
  Box,
  Button,
  InputLabel,
  Typography,
  InputAdornment,
  IconButton,
  Checkbox,
  Grid,
  TextField,
} from "@material-ui/core";
import EmailOutlinedIcon from "@material-ui/icons/EmailOutlined";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Snackbar from "@material-ui/core/Snackbar";
import { createTheme, ThemeProvider,withStyles } from "@material-ui/core/styles";
import { imgConnect } from "./assets";
import LockOpenIcon from '@material-ui/icons/LockOpen';
import LockOutlined from '@material-ui/icons/LockOutlined';
import { Link } from "react-router-dom";
import CircularProgress from '@material-ui/core/CircularProgress';
import Alert from '@material-ui/lab/Alert';
import { webStyle } from "./styles";

const StyledTextField = withStyles({
  root: {
       //fontFamily: "Roboto-Medium",
    fontSize: "16",
    "& .MuiFilledInput-root": {
      backgroundColor: "transparent",
    },
    "& .MuiFilledInput-root:hover": {
      backgroundColor: "transparent",
      "@media (hover: none)": { backgroundColor: "transparent",
      },
    },
    "& .MuiFilledInput-root.Mui-focused": {
      backgroundColor: "transparent",
    },
    "& input::placeholder": {
         //fontFamily: "Roboto-Medium",
      fontSize: "16",
      width: "222.98px",
      fontWeight: 500,
      height: "19px",
      color: "#44444f",
    },
    "& .MuiInputBase-input.MuiInput-input": { fontFamily: "Montserrat"
    }
  },
})((props: any) => <TextField {...props} />);

export const configJSON = require("./config");
// Customizable Area End

import EmailAccountLoginController, {
  Props,
} from "./EmailAccountLoginController";

const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
});

export default class EmailAccountLoginBlock extends EmailAccountLoginController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    // Customizable Area Start
    return (
      // Required for all blocks
      <ThemeProvider theme={theme}>
        <Container maxWidth={false} style={webStyle.ContainerWidth}>
          <Grid container style={webStyle.mainWrapper}>
            <Grid item xs={12} sm={6} style={webStyle.mainInnerWrapper}>
              <Box sx={webStyle.leftWrapper}>
                <Box sx={webStyle.innerLeftStyle}>
                  <Box style={webStyle.BoxImageStyle}>
                    <img src={imgConnect} style={webStyle.imgStyleLogin} />
                  </Box>
                  <Box style={{ paddingTop: '1em' }}>
                    <Typography
                      variant="h3"
                      component={"h5"}
                      style={webStyle.textStyleLeft}
                    >
                      Everything you need to{" "}
                      <span style={webStyle.fontColour}>maximize your HG homeschooling journey </span>
                      right at your fingertips
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} sm={6} style={webStyle.mainInnerWrapper}>
              <Box sx={{...webStyle.rightWrapper, alignItems: 'left'}}>
                <Box sx={webStyle.innerRightStyleLogin}>
                  <Typography
                    variant="h6"
                    component={"h6"}
                    style={webStyle.textStyleRightlogin}
                  >
                    Welcome Back! Sign in here.
                  </Typography>
                  <form onSubmit={(e)=>this.signupFormSubmit(e)}>
                  <Box>
                    <InputLabel style={webStyle.inputLabelStyleLogin}>
                      {" "}
                      Email * 
                    </InputLabel>
                    <StyledTextField
                      data-testid="email-input"
                      placeholder="sample@mail.com"
                      fullWidth={true}
                      inputProps={{ style: { textTransform: "lowercase" } }}
                      value={this.state.email}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        this.onEmailChange(e.target.value)
                      }
                      name="email_input"
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <EmailOutlinedIcon style={webStyle.EmailOutlinedIcon} />
                          </InputAdornment>
                        ),
                      }}
                    />
                    <small style={webStyle.errorStyle}>
                      {this.state.errors.email}
                    </small>
                  </Box>

                  
                  <Box style={webStyle.inputBoxStyle}>
                    <InputLabel style={webStyle.inputLabelStyleLogin}>
                      {" "}
                      {configJSON.placeHolderPassword}{" "}*
                    </InputLabel>
                    <StyledTextField
                      data-testid="password-input"
                      type={this.state.passwordInputState}
                      placeholder="Enter Password"
                      fullWidth={true}
                      value={this.state.password}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        {
                          this.onPasswordChange(e.target.value)
                        }
                      }
                      
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end"  >
                            <IconButton onClick={this.changePasswordState} style={{padding: '0px'}}> {
                              this.state.passwordState ?
                              <LockOutlined style={{...webStyle.EmailOutlinedIcon}} /> 
                              :<LockOpenIcon style={webStyle.EmailOutlinedIcon} /> 
                            }
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                    <small style={webStyle.errorStyle}>
                      {this.state.errors.password}
                    </small>
                  </Box>
                  <Box
                    style={webStyle.formFlex}
                  >
                    <Box>
                      <FormControlLabel
                        style={ webStyle.RembeberLabel }
                        data-testid="remember-checkbox"
                        control={
                          <Checkbox
                            style={webStyle.CustomCheckBox}
                            checked={this.state.checkedRememberMe}
                            onChange={this.onRememberMeCheck}

                          />
                        }
                        label="Remember Me"
                      />
                    </Box>
                    <Box
                      style={webStyle.LinkToForgetPassword}
                    >
 <Link to="ForgotPassword" style={{ textDecoration:"none" ,color:"#57AFEB", fontFamily: "Montserrat",                     
        fontSize: "14px",
        fontWeight: 500,}}>Forgot Password</Link>
                    </Box>
                  </Box>
                  <Snackbar anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                  }} open={this.state.snackbarOpen} autoHideDuration={4000} onClose={this.handleCloseAlert} >
                    <Alert severity="success">
                      {this.state.snackbarMessage}
                    </Alert>
                  </Snackbar>
                  <Snackbar anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                  }} open={this.state.snackbarOpen} autoHideDuration={4000} onClose={this.handleCloseAlert} >
                    <Alert severity="success">
                      {this.state.snackbarMessage}
                    </Alert>
                  </Snackbar>
                  <Snackbar anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                  }} open={this.state.snackbarOpenFalse} autoHideDuration={4000} onClose={this.handleCloseAlert1} >
                    <Alert severity="error">
                      {this.state.snackbarMessage}
                    </Alert>
                  </Snackbar>
                  <Button
                    type="submit"
                    style={{textTransform: "none",...webStyle.SignupFormSubmitStyle}}
                    fullWidth
                    onClick={(e)=>this.signupFormSubmit(e)}
                  >
                    {this.state.loadingSignin ? <CircularProgress /> : "Login"}
                  </Button> 
                  </form>
                  <Box
                    style={webStyle.landingToAnotherPage}
                  >
                    <Typography style={webStyle.textStyleInquiry}>
                      New to HG Connect?
                      <span style={webStyle.btnStyleInquiry}>
                        <Link to="EmailAccountRegistration"> <span style={webStyle.linkStyle}>Sign Up Now</span></Link>
                      </span>
                    </Typography>
                  </Box>
                </Box>
              </Box> 
            </Grid>
          </Grid>
        </Container>
      </ThemeProvider>
    );
    // Customizable Area End
  }
}
