// Customizable Area Start
import React from "react";
import {
  Container,
  Box,
  Button,
  Typography,
  Grid,
  Step,
  Stepper,
  StepLabel,
  TextareaAutosize,
  InputLabel,
  MenuItem,
  TextField,
  Paper,
  FormControlLabel,
  Checkbox,
  Modal
} from "@material-ui/core";


import { ThemeProvider } from "@material-ui/core/styles";
import Snackbar from "@material-ui/core/Snackbar";
import Alert from '@material-ui/lab/Alert';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CircularProgress from '@material-ui/core/CircularProgress';
import HorizontalLine from "../../../components/src/HorizontalLine.web"
import CheckCircleIcon from '@material-ui/icons/CheckCircle';

import CustomEnrollController, { Props } from "./CustomEnrollController.web";
import AppWithoutDrawer from "../../../components/src/AppWithoutDrawer.web";
import Sidebarmenu from "../../../components/src/Sidebarmenu.web";
import { StepperTheme, customStepIcon } from "../../../components/src/ComponentTheme";
import { ToggleSwitch, GetErrorStyle, GetLabelTextField, StyledTextField, getOrConditionValue } from "../../../components/src/EnrollFormHelperFunctions";
import TicketFileUpload from "../../../components/src/TicketFileUpload.web";
import CloseOutlinedIcon from '@material-ui/icons/CloseOutlined';
import ExpandMoreOutlinedIcon from '@material-ui/icons/ExpandMoreOutlined';
import ExpandLessOutlinedIcon from '@material-ui/icons/ExpandLessOutlined';



export interface IDItem {
  label: string,
  value: string,
}

// Customizable Area End

const finalStep = (activeStep: number, handleFunction: React.MouseEventHandler<HTMLButtonElement>) => {
  return (
    <Grid container spacing={4} style={{ paddingLeft: '3rem', paddingRight: '3rem' }}>
      <Grid item xs={12}>
        <Paper elevation={0} style={{
          borderRadius: '18px',
          backgroundColor: '#99CB51',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}>
          <CheckCircleIcon style={{
            color: '#ffffff', width: '40px',
            textAlign: 'center', height: '40px', padding: '35px 0px 15px 0px'
          }} />
          <Typography style={{
            color: '#ffffff',
            fontSize: '18px',
            fontWeight: 600,
            paddingBottom: '5px',
            textAlign: 'center',
          }}> Thank you! </Typography>
          <Typography style={{
            color: '#ffffff',
            fontSize: '18px',
            fontWeight: 600,
            paddingBottom: '5px',
            textAlign: 'center',
          }}>Your Student enrollment has been submitted.</Typography>
          <Typography style={{
            color: '#ffffff',
            fontSize: '16px',
            paddingTop: '15px',
            paddingBottom: '10px',
            textAlign: 'center',
            width: '60%',
          }}>Our Admissions Officer will get in touch with you regarding your application(s) within 1 to 3 working days.</Typography>
          <Typography style={{
            color: '#ffffff',
            fontSize: '14px',
            paddingTop: '5px',
            paddingBottom: '35px',
            textAlign: 'center',
          }}>If you want to know the status of your application, click on the "Student" tab on menu bar.</Typography>
        </Paper>
      </Grid>
      <Grid item xs={12} style={{ marginBottom: '3em', paddingRight: '5em', textAlign: "right" }}>
        <Button
          variant="contained"
          style={{
            ...(Number(activeStep) === 0 ? webStyle.backButtonStyles : webStyle.backButtonStyles2)
          }}
          data-test-id="backBtn"
          onClick={handleFunction}
        >
          Go to Home
        </Button>
      </Grid>
    </Grid>
  )
}

// Customizable Area Start



//istanbul ignore next
const StepOne = (props: any) => {
  const { state, handler } = props;
  const { firstStepData } = state;
  const provideLabel = (val : string) => {
    return (val == "choose") ?   <div style={{ color: "darkgrey" }}> Please Select </div> : null
  }
  const stepcompOne = () => {


    const nextedcomple = () => {
      return (
        <>
          <Grid item xs={12} md={6}>
        
            <InputLabel style={webStyle.inputLabelStyle}>Gender * </InputLabel>
            <StyledTextField
              label={state.firstStepData.studentGender === "" || !state.firstStepData.studentGender ?
                <div style={{ color: "darkgrey" }}>Choose Your Gender</div> : null}
              id="gender"
              select
              fullWidth
              name="studentGender"
              data-test-id='gender'
              InputLabelProps={{ shrink: false }}
              value={state.firstStepData.studentGender}
              onChange={handler.genderChange}

            >
              <MenuItem style={webStyle.StudentMenuItemsStyle} disabled value="choose"> <span style={{ color: "#ABABB0" }}

              >Choose Your Gender</span></MenuItem>
              <MenuItem value="Male">Male</MenuItem>
              <MenuItem value="Female">Female</MenuItem>
              <MenuItem value="Other">Other</MenuItem>

            </StyledTextField>

            {firstStepData.studentErrors.studentGender &&
              <span style={{ color: 'red', fontSize: "12px", fontFamily: "Montserrat" }}>
                {firstStepData.studentErrors.studentGender}
              </span>}

          </Grid>
          <Grid item xs={12} md={6}>

            <InputLabel style={webStyle.inputLabelStyle}>Date of Birth *</InputLabel>
            <TextField
              id="date"
              data-test-id='dateId'
              type="date"
              fullWidth
              InputProps={{ inputProps: { max: handler.dateFunction(), min: "1970-01-01",
                style: { fontFamily: "Montserrat" }
               } }}
              InputLabelProps={{
                shrink: false,
              }}
              name="studentDOB"
              value={state.firstStepData.studentDOB}
              onChange={handler.datechange}
            />


            {firstStepData.studentErrors.studentDOB &&
              <span style={{ color: 'red', fontSize: "12px", fontFamily: "Montserrat" }}>
                {firstStepData.studentErrors.studentDOB}
              </span>}
          </Grid>
        </>
      )
    }
    const againComReols = () => {
      return (
        <>
          <Grid item xs={12} md={6}>
            <InputLabel style={webStyle.inputLabelStyle}>Student's Middle Name * </InputLabel>
            <StyledTextField
              placeholder="Middle Name"
              fullWidth
              data-test-id="middleName"
              name='studentMiddleName'
              value={state.firstStepData.studentMiddleName}
              onChange={handler.handleChangeText2}
            />
            {firstStepData.studentErrors.studentMiddleName &&
              <span style={{ color: 'red', fontSize: "12px", fontFamily: "Montserrat" }}>
                {firstStepData.studentErrors.studentMiddleName}
              </span>}
          </Grid>
        </>
      )
    }

    const againcomReols = () => {
      return (
        <>
          <Grid item xs={12} md={6}>
            <InputLabel style={webStyle.inputLabelStyle}> Student's First Name * </InputLabel>
            <StyledTextField
              placeholder="First Name"
              fullWidth
              name='studentFirstName'
              data-test-id="firstName"
              value={state.firstStepData.studentFirstName}
              onChange={handler.handleChangeText1} 
            />
            {firstStepData.studentErrors.studentFirstName &&
              <span style={{ color: 'red', fontSize: "12px", fontFamily: "Montserrat" }}>
                {firstStepData.studentErrors.studentFirstName}
              </span>}
          </Grid>
        </>
      )
    }
    return (
      <>
        <Grid item xs={12} md={6}> 
          <InputLabel style={webStyle.inputLabelStyle}>Student's Last Name *</InputLabel>
          <StyledTextField
            placeholder="Last Name"
            fullWidth
            name='studentLastName'
            value={state.firstStepData.studentLastName }
            onChange={handler.handleChangeText}
            data-test-id="studentLastName"
          />
          {firstStepData.studentErrors.studentLastName &&
            <span style={{ color: 'red', fontSize: "12px", fontFamily: "Montserrat" }}>
              {firstStepData.studentErrors.studentLastName}
            </span>
          }
        </Grid>
        {againcomReols()}
        {againComReols()}
        {nextedcomple()}
      </>
    )
  }
  const firstLastNameError = () => {
    return (firstStepData.studentErrors.studentBothName &&
      <Box style={webStyle.headingError}>
        <Typography style={{ color: 'red', fontSize: "12px", fontFamily: "Montserrat" , fontWeight: 'bold'}}>  {firstStepData.studentErrors.studentBothName}</Typography>
      </Box>
    )
  }
  const stepmiddleFunc = () => {
    return (
      <>
        <Grid item xs={12} md={6}>
          <InputLabel style={webStyle.inputLabelStyle}>Nationality *</InputLabel>
          <StyledTextField
            label={state.firstStepData.studentNationality === "" || !state.firstStepData.studentNationality ?
              <div style={{ color: "darkgrey" }}>Please Select</div> :
              null}
            id="studentNationality"
            select
            fullWidth
            name="studentNationality"
            data-test-id='nationality'
            InputLabelProps={{ shrink: false }}
            value={state.firstStepData.studentNationality?.value}
            onChange={handler.nationalityDataChange}
          >
            <MenuItem style={webStyle.StudentMenuItemsStyle} disabled value="choose"> <span style={{ color: "#ABABB0" }}> Please Select </span></MenuItem>
            {
              state.nationalitiesData?.map((item: any, index: number) => (
                <MenuItem
                  key={item?.id}
                  value={item?.attributes.name}
                  selected={item.value == state.firstStepData.studentNationality?.value}
                >
                  {item?.attributes.name}
                </MenuItem>
              ))
            }
          </StyledTextField>

          {firstStepData.studentErrors.studentNationality &&
            <span style={{ color: 'red', fontSize: "12px", fontFamily: "Montserrat" }}>
              {firstStepData.studentErrors.studentNationality}
            </span>}

        </Grid>

        <Grid item xs={12} md={6}>
         
          <InputLabel style={webStyle.inputLabelStyle}>Country of Residence *</InputLabel>
          <Autocomplete
            id="country_of_residence"
            value={
              state.getResidenceCountries.find(
                (item: { name: string }) => item.name === state.firstStepData.countryResiAutoCompleteValue?.value
              ) || null
            }
            onChange={handler.changeResidenceAutoGetCountriesBirthCallBack}
            options={state.getResidenceCountries}
            getOptionLabel={handler.handleGetOptionLabel}
            data-test-id='countryOfResidence'
            getOptionSelected={handler.handleGetOptionSelected}
            renderInput={handler.handleRenderInput3}

            inputValue={state.firstStepData.studentCountryOfResidence?.value}
            onInputChange={handler.changeResidenceGetCountriesCallBack}
          />
          {firstStepData.studentErrors.studentCountryOfResidence &&
            <span style={{ color: 'red', fontSize: "12px", fontFamily: "Montserrat" }}>
              {firstStepData.studentErrors.studentCountryOfResidence}
            </span>}
        </Grid>

        <Grid item xs={12} md={6}>
          <InputLabel style={webStyle.inputLabelStyle}>City of Residence *</InputLabel>
          <Autocomplete
            id="city_of_residence"
            value={state.getResidenceCities.find(
              (item: { name: string }) => item.name === state.firstStepData.cityResiAutoCompleteValue?.value
            ) || null}
            onChange={handler.changeResidenceAutoGetCitiesBirthCallBack}
            options={state.getResidenceCities}
            getOptionLabel={handler.handleGetOptionLabel}
            data-test-id='cityOfResidence'
            getOptionSelected={handler.handleGetOptionSelected}
            renderInput={handler.handleRenderInput3}

            inputValue={state.firstStepData.studentCityOfResidence?.value}
            onInputChange={handler.handleResidenceCityCallBack}
          />

          {firstStepData.studentErrors.studentCityOfResidence &&
            <span style={{ color: 'red', fontSize: "12px", fontFamily: "Montserrat" }}>
              {firstStepData.studentErrors.studentCityOfResidence}
            </span>}
        </Grid>
        {(state.barangayDataBirth?.length > 0 && state.firstStepData.countryResiAutoCompleteValue.value === "Philippines") && (state.firstStepData.cityResiAutoCompleteValue?.value != "") ?
          <Grid item xs={12} md={6}>
            <InputLabel style={webStyle.inputLabelStyle}>Barangay *</InputLabel>
            <StyledTextField
              id="barangayBirth"
              select
              color="darkgrey"
              fullWidth
              name="barangayBirth"
              data-test-id='birth'
              InputLabelProps={{ shrink: false }}
              value={state.firstStepData.barangayBirth?.value}
              onChange={handler.barangayBirthChangeCallBack}
            >
              <MenuItem style={webStyle.StudentMenuItems} disabled value="choose"> <span style={{ color: "darkgrey" }}> Please Select </span></MenuItem>
              {

                state.barangayDataBirth?.map((item: any) => {
                  return (
                    <MenuItem
                      data-test-id="menuItemId"
                      key={item?.id}
                      value={item?.attributes?.barangay_name}
                    >
                      {item?.attributes?.barangay_name}
                    </MenuItem>
                  )

                })

              }
            </StyledTextField>
            {firstStepData.studentErrors.barangayBirth &&
            <span style={{ color: 'red', fontSize: "12px", fontFamily: "Montserrat" }}>
              {firstStepData.studentErrors.barangayBirth}
            </span>}
          </Grid>
          : null
        }

        <Grid item xs={12} md={6}>
          <InputLabel style={webStyle.inputLabelStyle}>Child's First Language * </InputLabel>
          <StyledTextField
            label={state.firstStepData.studentChildsFirstLanguage === "" || !state.firstStepData.studentChildsFirstLanguage ?
              <div style={{ color: "darkgrey" }}>Please Select</div> :
              null}
            id="studentChildsFirstLanguage"
            select
            fullWidth
            name="studentChildsFirstLanguage"
            data-test-id='child-first-language'
            InputLabelProps={{ shrink: false }}
            value={state.firstStepData.studentChildsFirstLanguage?.value}
            onChange={handler.handleChildFirstLanguage}

          >
            <MenuItem style={webStyle.StudentMenuItemsStyle} disabled value="choose"> <span style={{ color: "#ABABB0" }}> Please Select </span></MenuItem>
            {
              state.languagesData?.map((item: any, index: number) => (
                <MenuItem
                  key={item?.id}
                  value={item?.attributes.language}
                  selected={item.value == state.firstStepData.studentChildsFirstLanguage?.value}
                >
                  {item?.attributes.language}
                </MenuItem>
              ))
            }
          </StyledTextField>
          {firstStepData.studentErrors.studentChildsFirstLanguage &&
            <span style={{ color: 'red', fontSize: "12px", fontFamily: "Montserrat" }}>
              {firstStepData.studentErrors.studentChildsFirstLanguage}
            </span>}
        </Grid>
      </>
    )
  }
  const stepBottomComp = () => {
    return (
      <>   <Grid container spacing={4} style={{ paddingTop: '1.5em', paddingLeft: '3rem', paddingRight: '3rem' }}>
        <Grid item xs={12} md={6} style={{ paddingRight: '3em' }}>
          <InputLabel style={webStyle.studentReferralProgramForm1}>Applying for a PWD discount?</InputLabel>
          <ToggleSwitch
            name="studentPwd"
            checked={state.firstStepData.studentPwd}
            onChange={handler.handlePwdDiscount}
            inputProps={{ 'aria-label': 'controlled' }}
            data-test-id="pwdId"
          />
        </Grid>
        {state.firstStepData.studentPwd ?
          <Grid item xs={12} md={6} style={webStyle.gridView} >

            <InputLabel style={webStyle.inputLabelStyle}>PWD ID Number *</InputLabel>
            <StyledTextField
              placeholder="Enter ID Number"
              fullWidth
              name="studentPwdIdNumber"
              value={state.firstStepData.studentPwdIdNumber}
              onChange={handler.handleChangestudentPwdIdNumber}
              data-test-id='pwdNumberID'
            />
            {firstStepData.studentErrors.studentPwdIdNumber &&
              <span style={{ color: 'red', fontSize: "12px", fontFamily: "Montserrat" }}>
                {firstStepData.studentErrors.studentPwdIdNumber}
              </span>}
          </Grid>
          : null

        }
      </Grid>

        <Grid container spacing={4} style={{ paddingTop: '1.5em', paddingLeft: '3rem', paddingRight: '3rem' }}>
          <Grid item xs={12} md={6} >
            <InputLabel style={webStyle.studentReferralProgramForm1}>Applying for a Solo parent discount?</InputLabel>
            <ToggleSwitch
              name="studentParentDiscount"
              checked={state.firstStepData.studentParentDiscount}
              onChange={handler.handleParentDiscount}
              inputProps={{ 'aria-label': 'controlled' }}
              data-test-id='parentDiscountId'
            />
          </Grid>
          {state.firstStepData.studentParentDiscount ?
            <Grid item xs={12} md={6} style={webStyle.gridView}>
              <InputLabel style={webStyle.inputLabelStyle}>Solo Parent ID Number *</InputLabel>
              <StyledTextField
                placeholder="Enter Number"
                fullWidth
                name="studentSoloParentId"
                value={state.firstStepData.studentSoloParentId}
                onChange={handler.handleChangestudentSoloParentId}
                data-test-id='soloParentId'
              />
              {firstStepData.studentErrors.studentSoloParentId &&
                <span style={{ color: 'red', fontSize: "12px", fontFamily: "Montserrat" }}>
                  {firstStepData.studentErrors.studentSoloParentId}
                </span>}
            </Grid>
            : null
          }
        </Grid>
        <Grid item xs={12} sm={12} style={{ paddingTop: '1.5em', paddingLeft: '3rem', paddingRight: '3rem' }}>
          <InputLabel style={webStyle.inputLabelStyle}>Residential Address *</InputLabel>
          <TextareaAutosize
            placeholder="Unit / Bldg No. , Bldg Name / Lot No. , Street Name"
            aria-label="minimum height" minRows={3}
            style={webStyle.inputAdressForm1}
            name="studentResidentialAddress"
            value={state.firstStepData.studentResidentialAddress}
            onChange={handler.residentialAddressChange}
            // onFocus={handler.hideError}
            data-test-id='residentialAddressentId'
          ></TextareaAutosize>

          {firstStepData.studentErrors.studentResidentialAddress &&
            <span style={{ color: 'red', fontSize: "12px", fontFamily: "Montserrat" }}>
              {firstStepData.studentErrors.studentResidentialAddress}
            </span>}
        </Grid>
      </>
    )
  }
  
  const steotonComlex = () => {

    const steotonComplexs = () => {
      
      return (
        <>
          <Grid item xs={12} md={6}>
            <InputLabel style={webStyle.studentReferralProgramForm1}>Do you have an Advocate code (Referral Program)?</InputLabel>
            <ToggleSwitch
              name="studentReferralProgram"
              checked={state.firstStepData.studentReferralProgram}
              onChange={handler.handleAdvocatecode}
              inputProps={{ 'aria-label': 'controlled' }}
              data-test-id="refferalProgram"

            />
          </Grid>
        </>
      )
    }
    return (
      <>
        <Grid container spacing={4} style={{ paddingTop: '1.5em', paddingLeft: '3rem', paddingRight: '3rem' }}>
          {steotonComplexs()}
          {state.firstStepData.studentReferralProgram &&
            <Grid item xs={12} md={6} style={webStyle.gridView}>
              <InputLabel style={webStyle.inputLabelStyle}>Advocate code (Referral Program)*</InputLabel>
              <StyledTextField
                placeholder="Enter Code"
                fullWidth
                name="studentAdvocatecode"
                data-test-id='advocatecode'
                value={state.firstStepData.studentAdvocatecode}
                onChange={handler.handleChangestudentAdvocatecode}
              // onFocus={handler.hideError}
              />
              {firstStepData.studentErrors.studentAdvocatecode &&
                <span style={{ color: 'red', fontSize: "12px", fontFamily: "Montserrat" }}>
                  {firstStepData.studentErrors.studentAdvocatecode}
                </span>}
            </Grid>

          }
        </Grid>
      </>
    )
  }
  return (
    <>
      <Typography style={webStyle.headingStyles}>Student Profile </Typography>
      {firstLastNameError()}
      <Grid container spacing={4} style={{ paddingLeft: '3rem', paddingRight: '3rem' }}>
        {stepcompOne()}
        <Grid item xs={12} md={6}>
          <InputLabel style={webStyle.inputLabelStyle}>Country of Birth *</InputLabel>
          <Autocomplete
            id="Country_of_birth"
            data-test-id='countryOfBirth'
            onChange={handler.changeHandlerAutoGetCountriesBirthCallBack}
            value={state.getAllCountries.find(
              (item: { name: string }) => item.name === state.firstStepData.studentCountryAutoCompleteValue?.value
            ) || null}
            options={state.getAllCountries}
            getOptionLabel={handler.handleGetOptionLabel}
            getOptionSelected={handler.handleGetOptionSelected}
            renderInput={handler.handleRenderInput1}

            inputValue={state.firstStepData.studentCountry?.value}
            onInputChange={handler.changeHandlerGetCountriesCallBack}
          />
          {firstStepData.studentErrors.studentCountry &&
            <span style={{ color: 'red', fontSize: "12px", fontFamily: "Montserrat" }}>
              {firstStepData.studentErrors.studentCountry}
            </span>}
        </Grid>

        <Grid item xs={12} md={6}>
          <InputLabel style={webStyle.inputLabelStyle}>City of Birth *
          </InputLabel>
          <Autocomplete
            id="city_of_birth"
            value={state.getAllCities.find(
              (item: { name: string }) => item.name === state.firstStepData.studentBirthAutoCompleteValue?.value
            ) || null}
            onChange={handler.changeHandlerAutoGetCitiesBirthCallBack}
            options={state.getAllCities}
            getOptionLabel={handler.handleGetOptionLabel}
            data-test-id='cityOfBirth'
            getOptionSelected={handler.handleGetOptionSelected}
            renderInput={handler.handleRenderInput2}

            inputValue={state.firstStepData.studentBirthCity?.value}
            onInputChange={handler.handleCityBirthCallBack}
          />
          {firstStepData.studentErrors.studentBirthCity &&
            <span style={{ color: 'red', fontSize: "12px", fontFamily: "Montserrat" }}>
              {firstStepData.studentErrors.studentBirthCity}
            </span>}

        </Grid>

        <Grid item xs={12} md={6}>
          <InputLabel style={webStyle.inputLabelStyle}>Age</InputLabel>
          <StyledTextField
            placeholder="Age"
            color="darkgrey"
            fullWidth
            name="studentAge"
            disabled
            InputLabelProps={{ shrink: false }}
            value={state.firstStepData.studentAge}
            onChange={handler.datechange}
          >

          </StyledTextField>

          {firstStepData.studentErrors.studentAge &&
            <span style={{ color: 'red', fontSize: "12px", fontFamily: "Montserrat" }}>
              {firstStepData.studentErrors.studentAge}
            </span>}

        </Grid>
        <Grid item xs={12} md={6}>
          <InputLabel style={webStyle.inputLabelStyle}>Religion *</InputLabel>
          <StyledTextField
            id="studentReligion"
            select
            fullWidth
            name="studentReligion"
            data-test-id='religion'
            InputLabelProps={{ shrink: false }}
            value={state.firstStepData.studentReligion?.value}
            
            onChange={handler.religionDataChange}
          >
            <MenuItem style={webStyle.StudentMenuItemsStyle} disabled value="choose"> <span style={{ color: "#ABABB0" }}> Please Select </span></MenuItem>
            {
              state.religionData?.map((item: any, index: number) => (
                <MenuItem
                  key={item?.id}
                  value={item?.attributes.religion}
                  selected={item.value == state.firstStepData.studentReligion?.value}
                >
                  {item?.attributes.religion}
                </MenuItem>
              ))
            }
          </StyledTextField>
          {firstStepData.studentErrors.studentReligion &&
            <span style={{ color: 'red', fontSize: "12px", fontFamily: "Montserrat" }}>
              {firstStepData.studentErrors.studentReligion}
            </span>}


        </Grid>
        <GetLabelTextField
          firstStepDataValue={state.firstStepData.studentReligion?.value}
          compareValue="Otherasneed"
          textPlaceholder="Religion"
          textName='studentReligionOthers'
          data-test-id='religionOthers'
          textValue={state.firstStepData.studentReligionOthers}
          textOnChange={handler.handleChangeText}
          textOnFocus={handler.hideError}

        />
        {stepmiddleFunc()}
      </Grid>

      <Grid container style={{ paddingLeft: '3rem', paddingRight: '3rem', }} spacing={4}  >
        <Grid item xs={12} md={6}>

          <InputLabel style={webStyle.WouldYouLikeToApplyForaScholarship}>Would you like to apply for a Scholarship? </InputLabel>
          <ToggleSwitch
            name="studentWouldYouLikeToApplyForAScholarShip"
            checked={state.firstStepData.studentWouldYouLikeToApplyForAScholarShip}
            onChange={handler.handleCheckedStudentScholarShip}
            inputProps={{ 'aria-label': 'controlled' }}
            data-test-id='scholarshipCheck'
          />
        </Grid>

        {state.firstStepData.studentWouldYouLikeToApplyForAScholarShip &&
          <>
            <Grid item xs={12} md={6} style={{marginTop: '-5px'}} >
              <InputLabel style={webStyle.inputLabelStyle}>Scholarship Type*</InputLabel>
              <StyledTextField
                select
                color="darkgrey"
                fullWidth
                name="studentScholarShipType"
                InputLabelProps={{ shrink: false }}
                value={state.firstStepData.studentScholarShipType.value}
                onChange={handler.handleChangeScholarshipType}
                data-test-id='scholarShopType'
                SelectProps={{
                  native: false,
                  MenuProps: {
                      disableAutoFocusItem: false,
                  },
                  inputProps: {
                      'aria-label': 'scholarship-type',
                  }
              }}
              >
                <MenuItem style={webStyle.StudentMenuItems} disabled value="choose"> <span style={{ color: "darkgrey" }}> Please Select </span></MenuItem>
                <MenuItem value="Individual">Individual</MenuItem>
                <MenuItem value="Family">Family</MenuItem>
              </StyledTextField>

              {firstStepData.studentErrors.studentScholarShipType &&
                <span style={{ color: 'red', fontSize: "12px", fontFamily: "Montserrat" }}>
                  {firstStepData.studentErrors.studentScholarShipType}
                </span>}
            </Grid>
            <Grid item container spacing={4}>
              <Grid item xs={12} md={6} >

                <InputLabel
                  style={webStyle.inputLabelStyle}>Types of Scholarship *</InputLabel>
                <StyledTextField
                  color="darkgrey"

                  select
                  fullWidth
                  name="studentTypesOfScholarShip"
                  data-test-id='typesOfScholar'
                  helperText={<span style={webStyle.scholarshipChangeForm1}>Please update the required documents in the file upload section to be followed</span>}
                  value={state.firstStepData.studentTypesOfScholarShip?.value}
                  InputLabelProps={{ shrink: false }}
                  onChange={(e: any) => handler.scholarshipChange(e)}
                 
                >
                  <MenuItem style={webStyle.StudentMenuItems} disabled
                    value="choose"> <span style={{ color: "darkgrey" }}> Please Select </span></MenuItem>
                  {
                    state.scholarshipSelectedData?.map((item: any) => {
                      return (
                        <MenuItem
                          key={item?.id}
                          value={item?.scholarship_name}
                          selected={item.value == state.firstStepData.studentTypesOfScholarShip?.value}
                        >
                          {item?.scholarship_name}
                        </MenuItem>
                      )
                    })
                  }
                </StyledTextField>
                  <GetErrorStyle stateType={state.firstStepData.studentErrors.studentTypesOfScholarShip} />
              </Grid>
              {getOrConditionValue(state.firstStepData.studentTypesOfScholarShip === "", state.firstStepData.studentTypesOfScholarShip?.value === "HG Staff Student Scholarship")
                &&
                <Grid item xs={12} md={6}>

                  <InputLabel style={webStyle.inputLabelStyle}>Proof of relationship *</InputLabel>
                  <StyledTextField
                    select
                    color="darkgrey"
                    fullWidth
                    name="studentProofOfRelationship"
                    data-test-id='proofOfRelation'
                    InputLabelProps={{ shrink: false }}
                    value={state.firstStepData.studentProofOfRelationship.value}
                    onChange={handler.handleProofOfRelationship}
                  >
                    <MenuItem style={webStyle.StudentMenuItems} disabled value="choose"> <span style={{ color: "darkgrey" }}> Please Select </span></MenuItem>
                    <MenuItem value="Child">Child</MenuItem>
                    <MenuItem value="Sister">Sister</MenuItem>
                    <MenuItem value="Brother">Brother</MenuItem>
                    <MenuItem value="Nephew">Nephew</MenuItem>
                    <MenuItem value="Niece">Niece</MenuItem>
                  </StyledTextField>
                  <GetErrorStyle stateType={state.firstStepData.studentErrors.studentProofOfRelationship} />
                </Grid>
              }

              {getOrConditionValue(state.firstStepData.studentTypesOfScholarShip === "", state.firstStepData.studentTypesOfScholarShip?.value === "The Jovi Villote Memorial Scholarship")
                &&
                <>
                  <Grid item xs={12} md={6} >
                    <InputLabel style={webStyle.inputLabelStyle}>Date of father's death *</InputLabel>
                    <TextField
                      id="date"
                      type="date"
                      fullWidth
                      defaultValue={<span>yy-mm-dd</span>}
                      InputProps={{ inputProps: { max: handler.dateFunction() },
                      style: { fontFamily: "Montserrat" }
                    }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      data-test-id='fatherDeath'
                      name="studentfatherDeath"
                      value={state.firstStepData.studentfatherDeath}
                      onChange={handler.handleClearFatherDeath}
                    />
                   {firstStepData.studentErrors?.studentfatherDeath &&
                  <span style={{ color: 'red', fontSize: "12px", fontFamily: "Montserrat" }}>
                    {firstStepData.studentErrors?.studentfatherDeath}
                  </span>}
                  </Grid>

                  <Grid item xs={12} md={6} >
                    <InputLabel style={webStyle.inputLabelStyle}>Cause of the death *</InputLabel>
                    <StyledTextField
                      select
                      color="darkgrey"
                      fullWidth
                      name="studentCauseOfDeath"
                      InputLabelProps={{ shrink: false }}
                      data-test-id='causeOfDeath'
                      value={state.firstStepData.studentCauseOfDeath}
                      onChange={handler.handleStudentCauseOfDeath}
                    >
                      <MenuItem style={webStyle.StudentMenuItems} disabled value="choose"> <span style={{ color: "darkgrey" }}> Enter Cause </span></MenuItem>
                      <MenuItem value="Illness">Illness</MenuItem>
                      <MenuItem value="Accident">Accident</MenuItem>
                      <MenuItem value="Others">Others</MenuItem>
                    </StyledTextField>
                    {firstStepData.studentErrors?.studentCauseOfDeath &&
                  <span style={{ color: 'red', fontSize: "12px", fontFamily: "Montserrat" }}>
                    {firstStepData.studentErrors?.studentCauseOfDeath}
                  </span>}
                  </Grid>
                </>
              }

              {state.firstStepData.studentTypesOfScholarShip?.value == "Scholarship for HG Servant Leaders" ?
                <Grid item xs={12} md={6} >
                  <InputLabel style={{...webStyle.inputLabelStyle}}> Specification *</InputLabel>
                  <StyledTextField
                    select
                    color="darkgrey"
                    data-test-id='specification'
                    fullWidth
                    name="studentSpecification"
                    InputLabelProps={{ shrink: false }}
                    value={state.firstStepData.studentSpecification.value}
                    onChange={handler.handlestudentSpecification}
                  >
                    <MenuItem style={webStyle.StudentMenuItems} disabled value="choose"> <span style={{ color: "darkgrey" }}> Please Select </span></MenuItem>
                    <MenuItem value="HighUniteRevolutionariesCoreTeam">High Unite Revolutionaries Core Team</MenuItem>
                    <MenuItem value="HGndFAfamilies">HG and FA families</MenuItem>
                    <MenuItem value="HGStaffs"> HG Staffs</MenuItem>

                  </StyledTextField>
                  {firstStepData.studentErrors?.studentSpecification &&
                    <span style={{ color: 'red', fontSize: "12px", fontFamily: "Montserrat" }}>
                      {firstStepData.studentErrors?.studentSpecification}
                    </span>}
                </Grid>
                : null
              }
            </Grid>
          </>
        }
      </Grid>

      {steotonComlex()}

      {stepBottomComp()}

    </>
  )
}

//istanbul ignore next
const StepTwo = (props: any) => {
  const { state, handler } = props;
  const { secondStepData } = state;
  return (
    <>
      <Typography style={webStyle.headingStyles}>Previous School Information</Typography>
      <Grid container spacing={4} style={{ paddingLeft: '3rem', paddingRight: '3rem' }}>
        <Grid item xs={12} md={6}>
          <InputLabel style={webStyle.inputLabelStyle}>Last Grade Level *</InputLabel>
          <StyledTextField
            id="grade-level"
            select
            fullWidth
            name="prevGradeLevel"
            data-test-id='grade-level'
            InputLabelProps={{ shrink: false }}
            value={state.secondStepData.prevGradeLevel?.value}
            onChange={handler.handleGradeLevel}
            disabled = {state.disableField}
          >
            <MenuItem style={webStyle.StudentMenuItemsStyle} disabled value="choose"> <span style={{ color: "#ABABB0" }}> Please Select </span></MenuItem>
            {
              state.gradeData?.map((item: any, index: number) => (
                <MenuItem
                  key={item?.id}
                  value={item?.attributes.grade}
                  selected={item.value == state.secondStepData.prevGradeLevel?.value}
                >
                  {item?.attributes.grade}
                </MenuItem>
              ))
            }
          </StyledTextField>
          {secondStepData.studentErrors.prevGradeLevel &&
            <span style={{ color: 'red', fontSize: "12px", fontFamily: "Montserrat" }}>
              {secondStepData.studentErrors.prevGradeLevel}
            </span>}

        </Grid>
        <Grid item xs={12} md={6}>
          <InputLabel style={webStyle.inputLabelStyle}> Previous School Attended (if not applicable, please input "N/A") * </InputLabel>
          <StyledTextField
            placeholder="Please Input"
            fullWidth
            name='prevSchoolAttended'
            data-test-id="prevSchoolAttended"
            value={state.secondStepData.prevSchoolAttended}
            onChange={handler.handleChangeprevSchoolAttended}
          />
          {secondStepData.studentErrors.prevSchoolAttended &&
            <span style={{ color: 'red', fontSize: "12px", fontFamily: "Montserrat" }}>
              {secondStepData.studentErrors.prevSchoolAttended}
            </span>}
        </Grid>

        <Grid item xs={12} md={6}>
          <InputLabel style={webStyle.inputLabelStyle}>Previous School Curriculum *</InputLabel>
          <StyledTextField
            id="school-curriculum"
            select
            fullWidth
            name="prevSchoolCurriculum"
            data-test-id='curriculum-id'
            InputLabelProps={{ shrink: false }}
            value={state.secondStepData.prevSchoolCurriculum?.value}
            onChange={handler.handleCurriculumData}
            disabled = {state.disableField}
          >
            <MenuItem style={webStyle.StudentMenuItemsStyle} disabled value="choose"> <span style={{ color: "#ABABB0" }}> Please Select </span></MenuItem>
            {
              state.curriculumData?.map((item: any, index: number) => (
                <MenuItem
                  key={item?.id}
                  value={item?.attributes.curriculum_name}
                  selected={item.value == state.secondStepData.prevSchoolCurriculum?.value}
                >
                  {item?.attributes.curriculum_name}
                </MenuItem>
              ))
            }
          </StyledTextField>
                      {state.secondStepData.prevSchoolAttended === "N/A" ? (
              ""
            ) : (
              secondStepData.studentErrors.prevSchoolCurriculum && (
                <span style={{ color: 'red', fontSize: "12px", fontFamily: "Montserrat" }}>
                  {secondStepData.studentErrors.prevSchoolCurriculum}
                </span>
              )
            )}


        </Grid>
        <Grid item xs={12} md={6}>
          <InputLabel style={webStyle.inputLabelStyle}>Previous School Email Address *</InputLabel>
          <StyledTextField
            placeholder="Email Address"
            fullWidth
            type="email"
            data-test-id="prev-school-email"
            name='prevSchoolEmail'
            value={state.secondStepData.prevSchoolEmail}
            onChange={handler.handleChangeprevSchoolEmail}
            disabled = {state.disableField}
          />
          {state.secondStepData.prevSchoolAttended === "N/A" ? (
            ""
          ) : (
            secondStepData.studentErrors.prevSchoolEmail &&
            <span style={{ color: 'red', fontSize: "12px", fontFamily: "Montserrat" }}>
              {secondStepData.studentErrors.prevSchoolEmail}
            </span>
            
          )}
          
        </Grid>

        <Grid item xs={12} md={6}>
          <InputLabel style={webStyle.inputLabelStyle}>Previous School Contact Number *</InputLabel>
          <StyledTextField
            placeholder="0123456789"
            fullWidth
            data-test-id="prev-school-number"
            name='prevSchoolNumber'
            value={state.secondStepData.prevSchoolNumber}
            onChange={handler.handleChangeprevSchoolNumber}
            disabled = {state.disableField}
          />
           {state.secondStepData.prevSchoolAttended === "N/A" ? (
            ""
          ) : (
            secondStepData.studentErrors.prevSchoolNumber &&
            <span style={{ color: 'red', fontSize: "12px", fontFamily: "Montserrat" }}>
              {secondStepData.studentErrors.prevSchoolNumber}
            </span>
            
          )}
          
        </Grid>

        <Grid item xs={12} md={6}>
          <InputLabel style={webStyle.inputLabelStyle}>Previous School Address *</InputLabel>
          <StyledTextField
            placeholder="Address"
            fullWidth
            data-test-id="prev-school-address"
            name='prevSchoolAddress'
            value={state.secondStepData.prevSchoolAddress}
            onChange={handler.handleChangeprevSchoolAddress}
            disabled = {state.disableField}
          />
          {state.secondStepData.prevSchoolAttended === "N/A" ? (
            ""
          ) : (
            secondStepData.studentErrors.prevSchoolAddress &&
            <span style={{ color: 'red', fontSize: "12px", fontFamily: "Montserrat" }}>
              {secondStepData.studentErrors.prevSchoolAddress}
            </span>
            
          )}
         
        </Grid>

        <Grid item xs={12} md={6}>
          <InputLabel style={webStyle.inputLabelStyle}>Last School Year *</InputLabel>
          <StyledTextField
            placeholder="YYYY"
            fullWidth
            data-test-id="prev-school-year"
            name='prevSchoolYear'
            value={state.secondStepData.prevSchoolYear}
            onChange={handler.handleChangeprevSchoolYear}
            // onFocus={handler.hideError}
            disabled = {state.disableField}
          />
          {state.secondStepData.prevSchoolAttended === "N/A" ? (
            ""
          ) : (
            secondStepData.studentErrors.prevSchoolYear &&
            <span style={{ color: 'red', fontSize: "12px", fontFamily: "Montserrat" }}>
              {secondStepData.studentErrors.prevSchoolYear}
            </span>
            
          )}
          
        </Grid>

        <Grid item xs={12} md={6}>
          <InputLabel style={webStyle.inputLabelStyle}>Learner Reference Number (if not applicable, please input "0") *</InputLabel>
          <StyledTextField
            placeholder="Enter LRN"
            fullWidth
            data-test-id="learner-ref-number"
            name='learnerRefNumber'
            value={state.secondStepData.learnerRefNumber}
            onChange={handler.handleChangelearnerRefNumber}
            //onFocus={handler.hideError}
            inputProps={{ style: { textTransform: "capitalize" } }}
            disabled = {state.disableField}
          />
          {state.secondStepData.prevSchoolAttended === "N/A" ? (
            ""
          ) : (
            secondStepData.studentErrors.learnerRefNumber &&
            <span style={{ color: 'red', fontSize: "12px", fontFamily: "Montserrat" }}>
              {secondStepData.studentErrors.learnerRefNumber}
            </span>
            
          )}
          
        </Grid>
      </Grid>
    </>
  )
}
//istanbul ignore next
const StepThree = (props: any) => {
  const { state, handler } = props;
  const { thirdStepData } = state;
  const stepThrefunctComp = () => {
    return (
      <>
        <Grid item xs={12} md={6}>
          <InputLabel style={webStyle.inputLabelStyle}>Community *</InputLabel>
          <StyledTextField
            id="community"
            select
            fullWidth
            name="community"
            data-test-id='community-id'
            InputLabelProps={{ shrink: false }}
            value={state.thirdStepData.community?.value}
            onChange={handler.communityDataChange}
          >
            <MenuItem style={webStyle.StudentMenuItemsStyle } disabled value="choose"> <span style={{ color: "#ABABB0" }}> Please Select </span></MenuItem>
            {
              state.communityData?.map((item: any, index: number) => (
                <MenuItem
                  key={item?.id}
                  value={item?.attributes.community_name}
                  selected={item.value == state.thirdStepData.program?.value}
                >
                  {item?.attributes.community_name}
                </MenuItem>
              ))
            }
          </StyledTextField>
          {thirdStepData.programErrors.community &&
            <span style={{ color: 'red', fontSize: "12px", fontFamily: "Montserrat" }}>
              {thirdStepData.programErrors.community}
            </span>}

        </Grid>

        <Grid item xs={12} md={6}>
          <InputLabel style={webStyle.inputLabelStyle}>Hub *</InputLabel>
          <StyledTextField
            id="hub"
            select
            fullWidth
            name="hub"
            data-test-id='hub-id'
            InputLabelProps={{ shrink: false }}
            value={state.thirdStepData.hub?.value}
            onChange={handler.hubDataChange}
          >
            <MenuItem style={webStyle.StudentMenuItemsStyle} disabled value="choose"> <span style={{ color: "#ABABB0" }}> Please Select </span></MenuItem>
            {
              state.programIdData.length >0 &&
              state.programIdData?.map((item: any, index: number) => (

                <MenuItem
                  key={item?.id}
                  value={item}
                  selected={item.value == state.thirdStepData.hub?.value}
                >
                  {item}
                </MenuItem>
              ))
            }
          </StyledTextField>
          {thirdStepData.programErrors.hub &&
            <span style={{ color: 'red', fontSize: "12px", fontFamily: "Montserrat" }}>
              {thirdStepData.programErrors.hub}
            </span>}


        </Grid>

        <Grid item xs={12} md={6}>

          <InputLabel style={webStyle.inputLabelStyle}>Cycle *</InputLabel>
          <StyledTextField
            select
            fullWidth
            name="cycle"
            data-test-id='cycle-id'
            InputLabelProps={{ shrink: false }}
            value={state.thirdStepData.cycle?.value}
            onChange={handler.handleCycleChange}

          >
                        <MenuItem style={webStyle.StudentMenuItemsStyle} disabled value="choose"> <span style={{ color: "#ABABB0" }}> Please Select </span></MenuItem>

            {
              state.cycleDataProgram.length >0 &&
              state.cycleDataProgram?.map((item: any, index: number) => (

                <MenuItem
                  key={item?.id}
                  value={item}
                  selected={item.value == state.thirdStepData.hub?.value}
                >
                  {item}
                </MenuItem>
              ))
            }

          </StyledTextField>
          {thirdStepData.programErrors.cycle &&
            <span style={{ color: 'red', fontSize: "12px", fontFamily: "Montserrat" }}>
              {thirdStepData.programErrors.cycle}
            </span>}
        </Grid>
      </>
    )
  }
  const stepthreefuncBottom = () => {
    return (
      <>
        <Grid item xs={12} md={6}>
          <InputLabel style={webStyle.inputLabelStyle}>Incoming Grade Level *</InputLabel>
          <StyledTextField
            id="incoming-grade-level"
            select
            fullWidth
            name="incomingGradeLevel"
            data-test-id='incoming-grade-level-id'
            InputLabelProps={{ shrink: false }}
            value={state.thirdStepData.incomingGradeLevel?.value}
            onChange={handler.handleGradeLevel}
          >
            <MenuItem style={webStyle.StudentMenuItemsStyle} disabled value="choose"> <span style={{ color: "#ABABB0" }}> Please Select </span></MenuItem>
            {state.gradeDataProgram.length> 0 &&
              state.gradeDataProgram?.map((item: any, index: number) => (
                <MenuItem
                  key={item?.id}
                  value={item}
                  selected={item.value == state.thirdStepData.incomingGradeLevel?.value}
                >
                  {item}
                </MenuItem>
              ))
            }
          </StyledTextField>
          {thirdStepData.programErrors.incomingGradeLevel &&
            <span style={{ color: 'red', fontSize: "12px", fontFamily: "Montserrat" }}>
              {thirdStepData.programErrors.incomingGradeLevel}
            </span>}

        </Grid>
        {(state.thirdStepData.accreditation.value == 'PH Accreditation' && (state.thirdStepData.incomingGradeLevel.value == "Grade 11" || state.thirdStepData.incomingGradeLevel.value == "Grade 12")) &&
          <Grid item xs={12} md={6}>
            <InputLabel style={webStyle.inputLabelStyle}>SHS Track *</InputLabel>
            <StyledTextField
              id="shs-track"
              select
              fullWidth
              name="shsTrack"
              data-test-id='shs-track-id'
              InputLabelProps={{ shrink: false }}
              value={state.thirdStepData.shsTrack?.value}
              onChange={handler.shsTrackChange}
            >
              <MenuItem style={webStyle.StudentMenuItemsStyle} disabled  value="choose"> <span style={{ color: "#ABABB0" }}> Please Select </span></MenuItem>
              <MenuItem value="GAS">GAS</MenuItem>
              <MenuItem value="ABM">ABM</MenuItem>
              <MenuItem value="Arts and Designs">Arts and Designs</MenuItem>
            </StyledTextField>
            {thirdStepData.programErrors.shsTrack &&
              <span style={{ color: 'red', fontSize: "12px", fontFamily: "Montserrat" }}>
                {thirdStepData.programErrors.shsTrack}
              </span>}
          </Grid>
        }
      </>
    )
  }
  return (
    <>
      <Typography style={webStyle.headingStyles}>Program Details</Typography>
      <Grid container spacing={4} style={{ paddingLeft: '3rem', paddingRight: '3rem' }}>
        <Grid item xs={12} md={6}>
          <InputLabel style={webStyle.inputLabelStyle}>Program *</InputLabel>
          <StyledTextField
            id="program"
            select
            fullWidth
            name="program"
            data-test-id='program-id'
            InputLabelProps={{ shrink: false }}
            value={state.thirdStepData.program?.value}
            onChange={handler.programDataChange}
          >
            <MenuItem style={webStyle.StudentMenuItemsStyle} disabled value="choose"> <span style={{ color: "#ABABB0" }}> Please Select </span></MenuItem>
            {
              state.programData?.map((item: any, index: number) => (
                <MenuItem
                  key={item.id}
                  value={item.attributes.program_name}
                  selected={item.value == state.thirdStepData.program?.value}
                >
                  {item.attributes.program_name}
                </MenuItem>
              ))
            }
          </StyledTextField>
          {thirdStepData.programErrors.program &&
            <span style={{ color: 'red', fontSize: "12px", fontFamily: "Montserrat" }}>
              {thirdStepData.programErrors.program}
            </span>}
        </Grid>
        {stepThrefunctComp()}

        <Grid item xs={12} md={6}>
          <InputLabel style={webStyle.inputLabelStyle}>Accreditation *</InputLabel>
          <StyledTextField
            id="accreditation"
            select
            fullWidth
            name="accreditation"
            data-test-id='accreditation-id'
            InputLabelProps={{ shrink: false }}
            value={state.thirdStepData.accreditation?.value}
            onChange={handler.accreditationDataChange}
          >
            <MenuItem style={webStyle.StudentMenuItemsStyle} disabled value="choose"> <span style={{ color: "#ABABB0" }}> Please Select </span></MenuItem>
            {
              state.accreditationsDataProgram?.map((item: any, index: number) => (
                <MenuItem
                  key={item?.id}
                  value={item}
                  selected={item.value == state.thirdStepData.accreditation?.value}
                >
                  {item}
                </MenuItem>
              ))
            }
          </StyledTextField>
          {thirdStepData.programErrors.accreditation &&
            <span style={{ color: 'red', fontSize: "12px", fontFamily: "Montserrat" }}>
              {thirdStepData.programErrors.accreditation}
            </span>}

        </Grid>

        {stepthreefuncBottom()}
      </Grid>
    </>
  )

}
//istanbul ignore next
const StepFour = (props: any) => {
  const { state, handler } = props;
  const { fourthStepData } = state;
  const stepfourfunc = () => {
    return (
      <>

        <Grid item xs={12} md={6}>
          <InputLabel style={webStyle.inputLabelStyle}>Father's Email Address(if not applicable, please input "N/A") * </InputLabel>
          <StyledTextField
            placeholder="Email Address"
            fullWidth
            name='fatherEmail'
            value={state.fourthStepData.fatherEmail}
            onChange={handler.handleChangefatherEmail}
            data-test-id="fatherEmail"
          />
          {fourthStepData.parentsErrors.fatherEmail &&
            <span style={{ color: 'red', fontSize: "12px", fontFamily: "Montserrat" }}>
              {fourthStepData.parentsErrors.fatherEmail}
            </span>}
        </Grid>

        <Grid item xs={12} md={6}>
          <InputLabel style={webStyle.inputLabelStyle}>Mother's First Name *</InputLabel>
          <StyledTextField
            placeholder="First Name"
            fullWidth
            name='motherFirstName'
            value={state.fourthStepData.motherFirstName}
            onChange={handler.handleChangemotherFirstName}
            data-test-id="motherFirstName"
          />
          {fourthStepData.parentsErrors.motherFirstName &&
            <span style={{ color: 'red', fontSize: "12px", fontFamily: "Montserrat" }}>
              {fourthStepData.parentsErrors.motherFirstName}
            </span>}
        </Grid>

        <Grid item xs={12} md={6}>
          <InputLabel style={webStyle.inputLabelStyle}>Mother's Middle Name (Maiden)*</InputLabel>
          <StyledTextField
            placeholder="Middle Name"
            fullWidth
            name='motherMiddleName'
            value={state.fourthStepData.motherMiddleName}
            onChange={handler.handleChangemotherMiddleName}
            data-test-id="motherMiddleName"

          />
          {fourthStepData.parentsErrors.motherMiddleName &&
            <span style={{ color: 'red', fontSize: "12px", fontFamily: "Montserrat" }}>
              {fourthStepData.parentsErrors.motherMiddleName}
            </span>}

        </Grid>

        <Grid item xs={12} md={6}>
          <InputLabel style={webStyle.inputLabelStyle}>Mother's Last Name *</InputLabel>
          <StyledTextField
            placeholder="Last Name"
            fullWidth
            name='motherLastName'
            value={state.fourthStepData.motherLastName}
            onChange={handler.handleChangemotherLastName}
            data-test-id="motherLastName"
          />
          {fourthStepData.parentsErrors.motherLastName &&
            <span style={{ color: 'red', fontSize: "12px", fontFamily: "Montserrat" }}>
              {fourthStepData.parentsErrors.motherLastName}
            </span>}
        </Grid>

      </>
    )
  }
  const stepfourstepTwo = () => {
    return (
      <>
        <Grid item xs={12} md={6}>
          <InputLabel style={{...webStyle.inputLabelStyle}}>Guardian's Name (if not applicable, please input "N/A") * </InputLabel>
          <StyledTextField
            placeholder="N/A"
            fullWidth
            name='guardianName'
            value={state.fourthStepData.guardianName}
            onChange={handler.handleChangeguardianName}
            data-test-id="guardian_name"
          />
          {fourthStepData.parentsErrors.guardianName &&
            <span style={{ color: 'red', fontSize: "12px", fontFamily: "Montserrat" }}>
              {fourthStepData.parentsErrors.guardianName}
            </span>}
        </Grid>

        <Grid item xs={12} md={6}>
          <InputLabel style={webStyle.inputLabelStyle}>Guardian's Address (if not applicable, please input "N/A") * </InputLabel>
          <StyledTextField
            placeholder="N/A"
            fullWidth
            name='guardianAddress'
            value={state.fourthStepData.guardianAddress}
            onChange={handler.handleChangeguardianAddress}
            data-test-id="guardian_address"
          />
          {fourthStepData.parentsErrors.guardianAddress &&
            <span style={{ color: 'red', fontSize: "12px", fontFamily: "Montserrat" }}>
              {fourthStepData.parentsErrors.guardianAddress}
            </span>}
        </Grid>

        <Grid item xs={12} md={6}>
          <InputLabel style={webStyle.inputLabelStyle}>Guardian's Occupation (if not applicable, please input "N/A") * </InputLabel>
          <StyledTextField
            placeholder="N/A"
            fullWidth
            name='guardianOccupation'
            value={state.fourthStepData.guardianOccupation}
            onChange={handler.handleChangeguardianOccupation}
            data-test-id="guardian_occupation"
          />
          {fourthStepData.parentsErrors.guardianOccupation &&
            <span style={{ color: 'red', fontSize: "12px", fontFamily: "Montserrat" }}>
              {fourthStepData.parentsErrors.guardianOccupation}
            </span>}
        </Grid>

        <Grid item xs={12} md={6}>
          <InputLabel style={webStyle.inputLabelStyle}>Guardian's Mobile Number (if not applicable, please input "0") * </InputLabel>
          <Box style={{ display: 'flex', alignItems: 'end' }}>
            <Autocomplete
              id="guardianMobileNumberCode"
              data-test-id="guardianMobileNumberCode"
              onChange={handler.onGurdianMobileNumber}
              style={{ width: '190px' }}
              options={state.fourthStepData.countryPhoneCodeListOne}
              disableClearable
              getOptionSelected={(option, value) => option.value === value.value}
              getOptionLabel={(option) => `+ ${option.value}`}
              renderOption={(option) => (`+ ${option.value} - ${option.sortName}`)}
              renderInput={(params) =>
                <StyledTextField {...params} placeholder='Select Country' fullWidth={true}
                  testidentity="PleaseSelect"
                  name='country' InputLabelProps={{ shrink: false }}
                />}
              value={state.fourthStepData.countryPhoneCodeListOne.find((item: IDItem) => item.value == state.fourthStepData.guardianMobileNumberCode) || null}
            />
            <StyledTextField
              placeholder="123456789"
              fullWidth
              name='guardianMobileNumber'
              value={state.fourthStepData.guardianMobileNumber}
              onChange={handler.handlePhoneNumberss}
              style={{ marginLeft: '20px' }}
              data-test-id="guardian_mobile"
            />
          </Box>
          {fourthStepData.parentsErrors.guardianMobileNumber &&
            <span style={{ color: 'red', fontSize: "12px", fontFamily: "Montserrat"}}>
              {fourthStepData.parentsErrors.guardianMobileNumber}
            </span>}
        </Grid>
      </>
    )
  }
  const stepfouThree = () => {
    return (
      <>
        <Grid item xs={12} md={6}>
          <InputLabel style={webStyle.inputLabelStyle}>Father's First Name *</InputLabel>
          <StyledTextField
            placeholder="First Name"
            fullWidth
            name='fatherFirstName'
            value={state.fourthStepData.fatherFirstName}
            onChange={handler.handleChangefatherFirstName}
            data-test-id="fatherFirstName"
          />
          {fourthStepData.parentsErrors.fatherFirstName &&
            <span style={{ color: 'red', fontSize: "12px", fontFamily: "Montserrat" }}>
              {fourthStepData.parentsErrors.fatherFirstName}
            </span>}
        </Grid>

        <Grid item xs={12} md={6}>
          <InputLabel style={webStyle.inputLabelStyle}>Father's Middle Name *</InputLabel>
          <StyledTextField
            placeholder="Middle Name"
            fullWidth
            name='fatherMiddleName'
            value={state.fourthStepData.fatherMiddleName}
            onChange={handler.handleChangefatherMiddleName}
            data-test-id="fatherMiddleName"
          />
          {fourthStepData.parentsErrors.fatherMiddleName &&
            <span style={{ color: 'red', fontSize: "12px", fontFamily: "Montserrat" }}>
              {fourthStepData.parentsErrors.fatherMiddleName}
            </span>}
        </Grid>

        <Grid item xs={12} md={6}>
          <InputLabel style={webStyle.inputLabelStyle}>Father's Last Name *</InputLabel>
          <StyledTextField
            placeholder="Last Name"
            fullWidth
            name='fatherLastName'
            value={state.fourthStepData.fatherLastName}
            onChange={handler.handleChangefatherLastName}
            data-test-id="fatherLastName"
          />
          {fourthStepData.parentsErrors.fatherLastName &&
            <span style={{ color: 'red', fontSize: "12px", fontFamily: "Montserrat" }}>
              {fourthStepData.parentsErrors.fatherLastName}
            </span>}
        </Grid>

      </>
    )
  }
  return (
    <>
      <Typography style={webStyle.headingStyles}>Parents Info </Typography>
      <Grid container spacing={4} style={{ paddingLeft: '3rem', paddingRight: '3rem' }}>
        {stepfouThree()}
        <Grid item xs={12} md={6}>
          <InputLabel style={webStyle.inputLabelStyle}>Father's Occupation *</InputLabel>
          <StyledTextField
            placeholder="Occupation"
            fullWidth
            name='fatherOccupation'
            value={state.fourthStepData.fatherOccupation}
            onChange={handler.handleChangefatherOccupationt}
            data-test-id="fatherOccupation"
          />
          {fourthStepData.parentsErrors.fatherOccupation &&
            <span style={{ color: 'red', fontSize: "12px", fontFamily: "Montserrat" }}>
              {fourthStepData.parentsErrors.fatherOccupation}
            </span>}
        </Grid>

        <Grid item xs={12} md={6}>
          <InputLabel style={webStyle.inputLabelStyle}>Father's Mobile Number * </InputLabel>
          <Box style={{ display: 'flex', alignItems: 'end' }}>
            <Autocomplete
              id="fatherMobileNumberCode"
              style={{ width: '190px' }}
              data-test-id="fatherMobileNumberCode"
              onChange={handler.onCountryCodeSelectedWeb}
              options={state.fourthStepData.countryPhoneCodeListOne}
              disableClearable
              getOptionLabel={(option) => `+ ${option.value}`}
              renderOption={(option) => (`+ ${option.value} - ${option.sortName}`)}
              getOptionSelected={(option, value) => option.value === value.value}
              renderInput={(params) =>
                <StyledTextField {...params} placeholder='Select Country' fullWidth={true}
                  testidentity="PleaseSelect"
                  name='country' InputLabelProps={{ shrink: false }}
                />}
              value={state.fourthStepData.countryPhoneCodeListOne.find((item: IDItem) => item.value == state.fourthStepData.fatherMobileNumberCode) || null}
            />
            <StyledTextField
              placeholder="0123456789"
              fullWidth
              name='fatherMobileNumber'
              value={state.fourthStepData.fatherMobileNumber}
              onChange={handler.handlePhoneNumbers}
              style={{ marginLeft: '20px' }}
              data-test-id="fatherMobileNumber"
            />
          </Box>
          {fourthStepData.parentsErrors.fatherMobileNumber &&
            <span style={{ color: 'red', fontSize: "12px", fontFamily: "Montserrat"  }}>
              {fourthStepData.parentsErrors.fatherMobileNumber}
            </span>}
        </Grid>




        {stepfourfunc()}





        <Grid item xs={12} md={6}>
          <InputLabel style={webStyle.inputLabelStyle}>Mother's Occupation *</InputLabel>
          <StyledTextField
            placeholder="Occupation"
            fullWidth
            name='motherOccupation'
            value={state.fourthStepData.motherOccupation}
            onChange={handler.handleChangemotherOccupation}
            data-test-id="motherOccupation"
          />
          {fourthStepData.parentsErrors.motherOccupation &&
            <span style={{ color: 'red', fontSize: "12px", fontFamily: "Montserrat" }}>
              {fourthStepData.parentsErrors.motherOccupation}
            </span>}
        </Grid>

        <Grid item xs={12} md={6}>
          <InputLabel style={webStyle.inputLabelStyle}>Mother's Mobile Number * </InputLabel>
          <Box style={{ display: 'flex', alignItems: 'end' }}>
            <Autocomplete
              id="motherMobileNumberCode"
              data-test-id="motherMobileNumberCode"
              onChange={handler.onMotherMobileNumber}
              options={state.fourthStepData.countryPhoneCodeListOne}
              disableClearable
              style={{ width: '190px' }}
              renderOption={(option) => (`+ ${option.value} - ${option.sortName}`)}
              getOptionLabel={(option) => `+ ${option.value}`}
              getOptionSelected={(option, value) => option.value === value.value}
              renderInput={(params) =>
                <StyledTextField {...params} placeholder='Select Country' fullWidth={true}
                  testidentity="PleaseSelect"
                  name='country' InputLabelProps={{ shrink: false }}
                />}
              value={state.fourthStepData.countryPhoneCodeListOne.find((item: IDItem) => item.value == state.fourthStepData.motherMobileNumberCode) || null}
            />
            <StyledTextField
              placeholder="0123456789"
              fullWidth
              name='motherMobileNumber'
              value={state.fourthStepData.motherMobileNumber}
              onChange={handler.handlePhoneNumber}
              style={{ marginLeft: '20px' }}
              data-test-id="motherMobileNumber"
            />
          </Box>
          {fourthStepData.parentsErrors.motherMobileNumber &&
            <span style={{ color: 'red', fontSize: "12px", fontFamily: "Montserrat"}}>
              {fourthStepData.parentsErrors.motherMobileNumber}
            </span>}
        </Grid>

        <Grid item xs={12} md={6}>
          <InputLabel style={webStyle.inputLabelStyle}>Mother's Email Address (if not applicable, please input "N/A") * </InputLabel>
          <StyledTextField
            placeholder="Email Address"
            fullWidth
            name='motherEmail'
            value={state.fourthStepData.motherEmail}
            onChange={handler.handleChangemotherEmail}


            data-test-id="motherEmail"
          />
          {fourthStepData.parentsErrors.motherEmail &&
            <span style={{ color: 'red', fontSize: "12px", fontFamily: "Montserrat" }}>
              {fourthStepData.parentsErrors.motherEmail}
            </span>}
        </Grid>

        <Grid item xs={12} md={6}>
          <InputLabel style={webStyle.inputLabelStyle}>Marital Status *</InputLabel>
          <StyledTextField
            select
            fullWidth
            name="maritalStatus"
            data-test-id='marital_status'
            InputLabelProps={{ shrink: false }}
            value={state.fourthStepData.maritalStatus}
            onChange={handler.maritalStatusChange}
          >
            <MenuItem style={webStyle.StudentMenuItemsStyle} value="choose" disabled> <span style={{ color: "#ABABB0" }}>Please Select</span></MenuItem>
            <MenuItem value="married">Married</MenuItem>
            <MenuItem value="not_married">Not Married</MenuItem>
            <MenuItem value="solo_parent">Solo Parent</MenuItem>
            <MenuItem value="legally_separated">Legally Separated</MenuItem>
          </StyledTextField>

          {fourthStepData.parentsErrors.maritalStatus &&
            <span style={{ color: 'red', fontSize: "12px", fontFamily: "Montserrat" }}>
              {fourthStepData.parentsErrors.maritalStatus}
            </span>}

        </Grid>




        {stepfourstepTwo()}

        <Grid item xs={12} md={6}>
          <InputLabel style={webStyle.inputLabelStyle}>Guardian's Email Address (if not applicable, please input "N/A") * </InputLabel>
          <StyledTextField
            placeholder="N/A"
            fullWidth
            name='guardianEmail'
            value={state.fourthStepData.guardianEmail}
            onChange={handler.handleChangeguardianEmail}


            data-test-id="guardian_email"
          />
          {fourthStepData.parentsErrors.guardianEmail &&
            <span style={{ color: 'red', fontSize: "12px", fontFamily: "Montserrat" }}>
              {fourthStepData.parentsErrors.guardianEmail}
            </span>}
        </Grid>

        <Grid item xs={12} md={6}>
          <InputLabel style={webStyle.inputLabelStyle}>Relation to the student (if not applicable, please input "N/A") * </InputLabel>
          <StyledTextField
            placeholder="N/A"
            fullWidth
            name='relationWithStudent'
            value={state.fourthStepData.relationWithStudent}
            onChange={handler.handleChangerelationWithStudent}
            data-test-id="relation_student"
          />
          {fourthStepData.parentsErrors.relationWithStudent &&
            <span style={{ color: 'red', fontSize: "12px", fontFamily: "Montserrat" }}>
              {fourthStepData.parentsErrors.relationWithStudent}
            </span>}
        </Grid>
      </Grid>
    </>
  )
}
//istanbul ignore next
const StepFive = (props: any) => {
  const { state, handler } = props;
  const { fifthStepData, secondStepData } = state;

  const handleSwitch = () => {
    switch (state.firstStepData.studentTypesOfScholarShip?.value) {
      case 'Chrissie Gracia Scholarship':
        return Chrissie();
      case 'The International/National Achievement Scholarship':
        return internation();
      case 'The Ministry Leaders Assistance':
        return ministry();
      case 'Scholarship for HG Servant Leaders':
        return hgServant();
      case 'Financial Aid':
        return financial();
      case 'The Jovi Villote Memorial Scholarship':
        return jovi();
      case 'HG Staff Student Scholarship':
        return hgstaff();
    }
  }

  const handleSwichBottomFile = () => {
    switch (state.firstStepData.studentTypesOfScholarShip?.value) {
      case 'The International/National Achievement Scholarship':
        return internationBottom();
      case 'Financial Aid':
        return financialBottom();
      case 'The Jovi Villote Memorial Scholarship':
        return joviBottom();
      case 'HG Staff Student Scholarship':
        return hgstaffBottom();
    }
  }
  const Chrissie = () => {
    return (
      <Grid container>
        <Grid item xs={12} style={{ paddingLeft: '3rem', paddingRight: '3rem', }}>
          <Typography style={{
            color: '#9167ac',
            fontSize: '18px', padding: '20px 0px 10px 0px'
          }}>The Chrissie Garcia Scholarship</Typography>
          <InputLabel style={webStyle.fileLabelStyle}>Letter of Recommendation (scholarship) *</InputLabel>
          <TicketFileUpload uploadFiles={handler.chrissieUploadFile} ticketFile={state.fifthStepData.chrissie} />
          {fifthStepData.fileErrors.chrissie &&
            <span style={{ color: 'red', fontSize: "12px", fontFamily: "Montserrat" }}>
              {fifthStepData.fileErrors.chrissie}
            </span>}
        </Grid>
      </Grid>
    )
  }

  const internation = () => {
    return (
      <Grid container>
        <Grid item xs={12} style={{ paddingLeft: '3rem', paddingRight: '3rem', }}>
          <Typography style={{
            color: '#9167ac',
            fontSize: '18px', padding: '20px 0px 10px 0px'
          }}>The International/National Achievement Scholarship</Typography>
          <InputLabel style={webStyle.fileLabelStyle}>Letter of Recommendation (scholarship) *</InputLabel>
          <TicketFileUpload uploadFiles={handler.internationalLetterUploadFile} ticketFile={state.fifthStepData.internationalLetter} />
          {fifthStepData.fileErrors.internationalLetter &&
            <span style={{ color: 'red', fontSize: "12px", fontFamily: "Montserrat" }}>
              {fifthStepData.fileErrors.internationalLetter}
            </span>}
        </Grid>
      </Grid>
    )
  }

  const internationBottom = () => {
    return (
      <Grid item xs={12} style={{ paddingLeft: '3rem', paddingRight: '3rem', paddingTop: '1.5rem' }}>
        <InputLabel style={webStyle.fileLabelStyle}>Proof of achievements *</InputLabel>
        <TicketFileUpload uploadFiles={handler.internationalAchevementUploadFile} ticketFile={state.fifthStepData.internationalAchevement} />
        {fifthStepData.fileErrors.internationalAchevement &&
          <span style={{ color: 'red', fontSize: "12px", fontFamily: "Montserrat" }}>
            {fifthStepData.fileErrors.internationalAchevement}
          </span>}
      </Grid>
    )
  }

  const ministry = () => {
    return (
      <Grid container>
        <Grid item xs={12} style={{ paddingLeft: '3rem', paddingRight: '3rem', }}>
          <Typography style={{
            color: '#9167ac',
            fontSize: '18px', padding: '20px 0px 10px 0px'
          }}>The Ministry Leaders Assistance</Typography>
          <InputLabel style={webStyle.fileLabelStyle}>Letter from Church Affiliation *</InputLabel>
          <TicketFileUpload uploadFiles={handler.ministryUploadFile} ticketFile={state.fifthStepData.ministry} />
          {fifthStepData.fileErrors.ministry &&
            <span style={{ color: 'red', fontSize: "12px", fontFamily: "Montserrat" }}>
              {fifthStepData.fileErrors.ministry}
            </span>}
        </Grid>
      </Grid>
    )
  }

  const hgServant = () => {
    return (
      <Grid container>
        <Grid item xs={12} style={{ paddingLeft: '3rem', paddingRight: '3rem', }}>
          <Typography style={{
            color: '#9167ac',
            fontSize: '18px', padding: '20px 0px 10px 0px'
          }}>Scholarship for HG Servant Leaders</Typography>
          <InputLabel style={webStyle.fileLabelStyle}>Letter of Recommendation (scholarship) *</InputLabel>
          <TicketFileUpload uploadFiles={handler.HGServantUploadFile} ticketFile={state.fifthStepData.hgServant} />
          {fifthStepData.fileErrors.hgServant &&
            <span style={{ color: 'red', fontSize: "12px", fontFamily: "Montserrat" }}>
              {fifthStepData.fileErrors.hgServant}
            </span>}
        </Grid>
      </Grid>
    )
  }

  const financial = () => {
    return (
      <Grid container>
        <Grid item xs={12} style={{ paddingLeft: '3rem', paddingRight: '3rem', }}>
          <Typography style={{
            color: '#9167ac',
            fontSize: '18px', padding: '20px 0px 10px 0px'
          }}>Financial Aid</Typography>
          <InputLabel style={webStyle.fileLabelStyle}>Letter of Recommendation (scholarship) *</InputLabel>
          <TicketFileUpload uploadFiles={handler.financialuploadFile} ticketFile={state.fifthStepData.financial} />
          {fifthStepData.fileErrors.financial &&
            <span style={{ color: 'red', fontSize: "12px", fontFamily: "Montserrat" }}>
              {fifthStepData.fileErrors.financial}
            </span>}
        </Grid>
      </Grid>
    )
  }

  const financialBottom = () => {
    return (
      <Grid item xs={12} style={{ paddingLeft: '3rem', paddingRight: '3rem', paddingTop: '1.5rem' }}>
        <InputLabel style={webStyle.fileLabelStyle}>Proof of income/billing *</InputLabel>
        <TicketFileUpload uploadFiles={handler.proofIncomeuploadFile} ticketFile={state.fifthStepData.proofIncome} />
        {fifthStepData.fileErrors.proofIncome &&
          <span style={{ color: 'red', fontSize: "12px", fontFamily: "Montserrat" }}>
            {fifthStepData.fileErrors.proofIncome}
          </span>}
      </Grid>
    )
  }

  const jovi = () => {
    return (
      <Grid container>
        <Grid item xs={12} style={{ paddingLeft: '3rem', paddingRight: '3rem', }}>
          <Typography style={{
            color: '#9167ac',
            fontSize: '18px', padding: '20px 0px 10px 0px'
          }}>The Jovi Villote Memorial Scholarship</Typography>
          <InputLabel style={webStyle.fileLabelStyle}>Death certificate of the father *</InputLabel>
          <TicketFileUpload uploadFiles={handler.deathuploadFile} ticketFile={state.fifthStepData.death} />
          {fifthStepData.fileErrors.death &&
            <span style={{ color: 'red', fontSize: "12px", fontFamily: "Montserrat" }}>
              {fifthStepData.fileErrors.death}
            </span>}
        </Grid>
      </Grid>
    )
  }

  const joviBottom = () => {
    return (
      <>
      <Grid item xs={12} style={{ paddingLeft: '3rem', paddingRight: '3rem', paddingTop: '1.5rem' }}>
          <InputLabel style={webStyle.fileLabelStyle}>Birth certificate of the student/s *</InputLabel>
          <TicketFileUpload uploadFiles={handler.birthStudent} ticketFile={state.fifthStepData.birthStudent} />
          {fifthStepData.fileErrors.birthStudent &&
            <span style={{ color: 'red', fontSize: "12px", fontFamily: "Montserrat" }}>
              {fifthStepData.fileErrors.birthStudent}
            </span>}
        </Grid>

        <Grid item xs={12} style={{ paddingLeft: '3rem', paddingRight: '3rem', paddingTop: '1.5rem' }}>
          <InputLabel style={webStyle.fileLabelStyle}>A letter to the committee with the details of the family *</InputLabel>
          <TicketFileUpload uploadFiles={handler.letterCommitteeuploadFile} ticketFile={state.fifthStepData.letterCommittee} />
          {fifthStepData.fileErrors.letterCommittee &&
            <span style={{ color: 'red', fontSize: "12px", fontFamily: "Montserrat" }}>
              {fifthStepData.fileErrors.letterCommittee}
            </span>}
        </Grid>

        <Grid item xs={12} style={{ paddingLeft: '3rem', paddingRight: '3rem', paddingTop: '1.5rem' }}>
          <InputLabel style={webStyle.fileLabelStyle}>Most recent electric bill *</InputLabel>
          <TicketFileUpload uploadFiles={handler.recentBilluploadFile} ticketFile={state.fifthStepData.recentBill} />
          {fifthStepData.fileErrors.recentBill &&
            <span style={{ color: 'red', fontSize: "12px", fontFamily: "Montserrat" }}>
              {fifthStepData.fileErrors.recentBill}
            </span>}
        </Grid>
      </>
    )
  }

  const hgstaff = () => {
    return (
      <Grid container>
        <Grid item xs={12} style={{ paddingLeft: '3rem', paddingRight: '3rem', }}>
          <Typography style={{
            color: '#9167ac',
            fontSize: '18px', padding: '20px 0px 10px 0px'
          }}>HG Staff Student Scholarship</Typography>
          <InputLabel style={webStyle.fileLabelStyle}>Birth certificate of Parent *</InputLabel>
          <TicketFileUpload uploadFiles={handler.birthParentuploadFile} ticketFile={state.fifthStepData.birthParent} />
          {fifthStepData.fileErrors.birthParent &&
            <span style={{ color: 'red', fontSize: "12px", fontFamily: "Montserrat" }}>
              {fifthStepData.fileErrors.birthParent}
            </span>}
        </Grid>
      </Grid>
    )
  }

  const hgstaffBottom = () => {
    return (
      <Grid item xs={12} style={{ paddingLeft: '3rem', paddingRight: '3rem', paddingTop: '1.5rem' }}>
        <InputLabel style={webStyle.fileLabelStyle}>Birth certificate of the Luova staff *</InputLabel>
        <TicketFileUpload uploadFiles={handler.birthLuovaStaffuploadFile} ticketFile={state.fifthStepData.birthLuovaStaff} />
        {fifthStepData.fileErrors.birthLuovaStaff &&
          <span style={{ color: 'red', fontSize: "12px", fontFamily: "Montserrat" }}>
            {fifthStepData.fileErrors.birthLuovaStaff}
          </span>}
      </Grid>
    )
  }

  return (
    <>
      <Typography style={webStyle.headingStyles}>Files </Typography>

      {handleSwitch()}

      <Grid container>
        {handleSwichBottomFile()}

        <Grid item xs={12} style={{ paddingLeft: '3rem', paddingRight: '3rem', paddingTop: '1.5rem' }}>
          <InputLabel style={webStyle.fileLabelStyle}>Original/Photocopy Birth Certificate (issued by the Philippine Statistics Authority - PSA) *</InputLabel>
          <TicketFileUpload uploadFiles={handler.uploadBirthCertificateFile} ticketFile={state.fifthStepData.birthCertificate} />
          {fifthStepData.fileErrors.birthCertificate &&
            <span style={{ color: 'red', fontSize: "12px", fontFamily: "Montserrat" }}>
              {fifthStepData.fileErrors.birthCertificate}
            </span>}
        </Grid>

        {secondStepData.prevSchoolAttended.toLowerCase() != 'n/a' &&
          <>
            <Grid item xs={12} style={{ paddingLeft: '3rem', paddingRight: '3rem', paddingTop: '1.5rem' }}>
              <InputLabel style={webStyle.fileLabelStyle}>Original Report Card (Most recent report card(F-138) duly signed by the school head) *</InputLabel>
              <TicketFileUpload uploadFiles={handler.uploadReportCardFile} ticketFile={state.fifthStepData.reportCard} />
              {fifthStepData.fileErrors.reportCard &&
                <span style={{ color: 'red', fontSize: "12px", fontFamily: "Montserrat" }}>
                  {fifthStepData.fileErrors.reportCard}
                </span>}
            </Grid>

            <Grid item xs={12} style={{ paddingLeft: '3rem', paddingRight: '3rem', paddingTop: '1.5rem' }}>
              <InputLabel style={webStyle.fileLabelStyle}>Transcript/School Records (Incoming Grades 6, 10, 11, and 12 are required to submit the soft copy upon enrollment) *</InputLabel>
              <TicketFileUpload uploadFiles={handler.uploadSchoolRecordsFile} ticketFile={state.fifthStepData.schoolRecords} />
              {fifthStepData.fileErrors.schoolRecords &&
                <span style={{ color: 'red', fontSize: "12px", fontFamily: "Montserrat" }}>
                  {fifthStepData.fileErrors.schoolRecords}
                </span>}
            </Grid>
          </>
        }
        {(secondStepData.learnerRefNumber != null && secondStepData.learnerRefNumber != 0) &&
          <Grid item xs={12} style={{ paddingLeft: '3rem', paddingRight: '3rem', paddingTop: '1.5rem' }}>
            <InputLabel style={webStyle.fileLabelStyle}>LRN (Learner Reference Number- Students from Philippine Schools) *</InputLabel>
            <TicketFileUpload uploadFiles={handler.uploadLearnerRefNumberFile} ticketFile={state.fifthStepData.learnerRefNumberFile} />
            {fifthStepData.fileErrors.learnerRefNumberFile &&
              <span style={{ color: 'red', fontSize: "12px", fontFamily: "Montserrat" }}>
                {fifthStepData.fileErrors.learnerRefNumberFile}
              </span>}
          </Grid>
        }

        <Grid item xs={12} style={{ paddingLeft: '3rem', paddingRight: '3rem', paddingTop: '1.5rem' }}>
          <InputLabel style={webStyle.fileLabelStyle}>ID Picture (Digital copy of child's latest photo with white background - JPEG format) *</InputLabel>
          <TicketFileUpload uploadFiles={handler.uploadPictureFile} ticketFile={state.fifthStepData.picture} />
          {fifthStepData.fileErrors.picture &&
            <span style={{ color: 'red', fontSize: "12px", fontFamily: "Montserrat" }}>
              {fifthStepData.fileErrors.picture}
            </span>}
        </Grid>

        {state.firstStepData.studentParentDiscount &&
          <Grid item xs={12} style={{ paddingLeft: '3rem', paddingRight: '3rem', paddingTop: '1.5rem' }}>
            <InputLabel style={webStyle.fileLabelStyle}>Solo Parent ID *</InputLabel>
            <TicketFileUpload uploadFiles={handler.uploadSoloParentIdFile} ticketFile={state.fifthStepData.soloParentId} />
            {fifthStepData.fileErrors.soloParentId &&
              <span style={{ color: 'red', fontSize: "12px", fontFamily: "Montserrat" }}>
                {fifthStepData.fileErrors.soloParentId}
              </span>}
          </Grid>
        }

        <Grid item xs={12} style={{ paddingLeft: '3rem', paddingRight: '3rem', paddingTop: '1.5rem' }}>
          <InputLabel style={webStyle.fileLabelStyle}>Valid Parent ID *</InputLabel>
          <TicketFileUpload uploadFiles={handler.uploadValidParentIdFile} ticketFile={state.fifthStepData.validParentId} uploadFileImage={state.fifthStepData} />
          {fifthStepData.fileErrors.validParentId &&
            <span style={{ color: 'red', fontSize: "12px", fontFamily: "Montserrat" }}>
              {fifthStepData.fileErrors.validParentId}
            </span>}
        </Grid>

        {state.firstStepData.studentPwd &&
          <Grid item xs={12} style={{ paddingLeft: '3rem', paddingRight: '3rem', paddingTop: '1.5rem' }}>
            <InputLabel style={webStyle.fileLabelStyle}>PWD ID *</InputLabel>
            <TicketFileUpload uploadFiles={handler.uploadPWDIDFile} ticketFile={state.fifthStepData.pwdID} />
            {fifthStepData.fileErrors.pwdID &&
              <span style={{ color: 'red', fontSize: "12px", fontFamily: "Montserrat" }}>
                {fifthStepData.fileErrors.pwdID}
              </span>}
          </Grid>
        }
      </Grid>
    </>
  )
}
//istanbul ignore next
const StepSix = (props: any) => {
  const { state, handler } = props;
  const { reasonForRegistering } = state
  return (
    <>
      <Typography style={webStyle.headingStyles}>Review</Typography>
      <Grid container spacing={4} style={{ paddingLeft: '3rem', paddingRight: '3rem' }}>
        <Grid item xs={12}>
          <Paper elevation={0} style={webStyle.reviewPaperStyles}>
            <Box style={{ padding: '15px 0px 10px 35px' }}>
              <Typography style={webStyle.reviewHeadingStyles}>
                Student Information
              </Typography>
              <Grid container>
                <Grid item xs={12} sm={12} md={6} lg={6}>
                  <Box style={webStyle.displayFlex}>
                    <Typography style={webStyle.reviewNameTitleStyles}>Student's First Name:</Typography>
                    <Box style = {{overflow: "hidden" , textOverflow: "ellipsis", wordBreak: "break-word", overflowWrap: "break-word" , width : '45%'}}>
                    <Typography style={webStyle.reviewSubtitleStyles}> {state.firstStepData?.studentFirstName}</Typography>
                    </Box>
                  </Box>

                  <Box style={webStyle.displayFlex}>
                    <Typography style={webStyle.reviewNameTitleStyles}>Student's Middle Name:</Typography>
                    <Box style = {{overflow: "hidden" , textOverflow: "ellipsis", wordBreak: "break-word", overflowWrap: "break-word" , width : '45%'}}>
                    <Typography style={webStyle.reviewSubtitleStyles}>{state.firstStepData?.studentMiddleName}</Typography>
                    </Box>
                  </Box>

                  <Box style={webStyle.displayFlex}>
                    <Typography style={webStyle.reviewNameTitleStyles}>Student's Last Name:</Typography>
                    <Box style = {{overflow: "hidden" , textOverflow: "ellipsis", wordBreak: "break-word", overflowWrap: "break-word" , width : '45%'}}>
                    <Typography style={webStyle.reviewSubtitleStyles}>{state.firstStepData.studentLastName}</Typography>
                    </Box>
                  </Box>

                  <Box style={webStyle.displayFlex}>
                    <Typography style={webStyle.reviewNameTitleStyles}>Gender:</Typography>
                    <Typography style={webStyle.reviewSubtitleStyles}>{state.firstStepData.studentGender}</Typography>
                  </Box>

                  <Box style={webStyle.displayFlex}>
                    <Typography style={webStyle.reviewNameTitleStyles}>Date of Birth:</Typography>
                    <Typography style={webStyle.reviewSubtitleStyles}>{state.firstStepData?.studentDOB}</Typography>
                  </Box>

                  <Box style={webStyle.displayFlex}>
                    <Typography style={webStyle.reviewNameTitleStyles}>Age:</Typography>
                    <Typography style={webStyle.reviewSubtitleStyles}>{state.firstStepData?.studentAge || '0'}</Typography>
                  </Box>

                  <Box style={webStyle.displayFlex}>
                    <Typography style={webStyle.reviewNameTitleStyles}>Place of Birth (Country):</Typography>
                    <Typography style={webStyle.reviewSubtitleStyles}>{state.firstStepData.studentCountry?.value}</Typography>
                  </Box>

                  <Box style={webStyle.displayFlex}>
                    <Typography style={webStyle.reviewNameTitleStyles}>Place of Birth (City):</Typography>
                    <Typography style={webStyle.reviewSubtitleStyles}>{state.firstStepData.studentBirthCity?.value}</Typography>
                  </Box>

                  <Box style={webStyle.displayFlex}>
                    <Typography style={webStyle.reviewNameTitleStyles}>Religion:</Typography>
                    <Typography style={webStyle.reviewSubtitleStyles}>{state.firstStepData.studentReligion?.value}</Typography>
                  </Box>

                  <Box style={webStyle.displayFlex}>
                    <Typography style={webStyle.reviewNameTitleStyles}>Nationality:</Typography>
                    <Typography style={webStyle.reviewSubtitleStyles}>{state.firstStepData.studentNationality?.value}</Typography>
                  </Box>

                  <Box style={webStyle.displayFlex}>
                    <Typography style={webStyle.reviewNameTitleStyles}>Country of residence:</Typography>
                    <Typography style={webStyle.reviewSubtitleStyles}>{state.firstStepData.studentCountryOfResidence?.value}</Typography>
                  </Box>

                  <Box style={webStyle.displayFlex}>
                    <Typography style={webStyle.reviewNameTitleStyles}>City of residence:</Typography>
                    <Typography style={webStyle.reviewSubtitleStyles}>{state.firstStepData.studentCityOfResidence?.value}</Typography>
                  </Box>

                  <Box style={webStyle.displayFlex}>
                    <Typography style={webStyle.reviewNameTitleStyles}>Barangay:</Typography>
                    <Typography style={webStyle.reviewSubtitleStyles}>{handler.handleNoValueText(state.firstStepData.barangayBirth?.value)}</Typography>
                  </Box>

                  <Box style={webStyle.displayFlex}>
                    <Typography style={webStyle.reviewNameTitleStyles}>Child's First Language:</Typography>
                    <Typography style={webStyle.reviewSubtitleStyles}>{state.firstStepData.studentChildsFirstLanguage?.value}</Typography>
                  </Box>

                  <Box style={webStyle.displayFlex}>
                    <Typography style={webStyle.reviewNameTitleStyles}>Residential Address:</Typography>
                    <Box style = {{overflow: "hidden" , textOverflow: "ellipsis", wordBreak: "break-word", overflowWrap: "break-word" , width : '45%'}}>
                    <Typography style={webStyle.reviewSubtitleStyles}>{state.firstStepData.studentResidentialAddress}</Typography>
                    </Box>
                  </Box>

                  <Box style={webStyle.displayFlex}>
                    <Typography style={webStyle.reviewNameTitleStyles}>Scholarship:</Typography>
                    <Typography style={webStyle.reviewSubtitleStyles}>{state.firstStepData?.studentWouldYouLikeToApplyForAScholarShip ? "Yes" : "No"}</Typography>
                  </Box>

                  <Box style={webStyle.displayFlex}>
                    <Typography style={webStyle.reviewNameTitleStyles}>Scholarship Type:</Typography>
                    <Typography style={webStyle.reviewSubtitleStyles}>{state.firstStepData?.studentScholarShipType.value !== "choose" ? state.firstStepData.studentScholarShipType.value : "Not Selected"}</Typography>
                  </Box>

                  <Box style={webStyle.displayFlex}>
                    <Typography style={webStyle.reviewNameTitleStyles}>Type of Scholarship:</Typography>
                    <Box style = {{overflow: "hidden" , textOverflow: "ellipsis", wordBreak: "break-word", overflowWrap: "break-word" , width : '45%'}}>
                    <Typography style={webStyle.reviewSubtitleStyles}>{ state.firstStepData.studentTypesOfScholarShip?.value !== "choose" ? state.firstStepData.studentTypesOfScholarShip.value : "Not Selected"}</Typography>
                    </Box>
                  </Box>

                  <Box style={webStyle.displayFlex}>
                    <Typography style={webStyle.reviewNameTitleStyles}>Advocate / Referral Program:</Typography>
                    <Typography style={webStyle.reviewSubtitleStyles}>{(state.firstStepData?.studentAdvocatecode ? "Yes" : "No")}</Typography>
                  </Box>
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6}>

                <Box style={webStyle.displayFlex}>
                    <Typography style={webStyle.reviewNameTitleStyles}>Advocate / Referral Code:</Typography>
                    <Typography style={webStyle.reviewSubtitleStyles}>{(state.firstStepData.studentAdvocatecode) ? state.firstStepData.studentAdvocatecode : "N/A"}</Typography>
                  </Box>

                  <Box style={webStyle.displayFlex}>
                    <Typography style={webStyle.reviewNameTitleStyles}>PWD Discount:</Typography>
                    <Typography style={webStyle.reviewSubtitleStyles}>{state.firstStepData.studentPwd ? "Yes" : "No"}</Typography>
                  </Box>

                  <Box style={webStyle.displayFlex}>
                    <Typography style={webStyle.reviewNameTitleStyles}>PWD ID:</Typography>
                    <Typography style={webStyle.reviewSubtitleStyles}>{(state.firstStepData.studentPwdIdNumber) ? state.firstStepData.studentPwdIdNumber : "N/A"}</Typography>
                  </Box>

                  <Box style={webStyle.displayFlex}>
                    <Typography style={webStyle.reviewNameTitleStyles}>Solo Parent Discount:</Typography>
                    <Typography style={webStyle.reviewSubtitleStyles}>{state.firstStepData.studentParentDiscount ? "Yes" : "No"}</Typography>
                  </Box>

                  <Box style={webStyle.displayFlex}>
                    <Typography style={webStyle.reviewNameTitleStyles}>Solo Parent ID:</Typography>
                    <Box style = {{overflow: "hidden" , textOverflow: "ellipsis", wordBreak: "break-word", overflowWrap: "break-word" , width : '45%'}}>
                    <Typography style={webStyle.reviewSubtitleStyles}>{(state.firstStepData.studentSoloParentId ? state.firstStepData.studentSoloParentId : "N/A")}</Typography>
                    </Box>
                  </Box>

                  <Box style={webStyle.displayFlex}>
                    <Typography style={webStyle.reviewNameTitleStyles}>Last Grade Level:</Typography>
                    <Typography style={webStyle.reviewSubtitleStyles}>{handler.handleNoValueText(state.secondStepData.prevGradeLevel?.value)}</Typography>
                  </Box>

                  <Box style={webStyle.displayFlex}>
                    <Typography style={webStyle.reviewNameTitleStyles}>Previous School Attended:</Typography>
                    <Box style = {{overflow: "hidden" , textOverflow: "ellipsis", wordBreak: "break-word", overflowWrap: "break-word" , width : '45%'}}>
                    <Typography style={webStyle.reviewSubtitleStyles}>{handler.handleNoValueText(state.secondStepData.prevSchoolAttended)}</Typography>
                    </Box>
                  </Box>

                  <Box style={webStyle.displayFlex}>
                    <Typography style={webStyle.reviewNameTitleStyles}>Previous School Curriculum:</Typography>
                    <Typography style={webStyle.reviewSubtitleStyles}>{handler.handleNoValueText(state.secondStepData.prevSchoolCurriculum?.value)}</Typography>
                  </Box>

                  <Box style={webStyle.displayFlex}>
                    <Typography style={webStyle.reviewNameTitleStyles}>Previous School Email Address:</Typography>
                    <Box style = {{overflow: "hidden" , textOverflow: "ellipsis", wordBreak: "break-word", overflowWrap: "break-word" , width : '45%'}}>
                    <Typography style={webStyle.reviewSubtitleStyles}>{handler.handleNoValueText(state.secondStepData.prevSchoolEmail)}</Typography>
                    </Box>
                  </Box>

                  <Box style={webStyle.displayFlex}>
                    <Typography style={webStyle.reviewNameTitleStyles}>Previous School Contact Number:</Typography>
                    <Box style = {{overflow: "hidden" , textOverflow: "ellipsis", wordBreak: "break-word", overflowWrap: "break-word" , width : '45%'}}>
                    <Typography style={webStyle.reviewSubtitleStyles}>{handler.handleNoValueText(state.secondStepData.prevSchoolNumber)}</Typography>
                    </Box>
                  </Box>

                  <Box style={webStyle.displayFlex}>
                    <Typography style={webStyle.reviewNameTitleStyles}>Previous School Address:</Typography>
                    <Box style = {{overflow: "hidden" , textOverflow: "ellipsis", wordBreak: "break-word", overflowWrap: "break-word" , width : '45%'}}>
                    <Typography style={webStyle.reviewSubtitleStyles}>{handler.handleNoValueText(state.secondStepData.prevSchoolAddress)}</Typography>
                    </Box>
                  </Box>

                  <Box style={webStyle.displayFlex}>
                    <Typography style={webStyle.reviewNameTitleStyles}>Last School Year:</Typography>
                    <Typography style={webStyle.reviewSubtitleStyles}>{handler.handleNoValueText(state.secondStepData.prevSchoolYear)}</Typography>
                  </Box>

                  <Box style={webStyle.displayFlex}> 
                    <Typography style={webStyle.reviewNameTitleStyles}>Learner Reference Number:</Typography>
                    <Typography style={webStyle.reviewSubtitleStyles}>{handler.handleNoValueText(state.secondStepData.learnerRefNumber)}</Typography>
                  </Box>

                  <Box style={webStyle.displayFlex}>
                    <Typography style={webStyle.reviewNameTitleStyles}>Program:</Typography>
                    <Typography style={webStyle.reviewSubtitleStyles}>{state.thirdStepData.program?.value}</Typography>
                  </Box>
                  <Box style={webStyle.displayFlex}>
                  <Typography style={webStyle.reviewNameTitleStyles}>Community:</Typography>
                  <Typography style={webStyle.reviewSubtitleStyles}>{state.thirdStepData.community?.value}</Typography>
                  </Box>

                  <Box style={webStyle.displayFlex}>
                    <Typography style={webStyle.reviewNameTitleStyles}>Hub:</Typography>
                    <Typography style={webStyle.reviewSubtitleStyles}>{state.thirdStepData.hub?.value}</Typography>
                  </Box>

                  <Box style={webStyle.displayFlex}>
                    <Typography style={webStyle.reviewNameTitleStyles}>Cycle:</Typography>
                    <Typography style={webStyle.reviewSubtitleStyles}>{state.thirdStepData.cycle?.value}</Typography>
                  </Box>

                  <Box style={webStyle.displayFlex}>
                    <Typography style={webStyle.reviewNameTitleStyles}>Accreditation:</Typography>
                    <Typography style={webStyle.reviewSubtitleStyles}>{state.thirdStepData.accreditation?.value}</Typography>
                  </Box>

                  <Box style={webStyle.displayFlex}>
                    <Typography style={webStyle.reviewNameTitleStyles}>Incoming Grade Level:</Typography>
                    <Typography style={webStyle.reviewSubtitleStyles}>{state.thirdStepData.incomingGradeLevel?.value}</Typography>
                  </Box>

                  <Box style={webStyle.displayFlex}>
                    <Typography style={webStyle.reviewNameTitleStyles}>SHS Track:</Typography>
                    <Typography style={webStyle.reviewSubtitleStyles}>{handler.handleNoValueText(state.thirdStepData.shsTrack?.value)}</Typography>
                  </Box>
                </Grid>
              </Grid>
            </Box>

            <Box style={{ padding: '15px 0px 15px 35px' }}>
              <Typography style={webStyle.reviewHeadingStyles}>
                Family Details
              </Typography>
              <Grid container>
                <Grid item xs={12} sm={12} md={6} lg={6}>
                  <Box style={webStyle.displayFlex}>
                    <Typography style={webStyle.reviewNameTitleStyles}>Father's First Name:</Typography>
                    <Box style = {{overflow: "hidden" , textOverflow: "ellipsis", wordBreak: "break-word", overflowWrap: "break-word" , width : '45%'}}>
                    <Typography style={webStyle.reviewSubtitleStyles}>{state.fourthStepData.fatherFirstName}</Typography>
                    </Box>
                  </Box>

                  <Box style={webStyle.displayFlex}>
                    <Typography style={webStyle.reviewNameTitleStyles}>Father's Middle Name:</Typography>
                    <Box style = {{overflow: "hidden" , textOverflow: "ellipsis", wordBreak: "break-word", overflowWrap: "break-word" , width : '45%'}}>
                    <Typography style={webStyle.reviewSubtitleStyles}>{state.fourthStepData.fatherMiddleName}</Typography>
                    </Box>
                  </Box>

                  <Box style={webStyle.displayFlex}>
                    <Typography style={webStyle.reviewNameTitleStyles}>Father's Last Name:</Typography>
                    <Box style = {{overflow: "hidden" , textOverflow: "ellipsis", wordBreak: "break-word", overflowWrap: "break-word" , width : '45%'}}>
                    <Typography style={webStyle.reviewSubtitleStyles}>{state.fourthStepData.fatherLastName}</Typography>
                    </Box>
                  </Box>

                  <Box style={webStyle.displayFlex}>
                    <Typography style={webStyle.reviewNameTitleStyles}>Father's Occupation:</Typography>
                    <Box style = {{overflow: "hidden" , textOverflow: "ellipsis", wordBreak: "break-word", overflowWrap: "break-word" , width : '45%'}}>
                    <Typography style={webStyle.reviewSubtitleStyles}>{state.fourthStepData.fatherOccupation}</Typography>
                    </Box>
                  </Box>

                  <Box style={webStyle.displayFlex}>
                    <Typography style={webStyle.reviewNameTitleStyles}>Father's Mobile Number:</Typography>
                    <Box style = {{overflow: "hidden" , textOverflow: "ellipsis", wordBreak: "break-word", overflowWrap: "break-word" , width : '45%'}}>
                    <Typography style={webStyle.reviewSubtitleStyles}>{"+" + state.fourthStepData.fatherMobileNumberCode + state.fourthStepData.fatherMobileNumber}</Typography>
                    </Box>
                  </Box>

                  <Box style={webStyle.displayFlex}>
                    <Typography style={webStyle.reviewNameTitleStyles}>Father's Email Address:</Typography>
                    <Box style = {{overflow: "hidden" , textOverflow: "ellipsis", wordBreak: "break-word", overflowWrap: "break-word" , width : '45%'}}>
                    <Typography style={webStyle.reviewSubtitleStyles}>{state.fourthStepData.fatherEmail}</Typography>
                    </Box>
                  </Box>

                  <Box style={webStyle.displayFlex}>
                    <Typography style={webStyle.reviewNameTitleStyles}>Mother's First Name (Maiden):</Typography>
                    <Box style = {{overflow: "hidden" , textOverflow: "ellipsis", wordBreak: "break-word", overflowWrap: "break-word" , width : '45%'}}>
                    <Typography style={webStyle.reviewSubtitleStyles}>{state.fourthStepData.motherFirstName}</Typography>
                    </Box>
                  </Box>

                  <Box style={webStyle.displayFlex}>
                    <Typography style={webStyle.reviewNameTitleStyles}>Mother's Middle Name (Maiden):</Typography>
                    <Box style = {{overflow: "hidden" , textOverflow: "ellipsis", wordBreak: "break-word", overflowWrap: "break-word" , width : '45%'}}>
                    <Typography style={webStyle.reviewSubtitleStyles}>{state.fourthStepData.motherMiddleName}</Typography>
                    </Box>
                  </Box>

                  <Box style={webStyle.displayFlex}>
                    <Typography style={webStyle.reviewNameTitleStyles}>Mother's Last Name (Maiden):</Typography>
                    <Box style = {{overflow: "hidden" , textOverflow: "ellipsis", wordBreak: "break-word", overflowWrap: "break-word" , width : '45%'}}>
                    <Typography style={webStyle.reviewSubtitleStyles}>{state.fourthStepData.motherLastName}</Typography>
                    </Box>
                  </Box>

                  <Box style={webStyle.displayFlex}>
                    <Typography style={webStyle.reviewNameTitleStyles}>Mother's Occupation:</Typography>
                    <Box style = {{overflow: "hidden" , textOverflow: "ellipsis", wordBreak: "break-word", overflowWrap: "break-word" , width : '45%'}}>
                    <Typography style={webStyle.reviewSubtitleStyles}>{state.fourthStepData.motherOccupation}</Typography>
                    </Box>
                  </Box>
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6}>
                  <Box style={webStyle.displayFlex}>
                    <Typography style={webStyle.reviewNameTitleStyles}>Mother's Mobile Number:</Typography>
                    <Box style = {{overflow: "hidden" , textOverflow: "ellipsis", wordBreak: "break-word", overflowWrap: "break-word" , width : '45%'}}>        
                    <Typography style={webStyle.reviewSubtitleStyles}>{"+" + state.fourthStepData.motherMobileNumberCode + state.fourthStepData.motherMobileNumber}</Typography> 
                    </Box>
                  </Box>

                  <Box style={webStyle.displayFlex}>
                    <Typography style={webStyle.reviewNameTitleStyles}>Mother's Email Address:</Typography>
                    <Box style = {{overflow: "hidden" , textOverflow: "ellipsis", wordBreak: "break-word", overflowWrap: "break-word" , width : '45%'}}>
                    <Typography style={webStyle.reviewSubtitleStyles}>{state.fourthStepData.motherEmail}</Typography>
                    </Box>
                  </Box>

                  <Box style={webStyle.displayFlex}>
                    <Typography style={webStyle.reviewNameTitleStyles}>Marital Status:</Typography>
                    <Box style = {{overflow: "hidden" , textOverflow: "ellipsis", wordBreak: "break-word", overflowWrap: "break-word" , width : '45%'}}>
                    <Typography style={webStyle.reviewSubtitleStyles}>{state.fourthStepData.maritalStatus}</Typography>
                    </Box>
                  </Box>

                  <Box style={webStyle.displayFlex}>
                    <Typography style={webStyle.reviewNameTitleStyles}>Guardian's Name:</Typography>
                    <Box style = {{overflow: "hidden" , textOverflow: "ellipsis", wordBreak: "break-word", overflowWrap: "break-word" , width : '45%'}}>
                    <Typography style={webStyle.reviewSubtitleStyles}>{state.fourthStepData.guardianName}</Typography>
                    </Box>
                  </Box>

                  <Box style={webStyle.displayFlex}>
                    <Typography style={webStyle.reviewNameTitleStyles}>Guardian's Address:</Typography>
                    <Box style = {{overflow: "hidden" , textOverflow: "ellipsis", wordBreak: "break-word", overflowWrap: "break-word" , width : '45%'}}>
                    <Typography style={webStyle.reviewSubtitleStyles}>{state.fourthStepData.guardianAddress}</Typography>
                    </Box>
                  </Box>

                  <Box style={webStyle.displayFlex}>
                    <Typography style={webStyle.reviewNameTitleStyles}>Guardian's Occupation:</Typography>
                    <Box style = {{overflow: "hidden" , textOverflow: "ellipsis", wordBreak: "break-word", overflowWrap: "break-word" , width : '45%'}}>
                    <Typography style={webStyle.reviewSubtitleStyles}>{state.fourthStepData.guardianOccupation}</Typography>
                    </Box>
                  </Box>

                  <Box style={webStyle.displayFlex}>
                    <Typography style={webStyle.reviewNameTitleStyles}>Guardian's Mobile Number:</Typography>
                    <Box style = {{overflow: "hidden" , textOverflow: "ellipsis", wordBreak: "break-word", overflowWrap: "break-word" , width : '45%'}}>
                    <Typography style={webStyle.reviewSubtitleStyles}>{ "+" + state.fourthStepData.guardianMobileNumberCode + state.fourthStepData.guardianMobileNumber}</Typography>
                    </Box>
                  </Box>

                  <Box style={webStyle.displayFlex}>
                    <Typography style={webStyle.reviewNameTitleStyles}>Guardian's Email Address:</Typography>
                    <Box style = {{overflow: "hidden" , textOverflow: "ellipsis", wordBreak: "break-word", overflowWrap: "break-word" , width : '45%'}}>
                    <Typography style={webStyle.reviewSubtitleStyles}>{state.fourthStepData.guardianEmail}</Typography>
                    </Box>
                  </Box>

                  <Box style={webStyle.displayFlex}>
                    <Typography style={webStyle.reviewNameTitleStyles}>Relationship to the student:</Typography>
                    <Box style = {{overflow: "hidden" , textOverflow: "ellipsis", wordBreak: "break-word", overflowWrap: "break-word" , width : '45%'}}>
                    <Typography style={webStyle.reviewSubtitleStyles}>{state.fourthStepData.relationWithStudent}</Typography>
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Paper>
        </Grid>

        <Grid item xs={12} md={6}>
          <InputLabel style={webStyle.inputLabelStyle}>Reason for finally registering *</InputLabel>
          <StyledTextField
            id="reason"
            select
            fullWidth
            name="reason"
            data-test-id='reason-id'
            InputLabelProps={{ shrink: false }}
            value={state.reasonForRegistering.reason?.value}
            onChange={handler.reasonDataChange}
          >
            <MenuItem style={webStyle.StudentMenuItemsStyle} disabled value="choose"> <span style={{ color: "#ABABB0" }}> Please Select </span></MenuItem>
            {
              state.reasonData?.map((item: any, index: number) => (
                <MenuItem
                  data-test-id="menuIDTest"
                  key={item?.id}
                  value={item?.attributes.reason}
                  selected={item.value == state.reasonForRegistering.reason?.value}
                >
                  {item?.attributes.reason}
                </MenuItem>
              ))
            }
          </StyledTextField>
          {reasonForRegistering.reasonErrors.reason &&
            <span style={{ color: 'red', fontSize: "12px", fontFamily: "Montserrat" }}>
              {reasonForRegistering.reasonErrors.reason}
            </span>}
        </Grid>
      </Grid>
    </>
  )
}
//istanbul ignore next
const StepSeven = (props: any) => {
  const { state, handler } = props;
  const {sixStepData} = state
  return (
    <>
      <Typography style={webStyle.headingStyles}>Tuition Fee Terms & Conditions</Typography>
      <Grid container spacing={4} style={{ paddingLeft: '3rem', paddingRight: '3rem' }}>
        <Grid item xs={12}>
           <Box>
              {state.termsAndConditionData
                .sort(handler.sortTermsAndCondition)
                .map((item: any, index: number) => (
                  <div key={index}>
                    <Box
                      onClick={() => handler.toggleTerm(index)}
                      style={{
                        width: '100%',
                        backgroundColor: '#9167ac',
                        height: '34px',
                        borderRadius: '4px',
                        cursor: 'pointer',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        padding: '4px 20px',
                      }}
                    >
                      <Typography style={{ fontSize: '18px', fontWeight: 600, color: 'white' }}>
                        {item.attributes.title}
                      </Typography>
                      <Typography style={{ fontSize: '18px', fontWeight: 600, color: 'white' }}>
                        {state.seventhStepData.expandedTerms[index] ? <ExpandLessOutlinedIcon /> : <ExpandMoreOutlinedIcon/> } 
                      </Typography>
                    </Box>

                  
                    {state.seventhStepData.expandedTerms[index] && (
                      <div dangerouslySetInnerHTML={{ __html: item.attributes.terms }} />
                    )}
                  </div>
                ))
              }
            </Box>
        </Grid>

        <Grid item xs={12} style={{
          display: 'flex',
          flexDirection: 'column'
        }}>
          <FormControlLabel
            control={<Checkbox size='small' name='generalTermsAndConditions' color='default'
              checked={state.generalTermsAndConditions} onChange={handler.handlegeneralTermsAndConditions} />}
              style={{width: 'fit-content'}}
            label="I Agree The General Terms and Conditions"
          />

          <FormControlLabel
            control={<Checkbox size='small' name='refundPolicy' color='default'
              checked={state.refundPolicy} onChange={handler.handleCheckBoxrefundPolicy} />}
              style={{width: 'fit-content'}}
            label="I Agree The Refund Policy"
          />

          <FormControlLabel
            control={<Checkbox size='small' name='privacyPolicy' color='default'
              checked={state.privacyPolicy} onChange={handler.handleCheckBoxprivacyPolicy} />}
              style={{width: 'fit-content'}}
            label="I Agree The Privacy Policy"
          />

          <FormControlLabel
            control={<Checkbox size='small' name='pictureAuthorisation' color='default'
              checked={state.pictureAuthorisation} onChange={handler.handleCheckBoxpictureAuthorisation} />}
              style={{width: 'fit-content'}}
            label="I Agree The Privacy Policy - Picture Authorisation"
          />
          {sixStepData.error &&
            <span style={{ color: 'red', fontSize: "12px", fontFamily: "Montserrat" }}>
              All agreements are required. Please select all terms to proceed.
            </span>}

        </Grid>
      </Grid>


    </>
  )
}

function getStepContent(stepIndex: number, state: any, handlers: any) {

  switch (stepIndex) {
    case 0:
      return (
        <StepOne state={state} handler={handlers} />
      )
    case 1:
      return (
        <StepTwo state={state} handler={handlers} />
      )
    case 2:
      return (
        <StepThree state={state} handler={handlers} />
      )
    case 3:
      return (
        <StepFour state={state} handler={handlers} />
      );
    case 4:
      return (
        <StepFive state={state} handler={handlers} />
      );
    case 5:
      return (
        <StepSix state={state} handler={handlers} />
      );
    case 6:
      return (
        <StepSeven state={state} handler={handlers} />
      )
    default:
      return 'Unknown stepIndex';
  }
}
export default class StudentEnrollmentFormStepper extends CustomEnrollController {
  constructor(props: Props) {
    super(props);
  }
  handleTextNext = () => {
      if (this.state.loadingNext) {
       return <CircularProgress />
    }
  
    if (this.state.activeStep >= 6) {
       return 'Submit'
    }
      return 'Next';
  }
  handleTextDraft = () => {
     return this.state.isLoading ? <CircularProgress /> : "Save to Draft"
  }
  handletextModal = () => {
    return this.state.loadingNext ? <CircularProgress /> : "Yes"
  }
  render() {
    const steps = this.getSteps();
          
    //istanbul ignore next
    return (

      <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
        <AppWithoutDrawer navigation={this.props.navigation} />
        <Sidebarmenu activeKey="enrollment" />
        <ThemeProvider theme={StepperTheme}>
        <Container maxWidth={false}>
                  
                  <Grid container style={{display: 'block'}}>
                      <Grid item>
                          <Container maxWidth={false} >
                  <Container maxWidth={false} style={{ margin: '0 auto', width: "100%" }}>

                    <Box style={{ marginTop: '80px', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                      <Typography style={webStyle.welcomeStudentTextStyle} >
                        Student Enrollment</Typography>
                      <span style={{ width: '50%', display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}>
                        {this.state.loadingPendingStudent && localStorage.getItem('studentID') ? <CircularProgress /> : null}
                      </span>

                    </Box>
                    <Grid container style={{ display: 'flex' }}>
                      <HorizontalLine />
                    </Grid>
                      <Grid item xs={12} style={{ paddingTop: '20px', padding: '0' }}>
                        <Stepper activeStep={this.state.activeStep} alternativeLabel>
                          {this.getSteps().map((label: any) => (
                            <Step key={label} style={{
                              display: "flex",
                              flexDirection: "column",
                              alignItems: "center",
                              fontFamily: "Montserrat"
                            }}>
                              <StepLabel StepIconComponent={customStepIcon} style={{ flexDirection: "column-reverse" }}><Typography style={{fontFamily: "Montserrat"}}>{label}</Typography></StepLabel>
                            </Step>
                          ))}
                        </Stepper>
                      </Grid>
                    <Box maxWidth={'100%'} style={webStyle.mainWrapperStepContent}>

                      {
                        this.state.open === true ? (

                          <Grid container style={{ paddingLeft: '1em', marginTop: '2em', }}   >
                            <Modal
                              open={this.state.open}
                              onClose={this.handleModelCloseOnCross}
                            >
                              <Box style={webStyle.modalStyle}>
                                <button style={webStyle.cancelButton as React.CSSProperties} autoFocus onClick={this.handleModelCloseOnCross}>
                                  <CloseOutlinedIcon fontSize="small" />
                                </button>
                                <Box style={{ marginTop: '20px', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                  <Typography style={webStyle.modalTextTitle as React.CSSProperties}>
                                    Your Student Enrollment Form</Typography>
                                  <Typography style={webStyle.modalTextTitle as React.CSSProperties}>
                                    has been submitted.</Typography>
                                </Box>
                                <Box>
                                  <Box style={{ marginTop: '20px' }}>
                                    <InputLabel style={webStyle.inputLabelStyle1 as React.CSSProperties}>    Would you like to enroll another student? </InputLabel>

                                  </Box>
                                </Box>
                               
                                <Grid container spacing={4} style={{ textAlign: 'center' }}>
                                <Grid item xs={12} style={{ marginBottom: '20px', textAlign: "center" }}>
                                  <div style={{ marginTop: '1rem' }}>
                                   

                                    <Button data-test-id="btnNext" variant="contained"
                                      type="submit"
                                      style={{
                                        width: "200px",
                                        height: "52px",
                                        fontFamily: "Montserrat",
                                        fontSize: "16px",
                                        fontWeight: 600,
                                        color: "#fafafb",
                                        textAlign: "center",
                                        backgroundColor: "#fab03d",
                                        borderRadius: "10px",
                                        textTransform: "none",
                                       
                                      }}
                                      disabled={this.state.loadingNext ? true : false}
                                      onClick={this.YesBtn}
                                     
                                    >
                                      {
                                       this.handletextModal()
                                      }

                                    </Button>

                                    <Button variant="contained" style={{
                                      ... webStyle.savetodraftStyles2,
                                      textAlign: "center",
                                      textTransform: "none",
                                      backgroundColor: "#9167ac",

                                    }}
                                      data-test-id="draftBtn"
                                      onClick={this.handleModelClose}
                                    >
                                      {"No"}
                                    </Button>
                                  </div>
                                </Grid>
                              </Grid>
                               
                              </Box>
                            </Modal>
                          </Grid>


                        ) :
                          (

                            <>
                             

                              <Grid container spacing={4}>
                                
                              <Typography style={{
                                  color: '#ffffff',
                                  fontSize: '18px',
                                  fontWeight: 600,
                                  paddingBottom: '5px',
                                  textAlign: 'center',
                                }}>Your</Typography>
                              </Grid>
                            </>

                          )
                      }

                      {
                        this.state.activeStep === 7 ? (

                          finalStep(this.state.activeStep, this.handleBackMain)


                        ) :
                          (

                            <>
                              { this.state.loader ?
                               <Box style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}> <CircularProgress /></Box>
                              : getStepContent(this.state.activeStep, this.state, this.handlers)}
                              
                              <Grid container spacing={4}>
                                <Grid item xs={12} style={{ marginBottom: '3em', paddingRight: '5em', textAlign: "right" }}>
                                  <div style={{ marginTop: '2rem' }}>
                                    <Button variant="contained" style={{
                                      ...this.state.activeStep === 0 ? webStyle.backButtonStyles : webStyle.backButtonStyles2,
                                    }}
                                      data-test-id="backBtn"
                                      onClick={this.handleBackMain}
                                    >
                                      Back
                                    </Button>


                                    <Button variant="contained" style={{
                                      ...this.state.activeStep > 5 ? webStyle.savetodraftStyles : webStyle.savetodraftStyles2,
                                      textAlign: "center",
                                      textTransform: "none",
                                    }}
                                      data-test-id="draftBtn"
                                      onClick={this.onDraft}
                                    >
                                      {this.handleTextDraft()}
                                    </Button>
                                    <Snackbar
                                      anchorOrigin={{
                                        vertical: 'top',
                                        horizontal: 'center',
                                      }}
                                      open={this.state.snackbarOpen}
                                      autoHideDuration={2000}
                                      onClose={this.handleCloseSnackbar}
                                    >
                                      <Alert severity="success">
                                        {this.state.snackbarMessage}
                                      </Alert>

                                    </Snackbar>

                                    <Button data-test-id="btnNext" variant="contained"
                                      type="submit"
                                      disabled={this.state.loadingNext ? true : false}
                                      style={{
                                        width: "200px",
                                        height: "52px",
                                        fontFamily: "Montserrat",
                                        fontSize: "16px",
                                        fontWeight: 600,
                                        color: "#fafafb",
                                        textAlign: "center",
                                        backgroundColor: "#fab03d",
                                        borderRadius: "10px", marginLeft: '1em',
                                        textTransform: "none",
                                        ...this.state.activeStep === 7 && { display: 'none' }
                                      }}

                                      onClick={this.handleNextBtn}
                                    >
                                      {this.handleTextNext()}
                                    </Button>
                                  </div>
                                </Grid>
                              </Grid>
                            </>

                          )
                      }
                    </Box>



                  </Container>
                </Container>





              </Grid>

            </Grid>
            <Box style={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginTop: "4rem", marginBottom: "4.1rem", paddingRight: "3rem" }}>
              <div>

              </div>
            </Box>
          </Container>

        </ThemeProvider>
      </div>


    );
  }
}


const webStyle = {
  backButtonStyles: {
    width: "200px",
    height: "52px",
    fontFamily: "Montserrat",
    fontSize: "16px",

    fontWeight: 600,

    borderRadius: "10px",
    backgroundColor: "#9167ac",

    display: 'none',
  },
  gridView: {
    paddingRight: '4em',
    marginTop: '-5px'
  },
  backButtonStyles2: {
    width: "200px",
    height: "52px",
    fontFamily: "Montserrat",
    fontSize: "16px",
    color: "#fafafb",
    fontWeight: 600,

    borderRadius: "10px",
    backgroundColor: "#9167ac",
    textTransform: "none",
    display: 'inline'
  },
  savetodraftStyles: {
    width: "200px",
    height: "52px",
    color: "#fafafb",
    fontFamily: "Montserrat",
    fontSize: "16px",
    fontWeight: 600,

    borderRadius: "10px",
    marginLeft: '1em',
    backgroundColor: "#b9b9b9",

    display: 'none'

  },
  savetodraftStyles2: {
    width: "200px",
    height: "52px",
    color: "#fafafb",
    fontFamily: "Montserrat",
    fontSize: "16px",
    fontWeight: 600,

    borderRadius: "10px",
    marginLeft: '1em',
    backgroundColor: "#b9b9b9",

    display: 'inline'
  },

  mainWrapperStepContent: {
    borderRadius: " 50px",
    border: " 1px dashed #f1f1f5",
    background: "#ffffff",
    backgroundBlendMode: "normal",
    boxShadow: "0px 5px 15px rgba(68,68,79,0.1)",
    padding: '0',
    margin: '1em 0em 6em 0em ',

  },

  welcomeStudentTextStyle: {

    color: '#171725',
    fontFamily: "Montserrat",
    fontSize: '24px',
    fontWeight: 600,
  },
  inputAdressForm1: {
    paddingTop: '15px',
    paddingLeft: '20px',
    height: "91px",
    width: '100%',
    backgroundColor: "#e0e0e0",
    fontFamily: "Montserrat",
    backgroundBlendMode: "normal",
    outline: "none", border: "none"
  },
  studentReferralProgramForm1: {
    fontWeight: 400,
    color: "#757575",
    fontFamily: "Montserrat",
    fontSize: "12px",

    marginBottom: '10px'
  },
  StudentMenuItems: {
    color: 'darkgray'
  },
  scholarshipChangeForm1: {
    top: " 66px",
    color: " #757575",
    height: "22px",
    fontSize: "11px",
    fontFamily: "Montserrat",
    fontWeight: 400,
    lineHeight: "22px",
    fontStyle: "italic",
  },
  WouldYouLikeToApplyForaScholarship: {
    fontWeight: 400,
    color: "#757575",
    fontFamily: "Montserrat",
    fontSize: "12px",
    marginBottom: '10px',
  },
  inputLabelStyle: {
    marginBottom: '10px',
    height: '22px',
    color: "#757575",
    fontFamily: "Montserrat",
    fontSize: '12px',
    fontWeight: 400,
    lineHeight: '22px',
  },
  fileLabelStyle: {

    height: '16px',
    color: "#212121",
    fontFamily: "Montserrat",
    fontSize: '13px',
    fontWeight: 550,
    // lineHeight: '22px',
  },
  StudentMenuItemsStyle: {

    opacity: 1,
  },
  headingStyles: {

    //width: " 344px",
    height: " 30px",
    color: " #171725",
    fontFamily: "Montserrat",
    fontSize: "20px",
    fontWeight: 600,
    lineHeight: "30px",
    letterSpacing: "0.08333328px",
    marginTop: "3em",
    marginBottom: "2em",
    paddingLeft: '2.38em'

  },
  reviewNameTitleStyles: {
    fontSize: '10px',
    fontWeight: 600,
    paddingBottom: '5px',
    minWidth: '170px',
    maxWidth: '170px'
  },
  reviewSubtitleStyles: {
    fontSize: '10px',
    color: 'grey',
    paddingBottom: '5px',
    paddingLeft: '30px',
    
  },
  reviewHeadingStyles: {
    fontSize: '16px',
    fontWeight: 600,
    padding: '1rem 0rem 1.5rem 0rem',
  },
  reviewPaperStyles: {
    border: '3px solid #9167ac',
    borderRadius: '10px'
  },
  modalStyle: {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '520px',
    borderRadius: '20px',
    paddingTop: '2px',
    paddingLeft: '4px',
    paddingRight: '4px',
    paddingBottom: '3px',
    backgroundColor: '#ffffff',
  },
  modalTextTitle: {
    color: '#171725',
    fontFamily: "Montserrat",
    fontSize: '16px',
    fontWeight: 600,
    lineHeight: '24px',
    textAlign: "center"

  },
  UpdatePassword: {
    height: "52px",
    marginTop: "40px",
    border: "none",
    borderRadius: "10px",
    backgroundColor: " #fab03d",
    color: '#fafafb',
    fontFamily: "Montserrat",
    fontSize: ' 16px',
    fontWeight: 600,
    padding: '1.5em',
},
inputLabelStyle1:{
  marginBottom: '10px',
    height: '22px',
    color: "#757575",
    fontFamily: "Montserrat",
    fontSize: '12px',
    fontWeight: 400,
    lineHeight: '22px',
    textAlign: "center"
},
cancelButton: {
  position: "absolute",
  top: "-13px",
  background: "rgb(185, 142, 214)",
  color: "white",
  right: "-13px",
  borderRadius: '50%',
  border: 'none',
  cursor: 'pointer',  
  padding: '5px'
},
displayFlex: {
  display: 'flex',
  gap: '110px'
},
headingError: {
  paddingLeft: '3rem',
  paddingBottom: '1rem',
  color: "red"
}

};
// Customizable Area End
