import React, { CSSProperties } from "react";

import {

	// Customizable Area Start
	Container,
	Box,
	Button,
	Typography,
	Grid,
	styled,
	IconButton

	// Customizable Area End
} from "@material-ui/core";

// Customizable Area Start

import { createTheme, ThemeProvider } from '@material-ui/core/styles';
import Avatar from "@material-ui/core/Avatar";
import Checkbox from "@material-ui/core/Checkbox";
import AppWithoutDrawer from "../../../components/src/AppWithoutDrawer.web";
import Sidebarmenu from "../../../components/src/Sidebarmenu.web";
import { imgDownloadIcon } from "../../../blocks/customform3/src/assets";
import CheckBoxOutlineBlank from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckIcon from "@material-ui/icons/Check";
import CheckBoxOutlined from "@material-ui/icons/CheckBoxOutlined";
import HorizontalLine from "../../../components/src/HorizontalLine.web"

const theme = createTheme({

	palette: {
		primary: {
			main: "#nnn",
			contrastText: "#fff",
		},
	},
	typography: {
		h6: {
			fontWeight: 500,
		},
		subtitle1: {
			margin: "20px 0px",
		},
	},
});

const Boxcontainer = styled(Box)({
	marginTop: '4rem', display: 'flex', alignItems: 'center', justifyContent: 'space-between',
	"@media (max-width: 768px)": {

		flexWrap: "wrap",
		flexDirection: "column"
	}

})
const HrizontalBox = styled(Box)({
	width: '100%', height: '3px', backgroundColor: '#fab03d'

})
const Boxcontainerupper = styled(Box)({
	display: "flex", justifyContent: "flex-start", alignItems: "center", fontSize: "14px"

})


const HrizontalBoxtwo = styled(Box)({
	width: '100%', height: '3px', backgroundColor: '#9167ac'

})
const HrizontalBoxthree = styled(Box)({
	width: '100%', height: '3px', backgroundColor: '#99cb51'

})

const downLoadIconStudentsDetails = [
	{
		id: 1,
		Birthcertificate: "Birth certificate",
		FileType: "jpeg",
		SizeFile: "100kb",
		Dicon: imgDownloadIcon
	},
	{
		id: 2,
		Birthcertificate: "Birth certificate",
		FileType: "jpeg",
		SizeFile: "100kb",
		Dicon: imgDownloadIcon
	},
	{
		id: 3,
		Birthcertificate: "Birth certificate",
		FileType: "jpeg",
		SizeFile: "100kb",
		Dicon: imgDownloadIcon
	},
	{
		id: 4,
		Birthcertificate: "Birth certificate",
		FileType: "jpeg",
		SizeFile: "100kb",
		Dicon: imgDownloadIcon
	},
]

const ParentTwocoverAll = styled(Box)({
	paddingLeft: "3rem",
	'@media (max-width: 600px)': {
		paddingInline: "unset",

		// Adjust the padding for smaller screens
	},
});

const BoxGapContainer = styled(Box)({
	display: 'flex',
	gap: '90px',
	"@media (max-width: 1180px)": {
		gap: '20px',
	}
  
  
  })

// Customizable Area End

import Customform3Controller, {
	Props,
	Document,
	DocumentEntry
} from "../../customform3/src/Customform3Controller";

class StudentsDetails extends Customform3Controller {
	popoverAnchor: Element | ((element: Element) => Element) | null | undefined;
	constructor(props: Props) {
		super(props);
		// Customizable Area Start
		// Customizable Area End 
	}

	// Customizable Area Start

	StudentsInformationFunc = (student: any) => {
		let studentData: any = JSON.parse(student)
		console.log("testt12", studentData?.attributes?.first_name)
		return (
			<Grid item xs={12} sm={12} md={6} lg={6} style={{padding: '0 10px'}}>

				<BoxGapContainer>
					<Typography style={webStyle.headings}>Student's First Name:</Typography>
					<Typography style={webStyle.headingVal} >{studentData?.attributes?.first_name}</Typography>
				</BoxGapContainer>

				<BoxGapContainer>
					<Typography style={webStyle.headings}>Student's Middle Name:</Typography>
					<Typography style={webStyle.headingVal} >{studentData?.attributes?.middle_name}</Typography>
				</BoxGapContainer>

				<BoxGapContainer>
					<Typography style={webStyle.headings}>Student's Last Name:</Typography>
					<Typography style={webStyle.headingVal} >{studentData?.attributes?.last_name}</Typography>
				</BoxGapContainer>

				<BoxGapContainer>
					<Typography style={webStyle.headings}>Gender:</Typography>
					<Typography style={webStyle.headingVal} >{studentData?.attributes?.gender}</Typography>
				</BoxGapContainer>

				<BoxGapContainer>
					<Typography style={webStyle.headings}>Date of Birth:</Typography>
					<Typography style={webStyle.headingVal} >{studentData?.attributes?.date_of_birth}</Typography>
				</BoxGapContainer>

				<BoxGapContainer>
					<Typography style={webStyle.headings}>Age:</Typography>
					<Typography style={webStyle.headingVal} >{this.getAgeStudent(studentData?.attributes?.date_of_birth , studentData?.attributes?.submitted_date)}</Typography>
				</BoxGapContainer>

				<BoxGapContainer>
					<Typography style={webStyle.headings}>Place of Birth (Country):</Typography>
					<Typography style={webStyle.headingVal} >{studentData?.attributes?.country_of_birth}</Typography>
				</BoxGapContainer>

				<BoxGapContainer>
					<Typography style={webStyle.headings}>Place of Birth (City):</Typography>
					<Typography style={webStyle.headingVal} >{studentData?.attributes?.city_of_birth}</Typography>
				</BoxGapContainer>

				<BoxGapContainer>
					<Typography style={webStyle.headings}>Religion:</Typography>
					<Typography style={webStyle.headingVal} >{studentData?.attributes?.religion}</Typography>
				</BoxGapContainer>

				<BoxGapContainer>
					<Typography style={webStyle.headings}>Nationality:</Typography>
					<Typography style={webStyle.headingVal} >{studentData?.attributes.nationality ? studentData?.attributes.nationality : "N/A"}</Typography>
				</BoxGapContainer>

				<BoxGapContainer>
					<Typography style={webStyle.headings}>Country of Residence:</Typography>
					<Typography style={webStyle.headingVal} >{studentData?.attributes?.country_of_residence}</Typography>
				</BoxGapContainer>

				<BoxGapContainer>
					<Typography style={webStyle.headings}>City of Residence:</Typography>
					<Typography style={webStyle.headingVal} >{studentData?.attributes?.city_of_residence}</Typography>
				</BoxGapContainer>

				<BoxGapContainer>
					<Typography style={webStyle.headings}>Barangay:</Typography>
					<Typography style={webStyle.headingVal} >{studentData?.attributes.barangay ? studentData?.attributes.barangay : "N/A"}</Typography>
				</BoxGapContainer>

				<BoxGapContainer>
					<Typography style={webStyle.headings}>Child's First Language:</Typography>
					<Typography style={webStyle.headingVal}>{studentData?.attributes?.child_language ? studentData?.attributes?.child_language : "Not Selected"}</Typography>
				</BoxGapContainer>

				<BoxGapContainer>
					<Typography style={webStyle.headings}>Residential Address:</Typography>
					<Typography style={webStyle.headingVal} >{studentData?.attributes.residential_address ? studentData?.attributes.residential_address : "N/A"}</Typography>
				</BoxGapContainer>

				<BoxGapContainer>
					<Typography style={webStyle.headings}>Scholarship:</Typography>
					{studentData?.attributes?.scholarship ? (
						<Typography style={webStyle.headingVal} >Yes</Typography>
					) : (
						<Typography style={webStyle.headingVal}>No</Typography>
					)}
				</BoxGapContainer>

				<BoxGapContainer>
					<Typography style={webStyle.headings}>Scholarship Type:</Typography>
					{studentData?.attributes?.scholarship_category == null || studentData?.attributes?.scholarship_category == "choose" ?
						<Typography style={webStyle.headingVal}>No</Typography> :
						<Typography style={webStyle.headingVal}>{studentData?.attributes?.scholarship_category}</Typography>
					}
				</BoxGapContainer>

				<BoxGapContainer>
					<Typography style={webStyle.headings}>Type of Scholarship:</Typography>
					{studentData?.attributes?.scholarship_type == null || studentData?.attributes?.scholarship_type == "" ?
						<Typography style={webStyle.headingVal}>Not selected</Typography> :
						<Typography style={webStyle.headingVal}>{studentData?.attributes?.scholarship_type}</Typography>
					}
				</BoxGapContainer>

				<BoxGapContainer>
					<Typography style={webStyle.headings}>Advocate / Referral Program:</Typography>
					<Typography style={webStyle.headingVal} >{studentData?.attributes?.referral ? "Yes " : "No"}</Typography>
				</BoxGapContainer>
			</Grid>
		)
	}

	studentMiddle = (school_address: string, last_year_of_school: string, learner_reference_number: string, program: string, hub: string, cycle: string , community : string) => {
		return (
			<>
				<BoxGapContainer>
					<Typography style={webStyle.headings}>Previous School Address:</Typography>
					{school_address == null || school_address == "" ?
						<Typography style={webStyle.headingVal}>N/A</Typography> :
						<Typography style={webStyle.headingVal}>{school_address}</Typography>
					}
				</BoxGapContainer>

				<BoxGapContainer>
					<Typography style={webStyle.headings}>Last School Year:</Typography>
					{last_year_of_school == null || last_year_of_school == "" ?
						<Typography style={webStyle.headingVal}>N/A</Typography> :
						<Typography style={webStyle.headingVal}>{last_year_of_school}</Typography>
					}
				</BoxGapContainer>

				<BoxGapContainer>
					<Typography style={webStyle.headings}>Learner Reference Number:</Typography>
					{learner_reference_number == null || learner_reference_number == "" ?
						<Typography style={webStyle.headingVal}>N/A</Typography> :
						<Typography style={webStyle.headingVal}>{learner_reference_number}</Typography>
					}
				</BoxGapContainer>

				<BoxGapContainer>
					<Typography style={webStyle.headings}>Program:</Typography>
					{program == null || program == "choose" ?
						<Typography style={webStyle.headingVal}>Not selected</Typography> :
						<Typography style={webStyle.headingVal}>{program}</Typography>
					}
				</BoxGapContainer>
				<BoxGapContainer>
					<Typography style={webStyle.headings}>Community:</Typography>
					{community == null || community == "choose" ?
						<Typography style={webStyle.headingVal}>Not selected</Typography> :
						<Typography style={webStyle.headingVal}>{community}</Typography>
					}
				</BoxGapContainer>

				<BoxGapContainer>
					<Typography style={webStyle.headings}>Hub:</Typography>
					{hub == null || hub == "choose" ?
						<Typography style={webStyle.headingVal}>Not selected</Typography> :
						<Typography style={webStyle.headingVal}>{hub}</Typography>
					}
				</BoxGapContainer>

				<BoxGapContainer>
					<Typography style={webStyle.headings}>Cycle:</Typography>
					{cycle == null || cycle == "choose" ?
						<Typography style={webStyle.headingVal}>Not selected</Typography> :
						<Typography style={webStyle.headingVal}>{cycle}</Typography>
					}
				</BoxGapContainer>
			</>
		)
	}

	studentLast = (accreditation: string, grade: string, shs_track: string) => {
		return (
			<>
				<BoxGapContainer>
					<Typography style={webStyle.headings}>Accreditation:</Typography>
					{accreditation == null || accreditation == "choose" ?
						<Typography style={webStyle.headingVal}>Not selected</Typography> :
						<Typography style={webStyle.headingVal}>{accreditation}</Typography>
					}
				</BoxGapContainer>

				<BoxGapContainer>
					<Typography style={webStyle.headings}>Incoming Grade Level:</Typography>
					{grade == null || grade == "choose" ?
						<Typography style={webStyle.headingVal}>Not selected</Typography> :
						<Typography style={webStyle.headingVal}>{grade}</Typography>
					}
				</BoxGapContainer>

				<BoxGapContainer>
					<Typography style={webStyle.headings}>SHS Track:</Typography>
					{shs_track == null || shs_track == "choose" ?
						<Typography style={webStyle.headingVal}>Not selected</Typography> :
						<Typography style={webStyle.headingVal}>{shs_track}</Typography>
					}
				</BoxGapContainer>
			</>
		)
	}

	StudentsInfromationFunctwo = (student: any) => {
		let studentData: any = JSON.parse(student)
		console.log("testt12", studentData)
		return (
			<Grid item xs={12} sm={12} md={6} lg={6} style={{padding: '0 10px'}}>
				<BoxGapContainer>
					<Typography style={webStyle.headings}>Advocate / Referral Code:</Typography>
					<Typography style={webStyle.headingVal}>{studentData?.attributes?.referral_code ? studentData?.attributes?.referral_code : "N/A"}</Typography>
				</BoxGapContainer>

				<BoxGapContainer>
					<Typography style={webStyle.headings}>PWD Discount:</Typography>
					<Typography style={webStyle.headingVal}>{studentData?.attributes?.pwd_discount ? "Yes" : "No"}</Typography>
				</BoxGapContainer>

				<BoxGapContainer>
					<Typography style={webStyle.headings}>PWD ID:</Typography>
					<Typography style={webStyle.headingVal}>{studentData?.attributes?.pwd_id ? studentData.attributes.pwd_id : "N/A"}</Typography>
				</BoxGapContainer>

				<BoxGapContainer>
					<Typography style={webStyle.headings}>Solo Parent Discount:</Typography>
					<Typography style={webStyle.headingVal}>{studentData?.attributes?.solo_parent_discount ? "Yes" : "No"}</Typography>
				</BoxGapContainer>

				<BoxGapContainer>
					<Typography style={webStyle.headings}>Solo Parent ID:</Typography>
					<Typography style={webStyle.headingVal}>{studentData?.attributes?.solo_parent_id ? studentData?.attributes?.solo_parent_id : "N/A"}</Typography>
				</BoxGapContainer>

				<BoxGapContainer>
					<Typography style={webStyle.headings}>Last Grade Level:</Typography>
					{studentData?.attributes?.previous_school?.data?.attributes?.grade == null || studentData?.attributes?.previous_school?.data?.attributes?.grade == "choose" ?
						<Typography style={webStyle.headingVal}>Not selected</Typography> :
						<Typography style={webStyle.headingVal}>{studentData?.attributes?.previous_school?.data?.attributes?.grade}</Typography>
					}
				</BoxGapContainer>

				<BoxGapContainer>
					<Typography style={webStyle.headings}>Previous School Attended:</Typography>
					{studentData?.attributes?.previous_school?.data?.attributes?.school_attended == null || studentData?.attributes?.previous_school?.data?.attributes?.school_attended == "" ?
						<Typography style={webStyle.headingVal}>N/A</Typography> :
						<Typography style={webStyle.headingVal}>{studentData?.attributes?.previous_school?.data?.attributes?.school_attended}</Typography>
					}
				</BoxGapContainer>

				<BoxGapContainer>
					<Typography style={webStyle.headings}>Previous School Curriculum:</Typography>
					<Typography style={webStyle.headingVal}>{studentData?.attributes?.previous_school?.data?.attributes?.school_curriculum ? studentData?.attributes?.previous_school?.data?.attributes?.school_curriculum : "Not selected"}</Typography>
				</BoxGapContainer>

				<BoxGapContainer>
					<Typography style={webStyle.headings}>Previous School Email Address:</Typography>
					{studentData?.attributes?.previous_school?.data?.attributes?.school_email == null || studentData?.attributes?.previous_school?.data?.attributes?.school_email == "" ?
						<Typography style={webStyle.headingVal}>N/A</Typography> :
						<Typography style={webStyle.headingVal}>{studentData?.attributes?.previous_school?.data?.attributes?.school_email}</Typography>
					}
				</BoxGapContainer>

				<BoxGapContainer>
					<Typography style={webStyle.headings}>Previous School Contact Number:</Typography>
					{studentData?.attributes?.previous_school?.data?.attributes?.school_contact_number == null || studentData?.attributes?.previous_school?.data?.attributes?.school_contact_number == "" ?
						<Typography style={webStyle.headingVal}>N/A</Typography> :
						<Typography style={webStyle.headingVal}>{studentData?.attributes?.previous_school?.data?.attributes?.school_contact_number}</Typography>
					}
				</BoxGapContainer>

				{this.studentMiddle(studentData?.attributes?.previous_school?.data?.attributes?.school_address,
					studentData?.attributes?.previous_school?.data?.attributes?.last_year_of_school,
					studentData?.attributes?.previous_school?.data?.attributes?.learner_reference_number,
					studentData?.attributes?.program_detail?.data?.attributes?.program,
					studentData?.attributes?.program_detail?.data?.attributes?.hub,
					studentData?.attributes?.program_detail?.data?.attributes?.cycle,
					studentData?.attributes?.program_detail?.data?.attributes?.community
				)}

				{this.studentLast(studentData?.attributes?.program_detail?.data?.attributes?.accreditation,
					studentData?.attributes?.program_detail?.data?.attributes?.grade,
					studentData?.attributes?.program_detail?.data?.attributes?.shs_track
				)}
			</Grid>
		)
	}

	StudentsDetailsFuncChild = (student: any) => {
		let studentData = student
		console.log("testt12", studentData)
		return (
			<>
				<BoxGapContainer>
					<Typography style={webStyle.headings}>Father's Email Address:</Typography>
					{studentData?.attributes?.parent?.data?.attributes?.father_email == "" || studentData?.attributes?.parent?.data?.attributes?.father_email == null ?
						<Typography style={webStyle.headingVal} >Not selected</Typography>
						: <Typography style={webStyle.headingVal} >{studentData?.attributes?.parent?.data?.attributes?.father_email}</Typography>
					}
				</BoxGapContainer>

				<BoxGapContainer>
					<Typography style={webStyle.headings}>Mother's First Name (Maiden):</Typography>
					{studentData?.attributes?.parent?.data?.attributes?.mother_first_name == "" || studentData?.attributes?.parent?.data?.attributes?.mother_first_name == null ?
						<Typography style={webStyle.headingVal} >Not selected</Typography>
						: <Typography style={webStyle.headingVal} >{studentData?.attributes?.parent?.data?.attributes?.mother_first_name}</Typography>
					}
				</BoxGapContainer>

				<BoxGapContainer>
					<Typography style={webStyle.headings}>Mother's Middle Name (Maiden):</Typography>
					{studentData?.attributes?.parent?.data?.attributes?.mother_middle_name == "" || studentData?.attributes?.parent?.data?.attributes?.mother_middle_name == null ?
						<Typography style={webStyle.headingVal} >Not selected</Typography>
						: <Typography style={webStyle.headingVal} >{studentData?.attributes?.parent?.data?.attributes?.mother_middle_name}</Typography>
					}
				</BoxGapContainer>

				<BoxGapContainer>
					<Typography style={webStyle.headings}>Mother's Last Name (Maiden):</Typography>
					{studentData?.attributes?.parent?.data?.attributes?.mother_last_name == "" || studentData?.attributes?.parent?.data?.attributes?.mother_last_name == null ?
						<Typography style={webStyle.headingVal} >Not selected</Typography>
						: <Typography style={webStyle.headingVal} >{studentData?.attributes?.parent?.data?.attributes?.mother_last_name}</Typography>
					}
				</BoxGapContainer>

				<BoxGapContainer>
					<Typography style={webStyle.headings}>Mother's Occupation:</Typography>
					{studentData?.attributes?.parent?.data?.attributes?.mother_occupation == "" || studentData?.attributes?.parent?.data?.attributes?.mother_occupation == null ?
						<Typography style={webStyle.headingVal} >Not selected</Typography>
						: <Typography style={webStyle.headingVal} >{studentData?.attributes?.parent?.data?.attributes?.mother_occupation}</Typography>
					}
				</BoxGapContainer>
			</>
		)
	}

	familyDetailsLast = (marital_status: string, guardian_name: string, guardian_address: string, guardian_occupation: string, guardian_mobile_number: string, guardian_email: string, relationship_to_the_student: string) => {
		return (
			<>
				<BoxGapContainer>
					<Typography style={webStyle.headings}>Marital Status:</Typography>
					{marital_status == "" || marital_status == "choose" ?
						<Typography style={webStyle.headingVal} >Not selected</Typography>
						: <Typography style={webStyle.headingVal} >{marital_status}</Typography>
					}
				</BoxGapContainer>

				<BoxGapContainer>
					<Typography style={webStyle.headings}>Guardian's Name:</Typography>
					{guardian_name == "" || guardian_name == null ?
						<Typography style={webStyle.headingVal} >Not selected</Typography>
						: <Typography style={webStyle.headingVal} >{guardian_name}</Typography>
					}
				</BoxGapContainer>

				<BoxGapContainer>
					<Typography style={webStyle.headings}>Guardian's Address:</Typography>
					{guardian_address == "" || guardian_address == null ?
						<Typography style={webStyle.headingVal} >Not selected</Typography>
						: <Typography style={webStyle.headingVal} >{guardian_address}</Typography>
					}
				</BoxGapContainer>

				<BoxGapContainer>
					<Typography style={webStyle.headings}>Guardian's Occupation:</Typography>
					{guardian_occupation == "" || guardian_occupation == null ?
						<Typography style={webStyle.headingVal} >Not selected</Typography>
						: <Typography style={webStyle.headingVal} >{guardian_occupation}</Typography>
					}
				</BoxGapContainer>

				<BoxGapContainer>
					<Typography style={webStyle.headings}>Guardian's Mobile Number:</Typography>
					{guardian_mobile_number == "" || guardian_mobile_number == null ?
						<Typography style={webStyle.headingVal} >Not selected</Typography>
						: <Typography style={webStyle.headingVal} >{guardian_mobile_number}</Typography>
					}
				</BoxGapContainer>

				<BoxGapContainer>
					<Typography style={webStyle.headings}>Guardian's Email Address:</Typography>
					{guardian_email == "" || guardian_email == null ?
						<Typography style={webStyle.headingVal} >Not selected</Typography>
						: <Typography style={webStyle.headingVal} >{guardian_email}</Typography>
					}
				</BoxGapContainer>

				<BoxGapContainer>
					<Typography style={webStyle.headings}>Relationship to the student:</Typography>
					{relationship_to_the_student == "" || relationship_to_the_student == null ?
						<Typography style={webStyle.headingVal} >Not selected</Typography>
						: <Typography style={webStyle.headingVal} >{relationship_to_the_student}</Typography>
					}
				</BoxGapContainer>
			</>
		)
	}

	StudentsDetailsFunc = (student: any) => {
		let studentData: any = JSON.parse(student)
		console.log("testt12", studentData)

		return (
			<Grid container style={{ padding: '16px 0', display: 'flex', margin: '0 -10px' }}>
				<Grid item xs={12} sm={12} md={6} lg={6} style={{padding: '0 10px'}}>
					<BoxGapContainer>
						<Typography style={webStyle.headings}>Father's First Name:</Typography>
						{studentData?.attributes?.parent?.data?.attributes?.father_first_name == "" || studentData?.attributes?.parent?.data?.attributes?.father_first_name == null ?
							<Typography style={webStyle.headingVal} >Not selected</Typography>
							: <Typography style={webStyle.headingVal} >{studentData?.attributes?.parent?.data?.attributes?.father_first_name}</Typography>
						}
					</BoxGapContainer>

					<BoxGapContainer>
						<Typography style={webStyle.headings}>Father's Middle Name:</Typography>
						{studentData?.attributes?.parent?.data?.attributes?.father_first_name == "" || studentData?.attributes?.parent?.data?.attributes?.father_first_name == null ?
							<Typography style={webStyle.headingVal} >Not selected</Typography>
							: <Typography style={webStyle.headingVal} >{studentData?.attributes?.parent?.data?.attributes?.father_middle_name}</Typography>
						}
					</BoxGapContainer>

					<BoxGapContainer>
						<Typography style={webStyle.headings}>Father's Last Name:</Typography>
						{studentData?.attributes?.parent?.data?.attributes?.father_last_name == "" || studentData?.attributes?.parent?.data?.attributes?.father_last_name == null ?
							<Typography style={webStyle.headingVal} >Not selected</Typography>
							: <Typography style={webStyle.headingVal} >{studentData?.attributes?.parent?.data?.attributes?.father_last_name}</Typography>
						}
					</BoxGapContainer>

					<BoxGapContainer>
						<Typography style={webStyle.headings}>Father's Occupation:</Typography>
						{studentData?.attributes?.parent?.data?.attributes?.father_occupation == "" || studentData?.attributes?.parent?.data?.attributes?.father_occupation == null ?
							<Typography style={webStyle.headingVal} >Not selected</Typography>
							: <Typography style={webStyle.headingVal} >{studentData?.attributes?.parent?.data?.attributes?.father_occupation}</Typography>
						}
					</BoxGapContainer>

					<BoxGapContainer>
						<Typography style={webStyle.headings}>Father's Mobile Number:</Typography>
						{studentData?.attributes?.parent?.data?.attributes?.father_mobile_number == "" || studentData?.attributes?.parent?.data?.attributes?.father_mobile_number == null ?
							<Typography style={webStyle.headingVal} >Not selected</Typography>
							: <Typography style={webStyle.headingVal} >{"+" + studentData?.attributes?.parent?.data?.attributes?.father_country_code + studentData?.attributes?.parent?.data?.attributes?.father_mobile_number}</Typography>
						}
					</BoxGapContainer>
					{this.StudentsDetailsFuncChild(studentData)}
				</Grid>
				<Grid item xs={12} sm={12} md={6} lg={6} style={{padding: '0 10px'}}>
					<BoxGapContainer>
						<Typography style={webStyle.headings}>Mother's Mobile Number:</Typography>
						{studentData?.attributes?.parent?.data?.attributes?.mother_mobile_number == "" || studentData?.attributes?.parent?.data?.attributes?.mother_mobile_number == null ?
							<Typography style={webStyle.headingVal} >Not selected</Typography>
							: <Typography style={webStyle.headingVal} >{"+" + studentData?.attributes?.parent?.data?.attributes?.mother_country_code + studentData?.attributes?.parent?.data?.attributes?.mother_mobile_number}</Typography>
						}
					</BoxGapContainer>

					<BoxGapContainer>
						<Typography style={webStyle.headings}>Mother's Email Address:</Typography>
						{studentData?.attributes?.parent?.data?.attributes?.mother_email == "" || studentData?.attributes?.parent?.data?.attributes?.mother_email == null ?
							<Typography style={webStyle.headingVal} >Not selected</Typography>
							: <Typography style={webStyle.headingVal} >{studentData?.attributes?.parent?.data?.attributes?.mother_email}</Typography>
						}
					</BoxGapContainer>

					{this.familyDetailsLast(studentData?.attributes?.parent?.data?.attributes?.marital_status,
						studentData?.attributes?.parent?.data?.attributes?.guardian_name,
						studentData?.attributes?.parent?.data?.attributes?.guardian_address,
						studentData?.attributes?.parent?.data?.attributes?.guardian_occupation,
						"+" + studentData?.attributes?.parent?.data?.attributes.guardian_country_code + studentData?.attributes?.parent?.data?.attributes?.guardian_mobile_number,
						studentData?.attributes?.parent?.data?.attributes?.guardian_email,
						studentData?.attributes?.parent?.data?.attributes?.relationship_to_the_student
					)}
				</Grid>
			</Grid>
		)
	}

	studentsFuncTwo = (student: any) => {
		let studentData: any = JSON.parse(student)
		console.log("testt12", studentData)
		return (
			<div>
				<Grid container style={{ display: "flex", paddingTop: '1em' , flexWrap : "wrap" }}>
					<Grid item xs={12} lg={4} style={{ display: "flex"}} >
						<div style={{ display: 'flex', justifyContent: "flex-start", alignItems: "center" }}>
							<Avatar alt="student avatar" src={studentData?.attributes.id_picture?.url} style={webStyle.cardAvatarStyles} />
							{/* <Avatar alt="student avatar" src={avatar} style={webStyle.cardAvatarStyle} /> */}

							<Typography style={webStyle.studentDetailsHeadingsVal} >{studentData?.attributes?.first_name}</Typography>
						</div>

					</Grid>

					<Grid item xs={12} lg={4} style={webStyle.studentDetailsHeadings} >
						<Boxcontainerupper>
							<div>
								<span style={webStyle.studentDetailsHeadingsVal}>
									Grade Level:
								</span>
							</div>

							<div>
								<Typography style={webStyle.headingValMain}>
									{studentData?.attributes?.program_detail?.data?.attributes?.grade == null || studentData?.attributes?.program_detail?.data?.attributes?.grade == "choose" ?
										<Typography style={webStyle.headingValMain}>Not selected</Typography> :
										<Typography style={webStyle.headingValMain}>{studentData?.attributes?.program_detail?.data?.attributes?.grade}</Typography>
									}
								</Typography>
							</div>
						</Boxcontainerupper>


					</Grid>
					<Grid item xs={12} lg={4} style={webStyle.studentDetailsHeadings}>
						<Boxcontainerupper>
							<div>
								<span style={webStyle.studentDetailsHeadingsVal}>Program Selected:
								</span>
							</div>
							<div>
								<Typography style={webStyle.headingValMain}>
									{studentData?.attributes?.program_detail?.data?.attributes?.program == null || studentData?.attributes?.program_detail?.data?.attributes?.program == "choose" ?
										<Typography style={webStyle.headingValMain}>Not selected</Typography> :
										<Typography style={webStyle.headingValMain}>{studentData?.attributes?.program_detail?.data?.attributes?.program}</Typography>
									}
								</Typography>
							</div>
						</Boxcontainerupper>
					</Grid>

				</Grid>
				<hr style={{ border: "1px solid #ececf1" }}></hr>
				<Grid container style={{ display: "flex", flexWrap : "wrap"}}>
					<Grid item xs={12} lg={4} style={webStyle.studentDetailsHeadings} >
						<Boxcontainerupper>
							<div>
								<span style={webStyle.studentDetailsHeadingsVal}>Submitted on:</span>
							</div>
							<div>
								<Typography style={webStyle.headingValMain} >{this.formatSchoolYearDate(studentData?.attributes?.form_submit)}</Typography>
							</div>
						</Boxcontainerupper>
					</Grid>
					<Grid item xs={12} lg={4} style={webStyle.studentDetailsHeadings}>
						<span style={webStyle.studentDetailsHeadingsVal}>Student Advisor:</span>
						<div>
							<Typography style={webStyle.headingValMain}>Not selected</Typography>
						</div>
					</Grid>
					<Grid item xs={12} lg={4} >
						<Button
							variant='contained'
							style={{
								minWidth: '245px',
								height: '52px',
								borderRadius: '10px',
								border: "none",
								backgroundColor: "#ebb434",
								color: '#fafafb',
								fontFamily: "Montserrat",
								fontSize: ' 14px',
								fontWeight: 600,
								backgroundBlendMode: 'normal',
								textTransform: 'capitalize'

							}}>
							Submitted For Review</Button>
					</Grid>

				</Grid>
				<hr style={{ border: "1px solid #ececf1" }}></hr>

			</div>
		)
	}

	returnKey = (item: DocumentEntry) => {
		return (Object.keys(item).find(k => k !== 'label'))
	}

	returnUrl = (key: string | undefined, get_studentDetailsData: DocumentEntry) => {
		return (key && get_studentDetailsData[key] && typeof get_studentDetailsData[key] === 'object' ? (get_studentDetailsData[key] as Document).url : '')
	}

	returnContent = (key: string | undefined, get_studentDetailsData: DocumentEntry) => {
		return (key && get_studentDetailsData[key] && typeof get_studentDetailsData[key] === 'object' ? (get_studentDetailsData[key] as Document).content_type : '')
	}

	returnSize = (key: string | undefined, get_studentDetailsData: DocumentEntry) => {
		return (key && get_studentDetailsData[key] && typeof get_studentDetailsData[key] === 'object' ? (get_studentDetailsData[key] as Document).file_size : '')
	}

	// Customizable Area End

	render() {

		return (
			// Customizable Area Start

			<>
				<div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
					<AppWithoutDrawer navigation={this.props.navigation} />
					<Sidebarmenu activeKey="Students" />

					<ThemeProvider theme={theme}>
						<Container maxWidth={false}>

							<Grid container style={{ display: 'block' }}>
								<Grid item>
									<Container maxWidth={false} >

										<Container maxWidth={false} style={{ margin: '0 auto', width: "100%" }}>

											<Box style={{ marginTop: '76px', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
												<Typography style={webStyle.welcomeTextStyles} >Students</Typography>
												<Button style={{ textTransform: 'none', ...webStyle.ProceedCheckout }} onClick={this.NavigateCheckout}>
													Proceed To Checkout
												</Button>
											</Box>
											<Grid container style={{ display: 'flex' }}>
												<HorizontalLine />
											</Grid>
											<Grid item xs={12} style={{ marginTop: '17px', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
												<Typography align='left' style={webStyle.mainSubHeading}>Student Details</Typography>
											</Grid>

											<Container maxWidth={false} style={{ padding: '0' }}>
												<Container maxWidth={false} style={webStyle.GridWrappers}>
													<Container maxWidth={false} style={webStyle.GridWrappers1}>
														{this.studentsFuncTwo(localStorage.getItem("getStudentsViewId"))}
														<Typography style={webStyle.mainheadings} >Student Information</Typography>
														<Grid container style={{ padding: '16px 0', display: 'flex', margin : '0 -10px' }}>
															{this.StudentsInformationFunc(localStorage.getItem("getStudentsViewId"))}
															{this.StudentsInfromationFunctwo(localStorage.getItem("getStudentsViewId"))}
														</Grid>
														<hr style={{ border: "1px solid #ececf1", margin: "20px 0 0" }}></hr>
														<Typography style={webStyle.subheadings} >Family Details</Typography>
														<div>
															{this.StudentsDetailsFunc(localStorage.getItem("getStudentsViewId"))}
														</div>
														<Box style={{
															justifyContent: 'space-between', marginTop: '1.5em', display: 'flex',
														}}>
															<Typography
																style={{
																	fontWeight: 600,
																	color: "#171725",
																	fontSize: "16px"
																}}>Files</Typography>
														</Box>
														<Box>
															<hr style={{ border: "1px solid #ececf1", margin: "0.5em 0 0" }}></hr>
														</Box>

														<Box style={{ padding: '1.5em 0 0' }}>
															<Container maxWidth={false} style={webStyle.fileNames}>
																<Grid container style={{ display: 'block' }}>
																	<Box style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
																		<Grid style={{ width: "45%", display: "flex", alignItems: "center" }}>
																			<Box style={{ color: "white" }}>
																			</Box>
																			<Box style={{ ...webStyle.namesStyless }}>File Name</Box>
																		</Grid>
																		<Grid style={{ width: "150px" }}>
																			<Box style={webStyle.namesStyless}>File Type</Box>
																		</Grid>
																		<Grid style={{ width: "100px" }}>
																			<Box style={webStyle.namesStyless}>Sizes</Box>
																		</Grid>
																		<Grid style={{ width: "100px" }}>
																			<Box style={webStyle.namesStyless}>Action</Box>
																		</Grid>
																	</Box>
																</Grid>
															</Container>
														</Box>
														<Box style={{ maxHeight: '45%', overflowY: 'auto', minHeight: '10px' }}>
															{this.state.finalDocumentList.length > 0 ? this.state.finalDocumentList.map((get_studentDetailsData, index) => {
																const key = this.returnKey(get_studentDetailsData)
																const url = this.returnUrl(key, get_studentDetailsData)
																const content_type = this.returnContent(key, get_studentDetailsData)
																const file_size = this.returnSize(key, get_studentDetailsData)
																return (<Box key={index} style={{ padding: '1.5em 0 0' }}>
																	<Container maxWidth={false} style={webStyle.fileNamestwo}>
																		<Grid container style={{ display: 'block' }}>
																			<Box style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
																				<Grid style={{ width: "45%", display: "flex", alignItems: "center" }}>
																					<Box style={{ color: "#000000" }}>
																					</Box>
																					<Box style={webStyle.namesStylesstwo}>{get_studentDetailsData.label}</Box>
																				</Grid>
																				<Grid style={{ overflow: "hidden" , textOverflow: "ellipsis", wordBreak: "break-word", overflowWrap: "break-word" , width : '150px' }}>
																					<Box style={webStyle.namesStylesstwo}>{content_type}</Box>
																				</Grid>
																				<Grid style={{ width: "100px" }}>
																					<Box style={webStyle.namesStylesstwo}>{file_size}</Box>
																				</Grid>
																				<Grid style={{ width: "100px" }}>
																					<div>
																						<img src={imgDownloadIcon} onClick={() => this.downloadDoc(url)} alt="HG_application" style={{ width: "38px", height: '38px', cursor: 'pointer' }} />
																					</div>
																				</Grid>
																			</Box>
																		</Grid>
																	</Container>
																</Box>
																)
															})
																:
																<Box>
																	<Typography style={{ textAlign: 'center', marginTop: '16px' }}>No Files available</Typography>
																</Box>}
														</Box>
														<Box>
															<hr style={{ border: "1px solid #ececf1", margin: "1em 0.5em" }}></hr>
														</Box>
														<Box style={{ padding: '1.5em 0' }}>
															<Button variant='contained' style={{ textTransform: 'none', ...webStyle.Checkout }} onClick={this.NavigateCheckout}>Proceed To Checkout</Button>
														</Box>
													</Container>
												</Container>
											</Container>
										</Container>
									</Container>
								</Grid>
							</Grid>
						</Container>
					</ThemeProvider>
				</div >
			</>
			// Customizable Area End
		);
	}
}

// Customizable Area Start

const webStyle: Record<string, CSSProperties> = {
	ProceedCheckout: {
		fontFamily: "Montserrat",
		fontSize: "16px",
		color: "#fafafb",
		fontWeight: 1000,
		borderRadius: "10px",
		backgroundColor: "#fab03d"
	},
	studenContentWrapper: {
		[theme.breakpoints.down('sm')]: {
			display: 'none',
		},
	},
	studenContentBlock: {
		[theme.breakpoints.down("md")]: {
			marginLeft: "0 !important",
			maxWidth: "calc(100%) !important",// or any value you desire for smaller screens
		},
	},
	studentDetailsHeadingsVal: {
		fontWeight: 600,
		fontFamily: "Montserrat",
		paddingRight: "10px",
		fontSize: "16px",
		color: "#171725"
	},
	tableCol1: {
		fontFamily: "Montserrat",
		fontSize: "16px",
		fontWeight: 600,
		flexGrow: 1,
		maxWidth: "200px",
		overflow: "hidden",
		textOverflow: "ellipsis",
		whiteSpace: "normal",
	},
	tableCol2: {
		fontFamily: "Montserrat",
		fontSize: "14px",
		fontWeight: 500,
		flexGrow: 1,
		textTransform: "capitalize",
		maxWidth: "200px",
		overflow: "hidden",
		textOverflow: "ellipsis",
		whiteSpace: "normal",
	},
	fileNames: {
		borderRadius: "10px",

		padding: "0.6rem 1rem",
		border: "1px solid #e2e2ea",
		backgroundColor: '#9167ac',
	},
	fileNamestwo: {
		borderRadius: "10px",
		// height: '50px',
		padding: "1rem",
		border: "1px solid #e2e2ea",
		// backgroundColor: '#9167ac',
	},
	Checkout: {
		width: '235px',
		height: '52px',
		borderRadius: '10px',
		border: "none",
		backgroundColor: "#fab03d",
		color: '#fafafb',
		fontFamily: "Montserrat",
		fontSize: ' 16px',
		fontWeight: 600,
		backgroundBlendMode: 'normal',
	},
	namesStyless: {
		color: "#ffffff",
		fontFamily: "Montserrat",
		fontSize: "14px",
		fontWeight: 600,
		flexGrow: 1,

	},
	namesStylesstwo: {
		color: "#000000",
		fontFamily: "Montserrat",
		fontSize: "14px",
		fontWeight: 600,
		flexGrow: 1,

	},
	studentDetailsHeadingss: {
		color: " #171725",
		fontFamily: "Montserrat",
		fontSize: " 14px",
		fontWeight: 400,
		marginTop: '8px',
	},
	studentDetailsHeadings: {
		display: "flex",
		alignItems: "center"
	},
	headings: {
		color: "#555555",
		fontFamily: "Montserrat",
		fontSize: "12px",
		fontWeight: 500,
		lineHeight: "22px",
		minWidth: '185px',
    	maxWidth: '185px'
	},
	welcomeTextStyles: {
		//marginTop: '50px',
		color: '#171725',
        fontFamily: "Montserrat",
        fontSize: '24px',
        fontWeight: 600,
	},
	subheadings: {
		color: "#424244",
		fontFamily: "Montserrat",
		fontSize: "16px",
		fontWeight: 600,
		marginTop: '2em',
		// paddingLeft: '24px'
	},
	headingVal: {
		color: "#55555",
		fontFamily: "Montserrat",
		fontSize: "14px",
		fontWeight: 400,
		lineHeight: "22px",
		overflowWrap: 'break-word',
    	inlineSize: 'calc(100% - 190px)' ,
		maxWidth: 'calc(100% - 270px)',
		width: '100%'
	},
	headingValMain: {
		color: "#55555",
		fontFamily: "Montserrat",
		fontSize: "14px",
		fontWeight: 400,
		lineHeight: "27px"
	},
	mainWrappers: {
		display: 'flex',
		flexDirection: 'row' as 'row',
		height: '100%',
		justifyContent: 'center',
		boxShadow: '3px -5px 40px rgba(205,205,212,0.1)',
		padding: "1rem",


	},
	mainheadings: {
		color: "#424244",
		fontFamily: "Montserrat",
		fontSize: "16px",
		fontWeight: 600,
		lineHeight: "30px",
		letterSpacing: "0.075px",
		marginTop: "2em",
		marginBottom: "1em",
		// padding: '0 24px',
	},
	GridWrappers: {
		borderRadius: "15px",
		background: "#ffffff",
		border: "1px solid #f1f1f5",
		padding: "inherit",
		maxWidth: "auto",


		[theme.breakpoints.up('sm')]: {
			padding: "2em !important",
		},
	},
	GridWrappers1: {
		borderRadius: "15px",
		background: "#ffffff",
		border: "1px solid #f1f1f5",
		boxShadow: "rgba(68, 68, 79, 0.1) 0px 5px 15px",
		fontFamily: "Montserrat",
	},
	cardAvatarStyles: {
		width: '42px',
		height: '42px',
		marginRight: '10px'
	},
	EmailOutlinedIcon: {
		position: "relative",
		fontSize: "20px",
		marginLeft: "4px",
		marginTop: "5px",
		color: "#FFF",
		fontWeight: "bold"
	},
	EmailOutlinedIconC: {
		position: "relative",
		fontSize: "20px",
		marginLeft: "4px",
		marginTop: "5px",
		color: "#9167AC",
		fontWeight: "bold"
	},
	EmailOutlinedIconCC: {
		position: "absolute",
		fontSize: "1.9rem",
		color: "#9167AC"

	},
	displayFlex: {
		display: 'flex',
		gap: '90px'
	  },
	mainSubHeading: {
		height: '36px',
		color: '#171725',
		fontFamily: "Montserrat",
		fontSize: '18px',
		fontWeight: 500,
		lineHeight: '36px',
		paddingBottom: '1em'
	},

}
export default StudentsDetails
// Customizable Area End
